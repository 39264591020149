import React, { Component } from 'react';
import { connect } from 'react-redux';
import CustomerFooter from '../../../components/layout/CustomerFooter';
import { withRouter } from '../../../components/Reusable/WithRouter';
import CustomerHeader from '../../../components/layout/CustomerHeader';
import config from '../../../config/emrok.config';
import { Link } from 'react-router-dom';
import OtherService from '../../../services/OtherService';
import Common from '../../../hoc/Common.hoc';
import { format } from 'date-fns';
import moment from 'moment';


class TrackingOrder extends Component {
    constructor(props) {
        super(props)
        this.state = {
            orderId: "",
            customerId: "",
            orderDetails: undefined,
            orderDate: "",
            samplePickedUp: "",
            samplePickedUpDate: "",
            bloodRequestDate: "",
            rtochangeDate: "",
            checkSTATUS :['RTO','rejected']
        }
    }

    componentDidMount = () => {

        const orderDetails = this.props.OrderReducer.individualOrders;
        console.log(orderDetails[0], "order details 123");

        const orderId = this.props.OrderReducer.individualOrders[0].order_id;
        console.log(orderId, "orderid");
        const details = {
            orderId: this.props.OrderReducer.individualOrders[0].order_id
            //  orderId: 9321479403
        }


        const OrderDate = Object.keys(orderDetails[0]).length > 0 ? this.getOrderDateFormat(orderDetails[0].createdAt) : "";
        const bloodRequestDate = Object.keys(orderDetails[0]).length > 0 ? this.getOrderDateFormatInISO(orderDetails[0].collection_date + " " + orderDetails[0].collection_slot) : "";

        this.setState({ orderId: orderId })
        this.setState({ orderDetails: orderDetails[0] })
        this.setState({ orderDate: OrderDate })
        this.setState({ bloodRequestDate: bloodRequestDate })

        console.log(this.state.orderDetails, "orderdetails");

        OtherService.getSRLSamplePickupStatus(details).then(res => {

            if (res && res.data && res.data.success) {

                if (res.data.data.samplestatus) {
                    const allStatus = res.data.data.samplestatus;


                    const filteredItems = allStatus.filter(item => item.response_type === "order_status" && item.response_data.status === "pickedUp");

                    console.log(filteredItems, "filteredItems 123456");

                    console.log(allStatus, "allStatus srls");
                    const samplePickedUp = filteredItems[0].response_data.status == undefined ? "" : filteredItems[0].response_data.status;

                    console.log(samplePickedUp, "samplePickedUp")
                    const samplePickedUpDate = filteredItems[0].createdAt;

                    console.log(samplePickedUpDate, "samplePickedUpDate");
                    const SamplePickedUpFormatDate = samplePickedUpDate ? this.getOrderDateFormat(samplePickedUpDate) : "";
                    this.setState({ samplePickedUp: samplePickedUp })
                    this.setState({ samplePickedUpDate: SamplePickedUpFormatDate })




                }

                console.log(res.data.data.samplestatus, "save response status");
            }


        }).catch(e => {
            console.log("exception in save srl response", e)
        })

        OtherService.getStatusTrack({ order_id: orderId }).then(response => {

            if (response.data.success) {
                console.log(response, "statustrack")

                const changeDate = response.data.data[0].change_date;
                if (changeDate != "" && response.data.data[0].status == "RTO") {
                    this.setState({ rtochangeDate: changeDate })
                }
                // if (changeDate != "" && response.data.data[0].status == "Refund Completed") {
                //     this.setState({ refundchangeDate: changeDate })
                // }
                // console.log(changeDate,"changeDate")
            }
        })
    }

    getOrderDateFormat = (datestring) => {


        const date = new Date(datestring);

        const formattedDate = format(date, "hh:mm a, dd MMMM yyyy");

        return formattedDate;
    }

    checkWithCurrentDate = (givenDate) => {

        console.log(givenDate, "givenDate");

        if (givenDate != undefined) {
            const timestampMatches = givenDate.match(/\d+/);
            if (timestampMatches && timestampMatches.length > 0) {


                // const now = moment().add(1, 'day');

                // // Get the timestamp in milliseconds
                // const timestamp = now.valueOf();

                // // Get the timezone offset in minutes and format it as "+HHmm" or "-HHmm"
                // const timezoneOffsetMinutes = now.utcOffset();
                // const timezoneOffsetHours = Math.floor(Math.abs(timezoneOffsetMinutes) / 60);
                // const timezoneOffsetMinutesFormatted = String(Math.abs(timezoneOffsetMinutes) % 60).padStart(2, '0');
                // const timezoneOffsetSign = timezoneOffsetMinutes >= 0 ? '+' : '-';
                // const timezoneOffsetFormatted = `${timezoneOffsetSign}${timezoneOffsetHours}${timezoneOffsetMinutesFormatted}`;

                // // Construct the formatted date string
                // const formattedDate = `/Date(${timestamp}${timezoneOffsetFormatted})/`;

                // console.log(formattedDate,"formattedDate00");
                // return true;

                const timestamp = parseInt(timestampMatches[0]);

                // Create a moment object from the extracted timestamp
                const givenMoment = moment(timestamp);

                // Get the current date and time
                const now = moment();

                // Compare the current date with the given date
                const isSameOrGreater = now.isSameOrAfter(givenMoment, 'day');

                // Format the dates for display
                const formattedGivenDate = givenMoment.format('D MMM YYYY, h:mm a');
                const formattedCurrentDateTime = now.format('D MMM YYYY, h:mm a');

                // Update the state with the formatted dates
                // setCurrentDateTime(formattedCurrentDateTime);

                console.log('Is current date same or greater:', isSameOrGreater);


                // Get the current date and time
                if (isSameOrGreater) {
                    return true;
                } else {
                    return false;
                }


                // return true;
            }
        } else {
            return false;
        }


    }

    checkWithCurrentDateRequest = (givenDateValue) => {

        console.log(givenDateValue, "givenDate");

        if (givenDateValue != undefined) {
            const givenDateString = givenDateValue;

            // Parse the given date string using moment
            const givenDate = moment(givenDateString, 'hh:mm A, DD MMMM YYYY');

            // Get the current date and time using moment
            const currentDate = moment();

            // Compare the current date with the given date
            const isSameOrGreater = currentDate.isSameOrAfter(givenDate);

            console.log('Is current date same or greater:', isSameOrGreater);


            // Get the current date and time
            if (isSameOrGreater) {
                return true;
            } else {
                return false;
            }


            // return true;

        } else {
            return false;
        }


    }

    getOrderDateFormatInISO = (datestring) => {

        const changeDate = moment(datestring).toDate();
        const parsedDate = moment(changeDate, 'hh:mm A, DD MMMM YYYY');

        // Format the parsed date as ISODate format
        const isoDate = parsedDate.toISOString();

        const date = new Date(isoDate);

        const formattedDate = format(date, "hh:mm a, dd MMMM yyyy");

        return formattedDate;
    }

    handleClickHome = () => {

        this.props.navigate('/customer/order-history');
    }

    render = () => {
        const orderDetailsNew = this.props.OrderReducer.individualOrders[0];
        const customerDetails = orderDetailsNew.customer;

        console.log(orderDetailsNew.awbtrack, "orderDetailsNew");
        return (
            <>
                <section className="order-infrmtn-pt pt-0 order-information-container">
                    <div className="container">
                        <CustomerHeader page="tracking" />

                        <div className="row">
                            <div className="ordr-info-part">
                                <div className='row mt-20'>
                                    <div className='col-6'>
                                        <h2>Track Shipment</h2>
                                    </div>
                                    <div className='col-6'>
                                        <div className="btn-home flotright">
                                            <a href="javascript:void(0);" onClick={this.handleClickHome}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg>
                                                Back
                                            </a>
                                        </div>
                                    </div>

                                </div>

                                <div className="d-flex align-items-center justify-content-between mb-4">
                                    <div className="title">
                                        <div className='ml-20'>
                                            <p>Order Id - {this.state.orderId}</p>
                                            <p>Patient Name - {customerDetails.name}</p>
                                            <p>Patient Mobile No. - {customerDetails.mobileno}</p>
                                            <p>Patient Address - {customerDetails.address.addressLine2!=''?customerDetails.address.addressLine1 +", "+customerDetails.address.addressLine2+ "," + customerDetails.address.town + "," + customerDetails.address.state + "," + customerDetails.address.country + "," + customerDetails.address.pin : customerDetails.address.addressLine1+ "," + customerDetails.address.town + "," + customerDetails.address.state + "," + customerDetails.address.country + "," + customerDetails.address.pin}</p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="col-md-6 track-shipping-ft mt-4">
                                <div className="track-point-pt">
                                    {/* <h5>Track Shipment</h5> */}
                                    <div className="col-md-12 col-lg-12">
                                        <div id="tracking-pre" />
                                        <div id="tracking">
                                            <div className="tracking-list">
                                                {/* <div className={this.state.bloodRequestDate != "" && this.checkWithCurrentDateRequest(this.state.bloodRequestDate) ? "tracking-item current-tracking-item" : "tracking-item pending"}>
                                                 */}

                                                {this.state.checkSTATUS.includes(orderDetailsNew.orderStatus)===false ? <div>
                                                    <div className="tracking-item current-tracking-item">
                                                        <div className="tracking-icon status-current status-intransit">
                                                            <svg
                                                                className="svg-inline--fa fa-circle fa-w-16"
                                                                aria-hidden="true"
                                                                data-prefix="fas"
                                                                data-icon="circle"
                                                                role="img"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 512 512"
                                                                data-fa-i2svg=""
                                                            >
                                                                <path
                                                                    fill="currentColor"
                                                                    d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"
                                                                />
                                                            </svg>
                                                        </div>
                                                        <div className="tracking-content">
                                                            Order Placed<span>{this.state.orderDate}</span>
                                                        </div>
                                                    </div>
                                                    <div className={this.state.samplePickedUp == "" ? "tracking-item" : "tracking-item current-tracking-item"}>
                                                        <div className="tracking-icon status-current status-intransit">
                                                            <svg
                                                                className="svg-inline--fa fa-circle fa-w-16"
                                                                aria-hidden="true"
                                                                data-prefix="fas"
                                                                data-icon="circle"
                                                                role="img"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 512 512"
                                                                data-fa-i2svg=""
                                                            >
                                                                <path
                                                                    fill="currentColor"
                                                                    d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"
                                                                />
                                                            </svg>
                                                        </div>
                                                        <div className="tracking-content">
                                                            Blood Collection Request Date<span>{this.state.bloodRequestDate}</span>
                                                        </div>
                                                    </div>
                                                    <div className={orderDetailsNew.GRN_DATE != null ? "tracking-item current-tracking-item" : "tracking-item pending"}>
                                                        <div className={this.state.samplePickedUp == "" ? "tracking-icon status-intransit" : "tracking-icon status-current status-intransit"}>
                                                            <svg
                                                                className="svg-inline--fa fa-circle fa-w-16"
                                                                aria-hidden="true"
                                                                data-prefix="fas"
                                                                data-icon="circle"
                                                                role="img"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 512 512"
                                                                data-fa-i2svg=""
                                                            >
                                                                <path
                                                                    fill="currentColor"
                                                                    d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"
                                                                />
                                                            </svg>
                                                        </div>
                                                        <div className={this.state.samplePickedUp == "" ? "tracking-content text-muted" : "tracking-content"}>
                                                            Blood Collection Done<span>{this.state.samplePickedUp == "" ? "" : this.state.samplePickedUpDate}</span>
                                                        </div>
                                                    </div>
                                                    <div className={orderDetailsNew.awbtrack != undefined && this.checkWithCurrentDate(orderDetailsNew.awbtrack.ShipmentPickupDate) ? "tracking-item current-tracking-item" : "tracking-item pending"}>
                                                        <div className={orderDetailsNew.GRN_DATE != null ? "tracking-icon status-current status-intransit" : "tracking-icon status-intransit"}>
                                                            <svg
                                                                className="svg-inline--fa fa-circle fa-w-16"
                                                                aria-hidden="true"
                                                                data-prefix="fas"
                                                                data-icon="circle"
                                                                role="img"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 512 512"
                                                                data-fa-i2svg=""
                                                            >
                                                                <path
                                                                    fill="currentColor"
                                                                    d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"
                                                                />
                                                            </svg>
                                                        </div>
                                                        <div className="tracking-content pending">
                                                            Sample received at the Manufacturing site
                                                            <span>{orderDetailsNew.GRN_DATE == null ? "" : moment(orderDetailsNew.GRN_DATE).format('DD MMM YYYY, hh:mm a')}</span>

                                                        </div>
                                                    </div>

                                                    <div className={orderDetailsNew.is_received_by_doctor == true ? "tracking-item current-tracking-item" : "tracking-item pending"}>
                                                        <div className={orderDetailsNew.awbtrack != undefined && this.checkWithCurrentDate(orderDetailsNew.awbtrack.ShipmentPickupDate) ? "tracking-icon status-current status-intransit" : "tracking-icon status-intransit"}>
                                                            <svg
                                                                className="svg-inline--fa fa-circle fa-w-16"
                                                                aria-hidden="true"
                                                                data-prefix="fas"
                                                                data-icon="circle"
                                                                role="img"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 512 512"
                                                                data-fa-i2svg=""
                                                            >
                                                                <path
                                                                    fill="currentColor"
                                                                    d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"
                                                                />
                                                            </svg>
                                                        </div>
                                                        <div className={orderDetailsNew.awbtrack != undefined && this.checkWithCurrentDate(orderDetailsNew.awbtrack.ShipmentPickupDate) ? "tracking-content" : "tracking-content text-muted"}>
                                                            Product ready to dispatch from Manufacturing site
                                                            <span>
                                                                {orderDetailsNew.awbtrack !== undefined && this.checkWithCurrentDate(orderDetailsNew.awbtrack.ShipmentPickupDate) ? (
                                                                    <span>
                                                                        {moment(orderDetailsNew.awbtrack.ShipmentPickupDate).format('DD MMM YYYY, hh:mm a')} (AWB No: <a href={config.bdUrl + orderDetailsNew.awbtrack.AWBNo} target='_blank'>{orderDetailsNew.awbtrack.AWBNo}</a>)
                                                                        <span>Click the AWB No. to track your shipment</span>
                                                                    </span>

                                                                ) : (
                                                                    ""
                                                                )}
                                                            </span>
                                                            {/* <span>{orderDetailsNew.awbtrack != undefined && this.checkWithCurrentDate(orderDetailsNew.awbtrack.ShipmentPickupDate) ? moment(orderDetailsNew.awbtrack.ShipmentPickupDate).format('DD MMM YYYY, hh:mm a') : ""} (AWB No: {orderDetailsNew.awbtrack.AWBNo})</span> */}
                                                            {/* <p>{orderDetailsNew.awbtrack && this.checkWithCurrentDate(orderDetailsNew.awbtrack.ShipmentPickupDate) ? <span>AWBNo: {orderDetailsNew.awbtrack.AWBNo}</span> : ""}</p> */}
                                                        </div>
                                                    </div>
                                                    <div className={orderDetailsNew.is_received_by_doctor == true ? "tracking-item" : "tracking-item pending"}>
                                                        <div className={orderDetailsNew.is_received_by_doctor == true ? "tracking-icon status-current status-intransit" : "tracking-icon status-intransit"}>
                                                            <svg
                                                                className="svg-inline--fa fa-circle fa-w-16"
                                                                aria-hidden="true"
                                                                data-prefix="fas"
                                                                data-icon="circle"
                                                                role="img"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 512 512"
                                                                data-fa-i2svg=""
                                                            >
                                                                <path
                                                                    fill="currentColor"
                                                                    d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"
                                                                />
                                                            </svg>
                                                        </div>
                                                        <div className={orderDetailsNew.is_received_by_doctor == true ? "tracking-content" : "tracking-content text-muted"}>
                                                            Product available with Doctor
                                                            {/* <span>{orderDetailsNew.awbtrack != undefined && this.checkWithCurrentDate(orderDetailsNew.awbtrack.ShipmentPickupDate) ? moment(orderDetailsNew.awbtrack.ShipmentPickupDate).format('DD MMM YYYY, hh:mm a') : ""} (AWB No: {orderDetailsNew.awbtrack.AWBNo})</span> */}
                                                            <span>
                                                                {orderDetailsNew.is_received_by_doctor == true ? (
                                                                    <span>
                                                                        {moment(orderDetailsNew.is_received_by_doctor_datetime).format('DD MMM YYYY, hh:mm a')}
                                                                    </span>
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </span>

                                                            {/* <p>{orderDetailsNew.awbtrack && this.checkWithCurrentDate(orderDetailsNew.awbtrack.ShipmentPickupDate) ? <span>AWBNo: {orderDetailsNew.awbtrack.AWBNo}</span> : ""}</p> */}
                                                        </div>
                                                    </div>

                                                </div>:  
                                                
                                                <div>
                                                <div className="tracking-item current-tracking-item">
                                                    <div className="tracking-icon status-current status-intransit">
                                                        <svg
                                                            className="svg-inline--fa fa-circle fa-w-16"
                                                            aria-hidden="true"
                                                            data-prefix="fas"
                                                            data-icon="circle"
                                                            role="img"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            viewBox="0 0 512 512"
                                                            data-fa-i2svg=""
                                                        >
                                                            <path
                                                                fill="currentColor"
                                                                d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"
                                                            />
                                                        </svg>
                                                    </div>
                                                    <div className="tracking-content">
                                                        Order Placed<span>{this.state.orderDate}</span>
                                                    </div>
                                                </div>
                                                { orderDetailsNew.orderStatus =='RTO'? 
                                                        <div className="tracking-item current-tracking-item">
                                                            <div className="tracking-icon status-current status-intransit">
                                                                <svg
                                                                    className="svg-inline--fa fa-circle fa-w-16"
                                                                    aria-hidden="true"
                                                                    data-prefix="fas"
                                                                    data-icon="circle"
                                                                    role="img"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    viewBox="0 0 512 512"
                                                                    data-fa-i2svg=""
                                                                >
                                                                    <path
                                                                        fill="currentColor"
                                                                        d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"
                                                                    />
                                                                </svg>
                                                            </div>
                                                            <div className="tracking-content">
                                                                RTO<span>{this.state.rtochangeDate?moment(this.state.rtochangeDate).format('DD MMM YYYY, hh:mm a'):""}</span>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className="tracking-item current-tracking-item">
                                                            <div className="tracking-icon status-current status-intransit">
                                                                <svg
                                                                    className="svg-inline--fa fa-circle fa-w-16"
                                                                    aria-hidden="true"
                                                                    data-prefix="fas"
                                                                    data-icon="circle"
                                                                    role="img"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    viewBox="0 0 512 512"
                                                                    data-fa-i2svg=""
                                                                >
                                                                    <path
                                                                        fill="currentColor"
                                                                        d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"
                                                                    />
                                                                </svg>
                                                            </div>
                                                            <div className="tracking-content">
                                                                Order Rejected<span>{orderDetailsNew.orderRejectDateMyownn?moment(orderDetailsNew.orderRejectDateMyownn).format('hh:mm a, DD MMM YYYY'):""}</span>
                                                            </div>
                                                        </div>
                                                        }
                                            </div>
                                                
                                                
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-order-track-side-banner">
                                <div className="order-info-bg-box">
                                    {/* <img src="images/order-info-bg.png" alt="Order Info" /> */}
                                    <img src={`${config.serviceUrl}/images/myownn/cart-image-bg.png`} className="" alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="dwn-btn-trk mt-4">
                                <div>
                                    <button type="" className="feedbackBtn" onclick="">
                                        <Link to={'/customer/feedback-submit'}> Feedback </Link>

                                    </button>
                                </div>
                                <div>
                                    {/* <button
                                type=""
                                className="feedbk-wrt bg-yellow"
                                onclick="location.href = 'feedback-form.html';"
                            >
                                Next
                            </button> */}
                                </div>
                                {/* <div>
                                    <a href={config.knowMoreLink} className="knw-mr-track" target="_blank" rel="noopener noreferrer">
                                        Know more
                                    </a>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </section>

                <CustomerFooter />

            </>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        // dispatching plain actions
        // setCustomer: (customer) => dispatch(CustomerAction.setCustomer(customer))
    }
}

const mapStateToProps = (state) => {
    return {
        OrderReducer: state.OrderReducer,
        CustomerReducer: state.CustomerReducer
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Common(TrackingOrder)))