import {
    CLEAR_STATE,
    SET_ACTIVE_MENU,
    STORE_SEARCH_VALUE,
    SET_DELIVERY_PERSON_DATA,
    SET_ACTION_TYPE
} from '../types/DeliveryPerson.type'

const initState = {
    admin: {},
    token: null,
    actiontype: null,
    deliveryperson: {}
}

export default (state = initState, action) => {
    const { type, payload } = action
    console.log(action, 565656);
    switch (type) {
        case SET_ACTIVE_MENU:
            return Object.assign({}, state, { activeItem: payload })
        case STORE_SEARCH_VALUE:
            return Object.assign({}, state, { storeSearchData: payload })
        case SET_ACTION_TYPE:
            return Object.assign({}, state, { actiontype: payload })
        case SET_DELIVERY_PERSON_DATA:
            return Object.assign({}, state, { deliveryperson: payload })
        case CLEAR_STATE:
            return initState;
        default:
            return state
    }
}