import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from '../../../components/Reusable/WithRouter';
import config from '../../../config/emrok.config';
import Common from '../../../hoc/Common.hoc';
import axios from 'axios';
import moment from 'moment';
import AdminHeader from "../../../components/layout/Admin/AdminHeader";
import AdminSidebar from "../../../components/layout/Admin/AdminSidebar";
import AdminFooter from "../../../components/layout/Admin/AdminFooter";
import '../../../assets/css/admin/style.css';
import '../../../assets/css/admin/responsive.css';
import DropdownDoctorRuntime from '../../../components/SearchableDropdown/DropdownDoctorRuntime.component';
import AdminService from '../../../services/Admin.service';
import CustomDatatable from "../../../components/Datatable/CustomDatatable.component";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; // Import the CSS
import AdminAction from '../../../redux/actions/Admin.action';
import { ExportToExcel } from '../../../components/Excel/ExportToExcel';
class CustomerList extends Component {
    constructor(props) {
        super(props)

        this.state = {
            quantity: "",
            selectOrder: [],
            bankrefno: "",
            rtochangeDate: "",
            refundchangeDate: "",
            customerListArr: [],
            selectedDoctor: "",
            searchDoctor: "",
            currentPage: 1,
            itemsPerPage: 20,
            fromdate: "",
            todate: "",
            exportData: null,
            fileName: "Customer",
        }

    }


    componentDidMount() {

        this.fetchRecords();
        
    }

    fetchRecords = (payload = null) => {
        let newPayload;
        if(payload==null){
            newPayload = {
                startDate: null,
                endDate: null
            }
        }else{
            newPayload = {
                startDate: payload.startDate,
                endDate: payload.endDate
            }
        }

        AdminService.getAllUniqueCustomer(newPayload).then(data => {
            console.log(data.data.data.data, "all customer");
            if (data.data.success) {
                // console.log(data.data.data.data[0].totalCustomer, "10001");
                this.setState({ customerListArr: data.data.data.data });
                const formatedData = this.formatData(data.data.data.data);
                this.setState({ exportData: formatedData });
            }
        }).catch(err => {

            console.log(err, "err")
        })


    }

    formatData(auditdata) {
      console.log(auditdata,88888);
        let formatedArr = [];
        let j=1;
        for (let i = 0; i < auditdata.length; i++) {  
                formatedArr.push({

                    "SL. No.": j,
                    "Name": auditdata[i].name?auditdata[i].name:'',
                    "Mobile No": auditdata[i].mobileno?auditdata[i].mobileno:'',
                    "Consent Date": moment(auditdata[i].createdAt).format("DD-MM-YYYY h:mm a")
                  
                })
                j++;
        }

        return formatedArr;


    }


    componentWillUnmount() {
        if (this.dataTable) {
            this.dataTable.destroy(true);
        }
    }

    grGenarationHistory = () => {
        // const usertype = this.props.CommonReducer.userType;
        this.props.navigate('/admin/qr-generation-history');
    };

    changeVal = async (event) => {
        const name = event.target.name;
        const value = event.target.value;
        if (value > 1000) {
            this.setState({ quantity: '' });
        } else {
            this.setState({ quantity: value });
        }


    }

    generateQRService = async (payload) => {
        console.log(payload, 'quantity1');
        const { quantity } = this.state;
        try {
            this.setState({ qrList: [] })
            let res = await AdminService.generateQR(payload)
            console.log(res, 'quantity2')
            if (res.data.success) {
                this.fetchRecords();
                if (quantity == 1) {
                    this.props.hoc.customAlert(quantity + ' QR Code is Generated.', true)

                } else {
                    this.props.hoc.customAlert(quantity + ' QR Codes are Generated.', true)
                }
            }
        } catch (error) {
            this.props.hoc.customAlert('Something went wrong.', false)
        }
    }

    setOrderHistoryData = (data) => {
        let resp = []
        for (let i = 0; i < data.length; i++) {
            resp.push({
                _id: data[i]._id,
                name: data[i].name,
                mobileno: data[i].mobileno,
                createdAt: moment(data[i].createdAt).local().format('DD-MM-YYYY HH:mm'),
            
            })
        }
        return resp
    }
    changeValFromDate = async (date) => {

        // const formatedDate = moment(date).format('DD-MM-YYYY');
        console.log(date, "fromdate");
        this.setState({ fromdate: date });
    }

    changeValToDate = async (date) => {

        // const formatedDate = moment(date).format('DD-MM-YYYY');
        console.log(date, "todate");
        this.setState({ todate: date });
    }

    clearSearch = (event) => {
        this.setState({ doctorList: [], searchDoctor: "" })
    }
    searchBy = (event) => {
        const { fromdate, todate } = this.state;
        let payload = {
            startDate: fromdate,
            endDate: todate
        };
        console.log(payload, 55555);

        this.fetchRecords(payload);

    }

    clearFilter = (event) => {
        this.setState({ fromdate: null, todate: null });
        this.fetchRecords();

    }
    goToVacutainerMapping = () => {
        let data = "vr";
        this.props.setActiveMenuItem(data);
        // let res = AdminAction.setActiveMenuItem(data));
        this.props.navigate('/admin/vacutainer-record');
    }

    render = () => {
        const { customerListArr, quantity, fromdate, todate, exportData, fileName } = this.state;
        console.log(exportData,555);
        let _data = this.setOrderHistoryData(customerListArr)
        console.log(_data, "aksdhklsah");
        return (
            <>
                <div className="layout-wrapper">
                    <AdminHeader />
                    <AdminSidebar />
                    <div className="main-content">
                        <div className="page-content">
                            <div className="container-fluid">
                                <div className="row g-4">
                                    <div className='col-md-12'>
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <h5 className='mb-40 headingstyle'>Patient Consent for XO Clinical Data Assessment</h5>
                                            </div>
                                            {/* <div className='col-md-6 text-end'>
                                                <button className='btn btn-primary me-3' type='button' onClick={(event) => this.goToVacutainerMapping(event)}>Vacutainer Mapping</button>
                                            </div> */}
                                        </div>
                                    </div>


                                    {/* Row end */}
                                    <div className="row">
                                        <form className="mt-2 registration-form">
                                           
                                            <div className='row'>
                                                {/* <div className='col-md-6'> */}
                                                {/* <h5 className='mb-20 headingstyle'>QR Code Generation Records </h5> */}
                                                {/* </div> */}


                                                <div className="col-md-2 mb-3">
                                                    <label htmlFor className="form-label lvl-clr-hd">From Date</label>
                                                    {/* <input type="date" format="dd-mm-yyyy" value={fromdate} name="fromdate" onChange={(event) => this.changeVal(event)} id className="form-control name-pt-ft" placeholder /> */}
                                                    <DatePicker
                                                        selected={fromdate}
                                                        onChange={(date) => this.changeValFromDate(date)}
                                                        dateFormat="dd-MM-yyyy" // Set the desired display format
                                                        className="form-control name-pt-ft"
                                                        placeholderText="DD-MM-YYYY"
                                                    />
                                                </div>
                                                <div className="col-md-2 mb-3">
                                                    <label htmlFor className="form-label lvl-clr-hd">To Date</label>
                                                    {/* <input type="date" format="dd-mm-yyyy" min={this.state.fromdate} value={todate} name="todate" onChange={(event) => this.changeVal(event)} id className="form-control name-pt-ft" placeholder /> */}
                                                    <DatePicker
                                                        selected={todate}
                                                        onChange={(date) => this.changeValToDate(date)}
                                                        dateFormat="dd-MM-yyyy" // Set the desired display format
                                                        className="form-control name-pt-ft"
                                                        placeholderText="DD-MM-YYYY"
                                                        minDate={fromdate}
                                                    />
                                                </div>

                                                <div className="col-md-4 mt-4 mb-40">
                                                    <button className='btn btn-primary' type='button' onClick={(event) => this.searchBy(event)}>Search</button>
                                                    <button className='btn btn-primary ms-2' type='button' onClick={(event) => this.clearFilter(event)}>Clear Filter</button>
                                                </div>
                                                <div className='col-md-4 mt-4 exportclass'>
                                                <ExportToExcel apiData={exportData} fileName={fileName} />
                                                </div>

                                                <div className="table-responsive mt-4">


                                                    {
                                                        _data && _data.length ?
                                                            <CustomDatatable
                                                                pagination={true}
                                                                columns={[
                                                                    {
                                                                        name: 'SL. No.',
                                                                        // selector: row => row.mr_name,

                                                                    },
                                                                    {
                                                                        name: 'Customer Name',
                                                                        // selector: row => row.mr_name,

                                                                    },
                                                                    {
                                                                        name: 'Customer Mobile No.',
                                                                        // selector: row => row.mr_name,

                                                                    },
                                                                    {
                                                                        name: 'Consent Received Date',
                                                                        // selector: row => row.mr_name,

                                                                    },
                                                                    
                                                                   
                                                                ]}
                                                                data={this.setOrderHistoryData(customerListArr)}
                                                                pageName="customergeneration"
                                                                downloadQR={this.downloadQR}
                                                            />
                                                            : <p className="norecord"><strong>No Records Found</strong></p>
                                                    }
                                                </div>
                                            </div>

                                        </form>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <AdminFooter />
                    </div>
                </div>

            </>
        )

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        // dispatching plain actions
        setActiveMenuItem: (activeItem) => dispatch(AdminAction.setActiveMenuItem(activeItem))
    }
}

const mapStateToProps = (state) => {
    return {
        OrderReducer: state.OrderReducer,
        CustomerReducer: state.CustomerReducer,
        CommonReducer: state.CommonReducer,
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Common(CustomerList)))