import {
    CLEAR_STATE,
    SET_ACTIVE_MENU,
    STORE_SEARCH_VALUE,
    SET_DELIVERY_PERSON_DATA

    } from '../types/DeliveryPerson.type'
    class DeliveryPersonAction {
   
      logout = ()=>{
        return { type: CLEAR_STATE, payload: null }
      }
      setActiveMenuItem  = (data) =>{
        // alert(data)
        return { type: SET_ACTIVE_MENU, payload: data } 
      }
      setSearchValue  = (data) =>{
        // alert(data)
        return { type: STORE_SEARCH_VALUE, payload: data } 
      }
      setDeliveryPerson  = (data) =>{
        
        return { type: SET_DELIVERY_PERSON_DATA, payload: data } 
      }
    
     
      
    }
    export default new DeliveryPersonAction()