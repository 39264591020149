import React, { Component } from 'react';
import { connect } from 'react-redux';
import CustomerFooter from '../../../components/layout/CustomerFooter';
import { withRouter } from '../../../components/Reusable/WithRouter';
import CustomerHeader from '../../../components/layout/CustomerHeader';
import config from '../../../config/emrok.config';
import { Link } from 'react-router-dom';
import OtherService from '../../../services/OtherService';
import Common from '../../../hoc/Common.hoc';
import { format } from 'date-fns';
import moment from 'moment';


class TrackOrder extends Component {
    constructor(props) {
        super(props)
        this.state = {
            orderId: "",
            customerId: "",
            orderDetails: undefined,
            orderDate: "",
            samplePickedUp: "",
            samplePickedUpDate: "",
            bloodRequestDate: "",
         
        }
    }

    componentDidMount = () => {

        const orderDetails = this.props.OrderReducer.currentOrder;
        console.log(orderDetails, "order details");

        const orderId = this.props.OrderReducer.currentOrder.order_id;
        console.log(orderId, "orderid");
        const details = {
            orderId: this.props.OrderReducer.currentOrder.order_id
            //  orderId: 9321479403
        }


        const OrderDate = Object.keys(orderDetails).length > 0 ? this.getOrderDateFormat(orderDetails.createdAt) : "";
        const bloodRequestDate = Object.keys(orderDetails).length > 0 ? this.getOrderDateFormatInISO(orderDetails.collection_date + " " + orderDetails.collection_slot) : "";

        console.log(bloodRequestDate, "bloodRequestDate");

        this.setState({ orderId: orderId })
        this.setState({ orderDetails: orderDetails })
        this.setState({ orderDate: OrderDate })
        this.setState({ bloodRequestDate: bloodRequestDate })



        OtherService.getSRLSamplePickupStatus(details).then(res => {

            if (res && res.data && res.data.success) {

                if (res.data.data.samplestatus) {
                    const allStatus = res.data.data.samplestatus;
                    const samplePickedUp = allStatus[0].response_data.status == undefined ? "" : allStatus[0].response_data.status;

                    console.log(samplePickedUp, "samplePickedUp")
                    const samplePickedUpDate = allStatus[0].createdAt;
                    const SamplePickedUpFormatDate = samplePickedUpDate ? this.getOrderDateFormat(samplePickedUpDate) : "";
                    this.setState({ samplePickedUp: samplePickedUp })
                    this.setState({ samplePickedUpDate: SamplePickedUpFormatDate })




                }

                console.log(res.data.data.samplestatus, "save response status");
            }


        }).catch(e => {
            console.log("exception in save srl response", e)
        })


       
    }

    checkWithCurrentDate = (givenDateValue) => {

        console.log(givenDateValue, "givenDate");

        if (givenDateValue != undefined) {
            const givenDateString = givenDateValue;

            // Parse the given date string using moment
            const givenDate = moment(givenDateString, 'hh:mm A, DD MMMM YYYY');

            // Get the current date and time using moment
            const currentDate = moment();

            // Compare the current date with the given date
            const isSameOrGreater = currentDate.isSameOrAfter(givenDate);

            console.log('Is current date same or greater:', isSameOrGreater);


            // Get the current date and time
            if (isSameOrGreater) {
                return true;
            } else {
                return false;
            }


            // return true;

        } else {
            return false;
        }


    }

    getOrderDateFormat = (datestring) => {


        const date = new Date(datestring);

        const formattedDate = format(date, "hh:mm a, dd MMMM yyyy");

        return formattedDate;
    }


    getOrderDateFormatInISO = (datestring) => {

        const changeDate = moment(datestring).toDate();
        const parsedDate = moment(changeDate, 'hh:mm A, DD MMMM YYYY');

        // Format the parsed date as ISODate format
        const isoDate = parsedDate.toISOString();

        const date = new Date(isoDate);

        const formattedDate = format(date, "hh:mm a, dd MMMM yyyy");

        return formattedDate;
    }

    handleClickHome = () => {

        this.props.navigate('/customer/registration');
    }


    render = () => {

        const customerDetails = this.props.CustomerReducer.customer;
        // const customerDetails = orderDetails.customer;
        console.log(customerDetails, "customerDetails");
        return (
            <>
                <section className="order-infrmtn-pt pt-0 order-information-container">
                    <div className="container">
                        <CustomerHeader />

                        <div className="row">
                            <div className="ordr-info-part">
                                <div className='row mt-20'>
                                    <div className='col-6'>
                                        <h2>Track Shipment</h2>
                                    </div>
                                    <div className='col-6'>
                                        <div className="btn-home flotright">
                                            <a href="javascript:void(0);" onClick={this.handleClickHome}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg>
                                                Back
                                            </a>
                                        </div>
                                    </div>

                                </div>
                                <div className="d-flex align-items-center justify-content-between mt-10">
                                    <div className="title">
                                        <div className='ml-20'>
                                            <p>Order Id - {this.state.orderId}</p>
                                            <p>Patient Name - {customerDetails.name}</p>
                                            <p>Patient Mobile No. - {customerDetails.mobileno}</p>
                                            <p>Patient Address - {customerDetails.address.addressLine2!=''?customerDetails.address.addressLine1 +", "+customerDetails.address.addressLine2+ "," + customerDetails.address.town + "," + customerDetails.address.state + "," + customerDetails.address.country + "," + customerDetails.address.pin : customerDetails.address.addressLine1+ "," + customerDetails.address.town + "," + customerDetails.address.state + "," + customerDetails.address.country + "," + customerDetails.address.pin}</p>
                                        </div>
                                    </div>
                                    {/* <div className="btn-home">
                                        <a href="javascript:void(0);" onClick={this.handleClickHome}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg>
                                            Home
                                        </a>
                                    </div> */}
                                </div>
                            </div>
                            <div className="col-md-6 track-shipping-ft mt-4">
                                <div className="track-point-pt">
                                    {/* <h5>Track Shipment</h5> */}
                                    <div className="col-md-12 col-lg-12">
                                        <div id="tracking-pre" />
                                        <div id="tracking">
                                            <div className="tracking-list">
                                                {/* <div className={this.state.bloodRequestDate != "" && this.checkWithCurrentDate(this.state.bloodRequestDate) ? "tracking-item current-tracking-item" : "tracking-item pending"}> */}
                                                <div className="tracking-item current-tracking-item">
                                                    <div className="tracking-icon status-current status-intransit">
                                                        <svg
                                                            className="svg-inline--fa fa-circle fa-w-16"
                                                            aria-hidden="true"
                                                            data-prefix="fas"
                                                            data-icon="circle"
                                                            role="img"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            viewBox="0 0 512 512"
                                                            data-fa-i2svg=""
                                                        >
                                                            <path
                                                                fill="currentColor"
                                                                d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"
                                                            />
                                                        </svg>
                                                    </div>
                                                    <div className="tracking-content">
                                                        Order Placed<span>{this.state.orderDate}</span>
                                                    </div>
                                                </div>
                                                <div className={this.state.samplePickedUp == "" ? "tracking-item" : "tracking-item current-tracking-item"}>
                                                    <div className="tracking-icon status-current status-intransit">
                                                        <svg
                                                            className="svg-inline--fa fa-circle fa-w-16"
                                                            aria-hidden="true"
                                                            data-prefix="fas"
                                                            data-icon="circle"
                                                            role="img"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            viewBox="0 0 512 512"
                                                            data-fa-i2svg=""
                                                        >
                                                            <path
                                                                fill="currentColor"
                                                                d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"
                                                            />
                                                        </svg>
                                                    </div>
                                                    <div className="tracking-content">
                                                        Blood Collection Request Date<span>{this.state.bloodRequestDate }</span>
                                                    </div>
                                                </div>
                                                <div className="tracking-item ">
                                                    <div className={this.state.samplePickedUp == "" ? "tracking-icon status-intransit" : "tracking-icon status-current status-intransit"}>
                                                        <svg
                                                            className="svg-inline--fa fa-circle fa-w-16"
                                                            aria-hidden="true"
                                                            data-prefix="fas"
                                                            data-icon="circle"
                                                            role="img"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            viewBox="0 0 512 512"
                                                            data-fa-i2svg=""
                                                        >
                                                            <path
                                                                fill="currentColor"
                                                                d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"
                                                            />
                                                        </svg>
                                                    </div>
                                                    <div className={this.state.samplePickedUp == "" ? "tracking-content text-muted" : "tracking-content"}>
                                                        Blood Collection Done<span>{this.state.samplePickedUp == "" ? "" : this.state.samplePickedUpDate}</span>
                                                    </div>
                                                </div>
                                                <div className="tracking-item pending">
                                                    <div className="tracking-icon status-intransit">
                                                        <svg
                                                            className="svg-inline--fa fa-circle fa-w-16"
                                                            aria-hidden="true"
                                                            data-prefix="fas"
                                                            data-icon="circle"
                                                            role="img"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            viewBox="0 0 512 512"
                                                            data-fa-i2svg=""
                                                        >
                                                            <path
                                                                fill="currentColor"
                                                                d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"
                                                            />
                                                        </svg>
                                                    </div>
                                                    <div className="tracking-content pending">
                                                        Sample received at the Manufacturing site
                                                        {/* <span>09:10 PM, 19 June 2022</span> */}
                                                    </div>
                                                </div>
                                                {/* <div className="tracking-item pending">
                                                    <div className="tracking-icon status-intransit">
                                                        <svg
                                                            className="svg-inline--fa fa-circle fa-w-16"
                                                            aria-hidden="true"
                                                            data-prefix="fas"
                                                            data-icon="circle"
                                                            role="img"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            viewBox="0 0 512 512"
                                                            data-fa-i2svg=""
                                                        >
                                                            <path
                                                                fill="currentColor"
                                                                d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"
                                                            />
                                                        </svg>
                                                    </div>
                                                    <div className="tracking-content">
                                                        Manufacturing in process
                                                        {/* <span>09:10 PM, 19 June 2022</span> 
                                                    </div>
                                                </div> */}
                                                <div className="tracking-item pending">
                                                    <div className="tracking-icon status-intransit">
                                                        <svg
                                                            className="svg-inline--fa fa-circle fa-w-16"
                                                            aria-hidden="true"
                                                            data-prefix="fas"
                                                            data-icon="circle"
                                                            role="img"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            viewBox="0 0 512 512"
                                                            data-fa-i2svg=""
                                                        >
                                                            <path
                                                                fill="currentColor"
                                                                d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"
                                                            />
                                                        </svg>
                                                    </div>
                                                    <div className="tracking-content">
                                                    Product ready to dispatch from Manufacturing site
                                                        {/* <span>09:10 PM, 19 June 2022</span> */}
                                                    </div>
                                                </div>
                                                {/* <div className="tracking-item pending">
                                                    <div className="tracking-icon status-intransit">
                                                        <svg
                                                            className="svg-inline--fa fa-circle fa-w-16"
                                                            aria-hidden="true"
                                                            data-prefix="fas"
                                                            data-icon="circle"
                                                            role="img"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            viewBox="0 0 512 512"
                                                            data-fa-i2svg=""
                                                        >
                                                            <path
                                                                fill="currentColor"
                                                                d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"
                                                            />
                                                        </svg>
                                                    </div>
                                                    <div className="tracking-content">
                                                        Product received at Stockist
                                                        {/* <span>09:10 PM, 19 June 2022</span>
                                                    </div>
                                                </div> */}
                                                <div className="tracking-item pending">
                                                    <div className="tracking-icon status-intransit">
                                                        <svg
                                                            className="svg-inline--fa fa-circle fa-w-16"
                                                            aria-hidden="true"
                                                            data-prefix="fas"
                                                            data-icon="circle"
                                                            role="img"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            viewBox="0 0 512 512"
                                                            data-fa-i2svg=""
                                                        >
                                                            <path
                                                                fill="currentColor"
                                                                d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"
                                                            />
                                                        </svg>
                                                    </div>
                                                    <div className="tracking-content">
                                                        Product available with Doctor
                                                        {/* <span>09:10 PM, 19 June 2022</span> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-order-track-side-banner">
                                <div className="order-info-bg-box">
                                    {/* <img src="images/order-info-bg.png" alt="Order Info" /> */}
                                    <img src={`${config.serviceUrl}/images/myownn/cart-image-bg.png`} className="" alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="dwn-btn-trk mt-4">
                                <div>
                                    <button type="" className="feedbackBtn" onclick="">
                                        <Link to={'/customer/feedback-info'}> Feedback </Link>

                                    </button>
                                </div>
                                <div>
                                    {/* <button
                                type=""
                                className="feedbk-wrt bg-yellow"
                                onclick="location.href = 'feedback-form.html';"
                            >
                                Next
                            </button> */}
                                </div>
                                {/* <div>
                                    <a href={config.knowMoreLink} className="knw-mr-track" target="_blank" rel="noopener noreferrer">
                                        Know more
                                    </a>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </section>

                <CustomerFooter />

            </>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        // dispatching plain actions
        // setCustomer: (customer) => dispatch(CustomerAction.setCustomer(customer))
    }
}

const mapStateToProps = (state) => {
    return {
        OrderReducer: state.OrderReducer,
        CustomerReducer: state.CustomerReducer
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Common(TrackOrder)))