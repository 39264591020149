import React from "react";
import LogoutIcon from "../../../assets/Svg/LogoutICon";
import CommonAction from "../../../redux/actions/Common.action";
import CallCenterAction from "../../../redux/actions/CallCenter.action";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import OrderAction from "../../../redux/actions/Order.action";
import CustomerAction from "../../../redux/actions/Customer.action";
// import FeedbackAction from "../../../redux/actions/Feedback.action";
import MrAction from "../../../redux/actions/Mr.action";
import DoctorAction from "../../../redux/actions/Doctor.action";
import StockistAction from "../../../redux/actions/Stockist.action";
import AdminAction from "../../../redux/actions/Admin.action";
export default function AdminHeader() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const admin = useSelector((state) => state.AdminReducer.admin);
  console.log(admin, 'admin_user')
  const logout = () => {
    dispatch(CallCenterAction.logout());
    dispatch(CommonAction.logout());
    dispatch(CustomerAction.clearState());
    // dispatch(FeedbackAction.clearState());
    dispatch(OrderAction.clearState());
    dispatch(MrAction.logout());
    dispatch(DoctorAction.logout());
    dispatch(StockistAction.logout());
    dispatch(AdminAction.logout());
    // const id = customer && customer.prescribedDoctor && customer.prescribedDoctor._id;
    const url = "/";
    navigate(url);
  };
  return (
    <>
      <header className="page-topbar">
        <div className="layout-width">
          <div className="navbar-header">
            <div className="page-top-bar-left-content d-flex align-items-center">
              {/* Hamburger btn start*/}
              <button
                type="button"
                className="btn btn-sm pe-3 fs-16 header-item vertical-menu-btn topnav-hamburger d-md-none sideBarToggleBtn"
                id="sideBarToggleBtn"
              >
                <span className="hamburger-icon">
                  <span />
                  <span />
                  <span />
                </span>
              </button>
              {/* Hamburger btn end*/}
              <div className="flex-column">
                {/* <div className="page-breadcrumb d-none d-md-flex">
                      <ol className="breadcrumb breadcrumb--common m-0">
                          <li className="breadcrumb-item">
                              <a href={void(0)}>Dashboard</a>
                          </li>
                          <li className="breadcrumb-item">
                              <a href={void(0)}>Lorem Ipsum</a>
                          </li>
                          <li className="breadcrumb-item active">Ipsum</li>
                      </ol>
                  </div> */}
                <h4 className="mb-sm-0 page-topbar-title">{admin.userType == 'WARE_HOUSE' ? 'Warehouse' : 'Super Admin'}</h4>
              </div>
            </div>
            <div className="page-top-bar-right-content justify-content-end">
              <div className="dropdown ms-sm-2 header-item topbar-user custom-animated-dropdown">
                <button
                  type="button"
                  className="btn border-0"
                  id="page-header-user-dropdown"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <span className="d-flex align-items-center">
                    <span className="topbar-user-more">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-more-vertical"
                      >
                        <circle cx={12} cy={12} r={1} />
                        <circle cx={12} cy={5} r={1} />
                        <circle cx={12} cy={19} r={1} />
                      </svg>
                    </span>
                    {/* <img className="rounded-circle header-profile-user" src= {require("../../../assets/images/users/avatar-1.svg")} alt="Admin" /> */}
                  </span>
                </button>
                <div className="dropdown-menu dropdown-menu-end">
                  {/* item*/}
                  <h6 className="dropdown-header">Welcome {admin.name ?? (admin.userType == 'WARE_HOUSE' ? 'WareHouse User' : 'Admin')}!</h6>
                  <a
                    className="dropdown-item"
                    href={void (0)}
                    onClick={() => {
                      logout();
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={16}
                      height={16}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-user"
                    >
                      <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                      <circle cx={12} cy={7} r={4} />
                    </svg>
                    <span className="align-middle">Log out</span>
                  </a>
                  {/* <a class="dropdown-item" href={void(0)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-user"
              >
                <path
                  d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"
                ></path>
                <circle cx="12" cy="7" r="4"></circle>
              </svg>
              <span class="align-middle">Menu2</span>
            </a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}
