// import React, { Component } from 'react'
import React, { Component, useState, useRef } from 'react';
import { connect } from "react-redux";
import OtherService from '../../services/OtherService';
import style from "./Login.style";
import CustomerService from "../../services/Customer.service";
import { withRouter } from "../../components/Reusable/WithRouter";
import CustomerAction from "../../redux/actions/Customer.action";
import CommonAction from '../../redux/actions/Common.action';
import Common from '../../hoc/Common.hoc';
import TimerComponent from '../../components/Timer/LoginTimer';
import config from '../../config/emrok.config';
import MrAction from '../../redux/actions/Mr.action';
import DoctorAction from '../../redux/actions/Doctor.action';
import StockistAction from '../../redux/actions/Stockist.action';
import CallCenterAction from '../../redux/actions/CallCenter.action';
import ManufacturAction from '../../redux/actions/Manufactur.action';
import AdminAction from '../../redux/actions/Admin.action';
import PatientAction from '../../redux/actions/Patient.action';
import ClinicAction from '../../redux/actions/Clinic.action';
import $ from 'jquery';
import DeliveryPersonAction from '../../redux/actions/DeliveryPerson.action';

class Login extends Component {
    constructor(props) {
        super(props)
        this.firstRef = React.createRef();
        this.secondRef = React.createRef();
        this.thirdRef = React.createRef();
        this.fourthRef = React.createRef();
        // this.inputRefs = []; // Array to store input field references

        this.state = {
            mobileno: "",
            isMobileVerified: false,
            otp1: "",
            otp2: "",
            otp3: "",
            otp4: "",
            // currentInputIndex: 0,
            combineOtpValidLength: "",
            uniqueqr: ""
        }
        console.log(props)
    }

    componentDidMount() {
        setTimeout(() => {
            CustomerService.hideLoader()
        }, 5000);
        console.log(this.props)

    }
    /**
     * 
     * @param {Object} event 
     */
    setNumber = (event) => {
        const val = event.target.value;
        const reg = new RegExp('^[0-9]+$');
        if (!val || (reg.test(val) && val.length <= 10)) {
            this.setState({ mobileno: val })
        }
    }

    verifyMobileSubmit = (e) => {
        console.log('innn123');
        e.preventDefault();
        const { mobileno } = this.state;
        const data = { mobileno }
        if (mobileno.length == 10) {
            OtherService.generateOtp(data).then(res => {

                console.log(res, 2222222);
                console.log(res)
                if (res && res.data && res.data.success) {

                    if (res.data.data.hasOwnProperty('isFound') && res.data.data.isFound == false) {
                        this.setState({ isMobileVerified: false })
                        this.props.hoc.customAlert("User not found.", false)

                    } else {
                        this.setState({ isMobileVerified: true })

                    }
                }
            }).catch(err => {
                console.log(err)
            });
        }

    }

    mobileVerify = () => {
        const { mobileno } = this.state;
        const isValid = mobileno.length == 10;
        return (
            <section className="login-part-st">
                <div className="container">

                    <div className="login-form">
                        <div className="muown-logo">
                            <img src={require("../../assets/images/logo-image.png")} />
                        </div>
                        {/* changes by smarajit */}
                        <h2 className="pb-3">Welcome to the world of gentle beauty</h2>

                        <div className="mobile-inpt-pt pt-3">
                            <form onSubmit={e => { e.preventDefault(); }}>
                                <div className="cl-inpt-part-st">
                                    <input onChange={(event) => {
                                        this.setNumber(event)
                                    }} value={mobileno} type="text" className="form-control mbl-inpt-part" placeholder="Enter Mobile Number" /></div>
                            </form>
                        </div>

                        {/* <div className="know-mr-btn pt-3">
                            <p><a href={config.knowMoreLink}>Know more</a></p>
                        </div> */}

                        <div className="pt-3">
                            <a type="button" disabled={true} style={{ cursor: isValid ? "pointer" : "default" }} className={isValid ? "login-btn-usr" : "login-btn-usr login-disable"} onClick={this.verifyMobileSubmit}>Login</a>
                        </div>
                    </div>
                </div>
            </section>
        )
    }

    combineOtp = () => {
        const { otp1, otp2, otp3, otp4 } = this.state;
        const otp = otp1 + otp2 + otp3 + otp4;
        return otp;
    }

    changeOtp(event, nextRef) {

        console.log(event, 666666);
        const target = event.target;
        const { name, value } = event.target;
        const maxLength = parseInt(target.getAttribute('maxlength'));
        const currentLength = target.value.length;
        this.setState({ [name]: value })

        if (currentLength >= maxLength) {
            if (nextRef) {
                nextRef.focus();
            }
        }
    }



    handleBackspace = (index, event) => {

        const { otp1, otp2, otp3, otp4, combineOtpValidLength } = this.state;

        console.log(event.target.name, 56666);
        if (event.key === 'Backspace') {
            // Move focus to the previous input field
            if (index > 0) {
                const prevInput = document.getElementById(`otp-transpr-pt-${index - 1}`);
                prevInput.focus();
            }



            if (event.target.name == 'otp4') {
                const name = event.target.name;
                this.setState({ [name]: '' })
            }

            // event.target.value += ' ';
        }
    };

    verifyOtpSubmit = () => {
        const otp = this.combineOtp();
        const mobileno = this.state.mobileno
        const data = {
            mobileno, otp
        }
        const otherLogin = () => {


            const uniqueQRID = this.props.param.uniqueid;


            let payloadOne = {
                mobileno: mobileno,
                uniqueQRID: uniqueQRID

            };
            // console.log(uniqueQRID, 3333);
            if (uniqueQRID) {

                console.log(uniqueQRID, 3333);
                this.setState({ uniqueqr: uniqueQRID });
                OtherService.checksameDrMappedWithQr(payloadOne).then(res => {
                    console.log(res, 55555);

                    // return
                    console.log(this.state.uniqueqr, 99999);

                    if (res.data.success && res.data.data.isOrderPlaced == 'yes') {

                        let doctorName = res.data.data.data[0].doctor ? res.data.data.data[0].doctor.name : '';
                        let patientName = res.data.data.data[0].patient ? res.data.data.data[0].patient.name : '';
                        let mobileNo = res.data.data.data[0].patient ? res.data.data.data[0].patient.mobileno : '';
                        let qrcode = res.data.data.data[0].qrcode ? res.data.data.data[0].qrcode.unique_qr_id : '';
                        this.props.hoc.customAlert("<div class='row'><div class='col-md-6 text-left fontsizetext'>Hello Dr. " + doctorName + ",</div></div><div class='row'><div class='col-md-12 textleft'>This XO Face Serum [QR Code " + qrcode + "] is for " + patientName + " <br>(<b>✆</b> - " + mobileNo + ").</div></div><div class='row'><div class='col-md-6 textleft'>Thank You<br>Team XO</div></div> ", true)

                        return

                    }

                    

                    if (res.data.success && res.data.data.isOrderPlaced == 'no' && res.data.data.data.length > 0) {
                        OtherService.customerLogin({
                            mobileno
                        }).then(async (res) => {
                            console.log(res, 111);
                            if (res && res.data && res.data.success && res.data.data) {

                                console.log(res.data.data.userType, "details data");

                                if (res.data.data.userType === "MR") {
                                    this.props.setMr(res.data.data)
                                    this.props.setToken(res.data.data.token)
                                    this.props.navigate("/mr/list")
                                }

                                if (res.data.data.userType === "STOCKIST") {
                                    this.props.setStockist(res.data.data)
                                    this.props.setToken(res.data.data.token)
                                    this.props.navigate("/stockist/list")
                                }

                                if (res.data.data.userType === "DOCTOR") {
                                    this.props.setDoctor(res.data.data)
                                    this.props.setUniqueQR(this.state.uniqueqr)
                                    this.props.setToken(res.data.data.token)
                                    this.props.navigate("/doctor/landing")
                                }

                                if (res.data.data.userType === "CALL_CENTER") {
                                    this.props.setCallCenter(res.data.data)
                                    this.props.setToken(res.data.data.token)
                                    this.props.navigate("/callcenter/list")
                                }

                                if (res.data.data.userType === "MANUFACTUR") {
                                    this.props.setManufactur(res.data.data)
                                    this.props.setToken(res.data.data.token)
                                    this.props.navigate("/manufactur/list")
                                }

                                if (res.data.data.userType === "ADMIN") {
                                    // alert(JSON.stringify(res.data.data))
                                    await this.props.setAdmin(res.data.data)
                                    await this.props.setToken(res.data.data.token)
                                    this.props.navigate("/admin/dashboard")
                                }

                                if (res.data.data.userType === "WARE_HOUSE") {
                                    // alert(JSON.stringify(res.data.data))
                                    await this.props.setAdmin(res.data.data)
                                    await this.props.setToken(res.data.data.token)
                                    this.props.navigate("/warehouse/dashboard")
                                }
                                // if (res.data.data.userType === "PATIENT") {
                                //     console.log('inn')
                                //     // alert(JSON.stringify(res.data.data))
                                //     this.props.setPatient(res.data.data)
                                //     this.props.setToken(res.data.data.token)
                                //     this.props.navigate("/customer/dashboard")
                                // }
                                if (res.data.data.userType === "BU") {
                                    console.log('inn')
                                    // alert(JSON.stringify(res.data.data))
                                    this.props.setMr(res.data.data)
                                    this.props.setToken(res.data.data.token)
                                    this.props.navigate("/bu/dashboard")
                                }
                                if (res.data.data.userType === "NSM") {
                                    console.log('inn')
                                    // alert(JSON.stringify(res.data.data))
                                    this.props.setMr(res.data.data)
                                    this.props.setToken(res.data.data.token)
                                    this.props.navigate("/nsm/dashboard")
                                }
                                if (res.data.data.userType === "ZM") {
                                    console.log('inn')
                                    // alert(JSON.stringify(res.data.data))
                                    this.props.setMr(res.data.data)
                                    this.props.setToken(res.data.data.token)
                                    this.props.navigate("/zm/dashboard")
                                }
                                if (res.data.data.userType === "RM") {
                                    console.log('inn')
                                    // alert(JSON.stringify(res.data.data))
                                    this.props.setMr(res.data.data)
                                    this.props.setToken(res.data.data.token)
                                    this.props.navigate("/rm/dashboard")
                                }

                                // this.props.setCustomer(res.data.data._customer)
                                // this.props.setToken(res.data.data.token)
                                // this.props.navigate("/customer/registration")
                            } else {
                                this.props.hoc.customAlert("User Not Found", false)
                            }
                        }).catch(err => {
                            this.props.hoc.customAlert("User Not Found", false)
                            console.log(err)
                        })

                    } else {

                        this.props.hoc.customAlert("Doctor and QR mapping not correct.", false)
                    }

                }).catch(err => {

                    this.props.hoc.customAlert("Please login using correct URL", false)

                });
            } else {
                OtherService.customerLogin({
                    mobileno
                }).then(async (res) => {
                    console.log(res, 111);
                    if (res && res.data && res.data.success && res.data.data) {

                        console.log(res.data.data.userType, "details data");

                        if (res.data.data.userType === "MR") {
                            this.props.setMr(res.data.data)
                            this.props.setToken(res.data.data.token)
                            this.props.navigate("/mr/list")
                        }

                        if (res.data.data.userType === "STOCKIST") {
                            this.props.setStockist(res.data.data)
                            this.props.setToken(res.data.data.token)
                            this.props.navigate("/stockist/list")
                        }

                        if (res.data.data.userType === "DOCTOR") {
                            this.props.setDoctor(res.data.data)
                            this.props.setToken(res.data.data.token)
                            this.props.navigate("/doctor/landing")
                        }

                        if (res.data.data.userType === "CALL_CENTER") {
                            this.props.setCallCenter(res.data.data)
                            this.props.setToken(res.data.data.token)
                            this.props.navigate("/callcenter/list")
                        }

                        if (res.data.data.userType === "MANUFACTUR") {
                            this.props.setManufactur(res.data.data)
                            this.props.setToken(res.data.data.token)
                            this.props.navigate("/manufactur/list")
                        }

                        if (res.data.data.userType === "ADMIN") {
                            // alert(JSON.stringify(res.data.data))
                            await this.props.setAdmin(res.data.data)
                            await this.props.setToken(res.data.data.token)
                            this.props.navigate("/admin/dashboard")
                        }
                        if (res.data.data.userType === "WARE_HOUSE") {
                            // alert(JSON.stringify(res.data.data))
                            await this.props.setAdmin(res.data.data)
                            await this.props.setToken(res.data.data.token)
                            this.props.navigate("/warehouse/dashboard")
                        }
                        // if (res.data.data.userType === "PATIENT") {
                        //     console.log('inn')
                        //     // alert(JSON.stringify(res.data.data))
                        //     this.props.setPatient(res.data.data)
                        //     this.props.setToken(res.data.data.token)
                        //     this.props.navigate("/customer/dashboard")
                        // }
                        if (res.data.data.userType === "CLINIC") {
                            console.log('inn')
                            // alert(JSON.stringify(res.data.data))
                            this.props.setClinic(res.data.data)
                            this.props.setToken(res.data.data.token)
                            this.props.navigate("/clinic/dashboard")
                        }

                        if (res.data.data.userType === "BU") {
                            console.log('inn')
                            // alert(JSON.stringify(res.data.data))
                            this.props.setMr(res.data.data)
                            this.props.setToken(res.data.data.token)
                            this.props.navigate("/bu/dashboard")
                        }
                        if (res.data.data.userType === "NSM") {
                            console.log('inn')
                            // alert(JSON.stringify(res.data.data))
                            this.props.setMr(res.data.data)
                            this.props.setToken(res.data.data.token)
                            this.props.navigate("/nsm/dashboard")
                        }
                        if (res.data.data.userType === "ZM") {
                            console.log('inn')
                            // alert(JSON.stringify(res.data.data))
                            this.props.setMr(res.data.data)
                            this.props.setToken(res.data.data.token)
                            this.props.navigate("/zm/dashboard")
                        }
                        if (res.data.data.userType === "RM") {
                            console.log('inn')
                            // alert(JSON.stringify(res.data.data))
                            this.props.setMr(res.data.data)
                            this.props.setToken(res.data.data.token)
                            this.props.navigate("/rm/dashboard")
                        }
                        if (res.data.data.userType === "DELIVERY_PERSON") {
                            console.log('inn')
                            console.log(res.data.data, "delivery_person");
                            // alert(JSON.stringify(res.data.data))
                            this.props.setDeliveryPerson(res.data.data)
                            this.props.setToken(res.data.data.token)
                            this.props.navigate("/delivery-person/dashboard")
                        }

                        // this.props.setCustomer(res.data.data._customer)
                        // this.props.setToken(res.data.data.token)
                        // this.props.navigate("/customer/registration")
                    } else {
                        this.props.hoc.customAlert("User Not Found", false)
                    }
                }).catch(err => {
                    this.props.hoc.customAlert("User Not Found", false)
                    console.log(err)
                })
            }
            // 



        }
        OtherService.verifyOtp(data).then(res => {
            console.log(res, 7878);

            if (res && res.data && res.data.success) {
                // const drId = this.props.param.dr_id;
                otherLogin();

            }
            else if (res && res.data && !res.data.success) {
                this.props.hoc.customAlert(res.data?.message || "Something went wrong.", false)
            }
        }).catch(err => {
            console.log(err)
        })
    }




    otpVerify = () => {
        const { otp1, otp2, otp3, otp4, combineOtpValidLength } = this.state;
        const resendOtp = () => {

            const { mobileno } = this.state;
            const data = { mobileno }
            if (mobileno.length == 10) {
                OtherService.generateOtp(data).then(res => {
                    console.log(res)
                    if (res && res.data && res.data.success) {
                        this.setState({ isMobileVerified: true })
                    }
                }).catch(err => {
                    console.log(err)
                });
            }
        }


        const inputfocus = (elmnt) => {
            if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
                console.log(elmnt.target.value)
                const tabindex = elmnt.target.tabIndex;

                const prev = elmnt.target.tabIndex - 2;
                console.log(prev);
                if (prev > -1) {

                    elmnt.target.form.elements[prev].focus()
                }
            }
            else {
                console.log("next");

                const next = elmnt.target.tabIndex - 1;
                console.log(next);
                if (next < 4) {
                    elmnt.target.form.elements[next].focus()
                }
            }
        }




        return (
            <section className="login-part-st">
                <div className="container">

                    <div className="login-form">
                        <div className="muown-logo">
                            <img src={require("../../assets/images/logo-image.png")} />
                        </div>

                        <h2 className="pb-3">Welcome to the world of gentle beauty</h2>
                        <h1 className="otp-part-pt">OTP SENT</h1>
                        <p className="otp-text-pt">Enter the OTP sent to you</p>

                        <form onSubmit={e => { e.preventDefault(); }}>
                            <div className="otp-clm-gap d-flex mb-3 mt-3">
                                <div className="">
                                    <input type="text" inputMode="numeric" ref={input => { this.firstRef = input }} className='otp-transpr-pt' id="otp-transpr-pt-0" onChange={(event) => this.changeOtp(event, this.secondRef)} value={otp1} name="otp1" maxLength="1" onKeyDown={e => this.handleBackspace(0, e)} tabIndex="1" />
                                </div>
                                <div className="">
                                    <input type="text" inputMode="numeric" ref={input => { this.secondRef = input; }} className='otp-transpr-pt' id="otp-transpr-pt-1" onChange={(event) => this.changeOtp(event, this.thirdRef)} onKeyDown={e => this.handleBackspace(1, e)} value={otp2} name="otp2" maxLength="1" tabIndex="2" />
                                </div>
                                <div>
                                    <input type="text" inputMode="numeric" ref={input => { this.thirdRef = input; }} className='otp-transpr-pt' id="otp-transpr-pt-2" onChange={(event) => this.changeOtp(event, this.fourthRef)} onKeyDown={e => this.handleBackspace(2, e)} value={otp3} name="otp3" maxLength="1" tabIndex="3" />
                                </div>
                                <div className="">
                                    <input type="text" inputMode="numeric" ref={input => { this.fourthRef = input; }} className='otp-transpr-pt' id="otp-transpr-pt-3" onChange={(event) => this.changeOtp(event)} value={otp4} onKeyDown={e => this.handleBackspace(3, e)} name="otp4" tabIndex="4" maxLength="1" />
                                </div>

                            </div>

                            <div className="resend-otp-fr-login">
                                <span> <TimerComponent resendOtp={resendOtp} /> </span>
                            </div>
                            <div className="mb-3 mt-4">
                                <a disabled={combineOtpValidLength ? false : true} style={combineOtpValidLength ? { ...style.btn } : null} className="form-control login-btn-usr" id="" onClick={() => this.verifyOtpSubmit()}> Done</a>
                            </div>
                        </form>

                    </div>
                </div>
            </section>
        )
    }

    render = () => {

        const { isMobileVerified } = this.state;
        return (
            <>
                {
                    !isMobileVerified ? this.mobileVerify() : this.otpVerify()
                }
            </>
        )
    }

}

const mapDispatchToProps = (dispatch) => {
    return {
        // dispatching plain actions
        setCustomer: (customer) => dispatch(CustomerAction.setCustomer(customer)),
        setToken: (token) => dispatch(CommonAction.setToken(token)),
        setMr: (mrdetails) => dispatch(MrAction.setMr(mrdetails)),
        setDoctor: (doctordetails) => dispatch(DoctorAction.setDoctor(doctordetails)),
        setUniqueQR: (uniqueqrcode) => dispatch(DoctorAction.setUniqueQR(uniqueqrcode)),
        setStockist: (stockistdetails) => dispatch(StockistAction.setStockist(stockistdetails)),
        setCallCenter: (callcenterdetails) => dispatch(CallCenterAction.setCallCenter(callcenterdetails)),
        setManufactur: (manufacturdetails) => dispatch(ManufacturAction.setManufactur(manufacturdetails)),
        setAdmin: (admin) => dispatch(AdminAction.setAdmin(admin)),
        setPatient: (patientdetails) => dispatch(PatientAction.setPatient(patientdetails)),
        setClinic: (clinicdetails) => dispatch(ClinicAction.setClinic(clinicdetails)),
        setDeliveryPerson: (deliveryperson) => dispatch(DeliveryPersonAction.setDeliveryPerson(deliveryperson)),
    }
}

export default connect(null, mapDispatchToProps)(withRouter(Common(Login)))