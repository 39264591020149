import Base from './Base.service'
import { store } from '../redux/index.store'
import {
  GFC_RECEIVED,
  SERUM_READY_TO_DISPATCH


} from './Slugs'
class ClinicService extends Base {
  constructor(props) {
    super(props)
  }

  changegfcReceivedStatus(data) {
    return this.post(GFC_RECEIVED,data);
  }
  serumDispatched(data){
    return this.post(SERUM_READY_TO_DISPATCH,data);
  }

}

export default new ClinicService()