import {
    SET_PATIENT,
    CLEAR_STATE
    
    } from '../types/Patient.type'
    class PatientAction {
      setPatient = (data) => {
        console.log(">>>>>>>>>>>>>>>>",data)
        return { type: SET_PATIENT, payload: data }
      }
      logout = ()=>{
        return { type: CLEAR_STATE, payload: null }
      }
     
      
    }
    export default new PatientAction()