import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from '../../../components/Reusable/WithRouter';
import config from '../../../config/emrok.config';
import Common from '../../../hoc/Common.hoc';
import axios from 'axios';
import moment from 'moment';
import AdminHeader from "../../../components/layout/Admin/AdminHeader";
import AdminSidebar from "../../../components/layout/Admin/AdminSidebar";
import AdminFooter from "../../../components/layout/Admin/AdminFooter";
import '../../../assets/css/admin/style.css';
import '../../../assets/css/admin/responsive.css';
import DropdownDoctorRuntime from '../../../components/SearchableDropdown/DropdownDoctorRuntime.component';
import AdminService from '../../../services/Admin.service';
import CustomDatatable from "../../../components/Datatable/CustomDatatable.component";
import CallCenterAction from '../../../redux/actions/CallCenter.action';
import CountryService from '../../../services/Country.service';
import Dropdown from '../../../components/SearchableDropdown/Dropdown.component';
import AdminAction from '../../../redux/actions/Admin.action';
import DoctorService from '../../../services/Doctor.service';
class DoctorMasterCreate extends Component {
    constructor(props) {
        super(props)

        this.state = {
            fields: {},
            storeSearchData: {
                states: [],
                towns: [],
                selectedTown: '',
                selectedStates: '',
                states_clinic: [],
                towns_clinic: [],
                selectedTown_clinic: '',
                selectedStates_clinic: ''
            },
            states: [],
            towns: [],
            showTowns: false,
            selectedState: "",
            selectedState_new: "",
            selectedTown: "",
            selectedTown_new: "",
            states_clinic: [],
            towns_clinic: [],
            showTowns_clinic: false,
            selectedState_clinic: "",
            selectedTown_clinic: "",
            actionType: "",
            isEdit: "",
            doctorAddress: []
            // division_code:'',
            // division:'',
            // zone:'',
            // region:'',
            // location:'',
            // uid:'',
            // doctor_code:'',
            // doctor_name:'',
            // speciality:'',
            // classification:'',
            // beat:'',
            // is_dispensing:'',
            // town_type:'',
            // email_id:'',
            // mobile:'',
            // state:'',
            // town:'',
            // address_line_1:'',
            // address_line_2:'',
            // pin:'',
            // stockist_id:'',
            // stockist_name:'',
            // stockist_mobile_no:'',
            // stockist_email:'',
            // city_super_clinic_id:'',
            // city_super_clinic_name:'',
            // city_super_clinic_address:'',
            // city_super_clinic_state:'',
            // city_super_clinic_city:'',
            // city_super_clinic_pincode:'',
            // city_super_clinic_mobile:'',
            // city_super_clinic_email:'',
            // bu_head_name:'',
            // bu_head_email:'',
            // bu_head_mobile:'',
            // nsm_name:'',
            // nsm_email:'',
            // nsm_mobile:'',
            // zm_name:'',
            // zm_email:'',
            // zm_mobile:'',
            // rm_name:'',
            // rm_email:'',
            // rm_mobile:'',
            // tm_code:'',
            // tm_name:'',
            // tm_mobile:'',
            // tm_email:''

        }

    }


    componentDidMount() {


        let allDatas = {};

        const doctorDetails = this.props.AdminReducer.doctorDetails;
        const actionType = this.props.AdminReducer.actiontype;

        console.log(doctorDetails, 'doctorDetails');
        this.setState({ actionType: actionType.type })
        console.log(actionType, 888888);
        if (actionType.type == 'Edit') {
            const { fields } = this.state;

            allDatas["division_code"] = doctorDetails.division_code;
            allDatas["division"] = doctorDetails.division;
            allDatas["zone"] = doctorDetails.zone;
            allDatas["region"] = doctorDetails.region;
            allDatas["location"] = doctorDetails.location;
            allDatas["uid"] = doctorDetails.uid;
            allDatas["doctor_code"] = doctorDetails.doctor_code;
            allDatas["doctor_name"] = doctorDetails.name;
            allDatas["speciality"] = doctorDetails.speciality;
            allDatas["classification"] = doctorDetails.classification;
            allDatas["beat"] = doctorDetails.beat;
            allDatas["is_dispensing"] = doctorDetails.is_dispensing;
            allDatas["town_type"] = doctorDetails.address.town_type;
            allDatas["email_id"] = doctorDetails.email;
            allDatas["mobile"] = doctorDetails.mobileno;
            allDatas["state"] = doctorDetails.address.state;
            allDatas["town"] = doctorDetails.address.town;
            allDatas["address_line_1"] = doctorDetails.address.addressLine1;
            allDatas["address_line_2"] = doctorDetails.address.addressLine2;
            allDatas["pin"] = doctorDetails.address.pin;
            allDatas["stockist_id"] = doctorDetails.stockist.plant_id;
            allDatas["stockist_name"] = doctorDetails.stockist.name;
            allDatas["stockist_mobile_no"] = doctorDetails.stockist.mobileno;
            allDatas["stockist_email"] = doctorDetails.stockist.email;
            allDatas["city_super_clinic_id"] = doctorDetails.clinic.clinic_id;
            allDatas["city_super_clinic_name"] = doctorDetails.clinic.name;
            allDatas["city_super_clinic_address"] = doctorDetails.clinic.address.address1;
            allDatas["city_super_clinic_state"] = doctorDetails.clinic.address.state;
            allDatas["city_super_clinic_city"] = doctorDetails.clinic.address.city;
            allDatas["city_super_clinic_pincode"] = doctorDetails.clinic.address.zip;
            allDatas["city_super_clinic_mobile"] = doctorDetails.clinic.mobileno;
            allDatas["city_super_clinic_email"] = doctorDetails.clinic.email;
            allDatas["bu_head_name"] = doctorDetails.bu ? doctorDetails.bu.name : '';
            allDatas["bu_head_email"] = doctorDetails.bu ? doctorDetails.bu.email : '';
            allDatas["bu_head_mobile"] = doctorDetails.bu ? doctorDetails.bu.mobileno : ''
            allDatas["nsm_name"] = doctorDetails.nsm ? doctorDetails.nsm.name : '';
            allDatas["nsm_email"] = doctorDetails.nsm ? doctorDetails.nsm.email : '';
            allDatas["nsm_mobile"] = doctorDetails.nsm ? doctorDetails.nsm.mobileno : '';
            allDatas["zm_name"] = doctorDetails.zm ? doctorDetails.zm.name : '';
            allDatas["zm_email"] = doctorDetails.zm ? doctorDetails.zm.email : '';
            allDatas["zm_mobile"] = doctorDetails.zm ? doctorDetails.zm.mobileno : '';
            allDatas["rm_name"] = doctorDetails.rm ? doctorDetails.rm.name : '';
            allDatas["rm_email"] = doctorDetails.rm ? doctorDetails.rm.email : '';
            allDatas["rm_mobile"] = doctorDetails.rm ? doctorDetails.rm.mobileno : '';
            allDatas["tm_code"] = doctorDetails.mr ? doctorDetails.mr.mr_code : '';
            allDatas["tm_name"] = doctorDetails.mr ? doctorDetails.mr.name : '';
            allDatas["tm_mobile"] = doctorDetails.mr ? doctorDetails.mr.mobileno : '';
            allDatas["tm_email"] = doctorDetails.mr ? doctorDetails.mr.email : '';

            console.log(allDatas, 'allDatas')
            console.log(doctorDetails.address.state, 'state990');
            console.log(doctorDetails.address.town, 'state990');

            // this.setState({selectedState:doctorDetails.address.state})
            // this.setState({selectedTown:doctorDetails.address.town})
            // this.setState({selectedState_new:doctorDetails.address.state})
            setTimeout(() => {
                this.setState({ isEdit: true });
            }, 500);
            this.setState({ fields: allDatas, selectedState_new: doctorDetails.address.state, selectedTown_new: doctorDetails.address.town, selectedState_clinic: doctorDetails.clinic.address.state, selectedTown_clinic: doctorDetails.clinic.address.city, selectedState: doctorDetails.state, selectedTown: doctorDetails.town });
            let details = {
                doctor_id: doctorDetails._id
            };

            this.getMultipleAddress(details);
        } else {
            this.setState({ fields: allDatas });
        }

        console.log(this.state, 10101010)

        console.log(doctorDetails, 999999);
        // console.log(this.state.isFromEdit,'isFromEdit');

        setTimeout(() => {
            this.populateStates("IN")
            this.populateStates_clinic("IN")
            // this.populateStates(countryObj.isoCode)	
        }, 500);
    }

    getMultipleAddress = (payload) => {
        console.log(payload, 699999);
        DoctorService.getdoctorAddress(payload).then(data => {
            console.log(data.data, "doctor details123");

            if (data.data.success && data.data.data !== '') {
                console.log(data.data, "okkkkk doctor details");

                this.setState({ doctorAddress: data.data.data });
            }

        }).catch(err => {

            console.log(err, "err")
        })
    }

    handleBack = () => {
        this.props.navigate('/admin/doctor-master');
    }

    populateStates = (isoCode = null) => {


        if (isoCode) {
            CountryService.getStates(isoCode).then(data => {
                console.log(data, "states")
                if (data.success) {
                    const storeSearchData = this.state.storeSearchData;
                    storeSearchData.states = data.states;

                    console.log(storeSearchData.states, 6666666);
                    this.props.setSearchValue(storeSearchData);
                    this.setState({ states: data.states, towns: [] }, async () => {
                        this.setState({ showTowns: false })
                        let _state = this.state.storeSearchData.selectedStates || null
                        let _states = this.state.storeSearchData.states || []
                        console.log(_state, this.state.storeSearchData.states, "jhgjhgjhg");

                        console.log(this.state.isEdit, 'this.state.isEdit')

                        if (this.state.isEdit) {
                            setTimeout(() => {

                                this.populateTowns(null, null, this.state.selectedState_new)


                            }, 500);

                        }

                        if (_state) {
                            // alert("ok");
                            let stateObj = null
                            let stateObjAll = null
                            for (let i = 0; i < this.state.storeSearchData.states.length; i++) {

                                if (this.state.states[i].name === _state) {
                                    stateObj = this.state.storeSearchData.states[i]
                                }
                            }


                            console.log(stateObj, _state, 1234545677);
                            console.log("stateObj found", stateObj)


                            // if (stateObj) {
                            setTimeout(() => {
                                if (stateObj != null && stateObj.countryCode) {
                                    this.populateTowns(stateObj.countryCode, stateObj.isoCode)

                                }
                            }, 500);
                            // }
                        }
                    })


                    setTimeout(() => {
                        let stateDetails = this.state.storeSearchData.states;

                        const findIfExistAll = stateDetails.find(item => item.name === 'All');

                        console.log(findIfExistAll, 33333);

                        if (!findIfExistAll) {
                            console.log(stateDetails, "details state all 1")
                            const allOption = {
                                "name": "All",
                                "isoCode": null,
                                "countryCode": null,
                                "latitude": null,
                                "longitude": null,
                                "label": "All",
                                "value": "all"
                            };
                            console.log(this.state.storeSearchData.states, "details state")
                            // Create a new array with the "All" option at the beginning
                            let optionsWithAll = [allOption, ...stateDetails];
                            console.log(optionsWithAll, "details state all")
                            this.setState({ states: optionsWithAll })
                            const storeSearchData = this.state.storeSearchData;
                            storeSearchData.states = optionsWithAll;
                            this.props.setSearchValue(storeSearchData);
                        }

                    }, 500);
                }
            })
        }
    }

    populateTowns = async (countryCode = null, stateCode = null, stateName = null) => {
        console.log(countryCode, stateCode, stateName, this.state.isEdit, "countryCode stateCode")



        if ((countryCode && stateCode) || stateName) {


            let getStateCode = await CountryService.getStateCode(stateName);
            console.log(getStateCode, 'getStateCode');

            if (getStateCode.success && getStateCode.stateCode.length > 0) {
                console.log(getStateCode.stateCode, 'getStateCode[0]');
                stateCode = getStateCode.stateCode[0].isoCode;
                countryCode = getStateCode.stateCode[0].countryCode;
            }

            console.log(getStateCode, 'getStateCode');
            console.log(countryCode, 'countryCode0');


            CountryService.getTowns(countryCode, stateCode).then(data => {
                console.log(data, "towns")
                // setTimeout(() => {
                if (data.success) {
                    const storeSearchData = this.state.storeSearchData;
                    storeSearchData.towns = data.towns;
                    this.props.setSearchValue(storeSearchData);
                    this.setState({ towns: data.towns }, () => this.setState({ showTowns: true }))

                    setTimeout(() => {
                        let stateDetails = this.state.storeSearchData.towns;
                        const allOption = {
                            "name": "All",
                            "isoCode": null,
                            "countryCode": null,
                            "latitude": null,
                            "longitude": null,
                            "label": "All",
                            "value": "all"
                        };
                        console.log(this.state.storeSearchData.towns, "details state")
                        // Create a new array with the "All" option at the beginning
                        let optionsWithAll = [allOption, ...stateDetails];
                        console.log(optionsWithAll, "details state all")
                        this.setState({ towns: optionsWithAll })

                        const storeSearchData = this.state.storeSearchData;
                        storeSearchData.towns = optionsWithAll;
                        this.props.setSearchValue(storeSearchData);
                    }, 500);

                    // this.props.setSearchValue(this.state);
                }
                // }, 2000);
            })
        }
    }


    populateStates_clinic = (isoCode = null) => {
        if (isoCode) {
            CountryService.getStates(isoCode).then(data => {
                console.log(data, "states")
                if (data.success) {
                    const storeSearchData = this.state.storeSearchData;
                    storeSearchData.states_clinic = data.states;

                    console.log(storeSearchData.states_clinic, 6666666);
                    this.props.setSearchValue(storeSearchData);
                    this.setState({ states_clinic: data.states, towns_clinic: [] }, async () => {
                        this.setState({ showTowns_clinic: false })
                        let _state = this.state.storeSearchData.selectedStates_clinic || null
                        let _states = this.state.storeSearchData.states_clinic || []
                        console.log(_state, this.state.storeSearchData.states_clinic, "jhgjhgjhg");

                        if (this.state.isEdit) {
                            setTimeout(() => {

                                this.populateTowns_clinic(null, null, this.state.selectedState_clinic)


                            }, 500);

                        }

                        if (_state) {
                            // alert("ok");
                            let stateObj = null
                            let stateObjAll = null
                            for (let i = 0; i < this.state.storeSearchData.states_clinic.length; i++) {

                                if (this.state.states_clinic[i].name === _state) {
                                    stateObj = this.state.storeSearchData.states_clinic[i]
                                }
                            }


                            console.log(stateObj, _state, 1234545677);
                            console.log("stateObj found", stateObj)
                            // if (stateObj) {
                            setTimeout(() => {
                                if (stateObj != null && stateObj.countryCode)
                                    this.populateTowns_clinic(stateObj.countryCode, stateObj.isoCode)
                            }, 500);
                            // }
                        }
                    })


                    setTimeout(() => {
                        let stateDetails = this.state.storeSearchData.states;

                        const findIfExistAll = stateDetails.find(item => item.name === 'All');

                        console.log(findIfExistAll, 33333);

                        if (!findIfExistAll) {
                            console.log(stateDetails, "details state all 1")
                            const allOption = {
                                "name": "All",
                                "isoCode": null,
                                "countryCode": null,
                                "latitude": null,
                                "longitude": null,
                                "label": "All",
                                "value": "all"
                            };
                            console.log(this.state.storeSearchData.states, "details state")
                            // Create a new array with the "All" option at the beginning
                            let optionsWithAll = [allOption, ...stateDetails];
                            console.log(optionsWithAll, "details state all")
                            this.setState({ states: optionsWithAll })
                            const storeSearchData = this.state.storeSearchData;
                            storeSearchData.states = optionsWithAll;
                            this.props.setSearchValue(storeSearchData);
                        }

                    }, 500);
                }
            })
        }
    }

    populateTowns_clinic = async (countryCode = null, stateCode = null, stateName = null) => {
        console.log(countryCode, stateCode, "countryCode stateCode")
        if ((countryCode && stateCode) || stateName) {

            let getStateCode = await CountryService.getStateCode(stateName);
            console.log(getStateCode, 'getStateCode');

            if (getStateCode.success && getStateCode.stateCode.length > 0) {
                console.log(getStateCode.stateCode, 'getStateCode[0]');
                stateCode = getStateCode.stateCode[0].isoCode;
                countryCode = getStateCode.stateCode[0].countryCode;
            }

            console.log(getStateCode, 'getStateCode');
            console.log(countryCode, 'countryCode0');


            CountryService.getTowns(countryCode, stateCode).then(data => {
                console.log(data, "towns")
                // setTimeout(() => {
                if (data.success) {
                    const storeSearchData = this.state.storeSearchData;
                    storeSearchData.towns_clinic = data.towns;
                    this.props.setSearchValue(storeSearchData);
                    this.setState({ towns_clinic: data.towns }, () => this.setState({ showTowns_clinic: true }))

                    setTimeout(() => {
                        let stateDetails = this.state.storeSearchData.towns_clinic;
                        const allOption = {
                            "name": "All",
                            "isoCode": null,
                            "countryCode": null,
                            "latitude": null,
                            "longitude": null,
                            "label": "All",
                            "value": "all"
                        };
                        console.log(this.state.storeSearchData.towns_clinic, "details state")
                        // Create a new array with the "All" option at the beginning
                        let optionsWithAll = [allOption, ...stateDetails];
                        console.log(optionsWithAll, "details state all")
                        this.setState({ towns_clinic: optionsWithAll })

                        const storeSearchData = this.state.storeSearchData;
                        storeSearchData.towns_clinic = optionsWithAll;
                        this.props.setSearchValue(storeSearchData);
                    }, 500);

                    // this.props.setSearchValue(this.state);
                }
                // }, 2000);
            })
        }
    }

    changeVal = (e) => {
        const { fields } = this.state;
        let name = e.target.name;
        let value = e.target.value;

        if (name === 'mobile' || name === 'division_code' || name == 'pin' || name === 'stockist_id' || name === 'stockist_mobile_no' || name === 'city_super_clinic_pincode' || name === 'city_super_clinic_mobile' || name === 'tm_mobile' || name === 'tm_code' || name === 'rm_mobile' || name === 'zm_mobile' || name === 'nsm_mobile' || name === 'bu_head_mobile') {
            console.log('in mobile check');
            if (/^\d*$/.test(value)) {

            } else {
                value = '';
            }
        }

        // if(name==='email_id' || name==='stockist_email' || name==='city_super_clinic_email' || name==='tm_email' || name==='rm_email' || name==='zm_email' || name==='nsm_email' || name==='bu_head_email'){
        //     if (!this.props.hoc.verifyEmail(fields[name])) {
        //         // alert(2);
        //         this.props.hoc.customAlert('Please enter a valid Email.', false)
        //         return false
        //     }

        // }
        fields[name] = value;
        this.setState({ fields });

    }

    checkEmail = (e) => {
        const { fields } = this.state;
        let name = e.target.name;
        let value = e.target.value;
        if (name === 'email_id' || name === 'stockist_email' || name === 'city_super_clinic_email' || name === 'tm_email' || name === 'rm_email' || name === 'zm_email' || name === 'nsm_email' || name === 'bu_head_email') {
            if (!this.props.hoc.verifyEmail(fields[name])) {
                // alert(2);
                this.props.hoc.customAlert('Please enter a valid Email.', false)
                return false
            }

        }

    }
    submit = async () => {
        const { fields, states, selectedState, selectedState_new, towns, selectedTown, selectedTown_new, states_clinic, selectedState_clinic, towns_clinic, selectedTown_clinic, actionType, doctorAddress } = this.state;
        fields['state'] = selectedState_new;
        fields['town'] = selectedTown_new;
        fields['clinic_state'] = selectedState_clinic;
        fields['clinic_town'] = selectedTown_clinic;
        fields['doctor_address'] = doctorAddress;


        console.log(fields, 5555);
        // return false;

        // return false;
        let errors = {};
        let isFormValid = false;
        // alert('Please enter a valid Doctor Mobile No.');
        if (fields.hasOwnProperty('email_id') == true && fields['email_id'] != '') {
            // alert(1);
            if (!this.props.hoc.verifyEmail(fields['email_id'])) {
                // alert(2);
                this.props.hoc.customAlert('Please enter a valid Doctor Email.', false)
                return false
            }
        }
        if (fields.hasOwnProperty('stockist_email') == true && fields['stockist_email'] != '') {

            if (!this.props.hoc.verifyEmail(fields['stockist_email'])) {
                this.props.hoc.customAlert('Please enter a valid Stockist Email.', false)
                return false
            }

        }
        if (fields.hasOwnProperty('city_super_clinic_email') == true && fields['city_super_clinic_email'] != '') {
            if (!this.props.hoc.verifyEmail(fields['city_super_clinic_email'])) {
                this.props.hoc.customAlert('Please enter a valid Koshin Clinic Email.', false)
                return false
            }

        }
        if (fields.hasOwnProperty('tm_email') == true && fields['tm_email'] != '') {
            // alert(this.props.hoc.verifyEmail(fields['tm_email']));

            if (!this.props.hoc.verifyEmail(fields['tm_email'])) {
                this.props.hoc.customAlert('Please enter a valid TM Email.', false)
                return false
            }

        }
        // if (fields.hasOwnProperty('rm_email') == true && fields['rm_email'] != '') {
        //     if (!this.props.hoc.verifyEmail(fields['rm_email'])) {
        //         this.props.hoc.customAlert('Please enter a valid RM Email.', false)
        //         return false
        //     }

        // }
        if (fields.hasOwnProperty('pin') == true && fields['pin'].toString().length < 6) {
            this.props.hoc.customAlert('Please enter a valid Doctor Pin Code', false);
            return false;
        } else if (fields.hasOwnProperty('city_super_clinic_pincode') == true && fields['city_super_clinic_pincode'].toString().length < 6) {
            this.props.hoc.customAlert('Please enter a valid Koshin Clinic Pin Code', false);
            return false;
        }
        else if (fields.hasOwnProperty('mobile') == true && fields['mobile'].toString().length < 10) {

            this.props.hoc.customAlert('Please enter a valid Doctor Mobile No.', false)
            return false

        } else if (fields.hasOwnProperty('stockist_mobile_no') == true && fields['stockist_mobile_no'].toString().length < 10) {
            this.props.hoc.customAlert('Please enter a valid Stockist Mobile No.', false)
            return false

        } else if (fields.hasOwnProperty('city_super_clinic_mobile') == true && fields['city_super_clinic_mobile'].toString().length < 10) {
            this.props.hoc.customAlert('Please enter a valid Koshin Clinic Mobile No.', false)
            return false

        } else if (fields.hasOwnProperty('tm_mobile') == true && fields['tm_mobile'].toString().length < 10) {
            this.props.hoc.customAlert('Please enter a valid TM Mobile No.', false)
            return false

        } 
        // else if (fields.hasOwnProperty('rm_mobile') == true && fields['rm_mobile'].toString().length < 10) {
        //     this.props.hoc.customAlert('Please enter a valid RM Mobile No.', false)
        //     return false

        // }
        
        else {
            try {
                // this.setState({ callCenterList: [] })
                let payload = {
                    mobileno: 8145209604
                }

                console.log(fields, 6666666);
                let res = await AdminService.createDoctorMaster(fields)
                console.log(res, 123333)
                if (res.data.success) {
                    if (actionType == 'Add') {
                        // this.props.hoc.customAlert('Doctor added successfully.', true);

                        this.props.hoc.customAlert('Doctor added successfully', true, "", false, true, async () => {
                            this.props.navigate('/admin/doctor-master');
                        }, () => {

                        })


                    } else {
                        // this.props.hoc.customAlert('Doctor edited successfully.', true);
                        this.props.hoc.customAlert('Doctor edited successfully', true, "", false, true, async () => {
                            this.props.navigate('/admin/doctor-master');
                        }, () => {

                        })
                    }


                    console.log(res.data.data.data, "lasjdhjkash");
                    // this.setState({ callCenterList: res.data.data.data });
                } else {
                    this.props.hoc.customAlert('Something went wrong', false);

                }
            } catch (error) {
                this.props.hoc.customAlert('Something went wrong', false);
            }
        }

        console.log(fields, 9999);
    }

    handleInputChange = (index, event) => {
        const { doctorAddress } = this.state;
        const { name, value } = event.target;
        const updatedData = [...doctorAddress];
        updatedData[index][name] = value;
        this.setState({ doctorAddress: updatedData });
    };


    render = () => {
        const { states, selectedState, towns, selectedTown, states_clinic, selectedState_clinic, towns_clinic, selectedTown_clinic, actionType, selectedState_new, selectedTown_new, isEdit, doctorAddress } = this.state;

        console.log(doctorAddress, 222);

        console.log(isEdit, selectedState_new, selectedTown_new, selectedState, selectedTown, selectedState_clinic, selectedTown_clinic, 96969696)
        // let _data = this.setOrderHistoryData(callCenterList)
        // console.log(_data, "aksdhklsah");
        const { fields } = this.state;
        console.log(fields, 3333333)
        return (
            <>
                <div className="layout-wrapper">
                    <AdminHeader />
                    <AdminSidebar />
                    <div className="main-content">
                        <div className="page-content">
                            <div className="container-fluid">
                                <div className="row g-4">
                                    <div className='col-md-6'>
                                        <h5 className='mb-20 headingstyle'>Doctor Master {actionType}</h5>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className="btn-home flotright" onClick={this.handleBack}>
                                            <a href="javascript:void(0);">
                                                Back
                                            </a>
                                        </div>
                                    </div>
                                    {/* Row end */}
                                    <div className="row g-4">
                                        <form className="mt-3 registration-form">
                                            <div className="row g-4">
                                                <div className='col-md-6'>
                                                    <h5 className='mb-20 mt-20 headingstyle'>Doctor Details:</h5>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-3 mb-3">
                                                    <label htmlFor className="form-label lvl-clr-hd">Division Code <span className='requiredFld'> * </span></label>
                                                    <input type="text" value={this.state.fields["division_code"]} name="division_code" onChange={(event) => this.changeVal(event)} id className="form-control name-pt-ft" placeholder />
                                                </div>

                                                <div className="col-md-3 mb-3">
                                                    <label htmlFor className="form-label lvl-clr-hd">Division <span className='requiredFld'> * </span></label>
                                                    <input type="text" value={this.state.fields["division"]} name="division" onChange={(event) => this.changeVal(event)} id className="form-control name-pt-ft" placeholder />
                                                </div>
                                                <div className="col-md-3 mb-3">
                                                    <label htmlFor="disabledTextInput" className="form-label lvl-clr-hd">Zone <span className='requiredFld'> * </span>
                                                    </label>
                                                    <input type="text" value={this.state.fields["zone"]} onChange={(event) => this.changeVal(event)} name="zone" id className="form-control name-pt-ft" placeholder />
                                                </div>
                                                <div className="col-md-3 mb-3">
                                                    <label htmlFor="disabledTextInput" className="form-label lvl-clr-hd">Region <span className='requiredFld'> * </span>
                                                    </label>
                                                    <input type="text" value={this.state.fields["region"]} onChange={(event) => this.changeVal(event)} name="region" id className="form-control name-pt-ft" placeholder />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-3 mb-3">
                                                    <label htmlFor className="form-label lvl-clr-hd">Location <span className='requiredFld'> * </span></label>
                                                    <input type="text" value={this.state.fields["location"]} name="location" onChange={(event) => this.changeVal(event)} id className="form-control name-pt-ft" placeholder />
                                                </div>

                                                <div className="col-md-3 mb-3">
                                                    <label htmlFor className="form-label lvl-clr-hd">UID <span className='requiredFld'> * </span></label>
                                                    <input type="text" value={this.state.fields["uid"]} name="uid" onChange={(event) => this.changeVal(event)} id className="form-control name-pt-ft" placeholder readOnly={actionType === 'Edit'} />
                                                </div>
                                                <div className="col-md-3 mb-3">
                                                    <label htmlFor="disabledTextInput" className="form-label lvl-clr-hd">Doctor Code <span className='requiredFld'> * </span>
                                                    </label>
                                                    <input type="text" value={this.state.fields["doctor_code"]} onChange={(event) => this.changeVal(event)} name="doctor_code" id className="form-control name-pt-ft" placeholder readOnly={actionType === 'Edit'} />
                                                </div>
                                                <div className="col-md-3 mb-3">
                                                    <label htmlFor="disabledTextInput" className="form-label lvl-clr-hd">Doctor Name <span className='requiredFld'> * </span>
                                                    </label>
                                                    <input type="text" value={this.state.fields["doctor_name"]} onChange={(event) => this.changeVal(event)} name="doctor_name" id className="form-control name-pt-ft" placeholder />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-3 mb-3">
                                                    <label htmlFor className="form-label lvl-clr-hd">Speciality <span className='requiredFld'> * </span></label>
                                                    <input type="text" value={this.state.fields["speciality"]} name="speciality" onChange={(event) => this.changeVal(event)} id className="form-control name-pt-ft" placeholder />
                                                </div>

                                                <div className="col-md-3 mb-3">
                                                    <label htmlFor className="form-label lvl-clr-hd">Clasification <span className='requiredFld'> * </span></label>
                                                    <input type="text" value={this.state.fields["classification"]} name="classification" onChange={(event) => this.changeVal(event)} id className="form-control name-pt-ft" placeholder />
                                                </div>
                                                <div className="col-md-3 mb-3">
                                                    <label htmlFor="disabledTextInput" className="form-label lvl-clr-hd">Beat
                                                    </label>
                                                    <input type="text" value={this.state.fields["beat"]} onChange={(event) => this.changeVal(event)} name="beat" id className="form-control name-pt-ft" placeholder />
                                                </div>
                                                <div className="col-md-3 mb-3">
                                                    <label htmlFor="disabledTextInput" className="form-label lvl-clr-hd">Is Dispensing
                                                    </label>
                                                    <input type="text" value={this.state.fields["is_dispensing"]} onChange={(event) => this.changeVal(event)} name="is_dispensing" id className="form-control name-pt-ft" placeholder />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-3 mb-3">
                                                    <label htmlFor className="form-label lvl-clr-hd">Town Type <span className='requiredFld'> * </span></label>
                                                    <input type="text" value={this.state.fields["town_type"]} name="town_type" onChange={(event) => this.changeVal(event)} id className="form-control name-pt-ft" placeholder />
                                                </div>

                                                <div className="col-md-3 mb-3">
                                                    <label htmlFor className="form-label lvl-clr-hd">Email ID <span className='requiredFld'> * </span></label>
                                                    <input type="text" value={this.state.fields["email_id"]} name="email_id" onChange={(event) => this.changeVal(event)} onBlur={(event) => this.checkEmail(event)} id className="form-control name-pt-ft" placeholder />
                                                </div>
                                                <div className="col-md-3 mb-3">
                                                    <label htmlFor="disabledTextInput" className="form-label lvl-clr-hd">Mobile No. <span className='requiredFld'> * </span>
                                                    </label>
                                                    <input type="text" onInput={(e) => e.target.value = e.target.value.slice(0, 10)} value={this.state.fields["mobile"]} onChange={(event) => this.changeVal(event)} name="mobile" id className="form-control name-pt-ft" placeholder />
                                                </div>
                                                <div className="col-md-3 mb-3">
                                                    <label htmlFor="disabledTextInput" className="form-label lvl-clr-hd">State <span className='requiredFld'> * </span>
                                                    </label>
                                                    {/* <input type="text" value={this.state.fields["state"]} onChange={(event) => this.changeVal(event)} name="state" id className="form-control name-pt-ft" placeholder /> */}


                                                    {
                                                        states.length ?
                                                            <Dropdown
                                                                items={states}
                                                                onSelect={(item) => {
                                                                    if (item) {
                                                                        // let _registration = this.state.registration
                                                                        // _registration.address["state"] = item.name
                                                                        // _registration.address["town"] = ""
                                                                        this.setState({ selectedState: item.name, selectedState_new: item.name })
                                                                        const storeSearchData = this.state.storeSearchData;
                                                                        storeSearchData.selectedStates = item.name;
                                                                        this.props.setSearchValue(storeSearchData);
                                                                        //console.log(item, "item");
                                                                        // alert(item.name)
                                                                        if (item.name != "All") {
                                                                            this.populateTowns(item.countryCode, item.isoCode)
                                                                        } else {
                                                                            this.setState({ towns: [] })
                                                                            this.setState({ selectedTown: "", selectedTown_new: "" })
                                                                            const storeSearchData = this.state.storeSearchData;
                                                                            storeSearchData.towns = [];
                                                                            storeSearchData.selectedTown = "";
                                                                            this.props.setSearchValue(storeSearchData);
                                                                        }

                                                                    }
                                                                }}
                                                                value={selectedState_new}
                                                            /> :
                                                            <Dropdown
                                                                items={states}
                                                                value={selectedState_new}
                                                            />
                                                    }
                                                </div>

                                            </div>

                                            <div className="row">
                                                <div className="col-md-3 mb-3">
                                                    <label htmlFor className="form-label lvl-clr-hd">Town<span className='requiredFld'> * </span></label>
                                                    {/* <input type="text" value={this.state.fields["town"]} name="town" onChange={(event) => this.changeVal(event)} id className="form-control name-pt-ft" placeholder /> */}
                                                    {
                                                        towns.length ?
                                                            <Dropdown
                                                                items={towns}
                                                                onSelect={(item) => {
                                                                    if (item) {
                                                                        // alert(item.name);
                                                                        // let _registration = this.state.registration
                                                                        // _registration.address["town"] = item.name
                                                                        this.setState({ selectedTown: item.name, selectedTown_new: item.name })
                                                                        const storeSearchData = this.state.storeSearchData;
                                                                        storeSearchData.selectedTown = item.name;
                                                                        this.props.setSearchValue(storeSearchData);
                                                                        //console.log(item, "item")
                                                                    }
                                                                }}
                                                                value={selectedTown_new}
                                                            /> :
                                                            <Dropdown
                                                                items={towns}
                                                                value={selectedTown_new}
                                                            />
                                                    }
                                                </div>

                                                <div className="col-md-3 mb-3">
                                                    <label htmlFor className="form-label lvl-clr-hd">Address Line 1 <span className='requiredFld'> * </span></label>
                                                    <input type="text" value={this.state.fields["address_line_1"]} name="address_line_1" onChange={(event) => this.changeVal(event)} id className="form-control name-pt-ft" placeholder />
                                                </div>
                                                <div className="col-md-3 mb-3">
                                                    <label htmlFor="disabledTextInput" className="form-label lvl-clr-hd">Address Line 2 <span className='requiredFld'> * </span>
                                                    </label>
                                                    <input type="text" value={this.state.fields["address_line_2"]} onChange={(event) => this.changeVal(event)} name="address_line_2" id className="form-control name-pt-ft" placeholder />
                                                </div>
                                                <div className="col-md-3 mb-3">
                                                    <label htmlFor="disabledTextInput" className="form-label lvl-clr-hd">Pin Code <span className='requiredFld'> * </span>
                                                    </label>
                                                    <input type="text" onInput={(e) => e.target.value = e.target.value.slice(0, 6)} value={this.state.fields["pin"]} onChange={(event) => this.changeVal(event)} name="pin" id className="form-control name-pt-ft" placeholder />
                                                </div>

                                            </div>

                                            {
                                                actionType === 'Edit' ?
                                                    <>
                                                        <div className="row g-4">
                                                            <div className='col-md-6'>
                                                                <h5 className='mb-20 mt-20 headingstyle'>Doctor Clinic Multiple Address:</h5>
                                                            </div>
                                                        </div>

                                                        {doctorAddress.map((item, index) => (
                                                            <div className='row'>
                                                               <p className='addressheading'>{ item.address_type=='default'? 'Default Address ':'Address '+(index) }</p>
                                                                <div className="col-md-3 mb-3">
                                                                    <label htmlFor className="form-label lvl-clr-hd">Town</label>
                                                                    <input type="text" value={item.town} name="town"  id className="form-control name-pt-ft" placeholder disabled/>
                                                                </div>

                                                                <div className="col-md-3 mb-3">
                                                                    <label htmlFor className="form-label lvl-clr-hd">Address Line 1 <span className='requiredFld'> * </span></label>
                                                                    <input type="text" value={item.addressLine1} name="addressLine1" onChange={(event) => this.handleInputChange(index, event)} id className="form-control name-pt-ft" placeholder />
                                                                </div>
                                                                <div className="col-md-3 mb-3">
                                                                    <label htmlFor="disabledTextInput" className="form-label lvl-clr-hd">Address Line 2 <span className='requiredFld'> * </span>
                                                                    </label>
                                                                    <input type="text" value={item.addressLine2} onChange={(event) => this.handleInputChange(index, event)} name="addressLine2" id className="form-control name-pt-ft" placeholder />
                                                                </div>
                                                                <div className="col-md-3 mb-3">
                                                                    <label htmlFor="disabledTextInput" className="form-label lvl-clr-hd">Pin Code <span className='requiredFld'> * </span>
                                                                    </label>
                                                                    <input type="text" onInput={(e) => e.target.value = e.target.value.slice(0, 6)} value={item.pin} onChange={(event) => this.handleInputChange(index, event)} name="pin" id className="form-control name-pt-ft" placeholder />
                                                                </div>
                                                            </div>))}
                                                    </>
                                                    : ''
                                            }


                                            <div className="row g-4">
                                                <div className='col-md-6'>
                                                    <h5 className='mb-20 mt-20 headingstyle'>Stockist Details:</h5>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-3 mb-3">
                                                    <label htmlFor className="form-label lvl-clr-hd">Stockist ID <span className='requiredFld'> * </span></label>
                                                    <input type="text" value={this.state.fields["stockist_id"]} name="stockist_id" onChange={(event) => this.changeVal(event)} id className="form-control name-pt-ft" placeholder />
                                                </div>

                                                <div className="col-md-3 mb-3">
                                                    <label htmlFor className="form-label lvl-clr-hd">Stockist Name <span className='requiredFld'> * </span></label>
                                                    <input type="text" value={this.state.fields["stockist_name"]} name="stockist_name" onChange={(event) => this.changeVal(event)} id className="form-control name-pt-ft" placeholder />
                                                </div>


                                                <div className="col-md-3 mb-3">
                                                    <label htmlFor="disabledTextInput" className="form-label lvl-clr-hd">Stockist Mobile No. <span className='requiredFld'> * </span>
                                                    </label>
                                                    <input type="text" onInput={(e) => e.target.value = e.target.value.slice(0, 10)} value={this.state.fields["stockist_mobile_no"]} onChange={(event) => this.changeVal(event)} name="stockist_mobile_no" id className="form-control name-pt-ft" placeholder />
                                                </div>
                                                <div className="col-md-3 mb-3">
                                                    <label htmlFor="disabledTextInput" className="form-label lvl-clr-hd">Stockist Email <span className='requiredFld'> * </span>
                                                    </label>
                                                    <input type="text" value={this.state.fields["stockist_email"]} onChange={(event) => this.changeVal(event)} onBlur={(event) => this.checkEmail(event)} name="stockist_email" id className="form-control name-pt-ft" placeholder />
                                                </div>

                                            </div>

                                            <div className="row g-4">
                                                <div className='col-md-6'>
                                                    <h5 className='mb-20 mt-20 headingstyle'>Koshin Clinic Details:</h5>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-3 mb-3">
                                                    <label htmlFor className="form-label lvl-clr-hd">Koshin Clinic ID <span className='requiredFld'> * </span></label>
                                                    <input type="text" value={this.state.fields["city_super_clinic_id"]} name="city_super_clinic_id" onChange={(event) => this.changeVal(event)} id className="form-control name-pt-ft" placeholder />
                                                </div>

                                                <div className="col-md-3 mb-3">
                                                    <label htmlFor className="form-label lvl-clr-hd">Koshin Clinic Name <span className='requiredFld'> * </span></label>
                                                    <input type="text" value={this.state.fields["city_super_clinic_name"]} name="city_super_clinic_name" onChange={(event) => this.changeVal(event)} id className="form-control name-pt-ft" placeholder />
                                                </div>


                                                <div className="col-md-3 mb-3">
                                                    <label htmlFor="disabledTextInput" className="form-label lvl-clr-hd">Koshin Clinic Address <span className='requiredFld'> * </span>
                                                    </label>
                                                    <input type="text" value={this.state.fields["city_super_clinic_address"]} onChange={(event) => this.changeVal(event)} name="city_super_clinic_address" id className="form-control name-pt-ft" placeholder />
                                                </div>
                                                <div className="col-md-3 mb-3">
                                                    <label htmlFor="disabledTextInput" className="form-label lvl-clr-hd">Koshin Clinic State<span className='requiredFld'> * </span>
                                                    </label>
                                                    {/* <input type="text" value={this.state.fields["city_super_clinic_state"]} onChange={(event) => this.changeVal(event)} name="city_super_clinic_state" id className="form-control name-pt-ft" placeholder /> */}

                                                    {
                                                        states_clinic.length ?
                                                            <Dropdown
                                                                items={states_clinic}
                                                                onSelect={(item) => {
                                                                    if (item) {
                                                                        // let _registration = this.state.registration
                                                                        // _registration.address["state"] = item.name
                                                                        // _registration.address["town"] = ""
                                                                        this.setState({ selectedState_clinic: item.name })
                                                                        const storeSearchData = this.state.storeSearchData;
                                                                        storeSearchData.selectedState_clinic = item.name;
                                                                        this.props.setSearchValue(storeSearchData);
                                                                        //console.log(item, "item");
                                                                        // alert(item.name)
                                                                        if (item.name != "All") {
                                                                            this.populateTowns_clinic(item.countryCode, item.isoCode)
                                                                        } else {
                                                                            this.setState({ towns_clinic: [] })
                                                                            this.setState({ selectedTown_clinic: "" })
                                                                            const storeSearchData = this.state.storeSearchData;
                                                                            storeSearchData.towns_clinic = [];
                                                                            storeSearchData.selectedTown_clinic = "";
                                                                            this.props.setSearchValue(storeSearchData);
                                                                        }

                                                                    }
                                                                }}
                                                                value={selectedState_clinic}
                                                            /> :
                                                            <Dropdown
                                                                items={states_clinic}
                                                                value={selectedState_clinic}
                                                            />
                                                    }
                                                </div>

                                            </div>
                                            <div className="row">
                                                <div className="col-md-3 mb-3">
                                                    <label htmlFor className="form-label lvl-clr-hd">Koshin Clinic City <span className='requiredFld'> * </span></label>
                                                    {
                                                        towns_clinic.length ?
                                                            <Dropdown
                                                                items={towns_clinic}
                                                                onSelect={(item) => {
                                                                    if (item) {
                                                                        // alert(item.name);
                                                                        // let _registration = this.state.registration
                                                                        // _registration.address["town"] = item.name
                                                                        this.setState({ selectedTown_clinic: item.name })
                                                                        const storeSearchData = this.state.storeSearchData;
                                                                        storeSearchData.selectedTown_clinic = item.name;
                                                                        this.props.setSearchValue(storeSearchData);
                                                                        //console.log(item, "item")
                                                                    }
                                                                }}
                                                                value={selectedTown_clinic}
                                                            /> :
                                                            <Dropdown
                                                                items={towns_clinic}
                                                                value={selectedTown_clinic}
                                                            />
                                                    }


                                                    {/* <input type="text" value={this.state.fields["city_super_clinic_city"]} name="city_super_clinic_city" onChange={(event) => this.changeVal(event)} id className="form-control name-pt-ft" placeholder /> */}
                                                </div>

                                                <div className="col-md-3 mb-3">
                                                    <label htmlFor className="form-label lvl-clr-hd">Koshin Clinic Pin Code <span className='requiredFld'> * </span></label>
                                                    <input type="text" onInput={(e) => e.target.value = e.target.value.slice(0, 6)} value={this.state.fields["city_super_clinic_pincode"]} name="city_super_clinic_pincode" onChange={(event) => this.changeVal(event)} id className="form-control name-pt-ft" placeholder />
                                                </div>


                                                <div className="col-md-3 mb-3">
                                                    <label htmlFor="disabledTextInput" className="form-label lvl-clr-hd">Koshin Clinic Mobile No. <span className='requiredFld'> * </span>
                                                    </label>
                                                    <input type="text" onInput={(e) => e.target.value = e.target.value.slice(0, 10)} value={this.state.fields["city_super_clinic_mobile"]} onChange={(event) => this.changeVal(event)} name="city_super_clinic_mobile" id className="form-control name-pt-ft" placeholder />
                                                </div>
                                                <div className="col-md-3 mb-3">
                                                    <label htmlFor="disabledTextInput" className="form-label lvl-clr-hd">Koshin Clinic Email <span className='requiredFld'> * </span>
                                                    </label>
                                                    <input type="text" value={this.state.fields["city_super_clinic_email"]} onChange={(event) => this.changeVal(event)} onBlur={(event) => this.checkEmail(event)} name="city_super_clinic_email" id className="form-control name-pt-ft" placeholder />
                                                </div>

                                            </div>


                                            <div className="row g-4">
                                                <div className='col-md-6'>
                                                    <h5 className='mb-20 mt-20 headingstyle'>MR Details:</h5>
                                                </div>
                                            </div>
                                            <div className="row">


                                                <div className="col-md-3 mb-3">
                                                    <label htmlFor className="form-label lvl-clr-hd">MR Name <span className='requiredFld'> * </span></label>
                                                    <input type="text" value={this.state.fields["tm_name"]} name="tm_name" onChange={(event) => this.changeVal(event)} id className="form-control name-pt-ft" placeholder />
                                                </div>


                                                <div className="col-md-3 mb-3">
                                                    <label htmlFor="disabledTextInput" className="form-label lvl-clr-hd">MR Email <span className='requiredFld'> * </span>
                                                    </label>
                                                    <input type="text" value={this.state.fields["tm_email"]} onChange={(event) => this.changeVal(event)} onBlur={(event) => this.checkEmail(event)} name="tm_email" id className="form-control name-pt-ft" placeholder />
                                                </div>
                                                <div className="col-md-3 mb-3">
                                                    <label htmlFor="disabledTextInput" className="form-label lvl-clr-hd">MR Mobile No. <span className='requiredFld'> * </span>
                                                    </label>
                                                    <input type="text" onInput={(e) => e.target.value = e.target.value.slice(0, 10)} value={this.state.fields["tm_mobile"]} onChange={(event) => this.changeVal(event)} name="tm_mobile" id className="form-control name-pt-ft" placeholder />
                                                </div>
                                                <div className="col-md-3 mb-3">
                                                    <label htmlFor className="form-label lvl-clr-hd">MR Code <span className='requiredFld'> * </span></label>
                                                    <input type="text" value={this.state.fields["tm_code"]} name="tm_code" onChange={(event) => this.changeVal(event)} id className="form-control name-pt-ft" placeholder />
                                                </div>

                                            </div>
                                            <div className="row g-4">
                                                <div className='col-md-6'>
                                                    <h5 className='mb-20 mt-20 headingstyle'>RM Details:</h5>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-3 mb-3">
                                                    <label htmlFor className="form-label lvl-clr-hd">RM Name </label>
                                                    <input type="text" value={this.state.fields["rm_name"]} name="rm_name" onChange={(event) => this.changeVal(event)} id className="form-control name-pt-ft" placeholder />
                                                </div>

                                                <div className="col-md-3 mb-3">
                                                    <label htmlFor className="form-label lvl-clr-hd">RM Email </label>
                                                    <input type="text" value={this.state.fields["rm_email"]} name="rm_email" onChange={(event) => this.changeVal(event)} id className="form-control name-pt-ft" placeholder />
                                                </div>


                                                <div className="col-md-3 mb-3">
                                                    <label htmlFor="disabledTextInput" className="form-label lvl-clr-hd">RM Mobile No.
                                                    </label>
                                                    <input type="text" onInput={(e) => e.target.value = e.target.value.slice(0, 10)} value={this.state.fields["rm_mobile"]} onChange={(event) => this.changeVal(event)} name="rm_mobile" id className="form-control name-pt-ft" placeholder />
                                                </div>

                                            </div>
                                            <div className="row g-4">
                                                <div className='col-md-6'>
                                                    <h5 className='mb-20 mt-20 headingstyle'>ZM Details:</h5>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-3 mb-3">
                                                    <label htmlFor className="form-label lvl-clr-hd">ZM Name</label>
                                                    <input type="text" value={this.state.fields["zm_name"]} name="zm_name" onChange={(event) => this.changeVal(event)} id className="form-control name-pt-ft" placeholder />
                                                </div>

                                                <div className="col-md-3 mb-3">
                                                    <label htmlFor className="form-label lvl-clr-hd">ZM Email</label>
                                                    <input type="text" value={this.state.fields["zm_email"]} name="zm_email" onChange={(event) => this.changeVal(event)} onBlur={(event) => this.checkEmail(event)} id className="form-control name-pt-ft" placeholder />
                                                </div>


                                                <div className="col-md-3 mb-3">
                                                    <label htmlFor="disabledTextInput" className="form-label lvl-clr-hd">ZM Mobile No.
                                                    </label>
                                                    <input type="text" onInput={(e) => e.target.value = e.target.value.slice(0, 10)} value={this.state.fields["zm_mobile"]} onChange={(event) => this.changeVal(event)} name="zm_mobile" id className="form-control name-pt-ft" placeholder />
                                                </div>

                                            </div>
                                            <div className="row g-4">
                                                <div className='col-md-6'>
                                                    <h5 className='mb-20 mt-20 headingstyle'>NSM Details:</h5>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-3 mb-3">
                                                    <label htmlFor className="form-label lvl-clr-hd">NSM Name</label>
                                                    <input type="text" value={this.state.fields["nsm_name"]} name="nsm_name" onChange={(event) => this.changeVal(event)} id className="form-control name-pt-ft" placeholder />
                                                </div>

                                                <div className="col-md-3 mb-3">
                                                    <label htmlFor className="form-label lvl-clr-hd">NSM Email</label>
                                                    <input type="text" value={this.state.fields["nsm_email"]} name="nsm_email" onChange={(event) => this.changeVal(event)} onBlur={(event) => this.checkEmail(event)} id className="form-control name-pt-ft" placeholder />
                                                </div>


                                                <div className="col-md-3 mb-3">
                                                    <label htmlFor="disabledTextInput" className="form-label lvl-clr-hd">NSM Mobile No
                                                    </label>
                                                    <input type="text" onInput={(e) => e.target.value = e.target.value.slice(0, 10)} value={this.state.fields["nsm_mobile"]} onChange={(event) => this.changeVal(event)} name="nsm_mobile" id className="form-control name-pt-ft" placeholder />
                                                </div>

                                            </div>
                                            <div className="row g-4">
                                                <div className='col-md-6'>
                                                    <h5 className='mb-20 mt-20 headingstyle'>BU Head Details:</h5>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-3 mb-3">
                                                    <label htmlFor className="form-label lvl-clr-hd">BU Head Name</label>
                                                    <input type="text" value={this.state.fields["bu_head_name"]} name="bu_head_name" onChange={(event) => this.changeVal(event)} id className="form-control name-pt-ft" placeholder />
                                                </div>

                                                <div className="col-md-3 mb-3">
                                                    <label htmlFor className="form-label lvl-clr-hd">BU Head Email </label>
                                                    <input type="text" value={this.state.fields["bu_head_email"]} name="bu_head_email" onChange={(event) => this.changeVal(event)} onBlur={(event) => this.checkEmail(event)} id className="form-control name-pt-ft" placeholder />
                                                </div>


                                                <div className="col-md-3 mb-3">
                                                    <label htmlFor="disabledTextInput" className="form-label lvl-clr-hd">BU Head Mobile No.
                                                    </label>
                                                    <input type="text" onInput={(e) => e.target.value = e.target.value.slice(0, 10)} value={this.state.fields["bu_head_mobile"]} onChange={(event) => this.changeVal(event)} name="bu_head_mobile" id className="form-control name-pt-ft" placeholder />
                                                </div>

                                            </div>
                                            <div className="row mt-4">
                                                <div className="end-bck-btn-pt">

                                                    <div className>
                                                        <button type="button" className="btn-home" id onClick={() => this.submit()}>
                                                            Submit
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>

                                        </form>




                                    </div>
                                </div>
                            </div>
                        </div>

                        <AdminFooter />
                    </div>
                </div>

            </>
        )

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        // dispatching plain actions
        setCallCenter: (callcenterdetails) => dispatch(CallCenterAction.setCallCenter(callcenterdetails)),
        setSearchValue: (storeSearchData) => dispatch(AdminAction.setSearchValue(storeSearchData))
    }
}

const mapStateToProps = (state) => {
    return {
        OrderReducer: state.OrderReducer,
        CustomerReducer: state.CustomerReducer,
        CommonReducer: state.CommonReducer,
        CallCenterReducer: state.CallCenterReducer,
        AdminReducer: state.AdminReducer
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Common(DoctorMasterCreate)))