import Base from './Base.service'
import { store } from '../redux/index.store'
import {
  ADMIN_INFO,
  GENERATE_QR_CODE,
  GET_ALL_QR_CODES,
  ZIP_QR_CODES,
  CHECK_AVAILABLE_VACUTAINERS_SERIES,
  CREATE_VACUTAINERS_RECORD,
  GET_ALL_VACUTAINERS_RECORD,
  GET_ALL_CALL_CENTER,
  UPDATE_CALL_CENTER,
  CREATE_CALL_CENTER,
  REMOVE_CALL_CENTER,
  CREATE_DOCTOR_MASTER,
  FETCH_DOCTOR_LIST,
  DASHBOARD_TOTAL_COUNT,
  GET_QR_SERIES_NUMBER,
  GET_UNIQUE_CUSTOMER,
  GET_USER_BY_USER_TYPE,
  CREATE_DELIVERY_PERSON,
  REMOVE_DELIVERY_PERSON,
  GET_CLINIC_LIST,
  GET_DELIVERY_PERSON,
  ASSIGN_JOB_TO_DELIVERY_PERSON,
  GET_ASSIGN_JOB_HISTORY,
  GET_ALL_ADMIN_USERS,
  UPDATE_ADMIN_USER,
  CREATE_ADMIN_USER,
  ADMIN_USER_UPDATE_STATUS,
  GET_ALL_WAREHOUSE_USERS,
  UPDATE_WAREHOUSE_USER,
  CREATE_WAREHOUSE_USER,
  WAREHOUSE_USER_UPDATE_STATUS
} from './Slugs'

class AdminService extends Base {
  constructor(props) {
    super(props)
  }

  getAdminInfo(data) {
    return this.post(ADMIN_INFO, data);
  }
  generateQR(data) {
    return this.post(GENERATE_QR_CODE, data);
  }

  getAllQRCodes(data) {
    return this.post(GET_ALL_QR_CODES, data);
  }

  zipQrCodes(data) {
    return this.post(ZIP_QR_CODES, data);
  }
  checkVacutainersAvailable() {
    return this.get(CHECK_AVAILABLE_VACUTAINERS_SERIES);
  }
  createVacutainersRecord(data) {
    return this.post(CREATE_VACUTAINERS_RECORD, data);
  }
  getAllVacutainersRecords(data) {
    return this.post(GET_ALL_VACUTAINERS_RECORD, data);
  }
  /* call center actions */
  getallCallCenter() {
    return this.get(GET_ALL_CALL_CENTER);
  }
  updateCallCenter(data) {
    return this.post(UPDATE_CALL_CENTER, data);
  }
  createCallCenter(data) {
    return this.post(CREATE_CALL_CENTER, data);
  }
  removeCallCenter(data) {
    return this.post(REMOVE_CALL_CENTER, data);
  }

  /* admin user actions */
  getallAdminUsers() {
    return this.get(GET_ALL_ADMIN_USERS);
  }
  updateAdminUser(data) {
    return this.post(UPDATE_ADMIN_USER, data);
  }
  createAdminUser(data) {
    return this.post(CREATE_ADMIN_USER, data);
  }
  adminUserChangeStatus(data) {
    return this.post(ADMIN_USER_UPDATE_STATUS, data);
  }

  /* warehouse user actions */
  getallWareHouseUsers() {
    return this.get(GET_ALL_WAREHOUSE_USERS);
  }
  updateWareHouseUser(data) {
    return this.post(UPDATE_WAREHOUSE_USER, data);
  }
  createWareHouseUser(data) {
    return this.post(CREATE_WAREHOUSE_USER, data);
  }
  warehouseUserChangeStatus(data) {
    return this.post(WAREHOUSE_USER_UPDATE_STATUS, data);
  }

  createDoctorMaster(data) {
    console.log(data, 888888);
    return this.post(CREATE_DOCTOR_MASTER, data);
  }
  fetchDoctorList() {
    return this.get(FETCH_DOCTOR_LIST);
  }
  totalCount() {
    return this.get(DASHBOARD_TOTAL_COUNT);
  }
  getSeriesNumber(data) {
    return this.post(GET_QR_SERIES_NUMBER, data);
  }
  getAllUniqueCustomer(data) {
    return this.post(GET_UNIQUE_CUSTOMER, data);
  }
  getUserByUserType(data) {
    return this.post(GET_USER_BY_USER_TYPE, data);
  }
  createDeliveryPerson(data) {
    return this.post(CREATE_DELIVERY_PERSON, data);
  }

  removeDeliveryPerson(data) {
    return this.post(REMOVE_DELIVERY_PERSON, data);
  }

  getClinicDetails(data) {
    return this.post(GET_CLINIC_LIST, data);
  }

  getDeliveryPersonDetails(data) {
    return this.post(GET_DELIVERY_PERSON, data);
  }
  assignJobToDeliveryPerson(data) {
    return this.post(ASSIGN_JOB_TO_DELIVERY_PERSON, data);
  }

  getAssignJobHistory(data) {
    return this.post(GET_ASSIGN_JOB_HISTORY, data);
  }

}

export default new AdminService()