import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from '../../../../components/Reusable/WithRouter';
import config from '../../../../config/emrok.config';
import Common from '../../../../hoc/Common.hoc';
import axios from 'axios';
import moment from 'moment';
import AdminHeader from "../../../../components/layout/Admin/AdminHeader";
import AdminSidebar from "../../../../components/layout/Admin/AdminSidebar";
import AdminFooter from "../../../../components/layout/Admin/AdminFooter";
import '../../../../assets/css/admin/style.css';
import '../../../../assets/css/admin/responsive.css';
import DropdownDoctorRuntime from '../../../../components/SearchableDropdown/DropdownDoctorRuntime.component';
import AdminService from '../../../../services/Admin.service';
import CustomDatatable from "../../../../components/Datatable/CustomDatatable.component";
import CallCenterAction from '../../../../redux/actions/CallCenter.action';
import CountryService from '../../../../services/Country.service';
import Dropdown from '../../../../components/SearchableDropdown/Dropdown.component';
import AdminAction from '../../../../redux/actions/Admin.action';
class DeliveryPersonAdd extends Component {
    constructor(props) {
        super(props)

        this.state = {
            callCenterList: [],
            name: "",
            email: "",
            mobileno: "",
            id: "",
            storeSearchData: {
                states: [],
                towns: [],
                selectedTown: '',
                selectedStates: '',
                states_clinic: [],
                towns_clinic: [],
                selectedTown_clinic: '',
                selectedStates_clinic: ''
            },
            states: [],
            towns: [],
            showTowns: false,
            selectedState: "",
            selectedState_new: "",
            selectedTown: "",
            selectedTown_new: "",
            states_clinic: [],
            towns_clinic: [],
            showTowns_clinic: false,
            selectedState_clinic: "",
            selectedTown_clinic: "",
            actionType: "",
            isEdit: "",
            selectedStatus: "",
            clinicDetails: "",
            selectedClinic_new: "",
            selectedClinic: "",

        }

    }


    componentDidMount() {

        console.log(this.props.AdminReducer, 8989);

        const selectedCallCenter = this.props.AdminReducer ? this.props.AdminReducer.deliveryPersonDetails : null;
        console.log(selectedCallCenter, 9666);
        if (selectedCallCenter) {
            // alert(selectedCallCenter._id);
            this.setState({ name: selectedCallCenter.name, email: selectedCallCenter.email, mobileno: selectedCallCenter.mobileno, id: selectedCallCenter._id, selectedState_new: selectedCallCenter.address.state, selectedTown_new: selectedCallCenter.address.city, selectedClinic: selectedCallCenter.linked_clinic, selectedClinic_new: selectedCallCenter.user.name, selectedStatus: selectedCallCenter.status });

            const storeSearchData = this.state.storeSearchData;
            storeSearchData.selectedTown = selectedCallCenter.address.city;
            storeSearchData.selectedStates = selectedCallCenter.address.state;

            console.log(storeSearchData, 89899);

            this.populateTowns(null, null, selectedCallCenter.address.state)

        }
        console.log(selectedCallCenter, 5555);

        this.getAllClinicData()

        this.populateStates("IN")
    }

    getAllClinicData = async () => {

        const { selectedState, selectedState_new } = this.state;
        const storeSearchData = this.state.storeSearchData;
        let stateSelected = storeSearchData.selectedStates;

        try {
            this.setState({ clinicDetails: [] })
            let payload = {
                state: ""
            };

            console.log(payload, 3000);

            let res = await AdminService.getClinicDetails(payload)
            console.log(res, 123333)
            if (res.data.success) {
                console.log(res.data.data.data, "lasjdhjkash");
                let newData = [];

                for (let i = 0; i < res.data.data.data.length; i++) {
                    let dataStruct = {
                        "name": res.data.data.data[i].name,
                        "label": res.data.data.data[i].name,
                        "value": res.data.data.data[i]._id
                    };
                    newData.push(dataStruct);
                }
                this.setState({ clinicDetails: newData });
            }

        } catch (error) {

        }
    }

    changeVal = async (event) => {
        const name = event.target.name;
        const value = event.target.value;

        // alert(name);
        // alert(value);
        if (name == 'name') {
            // alert(1)
            this.setState({ name: value });
        }
        if (name == 'email') {
            this.setState({ email: value });
        }

        if (name == 'selectedStatus') {
            this.setState({ selectedStatus: value });
        }
        // if(name != 'name' || name!= 'email'){
        //this.setState({ name: value });
        // }

    }

    setNumber = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        const reg = new RegExp('^[0-9]+$');
        if (!value || (reg.test(value) && value.length <= 10)) {
            this.setState({ mobileno: value })
        }
    }
    next = async () => {

        const { name, email, mobileno, id, selectedStatus, selectedClinic } = this.state;
        // alert(id);
        const { selectedStates, selectedTown } = this.state.storeSearchData;


        if (email == "" && !this.props.hoc.verifyEmail(email)) {
            this.props.hoc.customAlert('Please Provide a valid email.', false)
            return false
        } else if (name == "") {
            this.props.hoc.customAlert('Please Provide Name.', false)
            return false
        } else if (mobileno == "" || mobileno.length != 10) {
            this.props.hoc.customAlert('Please Provide a Valid Mobile No.', false)
            return false
        }
        // else if (selectedStates && selectedStates == "") {
        //     this.props.hoc.customAlert('Please Provide State.', false)
        //     return false
        // } else if (selectedTown && selectedTown == "") {
        //     this.props.hoc.customAlert('Please Provide Town.', false)
        //     return false
        // } 

        else if (selectedClinic && selectedClinic == "") {
            this.props.hoc.customAlert('Please Provide Koshnin Clinic.', false)
            return false
        }
        else {

            let payload = {
                name,
                email,
                mobileno,
                selectedStates,
                selectedTown,
                id,
                selectedStatus,
                selectedClinic
            }

            console.log(payload, 1706);
            // return
            let res = await AdminService.createDeliveryPerson(payload)
            console.log(res, 123333)
            if (res.data.success) {
                console.log(res.data.data.data, "lasjdhjkash");
                this.setState({ name: res.data.data.data.name, email: res.data.data.data.email, mobileno: res.data.data.data.mobileno, id: res.data.data.data._id });

                const storeSearchData = this.state.storeSearchData;
                storeSearchData.selectedTown = res.data.data.data.address.states;
                storeSearchData.selectedStates = res.data.data.data.address.city;

                //  this.props.setCallCenter(res.data.data.data);
                if (id != "") {
                    this.props.hoc.customAlert('Delivery Person Updated Successfully', true);
                } else {
                    this.props.hoc.customAlert('Delivery Person Added Successfully', true);
                }
            }

        }

    }
    handleClickHome = () => {

        this.props.navigate('/admin/user-managment/delivery-person')
    }
    populateStates = (isoCode = null) => {
        if (isoCode) {
            CountryService.getStates(isoCode).then(data => {
                console.log(data, "states")
                if (data.success) {
                    const storeSearchData = this.state.storeSearchData;
                    storeSearchData.states = data.states;

                    console.log(storeSearchData.states, 6666666);
                    this.props.setSearchValue(storeSearchData);
                    this.setState({ states: data.states, towns: [] }, async () => {
                        this.setState({ showTowns: false })
                        let _state = this.state.storeSearchData.selectedStates || null
                        let _states = this.state.storeSearchData.states || []
                        console.log(_state, this.state.storeSearchData.states, "jhgjhgjhg");

                        console.log(this.state.isEdit, 'this.state.isEdit')

                        if (this.state.isEdit) {
                            setTimeout(() => {

                                this.populateTowns(null, null, this.state.selectedState_new)


                            }, 500);

                        }

                        if (_state) {
                            // alert("ok");
                            let stateObj = null
                            let stateObjAll = null
                            for (let i = 0; i < this.state.storeSearchData.states.length; i++) {

                                if (this.state.states[i].name === _state) {
                                    stateObj = this.state.storeSearchData.states[i]
                                }
                            }


                            console.log(stateObj, _state, 1234545677);
                            console.log("stateObj found", stateObj)


                            // if (stateObj) {
                            setTimeout(() => {
                                if (stateObj != null && stateObj.countryCode) {
                                    this.populateTowns(stateObj.countryCode, stateObj.isoCode)
                                    this.getAllClinicData();

                                }
                            }, 500);
                            // }
                        }
                    })


                    setTimeout(() => {
                        let stateDetails = this.state.storeSearchData.states;

                        const findIfExistAll = stateDetails.find(item => item.name === 'All');

                        console.log(findIfExistAll, 33333);

                        if (!findIfExistAll) {
                            console.log(stateDetails, "details state all 1")
                            const allOption = {
                                "name": "All",
                                "isoCode": null,
                                "countryCode": null,
                                "latitude": null,
                                "longitude": null,
                                "label": "All",
                                "value": "all"
                            };
                            console.log(this.state.storeSearchData.states, "details state")
                            // Create a new array with the "All" option at the beginning
                            let optionsWithAll = [allOption, ...stateDetails];
                            console.log(optionsWithAll, "details state all")
                            this.setState({ states: optionsWithAll })
                            const storeSearchData = this.state.storeSearchData;
                            storeSearchData.states = optionsWithAll;
                            this.props.setSearchValue(storeSearchData);
                        }

                    }, 500);
                }
            })
        }
    }

    populateTowns = async (countryCode = null, stateCode = null, stateName = null) => {
        console.log(countryCode, stateCode, stateName, this.state.isEdit, "countryCode stateCode")



        if ((countryCode && stateCode) || stateName) {


            let getStateCode = await CountryService.getStateCode(stateName);
            console.log(getStateCode, 'getStateCode');

            if (getStateCode.success && getStateCode.stateCode.length > 0) {
                console.log(getStateCode.stateCode, 'getStateCode[0]');
                stateCode = getStateCode.stateCode[0].isoCode;
                countryCode = getStateCode.stateCode[0].countryCode;
            }

            console.log(getStateCode, 'getStateCode');
            console.log(countryCode, 'countryCode0');


            CountryService.getTowns(countryCode, stateCode).then(data => {
                console.log(data, "towns")
                // setTimeout(() => {
                if (data.success) {
                    const storeSearchData = this.state.storeSearchData;
                    storeSearchData.towns = data.towns;
                    this.props.setSearchValue(storeSearchData);
                    this.setState({ towns: data.towns }, () => this.setState({ showTowns: true }))

                    setTimeout(() => {
                        let stateDetails = this.state.storeSearchData.towns;
                        const allOption = {
                            "name": "All",
                            "isoCode": null,
                            "countryCode": null,
                            "latitude": null,
                            "longitude": null,
                            "label": "All",
                            "value": "all"
                        };
                        console.log(this.state.storeSearchData.towns, "details state")
                        // Create a new array with the "All" option at the beginning
                        let optionsWithAll = [allOption, ...stateDetails];
                        console.log(optionsWithAll, "details state all")
                        this.setState({ towns: optionsWithAll })

                        const storeSearchData = this.state.storeSearchData;
                        storeSearchData.towns = optionsWithAll;
                        this.props.setSearchValue(storeSearchData);
                    }, 500);

                    // this.props.setSearchValue(this.state);
                }
                // }, 2000);
            })
        }
    }
    render = () => {
        const { name, email, mobileno, selectedStatus, clinicDetails } = this.state;

        console.log(selectedStatus, 255);
        const { states, selectedState, towns, selectedTown, states_clinic, selectedState_clinic, towns_clinic, selectedTown_clinic, actionType, selectedState_new, selectedTown_new, isEdit, selectedClinic_new, selectedClinic } = this.state;

        console.log(clinicDetails, 898989);
        return (
            <>
                <div className="layout-wrapper">
                    <AdminHeader />
                    <AdminSidebar />
                    <div className="main-content">
                        <div className="page-content">
                            <div className="container-fluid">
                                <div className="row g-4">
                                    <div className='col-md-6'>
                                        <h5 className='mb-20 headingstyle'>Delivery Person Add</h5>
                                    </div>

                                    <div className='col-md-6 '>
                                        <div className="btn-home flotright mx-4" onClick={this.handleClickHome}>
                                            <a href="javascript:void(0);" >
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg>
                                                Back
                                            </a>
                                        </div>
                                    </div>



                                    {/* Row end */}
                                    <div className="row g-4">
                                        <form className="mt-3 registration-form">
                                            <div className="row">
                                                <div className="col-md-4 mb-3">
                                                    <label htmlFor className="form-label lvl-clr-hd">Name <span className='requiredFld'> * </span></label>
                                                    <input type="text" value={name} name="name" onChange={(event) => this.changeVal(event)} id className="form-control name-pt-ft" placeholder />
                                                </div>

                                                <div className="col-md-4 mb-3">
                                                    <label htmlFor className="form-label lvl-clr-hd">Email <span className='requiredFld'> * </span></label>
                                                    <input type="text" value={email} name="email" onChange={(event) => this.changeVal(event)} id className="form-control name-pt-ft" placeholder />
                                                </div>


                                                <div className="col-md-4 mb-3">
                                                    <label htmlFor="disabledTextInput" className="form-label lvl-clr-hd">Mobile Number <span className='requiredFld'> * </span>
                                                    </label>
                                                    <input type="text" value={mobileno} onChange={(event) => this.setNumber(event)} name="mobileno" id className="form-control name-pt-ft" placeholder />
                                                </div>
                                                {/* <div className="col-md-3 mb-3">
                                                    <label htmlFor="disabledTextInput" className="form-label lvl-clr-hd">State <span className='requiredFld'> * </span>
                                                    </label>
                                                   


                                                    {
                                                        states.length ?
                                                            <Dropdown
                                                                items={states}
                                                                onSelect={(item) => {
                                                                    if (item) {
                                                                        
                                                                        this.setState({ selectedState: item.name, selectedState_new: item.name })
                                                                        const storeSearchData = this.state.storeSearchData;
                                                                        storeSearchData.selectedStates = item.name;
                                                                        this.props.setSearchValue(storeSearchData);
                                                                        
                                                                         alert(item.name)
                                                                        if (item.name != "All") {
                                                                            this.populateTowns(item.countryCode, item.isoCode)

                                                                        
                                                                            this.getAllClinicData();
                                                                        } else {
                                                                            this.setState({ towns: [] })
                                                                            this.setState({ selectedTown: "", selectedTown_new: "",clinicDetails:[] })
                                                                            const storeSearchData = this.state.storeSearchData;
                                                                            storeSearchData.towns = [];
                                                                            storeSearchData.selectedTown = "";
                                                                            this.props.setSearchValue(storeSearchData);
                                                                        }

                                                                    }
                                                                }}
                                                                value={selectedState_new}
                                                            /> :
                                                            <Dropdown
                                                                items={states}
                                                                value={selectedState_new}
                                                            />
                                                    }
                                                </div>

                                                <div className="col-md-3 mb-3">
                                                    <label htmlFor className="form-label lvl-clr-hd">Town<span className='requiredFld'> * </span></label>
                                                   
                                                    {
                                                        towns.length ?
                                                            <Dropdown
                                                                items={towns}
                                                                onSelect={(item) => {
                                                                    if (item) {
                                                                       
                                                                        this.setState({ selectedTown: item.name, selectedTown_new: item.name })
                                                                        const storeSearchData = this.state.storeSearchData;
                                                                        storeSearchData.selectedTown = item.name;
                                                                        this.props.setSearchValue(storeSearchData);
                                                                        //console.log(item, "item")
                                                                    }
                                                                }}
                                                                value={selectedTown_new}
                                                            /> :
                                                            <Dropdown
                                                                items={towns}
                                                                value={selectedTown_new}
                                                            />
                                                    }
                                                </div> */}

                                                <div className="col-md-3 mb-3">
                                                    <label htmlFor className="form-label lvl-clr-hd">Koshin Clinic<span className='requiredFld'> * </span></label>

                                                    {
                                                        clinicDetails.length ?
                                                            <Dropdown
                                                                items={clinicDetails}
                                                                onSelect={(item) => {
                                                                    if (item) {

                                                                        this.setState({ selectedClinic: item.value, selectedClinic_new: item.name })

                                                                        //console.log(item, "item")
                                                                    }
                                                                }}
                                                                value={selectedClinic_new}
                                                            /> :
                                                            <Dropdown
                                                                items={clinicDetails}
                                                                value={selectedClinic_new}
                                                            />
                                                    }
                                                </div>


                                                <div className="col-md-3 mb-3">
                                                    <label htmlFor className="form-label lvl-clr-hd">Status<span className='requiredFld'> * </span></label>
                                                    <select className='form-control' name="selectedStatus" onChange={(event) => this.changeVal(event)} >
                                                        <option value=''>Status</option>
                                                        <option value='ACTIVE' selected={selectedStatus === 'ACTIVE' ? 'selected' : ''}>Active</option>
                                                        <option value='INACTIVE' selected={selectedStatus === 'INACTIVE' ? 'selected' : ''}>In Active</option>

                                                    </select>
                                                </div>
                                            </div>


                                            <div className="row mt-4">
                                                <div className="end-bck-btn-pt">

                                                    <div className>
                                                        <button type="button" className="next-btn-crcl" id onClick={() => this.next()}>
                                                            Submit
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>




                                    </div>
                                </div>
                            </div>
                        </div>

                        <AdminFooter />
                    </div>
                </div>

            </>
        )

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        // dispatching plain actions
        setCallCenter: (callcenterdetails) => dispatch(CallCenterAction.setCallCenter(callcenterdetails)),
        setSearchValue: (storeSearchData) => dispatch(AdminAction.setSearchValue(storeSearchData))
    }
}

const mapStateToProps = (state) => {
    return {
        OrderReducer: state.OrderReducer,
        CustomerReducer: state.CustomerReducer,
        CommonReducer: state.CommonReducer,
        CallCenterReducer: state.CallCenterReducer,
        AdminReducer: state.AdminReducer
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Common(DeliveryPersonAdd)))