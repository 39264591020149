import React from "react";
import { withRouter } from "../../components/Reusable/WithRouter";
import { connect } from "react-redux";
import Common from "../../hoc/Common.hoc";
import moment from "moment";
import AdminHeader from "../../components/layout/Admin/AdminHeader";
import AdminFooter from "../../components/layout/Admin/AdminFooter";
import AdminSidebar from "../../components/layout/Admin/AdminSidebar";
import AdminAction from "../../redux/actions/Admin.action";
import AdminService from "../../services/Admin.service";
import '../../assets/css/admin/style.css';
import '../../assets/css/admin/responsive.css';

import 'react-datepicker/dist/react-datepicker.css'; // Import the CSS

class Dashboard extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            totalOrders: "",
            totalAmount: "",
            totalCustomerCount: "",
            userType: "",
        }
    }

    static getDerivedStateFromProps(props, state) {
        return {
            admin: props._id || null,
            summaryDetails: props.summaryDetails || null,
            userType: props.admin.userType || "ADMIN"
        }
    }

    componentDidMount() {
        try {

            AdminService.totalCount().then(data => {

                console.log(data, "10001");

                if (data.data.success) {

                    if (data.data.data.length > 0) {
                        this.setState({ totalOrders: data.data.data[0].totalOrders, totalCustomerCount: data.data.data[0].uniquePatientCount })
                    } else {
                        this.setState({ totalOrders: "", totalCustomerCount: "" })
                    }
                    // console.log(data.data.data.data[0].totalCustomer, "10001");
                    // this.setState({ totalCustomerCount:data.data.data.data[0].totalCustomer });
                }

            }).catch(err => {

                console.log(err, "err")
            })

            //  AdminService.getCustomerCount().then(data => {

            //     console.log(data.data.data.data[0].totalCustomer, "10001");

            //     if (data.data.success) {
            //         // console.log(data.data.data.data[0].totalCustomer, "10001");
            //         this.setState({ totalCustomerCount:data.data.data.data[0].totalCustomer });
            //     }

            // }).catch(err => {

            //     console.log(err, "err")
            // })

            // let res =  AdminService.getOrderCountDetails().then(data => {
            //     console.log(data.data.success, "1000");

            //     console.log(res, 123333)
            //     if (data.data.success) {

            //         this.setState({ totalAmount:data.data.data.data[0].totalAmount,totalOrders:data.data.data.data[0].totalOrders });
            //     }

            // }).catch(err => {

            //     console.log(err, "err")
            // })
        } catch (error) {

        }
    }
    componentWillUnmount() {
        if (this.dataTable) {
            this.dataTable.destroy(true);
        }
    }
    navigateTo = (type) => {

        if (type == 'order_management') {

            this.props.setActiveMenuItem('ordermanagment');
            this.props.navigate('/admin/order-managment');
        }
        if (type == 'customer_management') {
            this.props.setActiveMenuItem('dashboard');
            this.props.navigate('/admin/user-managment/customer-list');
        }

        if (type == "callcenter") {
            this.props.setActiveMenuItem('user_managment');
            this.props.navigate('/admin/user-managment');
        }

        if (type == "qr") {

            this.props.setActiveMenuItem('qr');
            this.props.navigate('/admin/qr-code-generation');

        }
        if (type == "vr") {

            this.props.setActiveMenuItem('vr');
            this.props.navigate('/admin/vacutainer-record');


        }

        if (type == "doctor_master") {
            this.props.setActiveMenuItem('doctor_master');
            this.props.navigate('/admin/doctor-master');

        }
        if (type == "qr_mapping") {
            this.props.setActiveMenuItem('qr_mapping');
            this.props.navigate('/admin/qr-mapping');

        }

    }

    render = () => {
        const { totalAmount, totalOrders, totalCustomerCount, userType } = this.state
        // console.log(totalCustomerCount, 2555);
        return (

            <>

                <div className="layout-wrapper">
                    <AdminHeader />
                    <AdminSidebar />
                    <div className="main-content">
                        <div className="page-content">
                            <div className="container-fluid">
                                <div className="dashboardheading">
                                    <h5 className="headingstyle">Dashboard</h5>
                                </div>
                                {userType == 'ADMIN' ?
                                    <div className="row g-4">
                                        {/* end col*/}
                                        <div className="col-md-6">
                                            <div className="card card-animate">
                                                <a className="" href={void (0)} onClick={() => { this.navigateTo('order_management') }}>
                                                    <div className="card-body">
                                                        <div className="d-flex justify-content-between">
                                                            <div>
                                                                <p className="fw-medium mb-0">Total Orders</p>
                                                                <h2 className="mt-4 ff-secondary fw-semibold">
                                                                    <span className="counter-value" data-target="97.66" />{totalOrders}
                                                                </h2>

                                                            </div>
                                                            <div>
                                                                <div className="avatar-sm flex-shrink-0">

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a>
                                                {/* end card body */}
                                            </div>
                                            {/* end card*/}
                                        </div>
                                        {/* end col*/}
                                        <div className="col-md-6">
                                            <div className="card card-animate">
                                                <a className="" href={void (0)} onClick={() => { this.navigateTo('customer_management') }}>
                                                    <div className="card-body">
                                                        <div className="d-flex justify-content-between">
                                                            <div>
                                                                <p className="fw-medium mb-0">Total Customers</p>
                                                                <h2 className="mt-4 ff-secondary fw-semibold">
                                                                    <span className="counter-value" data-target="97.66" />{totalCustomerCount}
                                                                </h2>

                                                            </div>
                                                            <div>
                                                                <div className="avatar-sm flex-shrink-0">

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a>

                                                {/* end card body */}
                                            </div>
                                            {/* end card*/}
                                        </div>

                                    </div> : null}
                                <div className="row g-4 mt-3 mb-3">
                                    <div className="col-md-12">
                                    {userType == 'ADMIN' ? <h5 className="headingstyle">Quick Links</h5>:""}
                                    </div>
                                </div>
                                <div className="row g-4">
                                    {/* this.props.navigate('/admin/product-managment/list') */}
                                    {userType == 'ADMIN' ?
                                        <div className="col-md-4">
                                            <div className="card card-animate">
                                                <a className="" href={void (0)} onClick={() => { this.navigateTo('order_management') }}>
                                                    <div className="card-body">
                                                        <div className="d-flex justify-content-between">
                                                            <div>
                                                                <p className="fw-medium text-link mb-0">Order Management</p>
                                                                <h2 className="mt-4 ff-secondary fw-semibold">
                                                                    {/* <span className="counter-value" data-target="28.05" />100 */}
                                                                </h2>

                                                            </div>
                                                            <div>
                                                                <div className="avatar-sm flex-shrink-0">

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div></a>
                                                {/* end card body */}
                                            </div>
                                            {/* end card*/}
                                        </div> : null}
                                    {/* end col*/}
                                    <div className="col-md-4">
                                        <div className="card card-animate">
                                            <a className="" href={void (0)} onClick={() => { this.navigateTo('qr') }}>
                                                <div className="card-body">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <p className="fw-medium text-link mb-0">QR Code Generation</p>
                                                            <h2 className="mt-4 ff-secondary fw-semibold">
                                                                {/* <span className="counter-value" data-target="97.66" />150 */}
                                                            </h2>

                                                        </div>
                                                        <div>
                                                            <div className="avatar-sm flex-shrink-0">

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div></a>
                                            {/* end card body */}
                                        </div>
                                        {/* end card*/}
                                    </div>
                                    {/* end col*/}
                                    <div className="col-md-4">
                                        <div className="card card-animate">
                                            <a className="" href={void (0)} onClick={() => { this.navigateTo('qr_mapping') }}>
                                                <div className="card-body">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <p className="fw-medium text-link mb-0">QR Code Mapping</p>
                                                            <h2 className="mt-4 ff-secondary fw-semibold">
                                                                {/* <span className="counter-value" data-target="97.66" />200 */}
                                                            </h2>

                                                        </div>
                                                        <div>
                                                            <div className="avatar-sm flex-shrink-0">

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                            {/* end card body */}
                                        </div>
                                        {/* end card*/}
                                    </div>
                                    <div className={userType == 'ADMIN' ? "col-md-4 mt-5" : "col-md-4"}>
                                        <div className="card card-animate">
                                            <a className="" href={void (0)} onClick={() => { this.navigateTo('vr') }}>
                                                <div className="card-body">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <p className="fw-medium text-link mb-0">X-Beau Kit Records</p>
                                                            <h2 className="mt-4 ff-secondary fw-semibold">
                                                                {/* <span className="counter-value" data-target="97.66" />200 */}
                                                            </h2>

                                                        </div>
                                                        <div>
                                                            <div className="avatar-sm flex-shrink-0">

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                            {/* end card body */}
                                        </div>
                                        {/* end card*/}
                                    </div>
                                    {userType == 'ADMIN' ?
                                        <>
                                            <div className="col-md-4 mt-5">
                                                <div className="card card-animate">
                                                    <a className="" href={void (0)} onClick={() => { this.navigateTo('callcenter') }}>
                                                        <div className="card-body">
                                                            <div className="d-flex justify-content-between">
                                                                <div>
                                                                    <p className="fw-medium text-link mb-0">User Management</p>
                                                                    <h2 className="mt-4 ff-secondary fw-semibold">
                                                                        {/* <span className="counter-value" data-target="97.66" />200 */}
                                                                    </h2>

                                                                </div>
                                                                <div>
                                                                    <div className="avatar-sm flex-shrink-0">

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                    {/* end card body */}
                                                </div>
                                                {/* end card*/}
                                            </div>
                                            <div className="col-md-4 mt-5">
                                                <div className="card card-animate">
                                                    <a className="" href={void (0)} onClick={() => { this.navigateTo('doctor_master') }}>
                                                        <div className="card-body">
                                                            <div className="d-flex justify-content-between">
                                                                <div>
                                                                    <p className="fw-medium text-link mb-0">Doctor Master</p>
                                                                    <h2 className="mt-4 ff-secondary fw-semibold">
                                                                        {/* <span className="counter-value" data-target="97.66" />200 */}
                                                                    </h2>

                                                                </div>
                                                                <div>
                                                                    <div className="avatar-sm flex-shrink-0">

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                    {/* end card body */}
                                                </div>
                                                {/* end card*/}
                                            </div>
                                        </>
                                        : null}

                                </div>
                                {/* Row end*/}
                            </div>
                        </div>
                        <AdminFooter />
                    </div>


                </div>






            </>

        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setActiveMenuItem: (actveiveItem) => dispatch(AdminAction.setActiveMenuItem(actveiveItem))
    }
}

const mapStateToProps = ({ AdminReducer, CommonReducer }) => {
    const { admin } = AdminReducer
    const { summaryDetails } = CommonReducer
    return {
        admin,
        summaryDetails
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Common(Dashboard)))