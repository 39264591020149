import React from "react";
import { withRouter } from "../../components/Reusable/WithRouter";
import { connect } from "react-redux";
import Common from "../../hoc/Common.hoc";
import CustomerFooter from "../../components/layout/CustomerFooter";
import MrHeader from "../../components/layout/MR/MrHeader";
import MrAction from "../../redux/actions/Mr.action";
import MrService from "../../services/Mr.service";
import { format } from 'date-fns';
import $ from "jquery";
import CustomDatatable from "../../components/Datatable/CustomDatatable.component";
import moment from "moment";
import StockistHeader from "../../components/layout/Stockist/StockistHeader";
import StockistService from "../../services/Stockist.service";
import CallCenterService from "../../services/CallCenter.service";
import OrderAction from "../../redux/actions/Order.action";
import OtherService from "../../services/OtherService";
import CustomerAction from "../../redux/actions/Customer.action";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; // Import the CSS
import CountryService from '../../services/Country.service';
import Dropdown from '../../components/SearchableDropdown/Dropdown.component';
import DropdownDoctor from '../../components/SearchableDropdown/DropdownDoctor.component';
import CommonAction from "../../redux/actions/Common.action";
import CallCenterAction from "../../redux/actions/CallCenter.action";
import Summary from "../../components/Summary/Summary.component";
import { ExportToExcel } from "../../components/Excel/ExportToExcel";
import OrderService from "../../services/Order.service";
import ClinicService from "../../services/Clinic.service";
class DeliveryPersonDashboard extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            callcenterdetails: null,
            scallcenterid: "",
            callcenterList: [],
            mrdetails: [],
            callcenterinfo: "",
            callcenterdetailsList: [],
            countries: [],
            states: [],
            towns: [],
            showTowns: false,
            selectedState: "",
            selectedTown: "",
            selectedDoctor: "",
            fromdate: "",
            todate: "",
            currentDate: this.getCurrentDate(),
            doctorList: [],
            summaryCols: ["Total Orders", "Orders Under Process", "Orders Pending", "Sample Received at Koshin Clinic", "XO Face Serum received By doctor",],
            summaryDetails: null,
            setorderStatus: "",
            exportData: null,
            fileName: "Delivery_Person",
            cityTAT: [],
            orders: [],
            personalDetails: []
        }
    }

    static getDerivedStateFromProps(props, state) {
        return {
            callcenterdetails: props._id || null,
            summaryDetails: props.summaryDetails || null,
        }
    }

    formatData(auditdata) {

        console.log(auditdata, auditdata.length, "auditdata")

        let formatedArr = [];
        let allfeedback = [];
        let feedbackString = "";
        for (let i = 0; i < auditdata.length; i++) {


            formatedArr.push({
                "Order No": auditdata[i].order.order_no,
                "Order Date": auditdata[i].order ? moment(auditdata[i].order.createdAt).format('DD-MM-YYYY') : "",
                "QR Code Name": auditdata[i].unique_qr_id ?? "",
                "Customer Name": auditdata[i].patient ? auditdata[i].patient?.name : "",
                // "Customer Mobile": auditdata[i].patient ? auditdata[i].patient?.mobileno : "",
                // "MR Name": auditdata[i].mr ? auditdata[i].mr?.name : "",
                "Doctor Name": auditdata[i].doctor ? auditdata[i].doctor?.name : "",
                "Doctor Mobile": auditdata[i].doctor ? auditdata[i].doctor?.mobileno : "",
                "Doctor Address": auditdata[i].doctor ? auditdata[i].doctor.address.addressLine1 + ', ' + auditdata[i].doctor.address.addressLine2 + ', ' + auditdata[i].doctor.address.town + ', ' + auditdata[i].doctor.address.pin : "",
                // "Stockist Name": auditdata[i].stockist ? auditdata[i].stockist[0]?.name : "",
                // "Vacutainer Serial No": auditdata[i].serial_no?'WH'+auditdata[i].serial_no:"",
                "Status": auditdata[i].order ? auditdata[i].order.orderStatus : '',
            })
        }
        return formatedArr;
    }

    getCurrentDate() {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const day = String(currentDate.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }



    componentDidMount() {

        const clinicdetails = this.props.deliveryperson;
        console.log(clinicdetails, 555);

        const clinic_id = clinicdetails._id;
        console.log(clinic_id, 666);
        // return
        let details = {
            user_type: "DELIVERY_PERSON",
            delivery_person_id: clinic_id
        };
        console.log(details, "callcenter details 1",);
        if (clinicdetails) {

            let infopayload = {
                type: "USER",
                id: clinic_id
            }
            this.getInfo(infopayload)


            this.getOrderList(details)
            // this.getCityTAT();

            setTimeout(() => {
                this.populateStates("IN")
                // this.populateStates(countryObj.isoCode)	
            }, 500);

        } else {
        }
    }

    getInfo = (payload) => {
        this.setState({ personalDetails: [] })

        OrderService.getPersonalDetails(payload).then(async (data) => {
            console.log(data, 100001)
            if (data.data.success) {
                // console.log(data.data.data.order,6666666)
                this.setState({ personalDetails: data.data.data[0] })
            }
        }).catch(err => {
        })
    }

    getDoctors = (doctors) => {

        // Create an array to store the grouped data
        const groupedData = [];
        const finalData = [];

        // Create an object to keep track of seen doctor._id values
        const seenDoctorIds = {};

        // Use forEach to iterate through the data
        doctors.forEach((currentValue) => {
            const doctorId = currentValue.doctor._id;

            // Check if we've already seen this doctor._id
            if (!seenDoctorIds[doctorId]) {
                // Add the current value to the groupedData array
                groupedData.push(currentValue.doctor);

                // Mark the doctor._id as seen
                seenDoctorIds[doctorId] = true;
            }
        });

        for (let index = 0; index < groupedData.length; index++) {
            finalData[index] = {
                "name": groupedData[index].name,
                "label": groupedData[index].name,
                "value": groupedData[index]._id,
                "id": groupedData[index]._id,
            };


        }


        const allOption = {
            "name": "All",
            "label": "All",
            "value": "All",
            "id": "All"
        };

        let optionsWithAll = [allOption, ...finalData];


        console.log(finalData, "finalData");
        // Now 'groupedData' contains unique doctors with a regular index

        this.setState({ doctorList: optionsWithAll });

        console.log(groupedData, "groupedData")

        // Now 'groupedData' contains the data grouped by doctor._id


    }

    populateStates = (isoCode = null) => {
        if (isoCode) {
            CountryService.getStates(isoCode).then(data => {
                //console.log(data, "states")
                if (data.success) {

                    this.setState({ states: data.states, towns: [] }, async () => {
                        this.setState({ showTowns: false })
                        let _state = this.state.selectedState || null
                        let _states = this.state.states || []
                        console.log(_state, this.state.states, "jhgjhgjhg");
                        if (_state) {
                            alert("ok");
                            let stateObj = null
                            let stateObjAll = null
                            for (let i = 0; i < this.state.states.length; i++) {

                                if (this.state.states[i].name === _state) {
                                    stateObj = this.state.states[i]
                                }
                            }


                            console.log(stateObj, _state, 1234545677);
                            console.log("stateObj found", stateObj)
                            // if (stateObj) {
                            setTimeout(() => {
                                if (stateObj.countryCode)
                                    this.populateTowns(stateObj.countryCode, stateObj.isoCode)
                            }, 500);
                            // }
                        }
                    })


                    setTimeout(() => {
                        let stateDetails = this.state.states;
                        const allOption = {
                            "name": "All",
                            "isoCode": null,
                            "countryCode": null,
                            "latitude": null,
                            "longitude": null,
                            "label": "All",
                            "value": "all"
                        };
                        console.log(this.state.states, "details state")
                        // Create a new array with the "All" option at the beginning
                        let optionsWithAll = [allOption, ...stateDetails];
                        console.log(optionsWithAll, "details state all")
                        this.setState({ states: optionsWithAll })
                    }, 500);


                }
            })
        }
    }

    populateTowns = (countryCode = null, stateCode = null) => {
        console.log(countryCode, stateCode, "countryCode stateCode")
        if (countryCode && stateCode) {
            CountryService.getTowns(countryCode, stateCode).then(data => {
                console.log(data, "towns")
                // setTimeout(() => {
                if (data.success) {
                    this.setState({ towns: data.towns }, () => this.setState({ showTowns: true }))

                    setTimeout(() => {
                        let stateDetails = this.state.towns;
                        const allOption = {
                            "name": "All",
                            "isoCode": null,
                            "countryCode": null,
                            "latitude": null,
                            "longitude": null,
                            "label": "All",
                            "value": "all"
                        };
                        console.log(this.state.towns, "details state")
                        // Create a new array with the "All" option at the beginning
                        let optionsWithAll = [allOption, ...stateDetails];
                        console.log(optionsWithAll, "details state all")
                        this.setState({ towns: optionsWithAll })
                    }, 500);
                }
                // }, 2000);
            })
        }
    }

    setOrderHistoryData = (data) => {
        let resp = []
        // console.log(data[0],66666)
        for (let i = 0; i < data.length; i++) {

            let checkisGFC = data[i].order_status.filter(item => {
                return item.patient_order_id == data[i].order._id && item.status == "received_at_clinic"
            })

            let serumDispatch = data[i].order_status.filter(item => {
                return item.patient_order_id == data[i].order._id && item.status == "dispatch_from_clinic"
            })

            let checkCollection = data[i].assignjob.filter(item => {
                return item.order_id == data[i].order._id && item.assignJob == "Collection Job"
            })
            console.log(checkCollection, 'checkCollection');

            let checkDelivery = data[i].assignjob.filter(item => {
                return item.order_id == data[i].order._id && item.assignJob == "Delivery Job"
            })

            console.log(checkDelivery, 'checkDelivery');

            let orderPlaced = data[i].order_status.filter(item => {
                return item.patient_order_id == data[i].order._id && item.status == "pending"
            })

            let status1A = data[i].order_status.filter(item => {
                return item.patient_order_id == data[i].order._id && item.status == "Out for X-care tubes collection"
            })

            let status1B = data[i].order_status.filter(item => {
                return item.patient_order_id == data[i].order._id && item.status == "X-care Tubes Picked-up from Doctor’s clinic and Out for delivery of X-care tubes to Koshin Clinic"
            })

            let status1C = data[i].order_status.filter(item => {
                return item.patient_order_id == data[i].order._id && item.status == "X-care Tubes delivered to Koshin Clinic"
            })

            let status2A = data[i].order_status.filter(item => {
                return item.patient_order_id == data[i].order._id && item.status == "XO Face serum picked up from Koshin Clinic and out for delivery to doctor’s clinic"
            })

            let status2B = data[i].order_status.filter(item => {
                return item.patient_order_id == data[i].order._id && item.status == "XO Face serum Delivered to Doctor’s clinic"
            })


            resp.push({
                _id: data[i]._id,
                order_no: data[i].order.order_no,
                order_date: data[i].order.createdAt ? moment(data[i].order.createdAt).format('DD-MM-YYYY') : "",
                order_status: data[i].order.orderStatus ? data[i].order.orderStatus.toUpperCase() : "",
                stockist_name: data[i].stockist[0].name,
                patient_name: data[i].patient.name,
                patient_mobile: data[i].patient.mobileno,
                vacutainer_code: data[i].unique_qr_id ?? "",
                serial_no: data[i].serial_no ? 'WH' + data[i].serial_no : '',
                mr_name: data[i].mr.name,
                doctor_naame: data[i].doctor.name,
                gfc_received: checkisGFC.length > 0 ? 'yes' : 'no',
                serum_dispatch: serumDispatch.length > 0 ? 'yes' : 'no',
                doctor_mobile: data[i].doctor.mobileno,
                doctor_address: data[i].doctor.address.addressLine1 + ', ' + data[i].doctor.address.addressLine2 + ', ' + data[i].doctor.address.town + ', ' + data[i].doctor.address.state + ', ' + data[i].doctor.address.pin,
                clinic_name: data[i].clinic.name,
                checkCollection: checkCollection.length > 0 ? 'yes' : 'no',
                checkDelivery: checkDelivery.length > 0 ? 'yes' : 'no',
                orderPlaced: orderPlaced.length > 0 ? 'yes' : 'no',
                status1A: status1A.length > 0 ? 'yes' : 'no',
                status1B: status1B.length > 0 ? 'yes' : 'no',
                status1C: status1C.length > 0 ? 'yes' : 'no',
                status2A: status2A.length > 0 ? 'yes' : 'no',
                status2B: status2B.length > 0 ? 'yes' : 'no',




            })
        }
        return resp


    }

    componentWillUnmount() {
        if (this.dataTable) {
            this.dataTable.destroy(true);
        }
    }

    handleTrackOrder = (event) => {

        // const allOrder = this.props.OrderReducer.allOrders;
        const targetId = event.target.name;
        console.log(targetId, "target ID");
        const { orders } = this.state;
        console.log(orders, 7777);
        try {

            const selectedOrder = orders.filter(item => item._id == targetId)
            console.log(selectedOrder, 'selectedOrder');
            this.props.saveSelectedOrders(selectedOrder)
            this.props.navigate('/delivery-person/track-info-order');
            this.props.setUserType('delivery_person');

        } catch (error) {

        }
    }

    changeValFromDate = async (date) => {

        const formatedDate = moment(date).format('DD-MM-YYYY');
        console.log(date, "fromdate");
        this.setState({ fromdate: date });
    }

    changeValToDate = async (date) => {

        const formatedDate = moment(date).format('DD-MM-YYYY');
        console.log(date, "todate");
        this.setState({ todate: date });
    }


    searchFilter = (event) => {
        this.setState({ callcenterdetailsList: [] });
        const callcenterdetails = this.props.clinicdetails;
        const callcenter_id = callcenterdetails._id;
        const { fromdate, todate, selectedState, selectedTown, selectedDoctor, setorderStatus } = this.state;

        const selectedMomentFrom = moment(fromdate);
        const selectedOnlyDateFrom = selectedMomentFrom.format('YYYY-MM-DD');
        // Calculate the next day using moment
        const nextDayMomentFrom = selectedMomentFrom.clone().add(1, 'day');
        const nextDayOnlyDateFrom = nextDayMomentFrom.format('YYYY-MM-DD');

        const selectedMomentTo = moment(todate);
        const selectedOnlyDateTo = selectedMomentTo.format('YYYY-MM-DD');
        // Calculate the next day using moment
        const nextDayMomentTo = selectedMomentTo.clone().add(1, 'day');
        const nextDayOnlyDateTo = nextDayMomentTo.format('YYYY-MM-DD');
        const clinicdetails = this.props.deliveryperson;
        console.log(clinicdetails, 555);

        const clinic_id = clinicdetails._id;

        let details = {
            callcenter_id: callcenter_id,
            fromDate: fromdate ? selectedOnlyDateFrom : "",
            toDate: todate ? selectedOnlyDateTo : "",
            stateName: selectedState,
            town: selectedTown,
            doctor_id: selectedDoctor,
            orderStatus: setorderStatus,
            user_type: "DELIVERY_PERSON",
            delivery_person_id: clinic_id

        };

        if (fromdate != "" && todate == "") {
            this.props.hoc.customAlert("Please select both fromdate and todate", false);
        } else if (fromdate == "" && todate != "") {
            this.props.hoc.customAlert("Please select both fromdate and todate", false);
        } else {

            this.getOrderList(details)
            this.getSummary()
        }
        console.log("search filter");
    }

    changeDoctor = (event) => {
        const doctorId = event.target.value;
        this.setState({ selectedDoctor: doctorId });
        console.log(doctorId, "doctorId");
    }

    getOrderList = (payload) => {

        console.log(payload, 'payload333');

        this.setState({ orders: [] })
        this.setState({ exportData: null });

        OrderService.getAllOrders(payload).then(async (data) => {
            console.log(data, 1000022)
            if (data.data.success) {
                console.log(data.data.data.order, 6666666)

                this.setState({ orders: data.data.data.order })

                const formatedData = await this.formatData(data.data.data.order);
                console.log(formatedData, "formatedData")
                this.setState({ exportData: formatedData });

                this.getDoctors(data.data.data.order)
            }


        }).catch(err => {
        })

    }

    clearFilter = () => {
        let details = {
        };
        this.setState({ selectedState: "", selectedTown: "", fromdate: "", todate: "", selectedDoctor: "" })

        this.getSummary()
        this.getOrderList(details)

    }

    handleChangeStatus = (event) => {

        this.props.hoc.customAlert('Are you sure you want to change status?', true, "", true, true, async () => {
            let order_id = event.target.name;
            let value = event.target.value;
            // alert(order_id);
            // alert(value);
            if (value != "") {
                let payload = {
                    order_id: order_id,
                    selectedStatus: value
                };
                console.log(payload, 6666);

                OrderService.changeStatus(payload).then(async (data) => {
                    console.log(data, 1000022)
                    if (data.data.success) {
                        const clinicdetails = this.props.deliveryperson;
                        console.log(clinicdetails, 555);

                        const clinic_id = clinicdetails._id;
                        console.log(clinic_id, 666);
                        // return
                        let details = {
                            user_type: "DELIVERY_PERSON",
                            delivery_person_id: clinic_id
                        };
                        this.getOrderList(details)

                        this.props.hoc.customAlert('Order Status Changed Successfully.', true);

                    }


                }).catch(err => {
                    console.log(err, 6666);
                    this.props.hoc.customAlert('Something went wrong.', false);
                })


            }
        }, () => {

        })





    }

    render = () => {

        const { countries, states, towns, isLoading, showTowns, selectedState, selectedTown, fromdate, todate, doctorList, selectedDoctor, summaryCols, summaryDetails, exportData, fileName, orders, personalDetails } = this.state;
        let _data = this.setOrderHistoryData(orders)
        console.log(_data, 'tat1234');
        console.log(orders, "callcenterdetailsList");
        console.log(doctorList, "doctorList");
        return (

            <>

                <section className="landing-profl-detls">
                    <div className="container">
                        <StockistHeader />
                        {/* Row end */}
                        <div className="row">
                            <div className="landing-page-pt pt-3 pb-4 px-0">
                                <div className="container">
                                    <div className="mr-landing-page">
                                        <div className="landing-head-pt">
                                            {/* <h2>Call Center</h2> */}
                                            <h5>Welcome {personalDetails?.name} to XO (Delivery Person)</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>{" "}
                        {/* Row end*/}
                        <div className="landing-form-dtls p-5">
                            <div className="row">
                                <div className="col-md-6">
                                    <h5 className="mb-20 headingstyle">Order Details</h5>
                                </div>
                                <div className="col-md-6">


                                    <div className="flotright marginright">
                                        <ExportToExcel apiData={exportData} fileName={fileName} />
                                    </div>
                                </div>

                            </div>
                            <div className="row mb-20 ">
                                <div className="col-md-6 col-lg-3 mb-3">
                                    <div className="date-picker-wrap dt-pick-container">
                                        <label htmlFor className="form-label lvl-clr-hd">Order From Date</label>

                                        <DatePicker
                                            selected={fromdate}
                                            onChange={(date) => this.changeValFromDate(date)}
                                            dateFormat="dd-MM-yyyy" // Set the desired display format
                                            className="form-control name-pt-ft"
                                            placeholderText="DD-MM-YYYY"
                                        />
                                    </div>

                                </div>
                                <div className="col-md-6 col-lg-3 mb-3">
                                    <div className="date-picker-wrap dt-pick-container">
                                        <label htmlFor className="form-label lvl-clr-hd">Order To Date</label>

                                        <DatePicker
                                            selected={todate}
                                            onChange={(date) => this.changeValToDate(date)}
                                            dateFormat="dd-MM-yyyy" // Set the desired display format
                                            className="form-control name-pt-ft"
                                            placeholderText="DD-MM-YYYY"
                                            minDate={this.state.fromdate}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-3 mb-3">
                                    <label htmlFor="disabledTextInput" className="form-label lvl-clr-hd">State </label>
                                    {
                                        states.length ?
                                            <Dropdown
                                                items={states}
                                                onSelect={(item) => {
                                                    if (item) {

                                                        this.setState({ selectedState: item.name })
                                                        //console.log(item, "item");
                                                        if (item.name != "All") {
                                                            this.populateTowns(item.countryCode, item.isoCode)
                                                        } else {
                                                            this.setState({ towns: [] })
                                                            this.setState({ selectedTown: "" })
                                                        }
                                                    }
                                                }}
                                                value={selectedState}
                                            /> :
                                            <Dropdown
                                                items={states}
                                                value={selectedState}
                                            />
                                    }

                                </div>
                                <div className="col-md-6 col-lg-3 mb-3">
                                    <label htmlFor className="form-label lvl-clr-hd">City/Town </label>
                                    {
                                        towns.length ?
                                            <Dropdown
                                                items={towns}
                                                onSelect={(item) => {
                                                    if (item) {

                                                        this.setState({ selectedTown: item.name })

                                                    }
                                                }}
                                                value={selectedTown}
                                            /> :
                                            <Dropdown
                                                items={towns}
                                                value={selectedTown}
                                            />
                                    }

                                </div>
                                <div className="col-md-3 mb-3">
                                    <label htmlFor className="form-label lvl-clr-hd">Doctors </label>


                                    {
                                        doctorList.length ?
                                            <DropdownDoctor
                                                items={doctorList}
                                                onSelect={(item) => {
                                                    if (item) {

                                                        this.setState({ selectedDoctor: item.id })

                                                    }
                                                }}
                                                value={selectedDoctor}
                                            /> :
                                            <DropdownDoctor
                                                items={doctorList}
                                                value={selectedDoctor}
                                            />
                                    }


                                </div>
                                <div className="">
                                    <button type="button" className="landing-success-btn" onClick={(event) => this.searchFilter(event)}>
                                        Search</button>
                                    <button type="button" className="landing-clear-btn" onClick={(event) => this.clearFilter()}>
                                        Clear Filter</button>
                                </div>

                            </div>

                            <div className="table-responsive">

                                {

                                    _data && _data.length ?
                                        <CustomDatatable
                                            pagination={true}
                                            columns={[
                                                {
                                                    name: 'Order No',
                                                    // selector: row => row.mr_name,

                                                },
                                                {
                                                    name: 'Order Date',
                                                    // selector: row => row.doctor_name,

                                                },
                                                {
                                                    name: 'QR Code',
                                                    // selector: row => row.doctor_name,

                                                },
                                                {
                                                    name: 'Customer Name',
                                                    // selector: row => row.mr_name,

                                                },
                                                // {
                                                //     name: 'Customer Mobile',
                                                //     // selector: row => row.mr_name,

                                                // },
                                                // {
                                                //     name: 'MR Name',
                                                //     // selector: row => row.mr_name,

                                                // },
                                                {
                                                    name: 'Doctor Name',
                                                    // selector: row => row.mr_name,

                                                },
                                                {
                                                    name: 'Doctor Mobile',
                                                    // selector: row => row.mr_name,

                                                },
                                                {
                                                    name: 'Doctor Address',
                                                    // selector: row => row.mr_name,

                                                },

                                                {
                                                    name: 'Koshin Clinic',
                                                    // selector: row => row.mr_name,

                                                },
                                                // {
                                                //     name: 'Stockist Name',
                                                //     // selector: row => row.mr_name,

                                                // },


                                                // {
                                                //     name: 'Payment Status',
                                                //     // selector: row => row.doctor_name,

                                                // },
                                                {
                                                    name: 'Status',
                                                    // selector: row => row.createdAt,

                                                },
                                                {
                                                    name: 'Track Order',
                                                    // selector: row => row.createdAt,

                                                },
                                                {
                                                    name: 'Change Status',
                                                    // selector: row => row.createdAt,

                                                },
                                                // {
                                                //     name: 'Dispatch Serum',
                                                //     // selector: row => row.createdAt,

                                                // }
                                            ]}
                                            data={this.setOrderHistoryData(orders)}
                                            pageName="deliverypersonlanding"
                                            handleTrackOrder={this.handleTrackOrder}
                                            handleChangeStatus={this.handleChangeStatus}
                                        />
                                        : <p className="norecord"><strong>No Records Found</strong></p>

                                }
                            </div>
                        </div>
                    </div>
                </section>
                <CustomerFooter />
            </>

        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        // dispatching plain actions
        setUserType: (userType) => dispatch(CommonAction.setUserType(userType)),
        saveSelectedOrders: (selectedOrders) => dispatch(OrderAction.saveSelectedOrders(selectedOrders)),
        saveIndividualOrders: (individualOrders) => dispatch(OrderAction.saveIndividualOrders(individualOrders)),
    }
}

const mapStateToProps = ({ DeliveryPersonReducer }) => {

    const { deliveryperson } = DeliveryPersonReducer
    // console.log(deliveryperson, 123456);
    return {
        deliveryperson
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Common(DeliveryPersonDashboard)))