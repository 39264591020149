import {
    SET_ADMIN,
    CLEAR_STATE,
    SET_ACTIVE_MENU,
    STORE_SEARCH_VALUE,
    STORE_DOCTOR_VALUE,
    SET_ACTION_TYPE,
    SET_DELIVERY_PERSON
} from '../types/Admin.type'

const initState = {
    admin: {},
    token: null,
    doctorDetails: {},
    actiontype: null,
    storeSearchData: {
        callcenterdetails: null,
        admin: null,
        storeSearchData: null,
        customerid: "",
        scallcenterid: "",
        callcenterList: [],
        callcenterinfo: "",
        callcenterdetailsList: [],
        countries: [],
        states: [],
        towns: [],
        showTowns: false,
        selectedState: "",
        selectedTown: "",
        states_clinic: [],
        towns_clinic: [],
        showTowns_clinic: false,
        selectedState_clinic: "",
        selectedTown_clinic: "",
        selectedDoctor: "",
        fromdate: "",
        todate: "",
        currentDate: "",
        doctorList: [],
        isSearch: false,
        isShowCancelModal: false,
        cancelOrderId: null,
        cancelReason: "",
        mobileno: "",
        summaryCols: ["Total Rx Generated", "Total Rx Fulfilled", "Total Rx Under Process", "Order Pending", "Pickup Initiated", "Order Delivered", "Total Revenue"],
        summaryDetails: null,
        setorderStatus: "",
        exportData: null,
        fileName: "Order Details",
    },
    deliveryPersonDetails: {}
}

export default (state = initState, action) => {
    const { type, payload } = action
    console.log(action, 565656);
    switch (type) {
        case SET_ADMIN:
            return Object.assign({}, state, { admin: { ...payload } })
        case SET_ACTIVE_MENU:
            return Object.assign({}, state, { activeItem: payload })
        case STORE_SEARCH_VALUE:
            return Object.assign({}, state, { storeSearchData: payload })
        case STORE_DOCTOR_VALUE:
            return Object.assign({}, state, { doctorDetails: payload })
        case SET_ACTION_TYPE:
            return Object.assign({}, state, { actiontype: payload })
        case SET_DELIVERY_PERSON:
            return Object.assign({}, state, { deliveryPersonDetails: payload })
        case CLEAR_STATE:
            return initState;
        default:
            return state
    }
}