import {
  SET_ADMIN,
  CLEAR_STATE,
  SET_ACTIVE_MENU,
  STORE_SEARCH_VALUE,
  STORE_DOCTOR_VALUE,
  SET_ACTION_TYPE,
  SET_DELIVERY_PERSON
} from '../types/Admin.type'

class AdminAction {
  setAdmin = (data) => {
    console.log(">>>>>>>>>>>>>>>>", data)
    return { type: SET_ADMIN, payload: data }
  }
  logout = () => {
    return { type: CLEAR_STATE, payload: null }
  }
  setActiveMenuItem = (data) => {
    // alert(data)
    return { type: SET_ACTIVE_MENU, payload: data }
  }
  setSearchValue = (data) => {
    // alert(data)
    return { type: STORE_SEARCH_VALUE, payload: data }
  }
  setDoctorDetails = (data) => {
    // alert(data)
    return { type: STORE_DOCTOR_VALUE, payload: data }
  }
  setActionType = (data) => {
    // alert(data)
    return { type: SET_ACTION_TYPE, payload: data }
  }
  setDeliveryPerson = (data) => {
    // alert(data)
    return { type: SET_DELIVERY_PERSON, payload: data }
  }
}
export default new AdminAction()