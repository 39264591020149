import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from '../../../components/Reusable/WithRouter';
import config from '../../../config/emrok.config';
import Common from '../../../hoc/Common.hoc';
import axios from 'axios';
import moment from 'moment';
import AdminHeader from "../../../components/layout/Admin/AdminHeader";
import AdminSidebar from "../../../components/layout/Admin/AdminSidebar";
import AdminFooter from "../../../components/layout/Admin/AdminFooter";
import '../../../assets/css/admin/style.css';
import '../../../assets/css/admin/responsive.css';
import AdminService from '../../../services/Admin.service';
import CustomDatatable from "../../../components/Datatable/CustomDatatable.component";
import Dropdown from '../../../components/SearchableDropdown/Dropdown.component';
import StockistService from '../../../services/Stockist.service';
import DoctorService from '../../../services/Doctor.service';
import Scanner from '../../../components/QrScanner/Scanner';
import Webcam from '../../../components/QrScanner/Webcam';

class QrmappingScreen extends Component {
    constructor(props) {
        super(props)

        this.state = {
            quantity: "",
            selectOrder: [],
            bankrefno: "",
            rtochangeDate: "",
            refundchangeDate: "",
            stockistList: [],
            selectedStockist: '',
            alldoctorList: [{
                "name": "Doctor 1",
                "stockist_id": 1,
                "doctor_id": 1,
                "label": "Doctor 1",
                "value": "d1"
            },
            {
                "name": "Doctor 2",
                "stockist_id": 1,
                "doctor_id": 2,
                "label": "Doctor 2",
                "value": "d2"
            },
            {
                "name": "Doctor 3",
                "stockist_id": 2,
                "doctor_id": 3,
                "label": "Doctor 3",
                "value": "d3"
            }],
            allStockistList: [{
                "name": "Stockist 1",
                "stockist_id": 1,
                "label": "Stockist 1",
                "value": "s1"
            },
            {
                "name": "Stockist 2",
                "stockist_id": 2,
                "label": "Stockist 2",
                "value": "s2"
            },
            {
                "name": "Stockist 3",
                "stockist_id": 3,
                "label": "Stockist 3",
                "value": "s3"
            }],
            doctorList: [],
            selectedDoctor: "",
            vacutainerRecords: [],
            searchDoctor: "",
            currentPage: 1,
            itemsPerPage: 20,
            availableseries: '',
            seriesCount: '',
            selectedStockist_id: '',
            selectedDoctor_id: '',
            allRecords: [],
            openscanner: 'true',
            finalscanResult: [],
            selectedSeries: "",
            allselectedSeries: []


        }

    }


    componentDidMount() {
        this.populateStockist();
        this.checkAvailableVacutainers();
        this.getAllrecords();



    }

    componentWillUnmount() {
        if (this.dataTable) {
            this.dataTable.destroy(true);
        }
    }

    goPreviousPage = () => {
        // const usertype = this.props.CommonReducer.userType;
        this.props.navigate('/admin/qr-code-generation');
    };

    downloadQR = async (event) => {

        let payload = {
            serial_no: event.target.id
        };

        console.log(payload, 55555);

        AdminService.zipQrCodes(payload).then(data => {
            console.log(data.data, "zip qr codes");
            if (data.data.success) {
                console.log('in success');

                const downloadUrl = config.serviceUrl + '/images/qr_codes/saisei/zip/' + data.data.data.zippath;
                console.log(downloadUrl, 'downloadUrl');

                // const downloadUrl = config.serviceUrl + "/images/invoice/" + order_id + ".pdf"; // Replace with the actual URL or path of the file
                const a = document.createElement('a');
                a.href = downloadUrl;
                a.target = '_blank';
                a.download = data.data.data.zippath;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            }
        }).catch(err => {
            this.props.hoc.customAlert("Something went wrong.", false)
            console.log(err, "err")
        })



        console.log(payload, 55555);


    }

    changeVal = async (event) => {
        const { seriesCount } = this.state;
        const name = event.target.name;
        const value = event.target.value;

        if (value > seriesCount) {
            this.setState({ quantity: '' });
            this.props.hoc.customAlert("Maximum 500 QR Codes can be mapped at a time.", false)
        } else {
            this.setState({ quantity: value });
        }


    }

    setOrderHistoryData = (data) => {
        const { allStockistList, alldoctorList } = this.state;
        console.log(allStockistList, 'stockistList1');
        console.log(alldoctorList, 'doctorList1');
        let resp = []
        for (let i = 0; i < data.length; i++) {
            // let stockistListData = allStockistList.filter(item => item.stockist_id == data[i].stockist_id);
            // let doctorListData = alldoctorList.filter(item => item.doctor_id == data[i].doctor_id);

            // console.log(stockistListData[0],'stockistListData123');
            resp.push({
                _id: moment(data[i].fullDate).local().format('YYYY-MM-DD HH:mm'),
                count: data[i].data[0].count,
                startSerialNo: data[i].data[0].startSerialNo,
                endSerialNo: data[i].data[0].endSerialNo,
                doctorName: data[i].data[0].doctor?.name,
                stockistName: data[i].data[0].stockist.name,
                fullDate: data[i].data[0].fullDate


            })
        }
        return resp
    }

    formatedData = (payload) => {
        let newData = [];
        if (payload) {

            payload.map((item) => {

                let itemData = {
                    "name": item.name,
                    "_id": item._id,
                    "label": item.name,
                    "value": item.name
                };
                newData.push(itemData);
            })


        }

        return newData;

    }

    populateStockist = () => {
        StockistService.getAllStockist().then(data => {
            console.log(data, 999999);
            if (data.data.success) {
                console.log(data.data.data.stockistlist, 9999999);
                const formatedData = this.formatedData(data.data.data.stockistlist);

                console.log(formatedData, 8888888);

                setTimeout(() => {

                    this.setState({ stockistList: formatedData });

                }, 500);



            }
        })


    }

    populateDoctor = (stockist_id = null) => {
        if (stockist_id) {
            console.log(stockist_id, 8888888);
            let payload = {
                stockist_id: stockist_id
            };

            DoctorService.getAllDoctorList(payload).then(data => {
                console.log(data, 999999);
                if (data.data.success) {
                    // console.log(data.data.data.stockistlist,9999999);
                    const formatedData = this.formatedData(data.data.data);

                    console.log(formatedData, 8888888);

                    setTimeout(() => {

                        this.setState({ doctorList: formatedData });

                    }, 500);

                }
            })

            // console.log(stockist_id,255);
            // const { alldoctorList } = this.state;
            // const doctorList = alldoctorList.filter(item => item.stockist_id == stockist_id);
            // this.setState({doctorList:doctorList});
        }
    }

    checkAvailableVacutainers = () => {
        AdminService.checkVacutainersAvailable().then(data => {
            console.log(data.data.data.data, "availableseries");
            if (data.data.success) {
                if (data.data.data.data.length > 0) {
                    console.log(data.data.data.data[0].firstSerialNo, 'in3');
                    let series = config.seriesName + data.data.data.data[0].firstSerialNo + '-' + config.seriesName + data.data.data.data[0].lastSerialNo;

                    this.setState({ availableSeries: series });
                    this.setState({ seriesCount: data.data.data.data[0].count });
                } else {
                    this.setState({ availableSeries: '' });
                    this.setState({ seriesCount: '' });
                }


                // if(data.data.data.data.length>0){

                // }
                // console.log(data.data.data.data[0].totalCustomer, "10001");

            }
        }).catch(err => {
            this.props.hoc.customAlert("Something went wrong.", false)
            console.log(err, "err")
        })
    }

    getAllrecords = () => {
        AdminService.getAllVacutainersRecords().then(data => {
            console.log(data.data.data.data, "allrecords");
            if (data.data.success) {
                this.setState({ vacutainerRecords: data.data.data.data })
                console.log(data, 'in3');

            }
        }).catch(err => {

            console.log(err, "err")
        })

    }

    handleSubmit = () => {
        const { selectedStockist_id, selectedDoctor_id, quantity, availableSeries, finalscanResult } = this.state;

        if (selectedStockist_id == '') {
            this.props.hoc.customAlert("Please Choose Stockist.", false)
            return false;
        }
        else if (finalscanResult.length == 0) {
            this.props.hoc.customAlert("No scan QR found.", false)
            return false;
        } else {
            let payload = {
                stockist_id: selectedStockist_id,
                availableQR: JSON.stringify(finalscanResult)
            };
            console.log(payload, 80000);

            AdminService.createVacutainersRecord(payload).then(data => {
                console.log(data.data, "zip qr codes");
                if (data.data.success) {
                    this.checkAvailableVacutainers();
                    this.getAllrecords();
                    this.setState({selectedStockist: "", selectedStockist_id: "", finalscanResult: []})
                    this.props.hoc.customAlert("QR Codes Mapped Successfully.", true)
                }
            }).catch(err => {
                this.props.hoc.customAlert("Something went wrong.", false)
                console.log(err, "err")
            })
        }
        // else if(selectedDoctor_id==''){
        //     this.props.hoc.customAlert("Please Choose Doctor.", false)
        //     return false;
        // }else if(quantity==''){
        //     this.props.hoc.customAlert("Please Choose Quantity.", false)
        //     return false;
        // }else if(quantity>500){
        //     this.props.hoc.customAlert("Maximum 500 QR Codes can be mapped at a time.", false)
        //     return false;
        // }else{
        //     let payload = {
        //         stockist_id:selectedStockist_id,
        //         doctor_id:selectedDoctor_id,
        //         quantity:quantity,
        //         availableseries:availableSeries
        //     };
        //     console.log(payload,80000);

        //     AdminService.createVacutainersRecord(payload).then(data => {
        //         console.log(data.data, "zip qr codes");
        //         if (data.data.success) {
        //             this.checkAvailableVacutainers();
        //             this.getAllrecords();
        //             this.props.hoc.customAlert("QR Codes Mapped Successfully.", true)
        //         }
        //     }).catch(err => {
        //         this.props.hoc.customAlert("Something went wrong.", false)
        //         console.log(err, "err")
        //     })



        // }
        console.log(selectedStockist_id, 'selectedStockist');

    }
    clearSearch = (event) => {
        this.setState({ selectedStockist: '', selectedDoctor: "", quantity: '', doctorList: [] });
        this.getAllrecords();


    }
    openScanner = (event) => {
        
        const { openscanner } = this.state;
        if (openscanner == 'false') {
            alert('in if');
            this.setState({ openscanner: 'true' });
        } 
        else {
            // alert('in else');

            // setTimeout(() => {
            //     this.setState({ openscanner: 'false' });
                
            // }, 500);

        }
    }

    getScanResultData = async (result) => {
        const splitArray = result.split('/');
        let _finalscanResult = this.state.finalscanResult
        let _allseriesNo = this.state.allselectedSeries
        if (_finalscanResult.indexOf(splitArray[splitArray.length - 1]) === -1) {
            await _finalscanResult.push(splitArray[splitArray.length - 1]);
            if (splitArray[splitArray.length - 1] != '') {
                let payload = {
                    uniqueQR: splitArray[splitArray.length - 1]
                }
                // let payload = {
                //     uniqueQR: '3TpMmXV2zB'
                // }
                AdminService.getSeriesNumber(payload).then(data => {
                    console.log(data.data.data.data[0].serial_no, 222);
                    if (data.data.success) {

                        if (data.data.data.data[0].stockist_id != null) {
                            this.props.hoc.customAlert("This QR Code is already mapped with stockist.", false)
                        } else {
                            let seriesno = config.seriesName + data.data.data.data[0].serial_no

                            _allseriesNo.push(seriesno)

                            this.setState({ allselectedSeries: _allseriesNo })

                            this.setState({ finalscanResult: _finalscanResult })

                            let message = seriesno+" mapped..!";
                            this.props.hoc.customAlert(message, true)
                        }


                        // this.props.hoc.customAlert("Success.", true)
                    }
                }).catch(err => {
                    this.props.hoc.customAlert("Something went wrong.", false)
                    console.log(err, "err")
                })
            }


        }


    }




    render = () => {
        const { stockistList, selectedStockist, doctorList, selectedDoctor, vacutainerRecords, quantity, availableSeries, seriesCount, openscanner, allselectedSeries } = this.state;

        console.log(openscanner);
        console.log(availableSeries, 'availableseries');
        console.log(vacutainerRecords, 'vacutainerRecords');
        let _data = this.setOrderHistoryData(vacutainerRecords)
        console.log(_data, "aksdhklsah");
        return (
            <>
                <div className="layout-wrapper">
                    <AdminHeader />
                    <AdminSidebar />
                    <div className="main-content">
                        <div className="page-content">
                            <div className="container-fluid">
                                <div className="row g-4">
                                    <div className='col-md-6'>
                                        <h5 className='mb-20 headingstyle'>QR Mapping</h5>
                                    </div>
                                    <div className='col-md-6 text-end'>
                                        {/* <button className='btn btn-primary' type='button' onClick={(event) => this.goPreviousPage(event)}>Back</button> */}
                                    </div>
                                    {/* Row end */}
                                    <div className="row">
                                        <form className="mt-3 registration-form">
                                            <div className='row mb-5'>
                                                <div className="col-md-3 mb-3">
                                                    <label htmlFor="disabledTextInput" className="form-label lvl-clr-hd">Please Choose Stockist<span className='requiredFld'> * </span> </label>
                                                    {
                                                        stockistList.length ?
                                                            <Dropdown
                                                                items={stockistList}
                                                                onSelect={(item) => {
                                                                    if (item) {
                                                                        console.log(item.stockist_id, '2222');

                                                                        this.setState({ selectedStockist: item.name })
                                                                        this.setState({ selectedStockist_id: item._id })
                                                                        //console.log(item, "item");
                                                                        if (item.name != "All") {
                                                                            // this.populateDoctor(item._id)
                                                                        } else {
                                                                            this.setState({ doctorList: [] })
                                                                            this.setState({ selectedDoctor: "" })
                                                                        }
                                                                    }
                                                                }}
                                                                value={selectedStockist}
                                                            /> :
                                                            <Dropdown
                                                                items={stockistList}
                                                                value={selectedStockist}
                                                            />
                                                    }

                                                </div>

                                                <div className='col-md-6 gap-2 mt-3'>

                                                    <button className='btn btn-primary mt-2' type='button' onClick={(event) => this.openScanner(event)}>Scan QR Codes</button>
                                                    {/* <button className='btn btn-primary mt-2 mx-2' type='button' onClick={(event) => this.openScanner(event)}>Close Scanner</button> */}


                                                </div>
                                                {openscanner == 'true' ?
                                                    <div className='col-md-10 mt-3 marginleft'>
                                                        {/* <Webcam /> */}

                                                        {/* <input type="file" accept="image/*, video/*" capture="environment" /> */}

                                                        <Scanner getScanResultData={this.getScanResultData} allselectedSeries={allselectedSeries} openscanner={openscanner} />
                                                    </div> : ''}

                                                <div className='col-md-6'>
                                                    <button className='btn btn-primary mt-2' type='button' onClick={(event) => this.handleSubmit(event)}>Submit</button>
                                                </div>



                                                {/* <div className="col-md-1 mt-4">
                                                    <button className='btn btn-primary' type='button' onClick={(event) => this.allotVacutainer(event)}>Scanning</button>
                                                </div> */}
                                                {/* <div className="col-md-2 mt-4">
                                                    <button className='btn btn-primary' type='button' onClick={(event) => this.clearSearch(event)}>Clear</button>
                                                </div> */}


                                            </div>
                                            {/* <h5 className='mb-20 headingstyle'>QR Mapping Records</h5> */}

                                            <div className='row'>
                                                {/* <div className="table-responsive">


                                                    {
                                                        _data && _data.length>0 ?
                                                            <CustomDatatable
                                                                pagination={true}
                                                                columns={[
                                                                    {
                                                                        name: 'Mapping Date',
                                                                        // selector: row => row.mr_name,

                                                                    },
                                                                    {
                                                                        name: 'Stockist',
                                                                        // selector: row => row.mr_name,

                                                                    },
                                                                    {
                                                                        name: 'Doctor',
                                                                        // selector: row => row.mr_name,

                                                                    },
                                                                    {
                                                                        name: 'Vacutainer Count',
                                                                        // selector: row => row.mr_name,

                                                                    },
                                                                    {
                                                                        name: 'QR Code Series',
                                                                        // selector: row => row.mr_name,

                                                                    },
                                                                    {
                                                                        name: 'Download QR Code',
                                                                        // selector: row => row.createdAt,

                                                                    }
                                                                ]}
                                                                data={this.setOrderHistoryData(vacutainerRecords)}
                                                                pageName="vacutainerrecord"
                                                                downloadQR={this.downloadQR}
                                                            />
                                                            : <p className="norecord"><strong>No Records Found</strong></p>
                                                    }
                                                </div> */}
                                            </div>

                                        </form>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <AdminFooter />
                    </div>
                </div>

            </>
        )

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        // dispatching plain actions
        // setCustomer: (customer) => dispatch(CustomerAction.setCustomer(customer))
    }
}

const mapStateToProps = (state) => {
    return {
        OrderReducer: state.OrderReducer,
        CustomerReducer: state.CustomerReducer,
        CommonReducer: state.CommonReducer,
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Common(QrmappingScreen)))