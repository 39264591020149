import React, { Component } from 'react';
import { connect } from 'react-redux';
import CustomerFooter from '../../../components/layout/CustomerFooter';
import { withRouter } from '../../../components/Reusable/WithRouter';
import CustomerHeader from '../../../components/layout/CustomerHeader';
import config from '../../../config/emrok.config';
import { Link } from 'react-router-dom';
import SuccessFullPaymentModal from '../../../components/Order/SuccessFullPaymentModal';


class PlaceOrderModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
           orderId:""
        }
    }

    componentDidMount = () => {
        const orderDetails = this.props.OrderReducer.currentOrder.order_id;
        this.setState({ orderId:  orderDetails})
        console.log(orderDetails,"orderDetails");
    }

    render = () => {
        const {orderId} = this.state;
        return (
            <>
                <section className="regstr-form emr-cart-page">
                    <div className="container">
                    <CustomerHeader />
                        {/* <div className="row">
                        <div className="w-100 logo-top-box mb-0">
                            <div className="d-flex justify-content-between align-items-center">
                            <div className="logo-wrap">
                                <a href="index.html">
                                <img src="images/logo.png" alt="logo" />
                                </a>
                            </div>
                            <div>
                                <button type="submit" className="btn-logout">
                                <svg
                                    width={16}
                                    height={16}
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                    className="logout-icon"
                                    d="M14.6666 7.99992H6.74992M12.9999 10.4999L15.4999 7.99992L12.9999 5.49992M8.83325 3.83325V2.99992C8.83325 2.55789 8.65766 2.13397 8.3451 1.82141C8.03254 1.50885 7.60861 1.33325 7.16659 1.33325H2.99992C2.55789 1.33325 2.13397 1.50885 1.82141 1.82141C1.50885 2.13397 1.33325 2.55789 1.33325 2.99992V12.9999C1.33325 13.4419 1.50885 13.8659 1.82141 14.1784C2.13397 14.491 2.55789 14.6666 2.99992 14.6666H7.16659C7.60861 14.6666 8.03254 14.491 8.3451 14.1784C8.65766 13.8659 8.83325 13.4419 8.83325 12.9999V12.1666"
                                    stroke=""
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    />
                                </svg>
                                Logout
                                </button>
                            </div>
                            </div>
                        </div>
                        </div> */}
                        <div className="reg-frm-dta">
                        <div className="reg-text-ft">
                            <h2 />
                        </div>
                        </div>
                        <div className="row">
                        <div className="col-md-6 cart-image">
                            {/* <img src="images/cart-image-bg.png" className="" alt="" /> */}
                            <img src={`${config.serviceUrl}/images/myownn/cart-image-bg.png`} className="" alt="" />
                        </div>
                        <div className="col-md-6 cart-image-details">
                            <h6>Medicine</h6>
                            <h2>MYOWNN</h2>
                            <div className="products-details">
                            <h5>Product Details</h5>
                            <p>
                                Lorem Ipsum is simply dummy text of the printing and typesetting
                                industry. Lorem Ipsum has been the industry's standard dummy text
                                ever since the
                            </p>
                            {/* <div className="cart-review-mr mb-5 mt-3">
                                <a href="javascript:void(0)" className="">
                                Know more
                                </a>
                            </div> */}
                            <button
                                type=""
                                data-bs-target="#paymentSuccessModal"
                                data-bs-toggle="modal"
                                className="place-ordr-itm me-2"
                                onclick=""
                            >
                                Place Order
                               
                            </button>
                            <button
                                type=""
                                className="place-ordr-itm"
                                onclick="location.href = 'order-information.html';"
                            >
                                <Link to={'/customer/track-order'} className='nextLinkStyle'> Next </Link> 
                            </button>
                            </div>
                        </div>
                        </div>
                    </div>
                </section>
                <SuccessFullPaymentModal dataTargetId={"orderPlaced"} orderId={orderId}/> 
                <CustomerFooter />

          </>          
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        // dispatching plain actions
        // setCustomer: (customer) => dispatch(CustomerAction.setCustomer(customer))
    }
}

const mapStateToProps = (state) => {
    return {
        OrderReducer: state.OrderReducer,
        CustomerReducer: state.CustomerReducer
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PlaceOrderModal))