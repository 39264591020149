import React from "react";
import { withRouter } from "../../components/Reusable/WithRouter";
import { connect } from "react-redux";
import Common from "../../hoc/Common.hoc";
import '../../assets/css/admin/style.css';
import '../../assets/css/admin/responsive.css';
import 'react-datepicker/dist/react-datepicker.css'; // Import the CSS

class PrivacyPolicy extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            totalOrders: "",
            totalAmount: "",
            totalCustomerCount: ""
        }
    }


    componentDidMount() {

    }
    componentWillUnmount() {

    }


    render = () => {

        return (

            <>

                <div className="layout-wrapper">
                    {/* <AdminHeader /> */}
                    {/* <AdminSidebar /> */}
                    <div className="main-content-privacy">
                        <div className="mt-10 p-20 privacyblock">
                            <div className="container-fluid">
                                <div className="dashboardheading">

                                    <h5 className="mb-10 text-center">Privacy Policy</h5>

                                </div>
                                <div className="row g-4">
                                    <div className="col-md-12 privacytext">

                                    <h4>1. Introduction</h4>
                                    <p>Welcome to the XO Face Serum website. We are committed to protecting your privacy and ensuring that you and your patient information is handled in a safe and responsible manner.</p>
                                    <h4>2. Information We Collect</h4>
                                    <p>We may collect the following types of information:</p>
                                    <ul>
                                        <li>Information of the Doctor: Name, email address, phone number, and shipping address</li>
                                        <li>Information of the Patient: Name & Phone number</li>
                                    </ul>
                                
                                
                                    <h4>3. How We Use Your Information</h4>
                                    <p>We use your information to:</p>
                                    <ul>
                                        <li>Process and fulfil your orders.</li>
                                        <li>Communicate with you about your orders.</li>
                                        <li>Improve our website and customer service.</li>
                                        <li>We will not be using any of the information shared for any promotion or marketing.</li>
                                    </ul>
                                  
                                    <h4>4. Sharing Your Information</h4>
                                   <p>We do not sell or share your information with third parties, except:</p> 
                                   <ul>
                                    <li>With service providers who assist in our business operations.</li>
                                    <li>When required by law or to protect our rights.</li>
                                   </ul>
                                   
                                    <h4>5. Data Security</h4>
                                   <p>We implement appropriate technical and organizational measures to protect your information against accidental or unlawful destruction, loss, alteration, unauthorized disclosure, or access.</p> 
                                    <h4>6. Your Rights</h4>
                                    <p>You have the right to:</p>
                                    <ul>
                                        <li>Access the information we hold about you.</li>
                                        <li>Request the correction of inaccurate information.</li>
                                        <li>Request the deletion of your personal information.</li>
                                    </ul>
                                  
                                    <h4>7. Changes to This Policy</h4>
                                    <p>We may update this Privacy Policy from time to time. We will notify you of any significant changes by posting the new policy on our website.</p>
                                    <h4>8. Contact Us</h4>
                                    <p>If you have any questions about this Privacy Policy, please contact us at:</p>
                                    <p><b>Email:</b> privacy@xoserum.com</p>
                                    <p><b>Phone:</b> +91 - 86578 07446</p>
                                    </div>
                                </div>

                                {/* Row end*/}
                            </div>
                        </div>
                        {/* <AdminFooter /> */}
                    </div>


                </div>






            </>

        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {

    }
}

const mapStateToProps = ({ AdminReducer, CommonReducer }) => {
    const { admin } = AdminReducer
    const { summaryDetails } = CommonReducer
    return {
        admin,
        summaryDetails
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Common(PrivacyPolicy)))