import React from "react";
import { withRouter } from "../../components/Reusable/WithRouter";
import { connect } from "react-redux";
import Common from "../../hoc/Common.hoc";
import '../../assets/css/admin/style.css';
import '../../assets/css/admin/responsive.css';
import 'react-datepicker/dist/react-datepicker.css'; // Import the CSS

class TermsAndCondition extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            totalOrders: "",
            totalAmount: "",
            totalCustomerCount: ""
        }
    }


    componentDidMount() {

    }
    componentWillUnmount() {

    }


    render = () => {

        return (

            <>

                <div className="layout-wrapper">
                    {/* <AdminHeader /> */}
                    {/* <AdminSidebar /> */}
                    <div className="main-content-privacy">
                        <div className="mt-10 p-20 privacyblock">
                            <div className="container-fluid">
                                <div className="dashboardheading">

                                    <h5 className="mb-10 text-center">Terms & Conditions</h5>

                                </div>
                                <div className="row g-4">
                                    <div className="col-md-12 privacytext">

                                    <h4>1. Acceptance of Terms</h4>
                                    <p>By accessing and using the XO Face Serum website, you agree to comply with and be bound by these Terms & Conditions.</p>
                                    <h4>2. Use of the Website</h4>
                                    <p>You agree to use the website for lawful purposes only. You must not use the website in any way that causes, or may cause, damage to the website or impairment of the availability or accessibility of the website.</p>
                                   
                                
                                    <h4>3. Intellectual Property Rights</h4>
                                    <p>All content, trademarks, and data on this website, including but not limited to text, images, logos, and software, are the property of XO Face Serum or its licensors, unless explicitly stated otherwise.</p>
                                  
                                    <h4>4. Product Information</h4>
                                   <p>We strive to ensure that the information on our website is accurate and up-to-date. However, we do not warrant that the product descriptions or other content on this site are accurate, complete, reliable, current, or error-free.</p> 
                                 
                                   
                                    <h4>5. Orders and Payments</h4>
                                   <p>By placing an order, you agree to prescribe the product to your patient. Payment has to be made within stipulated time to Koshin Clinic.</p> 
                                    <h4>6. Shipping and Delivery</h4>
                                    <p>We will make every effort to deliver your order within the estimated delivery times. However, delays are occasionally inevitable due to unforeseen factors, and we shall not be liable for any delay or failure to deliver the products within the estimated times.</p>
                                   
                                  
                                    <h4>7. Limitation of Liability</h4>
                                    <p>To the maximum extent permitted by law, XO Face Serum shall not be liable for any indirect, incidental, special, or consequential damages arising out of or in connection with the use of this website.</p>
                                    <h4>8. Governing Law</h4>
                                    <p>These Terms & Conditions shall be governed by and construed in accordance with the laws of India, and any disputes relating to these terms will be subject to the exclusive jurisdiction of the courts of Mumbai, Maharashtra.</p>
                                    <h4>9. Changes to the Terms</h4>
                                    <p>We reserve the right to modify these Terms & Conditions at any time. Any changes will be posted on this page, and your continued use of the website after such changes have been made will constitute your acceptance of the new Terms & Conditions.</p>
                                    <h4>10. Contact Us</h4>
                                    <p>If you have any questions about these Terms & Conditions, please contact us at: support@xoserum.com</p>
                                  
                                    </div>
                                </div>

                                {/* Row end*/}
                            </div>
                        </div>
                        {/* <AdminFooter /> */}
                    </div>


                </div>






            </>

        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {

    }
}

const mapStateToProps = ({ AdminReducer, CommonReducer }) => {
    const { admin } = AdminReducer
    const { summaryDetails } = CommonReducer
    return {
        admin,
        summaryDetails
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Common(TermsAndCondition)))