import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from '../../../components/Reusable/WithRouter';
import config from '../../../config/emrok.config';
import Common from '../../../hoc/Common.hoc';
import axios from 'axios';
import moment from 'moment';
import AdminHeader from "../../../components/layout/Admin/AdminHeader";
import AdminSidebar from "../../../components/layout/Admin/AdminSidebar";
import AdminFooter from "../../../components/layout/Admin/AdminFooter";
import '../../../assets/css/admin/style.css';
import '../../../assets/css/admin/responsive.css';
import DropdownDoctorRuntime from '../../../components/SearchableDropdown/DropdownDoctorRuntime.component';
import AdminService from '../../../services/Admin.service';
import CustomDatatable from "../../../components/Datatable/CustomDatatable.component";
class QrCodeGenerationHistory extends Component {
    constructor(props) {
        super(props)

        this.state = {
            quantity: "",
            selectOrder: [],
            bankrefno: "",
            rtochangeDate: "",
            refundchangeDate: "",
            qrList: [],
            selectedDoctor: "",
            searchDoctor: "",
            currentPage: 1,
            itemsPerPage: 20
        }

    }


    componentDidMount() {

        let payload = {
            fetchtype : 'all'
        };
        AdminService.getAllQRCodes(payload).then(data => {
            console.log(data.data.data.data, "all qr codes");
            if (data.data.success) {
                // console.log(data.data.data.data[0].totalCustomer, "10001");
                this.setState({ qrList: data.data.data.data });
            }
        }).catch(err => {

            console.log(err, "err")
        })


    }

    componentWillUnmount() {
        if (this.dataTable) {
            this.dataTable.destroy(true);
        }
    }

    goPreviousPage = () => {
        // const usertype = this.props.CommonReducer.userType;
        this.props.navigate('/admin/qr-code-generation');
    };

    downloadQR = async (event) => {

        let payload = {
            serial_no: event.target.id
        };

        AdminService.zipQrCodes(payload).then(data => {
            console.log(data.data, "zip qr codes");
            if (data.data.success) {
                console.log('in success');

                const downloadUrl = config.serviceUrl + '/images/qr_codes/saisei/zip/' + data.data.data.zippath;
                console.log(downloadUrl,'downloadUrl');

                // const downloadUrl = config.serviceUrl + "/images/invoice/" + order_id + ".pdf"; // Replace with the actual URL or path of the file
                const a = document.createElement('a');
                a.href = downloadUrl;
                a.download = data.data.data.zippath;
                a.click();
            }
        }).catch(err => {
            this.props.hoc.customAlert("Something went wrong.", false)
            console.log(err, "err")
        })



        console.log(payload, 55555);
        // const {doctorList} = this.state;
        // console.log(doctorList,'666666')
        // const selectedDoctorDetails = await doctorList.filter(item => item._id == payload.doctor_id)
        // console.log(selectedDoctorDetails,"666666")
        // AdminService.downloadQrCode(payload).then(res => {
        //     console.log(res.data.data, 88888899)
        //     if(res.data.data && res.data.data.startsWith('data:image/png;base64')){
        //        const name = selectedDoctorDetails?`${selectedDoctorDetails[0].name}.png`:`doc_id_${payload.doctor_id}.png`;

        //         this.downloadBase64Image(res.data.data,name)
        //     }
        //     // const downloadUrl = config.serviceUrl + "/images/invoice/" + event.target.id + ".pdf"; // 

        // }).catch(e => {

        // })

    }

    changeVal = async (event) => {
        const name = event.target.name;
        const value = event.target.value;

        this.setState({ quantity: value });
    }

    setOrderHistoryData = (data) => {
        let resp = []
        for (let i = 0; i < data.length; i++) {
            resp.push({
                _id: moment(data[i].fullDate).local().format('YYYY-MM-DD HH:mm'),
                count: data[i].count,
                startSerialNo: data[i].startSerialNo,
                endSerialNo: data[i].endSerialNo


            })
        }
        return resp
    }



    render = () => {
        const { qrList } = this.state;
        let _data = this.setOrderHistoryData(qrList)
        console.log(_data, "aksdhklsah");
        return (
            <>
                <div className="layout-wrapper">
                    <AdminHeader />
                    <AdminSidebar />
                    <div className="main-content">
                        <div className="page-content">
                            <div className="container-fluid">
                                <div className="row g-4">
                                    <div className='col-md-6'>
                                        <h5 className='mb-20'>ALL QR Codes</h5>
                                    </div>
                                    <div className='col-md-6 text-end'>
                                        <button className='btn btn-primary' type='button' onClick={(event) => this.goPreviousPage(event)}>Back</button>
                                    </div>
                                    {/* Row end */}
                                    <div className="row">
                                        <form className="mt-3 registration-form">
                                           
                                            <div className='row'>
                                                <div className="table-responsive">


                                                    {
                                                        _data && _data.length ?
                                                            <CustomDatatable
                                                                pagination={true}
                                                                columns={[
                                                                    {
                                                                        name: 'QR Code Generation Date',
                                                                        // selector: row => row.mr_name,

                                                                    },
                                                                    {
                                                                        name: 'Serial No',
                                                                        // selector: row => row.mr_name,

                                                                    },
                                                                    {
                                                                        name: 'Count of QR Codes',
                                                                        // selector: row => row.mr_name,

                                                                    },

                                                                    {
                                                                        name: 'Action',
                                                                        // selector: row => row.createdAt,

                                                                    }
                                                                ]}
                                                                data={this.setOrderHistoryData(qrList)}
                                                                pageName="qrcodegeneration"
                                                                downloadQR={this.downloadQR}
                                                            />
                                                            : <p className="norecord"><strong>No Records Found</strong></p>
                                                    }
                                                </div>
                                            </div>

                                        </form>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <AdminFooter />
                    </div>
                </div>

            </>
        )

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        // dispatching plain actions
        // setCustomer: (customer) => dispatch(CustomerAction.setCustomer(customer))
    }
}

const mapStateToProps = (state) => {
    return {
        OrderReducer: state.OrderReducer,
        CustomerReducer: state.CustomerReducer,
        CommonReducer: state.CommonReducer,
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Common(QrCodeGenerationHistory)))