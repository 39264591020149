import React, { Component } from 'react'
import $ from 'jquery';
import moment from 'moment';
import { format } from 'date-fns';
import config from "../../config/emrok.config";
import { Lightbox } from "react-modal-image";
import UserEdit from "../../assets/images/icons/user-edit.svg";
import UserCross from '../../assets/images/icons/user-cross.svg';
import UserCheck from '../../assets/images/icons/user-check.svg';

class CustomDatatable extends Component {
    constructor(props) {
        super(props)
        this.state = {
            columns: [],
            data: [],
            pagination: false,
            selectable: false,
            handleTrackOrderClick: undefined,
            handleEditPatientDetails: undefined,
            handleTrackOrder: undefined,
            pageName: null,
            handleStatusChangeOnClick: undefined,
            handleRTOAction: undefined,
            handleRTOAction: undefined,
            downloadQR: undefined,
            handleEditCallCenter: undefined,
            removeCallCenter: undefined,
            handleEditDoctor: undefined,
            handleGFC: undefined,
            handleSerumDispatch: undefined,
            handleProductReceived: undefined,
            handleEditDeliveryPerson: undefined,
            removeDeliveryPerson: undefined,
            handleAssignDeliveryPerson: undefined,
            handleChangeStatus: undefined
        }
    }

    static getDerivedStateFromProps(props, state) {
        return {
            columns: props.columns || [],
            data: props.data || [],
            pagination: props.hasOwnProperty("pagination") ? props.pagination : false,
            selectable: props.hasOwnProperty("selectable") ? props.selectable : false,
            handleTrackOrderClick: props.handleTrackOrderClick ? props.handleTrackOrderClick : null,
            handleEditPatientDetails: props.handleEditPatientDetails ? props.handleEditPatientDetails : null,
            handleTrackOrder: props.handleTrackOrder ? props.handleTrackOrder : null,
            handleStatusChangeOnClick: props.handleStatusChangeOnClick ? props.handleStatusChangeOnClick : undefined,
            pageName: props.pageName ? props.pageName : null,
            handleRTOAction: props.handleRTOAction ? props.handleRTOAction : null,
            handleRTOAction: props.handleRTOAction ? props.handleRTOAction : null,
            downloadQR: props.downloadQR ? props.downloadQR : null,
            handleEditCallCenter: props.handleEditCallCenter ? props.handleEditCallCenter : null,
            removeCallCenter: props.removeCallCenter ? props.removeCallCenter : null,
            handleEditDoctor: props.handleEditDoctor ? props.handleEditDoctor : null,
            handleGFC: props.handleGFC ? props.handleGFC : null,
            handleSerumDispatch: props.handleSerumDispatch ? props.handleSerumDispatch : null,
            handleProductReceived: props.handleProductReceived ? props.handleProductReceived : null,
            handleEditDeliveryPerson: props.handleEditDeliveryPerson ? props.handleEditDeliveryPerson : null,
            removeDeliveryPerson: props.removeDeliveryPerson ? props.removeDeliveryPerson : null,
            handleAssignDeliveryPerson: props.handleAssignDeliveryPerson ? props.handleAssignDeliveryPerson : null,
            handleChangeStatus: props.handleChangeStatus ? props.handleChangeStatus : null
        }
    }

    closeLightbox = () => {
        // this.state.open = true;
        this.setState({ open: false });
    };
    openLightBox = (itemid) => {
        //  alert(itemid);
        this.setState({ selectedid: itemid });
        this.setState({ open: true });
        // this.state.open = true;
    };

    componentDidMount = () => {

        console.log(this.props.columns, "columns");
        console.log(this.props.data, "data");
        // Initialize the DataTable when the component mounts
        // if(this.state.data.length) {

        $('#dt-tbl').DataTable({
            searching: true, // Enable search functionality
            paging: true, // Enable pagination
            // "order": [[0, "desc"]],
            "aaSorting": [],
            "language": {
                "emptyTable": "No Records Found"
            },
            scrollX: true
            // sorting: true, 
        },);
        // }
    }

    handleClick = (orderid, actiontype) => {
        // Call the function passed from the parent
        if (this.state.handleStatusChangeOnClick) {
            this.state.handleStatusChangeOnClick(orderid, actiontype);
        }
    };

    // handleCheckStatus = (item) => {

    //     console.log(item, "123");
    //     // Call the function passed from the parent
    //     this.props.handleOnChangeStatus(item);
    // };



    componentWillUnmount() {
        // Destroy the DataTable when the component unmounts
        // $(this.tableRef).DataTable().destroy();
        $('#dt-tbl').DataTable().destroy();
        // common-table cell-border
    }

    render = () => {

        const { columns, data, pagination, selectable, pageName } = this.props
        const { HandleOnChangeStatus } = this.state
        console.log(this.state, "dataallalllllll");
        return (

            <table id="dt-tbl" className="display common-table cell-border common-fixed-coloumn-first" style={{ width: "100%" }}>
                <thead>
                    <tr>
                        {columns.map((item, index) => (
                            <th key={`col-${index}`}>{item.name}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>


                    {pageName && pageName == "customergeneration" ? data.map((item, index) => (
                        <tr key={`body-${index}`}>
                            <td>{index + 1}</td>
                            <td>{item.name}</td>
                            <td>{item.mobileno}</td>
                            <td>{item.createdAt}</td>
                        </tr>

                    )) : null}


                    {pageName && pageName == "orderhistory" ? data.map((item, index) => (
                        <tr key={`body-${index}`}>
                            <td>ID-{item.order_id}</td>
                            <td><p style={{ display: 'none' }}>{item.collection_date && moment(item.collection_date).format('YYYY-MM-DD')}</p>{item.collection_date && moment(item.collection_date).format('DD-MM-YYYY')}</td>
                            <td>Address-{item.address}</td>
                            <td></td>
                            <td>
                                <button type="button" className="landing-pending-btn">
                                    {item.orderStatus}
                                </button>
                            </td>
                            <td >
                                <div className="track-order-btn">
                                    <a href="javscript:void(0);" onClick={this.state.handleTrackOrderClick} name={item._id}> Track Order </a>
                                </div>
                            </td>

                        </tr>

                    )) : null}

                    {pageName && pageName == "mrlanding"
                        ? data.map((item, index) => (
                            <tr key={`body-${index}`}>
                                <td>{item.order_no}</td>
                                <td>{item.order_date}</td>
                                <td>{item.patient_name}</td>
                                <td>{item.patient_mobile}</td>

                                <td>{item.doctor_naame}</td>
                                <td>{item.stockist_name}</td>
                                <td>{item.clinic_name}</td>
                                {/* <td>{item.mr_name}</td> */}

                                <td>{item.order_status}</td>
                                <td>
                                    {/* <div className="d-flex"> */}
                                    {/* {
                                        <button
                                            type="button"
                                            className="landing-success-btn"
                                            onClick={this.state.handleTrackOrder}
                                            name={item._id}
                                        >
                                            Track Order
                                        </button>
                                    } */}

                                    {
                                        <button
                                            type="button"
                                            className="landing-success-btn track-order-btn"


                                        >
                                            <img onClick={this.state.handleTrackOrder} name={item._id} src={require("../../assets/images/action.png")} alt=""></img>
                                            {/* <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                viewBox="0 0 24 24"
                                            >
                                                <path
                                                    fill="currentColor"
                                                    d="M6.016 4.502A2.976 2.976 0 0 0 3.038 7.48c0 2.233 2.978 5.53 2.978 5.53s2.978-3.297 2.978-5.53a2.976 2.976 0 0 0-2.978-2.978m0 4.041A1.063 1.063 0 1 1 7.079 7.48a1.064 1.064 0 0 1-1.063 1.063m15.008 2.753v-4.3a4.962 4.962 0 0 0-.204-1.333a4.996 4.996 0 0 0-9.796 1.216v.248l-.01.87v9.952h-.004v.041a2 2 0 0 1-4 0c0-.012.003-.024.004-.037H7.01V16.01h-2v2h.002a3.998 3.998 0 0 0 7.996-.005h.002v-.982h.005V8.997l.01-1.87V6.88a3.001 3.001 0 0 1 6 .123v4.275a1.999 1.999 0 1 0 2 .018"
                                                />
                                            </svg> */}
                                        </button>
                                    }


                                    {/* {item.order_status == "pending" ? (
                                            <button
                                                type="button"
                                                className="landing-success-btn bg-danger ms-3"
                                                onClick={this.state.handleCancelOrder}
                                                name={item._id}
                                                id={item.patient_mobile}
                                                data-cid={item._id}
                                                data-bs-target="#orderCanceledModal"
                                                data-bs-toggle="modal"
                                            >
                                                Reject
                                            </button>
                                        ) : (
                                            ""
                                        )} */}
                                    {/* </div> */}
                                </td>
                            </tr>
                        ))
                        : null}


                    {pageName && pageName == "stockistlanding" ? data.map((item, index) => (
                        <tr key={`body-${index}`}>


                            <td>{item.order_id}</td>
                            <td>{item.mr_name}</td>
                            <td>{item.customer_name}</td>
                            <td>{item.doctor_name}</td>
                            <td>{item.customer_state}</td>
                            <td>{item.customer_town}</td>
                            <td><p style={{ display: 'none' }}>{item.order_date && moment(item.order_date).format('YYYY-MM-DD')}</p>{item.order_date && moment(item.order_date).format('DD-MM-YYYY')}</td>
                            {/* <td>
                                <p>{item.customer_name}</p>
                                <p>Mob-{item.customer_mobile}</p>
                                <p>Address-{item.customer_address}</p>
                            </td> */}
                            <td><p style={{ display: 'none' }}>{item.collection_date && moment(item.collection_date).format('YYYY-MM-DD')}</p>{item.collection_date && moment(item.collection_date).format('DD-MM-YYYY')}</td>
                            <td className='middleallignment'>{item.standard_srl_value + "(" + (item.actual_srl_value != null ? item.actual_srl_value : 0) + ")"}</td>

                            <td><p style={{ display: 'none' }}>{item.grn_date && moment(item.grn_date).format('YYYY-MM-DD')}</p>{item.grn_date && moment(item.grn_date).format('DD-MM-YYYY')}</td>
                            <td><p style={{ display: 'none' }}>{item.delivery_date && moment(item.delivery_date).format('YYYY-MM-DD')}</p>{item.delivery_date && moment(item.delivery_date).format('DD-MM-YYYY')}</td>
                            {/* <td></td> */}
                            <td>
                                {<button type="button" className={item.order_status != 'complete' ? "landing-pending-btn" : "landing-success-btn"} onClick={this.state.handleTrackOrder} name={item._id}>
                                    {item.order_status}</button>}
                            </td>


                        </tr>

                    )) : null}



                    {pageName && pageName == "doctorlanding" ? data.map((item, index) => (
                        <tr key={`body-${index}`}>
                            <td>{item.order_id}</td>
                            <td>{item.customer_name}</td>
                            <td>{item.mr_name}</td>
                            <td>{item.stockist_name}</td>
                            <td>{item.customer_state}</td>
                            {/* <td>{item.customer_town}</td>
                            <td><p style={{ display: 'none' }}>{item.order_date && moment(item.order_date).format('YYYY-MM-DD')}</p>{item.order_date && moment(item.order_date).format('DD-MM-YYYY')}</td> */}
                            {/* <td>
                                <p>{item.customer_name}</p>
                                <p>Mob-{item.customer_mobile}</p>
                                <p>Address-{item.customer_address}</p>
                            </td> */}
                            {/* <td><p style={{ display: 'none' }}>{item.collection_date && moment(item.collection_date).format('YYYY-MM-DD')}</p>{item.collection_date && moment(item.collection_date).format('DD-MM-YYYY')}</td>
                            <td><p style={{ display: 'none' }}>{item.GRN_DATE && moment(item.GRN_DATE).format('YYYY-MM-DD')}</p>{item.GRN_DATE && moment(item.GRN_DATE).format('DD-MM-YYYY')}</td>
                            <td><p style={{ display: 'none' }}>{item.delivery_date && moment(item.delivery_date).format('YYYY-MM-DD')}</p>{item.delivery_date && moment(item.delivery_date).format('DD-MM-YYYY')}</td> */}
                            {/* <td></td> */}

                            <td>
                                {<button type="button" className={item.order_status != 'complete' ? "landing-pending-btn" : "landing-success-btn"} onClick={this.state.handleTrackOrder} name={item._id}>
                                    {item.order_status}</button>}
                            </td>


                        </tr>

                    )) : null}

                    {pageName && pageName == "orderlist" ? data.map((item, index) => (
                        <tr key={`body-${index}`}>


                            <td>{item.order_no}</td>
                            <td>{item.order_date}</td>
                            <td>{item.patient_name}</td>
                            <td>{item.patient_mobile}</td>
                            <td>{item.stockist_name}</td>
                            <td>{item.vacutainer_code}</td>

                            <td>{item.order_status.toUpperCase()}</td>
                            <td>
                                {/* <div className="d-flex"> */}
                                {/* {
                                    <button
                                        type="button"
                                        className="landing-success-btn"
                                        onClick={this.state.handleTrackOrder}
                                        name={item._id}
                                    >
                                        Track Order
                                    </button>
                                } */}
                                {
                                    <button
                                        type="button"
                                        className="landing-success-btn track-order-btn"


                                    >
                                        <img onClick={this.state.handleTrackOrder} name={item._id} src={require("../../assets/images/action.png")} alt=""></img>

                                    </button>
                                }
                                {/* {item.order_status == "pending" ? (
                                            <button
                                                type="button"
                                                className="landing-success-btn bg-danger ms-3"
                                                onClick={this.state.handleCancelOrder}
                                                name={item._id}
                                                id={item.patient_mobile}
                                                data-cid={item._id}
                                                data-bs-target="#orderCanceledModal"
                                                data-bs-toggle="modal"
                                            >
                                                Reject
                                            </button>
                                        ) : (
                                            ""
                                        )} */}
                                {/* </div> */}
                            </td>

                        </tr>

                    )) : null}

                    {pageName && pageName == "patientlist" ? data.map((item, index) => (
                        <tr key={`body-${index}`}>


                            <td>{item.order_no}</td>
                            <td>{item.order_date}</td>
                            <td>{item.doctor_name}</td>
                            {/* <td>{item.stockist_name}</td> */}
                            <td>{item.vacutainer_code}</td>

                            <td>{item.order_status}</td>
                            <td>
                                {/* <div className="d-flex"> */}
                                {/* {
                                    <button
                                        type="button"
                                        className="landing-success-btn"
                                        onClick={this.state.handleTrackOrder}
                                        name={item._id}
                                    >
                                        Track Order
                                    </button>
                                } */}

                                {
                                    <button
                                        type="button"
                                        className="landing-success-btn track-order-btn"


                                    >
                                        <img onClick={this.state.handleTrackOrder} name={item._id} src={require("../../assets/images/action.png")} alt=""></img>
                                        {/* <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                viewBox="0 0 24 24"
                                            >
                                                <path
                                                    fill="currentColor"
                                                    d="M6.016 4.502A2.976 2.976 0 0 0 3.038 7.48c0 2.233 2.978 5.53 2.978 5.53s2.978-3.297 2.978-5.53a2.976 2.976 0 0 0-2.978-2.978m0 4.041A1.063 1.063 0 1 1 7.079 7.48a1.064 1.064 0 0 1-1.063 1.063m15.008 2.753v-4.3a4.962 4.962 0 0 0-.204-1.333a4.996 4.996 0 0 0-9.796 1.216v.248l-.01.87v9.952h-.004v.041a2 2 0 0 1-4 0c0-.012.003-.024.004-.037H7.01V16.01h-2v2h.002a3.998 3.998 0 0 0 7.996-.005h.002v-.982h.005V8.997l.01-1.87V6.88a3.001 3.001 0 0 1 6 .123v4.275a1.999 1.999 0 1 0 2 .018"
                                                />
                                            </svg> */}
                                    </button>
                                }


                                {/* {item.order_status == "pending" ? (
                                            <button
                                                type="button"
                                                className="landing-success-btn bg-danger ms-3"
                                                onClick={this.state.handleCancelOrder}
                                                name={item._id}
                                                id={item.patient_mobile}
                                                data-cid={item._id}
                                                data-bs-target="#orderCanceledModal"
                                                data-bs-toggle="modal"
                                            >
                                                Reject
                                            </button>
                                        ) : (
                                            ""
                                        )} */}
                                {/* </div> */}
                            </td>
                            <td>
                                {
                                    item.is_completed != 'yes' ? (<button
                                        type="button"
                                        className="landing-success-btn dispatch-btn"


                                    >
                                        <img onClick={this.state.handleProductReceived} name={item.order_no} src={require("../../assets/images/delivered.png")} alt=""></img>
                                        {/* <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                viewBox="0 0 32 32"
                                            >
                                                <path
                                                    fill="currentColor"
                                                    d="M12 6H8V2H6v4H2v2h4v4h2V8h4z"
                                                />
                                                <path
                                                    fill="currentColor"
                                                    d="m29.919 16.606l-3-7A.999.999 0 0 0 26 9h-3V7a1 1 0 0 0-1-1h-7v2h6v12.556A3.992 3.992 0 0 0 19.142 23h-6.284a3.98 3.98 0 0 0-7.716 0H4v-9H2v10a1 1 0 0 0 1 1h2.142a3.98 3.98 0 0 0 7.716 0h6.284a3.98 3.98 0 0 0 7.716 0H29a1 1 0 0 0 1-1v-7a.997.997 0 0 0-.081-.394M9 26a2 2 0 1 1 2-2a2.003 2.003 0 0 1-2 2m14-15h2.34l2.144 5H23Zm0 15a2 2 0 1 1 2-2a2.002 2.002 0 0 1-2 2m5-3h-1.142A3.995 3.995 0 0 0 23 20v-2h5Z"
                                                />
                                            </svg> */}
                                    </button>) : (
                                        <p>Yes</p>
                                    )
                                }
                            </td>

                        </tr>

                    )) : null}

                    {pageName && pageName == "callcenterlanding"
                        ? data.map((item, index) => (
                            <tr key={`body-${index}`}>
                                <td>{item.order_no}</td>
                                <td>{item.order_date}</td>
                                <td>{item.patient_name}</td>
                                <td>{item.patient_mobile}</td>

                                <td>{item.doctor_naame}</td>
                                <td>{item.stockist_name}</td>
                                <td>{item.clinic_name}</td>
                                <td>{item.mr_name}</td>


                                <td>{item.order_status}</td>
                                <td>
                                    {/* <div className="d-flex"> */}
                                    {
                                        <button
                                            type="button"
                                            className="landing-success-btn track-order-btn"


                                        >
                                            <img onClick={this.state.handleTrackOrder} name={item._id} src={require("../../assets/images/action.png")} alt=""></img>

                                        </button>
                                    }
                                    {/* {item.order_status == "pending" ? (
                                            <button
                                                type="button"
                                                className="landing-success-btn bg-danger ms-3"
                                                onClick={this.state.handleCancelOrder}
                                                name={item._id}
                                                id={item.patient_mobile}
                                                data-cid={item._id}
                                                data-bs-target="#orderCanceledModal"
                                                data-bs-toggle="modal"
                                            >
                                                Reject
                                            </button>
                                        ) : (
                                            ""
                                        )} */}
                                    {/* </div> */}
                                </td>
                            </tr>
                        ))
                        : null}

                    {pageName && pageName == "cliniclanding"
                        ? data.map((item, index) => (
                            <tr key={`body-${index}`}>
                                <td>{item.order_no}</td>
                                <td>{item.order_date}</td>
                                <td>{item.vacutainer_code}</td>
                                <td>{item.patient_name}</td>
                                <td>{item.patient_mobile}</td>
                                <td>{item.mr_name}</td>
                                <td>{item.doctor_naame}</td>
                                <td>{item.doctor_mobile}</td>
                                <td>{item.stockist_name}</td>
                                <td>{item.order_status}</td>
                                <td>
                                    {/* <div className="d-flex"> */}
                                    {
                                        <button
                                            type="button"
                                            className="landing-success-btn track-order-btn"


                                        >
                                            <img onClick={this.state.handleTrackOrder} name={item._id} src={require("../../assets/images/action.png")} alt=""></img>

                                        </button>
                                    }

                                </td>
                                <td>
                                    <button className='landing-success-btn dispatch-btn'  >

                                        <img className='assignjob' onClick={this.state.handleAssignDeliveryPerson} id="collection" name={item.order_no} src={require("../../assets/images/assign (1).png")} alt=""></img>

                                    </button>
                                </td>
                                <td>
                                    {
                                        item.status1C == 'yes' ? (<button
                                            type="button"
                                            className="landing-success-btn gfc-btn"

                                        >

                                            <img onClick={this.state.handleGFC} name={item.order_no} src={require("../../assets/images/gfc.png")} alt=""></img>
                                        </button>
                                        ) : (
                                            item.gfc_received == 'yes' ? (
                                                <p>Yes</p>
                                                // <button
                                                //     type="button"
                                                //     className="landing-success-btn gfc-btn"

                                                // >

                                                //     <img onClick={this.state.handleGFC} name={item.order_no} src={require("../../assets/images/gfc.png")} alt=""></img>
                                                // </button>
                                            ) : (
                                                <p></p>
                                            )
                                        )
                                    }
                                </td>
                                {/* <td>
                                    {
                                        item.serum_dispatch != 'yes' ? (<button
                                            type="button"
                                            className="landing-success-btn dispatch-btn"


                                        >
                                            <img onClick={this.state.handleSerumDispatch} name={item.order_no} src={require("../../assets/images/dispatch.png")} alt=""></img>
                                            
                                        </button>) : (
                                            <p>Yes</p>
                                        )
                                    }
                                </td> */}
                                <td>
                                    {item.gfc_received == 'yes' ? <button className='landing-success-btn dispatch-btn'  >

                                        <img className='assignjob' onClick={this.state.handleAssignDeliveryPerson} id="delivery" name={item.order_no} src={require("../../assets/images/assign (1).png")} alt=""></img>

                                    </button> : ''}
                                </td>
                            </tr>
                        ))
                        : null}

                    {pageName && pageName == "deliverypersonlanding"
                        ? data.map((item, index) => (
                            <tr key={`body-${index}`}>
                                <td>{item.order_no}</td>
                                <td>{item.order_date}</td>
                                <td>{item.vacutainer_code}</td>
                                <td>{item.patient_name}</td>
                                {/* <td>{item.patient_mobile}</td> */}
                                {/* <td>{item.mr_name}</td> */}
                                <td>{item.doctor_naame}</td>
                                <td>{item.doctor_mobile}</td>
                                <td>{item.doctor_address}</td>
                                <td>{item.clinic_name}</td>
                                {/* <td>{item.stockist_name}</td> */}

                                <td>{item.order_status}</td>
                                <td>
                                    {/* <div className="d-flex"> */}
                                    {
                                        <button
                                            type="button"
                                            className="landing-success-btn track-order-btn"


                                        >
                                            <img onClick={this.state.handleTrackOrder} name={item._id} src={require("../../assets/images/action.png")} alt=""></img>

                                        </button>
                                    }

                                </td>
                                <td>
                                    <select className='form-control' onChange={this.state.handleChangeStatus} name={item._id}>
                                        <option value="" disabled selected>Select status...</option>
                                        <option value="Out for X-care tubes collection" disabled={item.status1A === "yes"}>Out for X-care tubes collection</option>
                                        <option value="X-care Tubes Picked-up from Doctor’s clinic and Out for delivery of X-care tubes to Koshin Clinic" disabled={item.status1A === "no" ? true : (item.checkCollection === 'no' ? true : (item.status1B === "yes" ? true : false))}>X-care Tubes Picked-up from Doctor’s clinic and Out for delivery of X-care tubes to Koshin Clinic</option>

                                        <option value="X-care Tubes delivered to Koshin Clinic" disabled={item.status1B === "no" ? true : (item.status1C === "yes" ? true : false)}>X-care Tubes delivered to Koshin Clinic</option>

                                        <option value="XO Face serum picked up from Koshin Clinic and out for delivery to doctor’s clinic" disabled={item.gfc_received === "no" ? true : (item.checkDelivery === 'no' ? true : (item.status2A === 'yes' ? true : false))}>XO Face serum picked up from Koshin Clinic and out for delivery to doctor’s clinic</option>

                                        <option value="XO Face serum Delivered to Doctor’s clinic" disabled={item.status2A === "no" ? true : (item.status2B === "yes" ? true : false)}>XO Face serum Delivered to Doctor’s clinic</option>

                                    </select>


                                </td>



                            </tr>
                        ))
                        : null}


                    {pageName && pageName == "manufacturlanding" ? data.map((item, index) => (
                        <tr key={`body-${index}`}>
                            <td>{item.order_id}</td>
                            <td>{item.customer_name}</td>
                            <td>{item.mr_name}</td>
                            <td>{item.doctor_name}</td>
                            <td>{item.stockist_name}</td>
                            <td>{item.customer_state}</td>
                            <td>{item.customer_town}</td>
                            <td><p style={{ display: 'none' }}>{item.order_date && moment(item.order_date).format('YYYY-MM-DD')}</p>{item.order_date && moment(item.order_date).format('DD-MM-YYYY')}</td>
                            <td><p style={{ display: 'none' }}>{item.collection_date && moment(item.collection_date).format('YYYY-MM-DD')}</p>{item.collection_date && moment(item.collection_date).format('DD-MM-YYYY')}</td>
                            <td className='middleallignment'>{item.standard_srl_value + "(" + (item.actual_srl_value != null ? item.actual_srl_value : 0) + ")"}</td>
                            <td><p style={{ display: 'none' }}>{item.GRN_DATE && moment(item.GRN_DATE).format('YYYY-MM-DD')}</p>{item.GRN_DATE && moment(item.GRN_DATE).format('DD-MM-YYYY')}</td>
                            <td><p style={{ display: 'none' }}>{item.delivery_date && moment(item.delivery_date).format('YYYY-MM-DD')}</p>{item.delivery_date && moment(item.delivery_date).format('DD-MM-YYYY')}</td>
                            {/* <td></td> */}
                            <td>
                                {<button type="button" className={item.order_status != 'complete' ? "landing-pending-btn" : "landing-success-btn"} onClick={this.state.handleTrackOrder} name={item._id}>
                                    {item.order_status}</button>}
                            </td>
                            <td>
                                <div className="d-flex justify-content-center">

                                    {item.GRN_DATE != null ?
                                        item.GRN_STATUS === 'accept' ?
                                            <button type="button" className="landing-success-btn text-center w-110">Accepted</button>
                                            : item.GRN_STATUS === "reject" ?
                                                <button type="button" className="landing-pending-btn text-center w-110">Rejected</button>
                                                : <div className="d-flex justify-content-center">
                                                    <a href={void (0)} onClick={() => this.handleClick(item.order_id, "reject")} className="landing-pending-btn text-center w-110 me-1">Reject</a>
                                                    <a href={void (0)} onClick={() => this.handleClick(item.order_id, "accept")} className="landing-success-btn text-center w-110 ms-1">Accept</a>
                                                </div>
                                        : ""
                                    }

                                    {/* <button type="button" className="landing-success-btn w-110">
                                        Accepted
                                    </button>

                                    <a href={void(0)} onClick={() => this.handleClick(item.order_id, "reject")} className="landing-pending-btn text-center w-110 me-1">
                                        Reject
                                    </a>
                                    <a href={void(0)} onClick={() => this.handleClick(item.order_id, "accept")} className="landing-success-btn text-center w-110 ms-1">
                                        Accept
                                    </a> */}
                                </div>
                            </td>


                        </tr>

                    )) : null}


                    {pageName && pageName == "qrcodegeneration" ? data.map((item, index) => (
                        <tr key={`body-${index}`}>
                            <td className='text-center'>{moment(item._id).format('DD-MM-YYYY')}</td>
                            <td className='text-center'>{moment(item._id).format('HH:mm')}</td>
                            {/* <td className='text-center'><p className='wraptext'>{item.qrIDs}</p></td> */}
                            <td className='text-center'>{item.startSerialNo != item.endSerialNo ? config.seriesName + item.startSerialNo + '-' + config.seriesName + item.endSerialNo : config.seriesName + item.startSerialNo}</td>

                            <td className='text-center'>{item.count}</td>

                            <td className='text-center'>
                                <div className="qr-download-btn text-center">
                                    <a
                                        href="javscript:void(0);"
                                        className="download-btn"
                                        src={require("../../assets/images/icons/download.png")}
                                    >
                                        <img onClick={this.state.downloadQR} name={item._id} id={item.startSerialNo + "-" + item.endSerialNo} src={require("../../assets/images/download.png")} alt=""></img>
                                        {" "}
                                        {/* <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            viewBox="0 0 24 24"
                                        >
                                            <path
                                                fill="currentColor"
                                                d="M5 20h14v-2H5zM19 9h-4V3H9v6H5l7 7z"
                                            />
                                        </svg> */}
                                        {" "}
                                    </a>
                                </div>
                            </td>

                        </tr>

                    )) : null}

                    {pageName && pageName == "vacutainerrecord" ? data.map((item, index) => (
                        <tr key={`body-${index}`}>
                            <td className='text-center'>{moment(item.fullDate).format('DD-MM-YYYY HH:mm')}</td>
                            <td className='text-center'>{item.stockistName}</td>
                            {/* <td className='text-center'>{item.doctorName}</td> */}
                            <td className='text-center'>{item.count}</td>
                            {/* <td className='text-center'>{config.seriesName + item.startSerialNo + '-' + config.seriesName + item.endSerialNo}</td> */}
                            <td className='text-center'><p className='break-series'>{item.qrIds}</p></td>

                            <td className='text-center'>
                                <div className="qr-download-btn text-center">
                                    {/* <a href="javscript:void(0);" > <img onClick={this.state.downloadQR} name={item._id} id={item.startSerialNo + '-' + item.endSerialNo} src={require('../../assets/images/icons/download.png')} /> </a> */}

                                    <a
                                        href={void (0)}
                                        className="download-btn"
                                        // onClick={this.state.downloadQR}
                                        // name={item._id}
                                        // id={item.startSerialNo + "-" + item.endSerialNo}
                                        src={require("../../assets/images/icons/download.png")}
                                    >
                                        <img onClick={this.state.downloadQR} name={item._id} id={item.startSerialNo + "-" + item.endSerialNo} src={require("../../assets/images/download.png")} alt=""></img>
                                        {/* <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            viewBox="0 0 24 24"
                                        >
                                            <path
                                                fill="currentColor"
                                                d="M5 20h14v-2H5zM19 9h-4V3H9v6H5l7 7z"
                                            />
                                        </svg> */}

                                    </a>
                                </div>
                            </td>

                        </tr>

                    )) : null}

                    {pageName && pageName == "assignjobhistory"
                        ? data.map((item, index) => (
                            <tr key={`body-${index}`}>
                                <td className='text-center'>{item.order_no}</td>
                                <td className='text-center'>{item.order_date}</td>
                                <td className='text-center'>{item.qrname}</td>
                                <td className='text-center'>{item.drname}</td>
                                <td className='text-center'>{item.patientname}</td>
                                <td className='text-center'>{item.assignDate}</td>
                                <td className='text-center'>{item.assignJob}</td>
                                <td className='text-center'>{item.deliveryPersonName}</td>
                                <td className='text-center'>{item.deliveryPersonPhoneNo}</td>
                            </tr>
                        ))
                        : null}


                    {pageName && pageName == "callcentermanagment"
                        ? data.map((item, index) => (
                            <tr key={`body-${index}`}>
                                <td className='text-center'>{item.name}</td>
                                <td className='text-center'>{item.email}</td>
                                <td className='text-center'>{item.mobileno}</td>
                                <td className='text-center'><p className={item.status == 'INACTIVE' ? 'inactive_status' : 'active_status'}>{item.status}</p></td>
                                <td className='text-center'>
                                    <div className="d-flex justify-content-center">
                                        {
                                            // <button
                                            //     type="button"
                                            //     className="btn btn-primary"
                                            //     onClick={this.state.handleEditCallCenter}
                                            //     id={item._id}
                                            // >
                                            //     Edit
                                            // </button>

                                            <a
                                                href={void (0)}
                                                className="download-btn"
                                                onClick={this.state.handleEditCallCenter}
                                                id={item._id}
                                            >
                                                <img
                                                    id={item._id} src={UserEdit} style={{ height: 15, width: 15 }} alt="" />
                                            </a>
                                        }
                                        {/* {
                                            <button
                                                type="button"
                                                className="btn btn-primary ms-3"
                                                onClick={this.state.removeCallCenter}
                                                id={item._id}
                                            >
                                                Remove
                                            </button>
                                        } */}
                                    </div>
                                </td>
                            </tr>
                        ))
                        : null}

                    {pageName && pageName == "admin_user_managment"
                        ? data.map((item, index) => (
                            <tr key={`body-${index}`}>
                                <td className='text-center'>{item.name}</td>
                                <td className='text-center'>{item.email}</td>
                                <td className='text-center'>{item.mobileno}</td>
                                <td className='text-center'><p className={item.status == 'INACTIVE' ? 'inactive_status' : 'active_status'}>{item.status}</p></td>
                                <td className='text-center'>
                                    <div className="d-flex justify-content-center">
                                        {
                                            <a
                                                href={void (0)}
                                                className="download-btn"
                                                onClick={this.state.handleEditCallCenter}
                                                id={item._id}
                                            >
                                                <img
                                                    id={item._id} src={UserEdit} style={{ height: 15, width: 15 }} alt="" />
                                            </a>
                                        }
                                        {/* {
                                            <a
                                                href={void (0)}
                                                className="download-btn"
                                                onClick={this.state.handleChangeStatus}
                                                id={item._id}
                                                dataStatus={item.status}
                                            > <img id={item._id} data-status={item.status} style={{ height: 15, width: 15 }} src={item.status === 'ACTIVE' ? UserCross : UserCheck} alt="" /></a>
                                        } */}
                                    </div>
                                </td>
                            </tr>
                        ))
                        : null}

                    {pageName && pageName == "deliverypersonmanagment"
                        ? data.map((item, index) => (
                            <tr key={`body-${index}`}>
                                <td className='text-center'>{item.name}</td>
                                <td className='text-center'>{item.email}</td>
                                <td className='text-center'>{item.mobileno}</td>
                                {/* <td className='text-center'>{item.state}</td>
                                <td className='text-center'>{item.city}</td> */}
                                <td className='text-center'> {item.clinic} </td>
                                <td className='text-center'><p className={item.status == 'INACTIVE' ? 'inactive_status' : 'active_status'}>{item.status}</p></td>
                                <td className='text-center'>
                                    <div className="d-flex justify-content-center">
                                        {
                                            // <button
                                            //     type='button'
                                            //     className='btn btn-primary'
                                            //     onClick={this.state.handleEditDeliveryPerson}

                                            // >
                                            //     <i id={item._id} class="fa fa-pencil-square-o" aria-hidden="true"></i>
                                            // </button>

                                            <a
                                                href={void (0)}
                                                className="download-btn"
                                                onClick={this.state.handleEditDeliveryPerson}
                                                id={item._id}
                                            >
                                                <img
                                                    id={item._id} src={UserEdit} style={{ height: 15, width: 15 }} alt="" />
                                            </a>
                                        }
                                        {/* {
                                            <button
                                                type='button'
                                                className='btn btn-primary ms-3'

                                                onClick={this.state.removeDeliveryPerson}

                                            >
                                                <i id={item._id} class="fa fa-trash" aria-hidden="true"></i>
                                            </button>
                                        } */}
                                    </div>
                                </td>
                            </tr>
                        ))
                        : null}

                    {pageName && pageName == "adminlanding"
                        ? data.map((item, index) => (
                            <tr key={`body-${index}`}>
                                <td>{item.order_no}</td>
                                <td>{item.order_date}</td>
                                <td>{item.unique_qr_id}</td>
                                <td>{item.patient_name}</td>
                                <td>{item.patient_mobile}</td>

                                <td>{item.doctor_naame}</td>
                                <td>{item.stockist_name}</td>
                                <td>{item.clinic_name}</td>
                                <td>{item.mr_name}</td>
                                <td>{item.concent}</td>
                                <td>{item.order_status}</td>
                                <td>
                                    {/* <div className="d-flex"> */}
                                    {/* {
                                        <button
                                            type="button"
                                            className="landing-success-btn"
                                            onClick={this.state.handleTrackOrder}
                                            name={item._id}
                                        >
                                            Track Order
                                        </button>
                                    } */}

                                    {
                                        <button
                                            type="button"
                                            className="landing-success-btn track-order-btn"


                                        >
                                            <img onClick={this.state.handleTrackOrder} name={item._id} src={require("../../assets/images/action.png")} alt=""></img>
                                            {/* <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                viewBox="0 0 24 24"
                                            >
                                                <path
                                                    fill="currentColor"
                                                    d="M6.016 4.502A2.976 2.976 0 0 0 3.038 7.48c0 2.233 2.978 5.53 2.978 5.53s2.978-3.297 2.978-5.53a2.976 2.976 0 0 0-2.978-2.978m0 4.041A1.063 1.063 0 1 1 7.079 7.48a1.064 1.064 0 0 1-1.063 1.063m15.008 2.753v-4.3a4.962 4.962 0 0 0-.204-1.333a4.996 4.996 0 0 0-9.796 1.216v.248l-.01.87v9.952h-.004v.041a2 2 0 0 1-4 0c0-.012.003-.024.004-.037H7.01V16.01h-2v2h.002a3.998 3.998 0 0 0 7.996-.005h.002v-.982h.005V8.997l.01-1.87V6.88a3.001 3.001 0 0 1 6 .123v4.275a1.999 1.999 0 1 0 2 .018"
                                                />
                                            </svg> */}
                                        </button>
                                    }


                                    {/* {item.order_status == "pending" ? (
                                            <button
                                                type="button"
                                                className="landing-success-btn bg-danger ms-3"
                                                onClick={this.state.handleCancelOrder}
                                                name={item._id}
                                                id={item.patient_mobile}
                                                data-cid={item._id}
                                                data-bs-target="#orderCanceledModal"
                                                data-bs-toggle="modal"
                                            >
                                                Reject
                                            </button>
                                        ) : (
                                            ""
                                        )} */}
                                    {/* </div> */}
                                </td>
                            </tr>
                        ))
                        : null}


                    {pageName && pageName == "doctormaster"
                        ? data.map((item, index) => (
                            <tr key={`body-${index}`}>
                                <td className='text-center'>
                                    {/* <div className="d-flex justify-content-center"> */}
                                    {
                                        // <button
                                        //     type="button"
                                        //     className="btn btn-primary"
                                        //     onClick={this.state.handleEditDoctor}
                                        //     id={item._id}

                                        // >
                                        //     Edit
                                        // </button>

                                        <a
                                            href={void (0)}
                                            className="download-btn"
                                            onClick={this.state.handleEditDoctor}
                                            id={item._id}
                                        >
                                            <img
                                                id={item._id} src={UserEdit} style={{ height: 15, width: 15 }} alt="" />
                                        </a>
                                    }

                                    {/* </div> */}
                                </td>
                                <td className='text-center'>{item.division_code}</td>
                                <td className='text-center'>{item.division}</td>
                                <td className='text-center'>{item.zone}</td>
                                <td className='text-center'>{item.region}</td>
                                <td className='text-center'>{item.location}</td>
                                <td className='text-center'>{item.uid}</td>
                                <td className='text-center'>{item.doctor_code}</td>
                                <td className='text-center'>{item.name}</td>
                                <td className='text-center'>{item.speciality}</td>
                                <td className='text-center'>{item.classification}</td>
                                <td className='text-center'>{item.beat}</td>
                                <td className='text-center'>{item.is_dispensing}</td>
                                <td className='text-center'>{item.town_type}</td>
                                <td className='text-center'>{item.email}</td>
                                <td className='text-center'>{item.mobileno}</td>
                                <td className='text-center'>{item.state}</td>
                                <td className='text-center'>{item.town}</td>
                                <td className='text-center'>{item.addressLine1}</td>
                                <td className='text-center'>{item.addressLine2}</td>
                                <td className='text-center'>{item.pin}</td>
                                <td className='text-center'>{item.stockist_id}</td>
                                <td className='text-center'>{item.stockist_name}</td>
                                <td className='text-center'>{item.stockist_mobileno}</td>
                                <td className='text-center'>{item.stockist_email}</td>
                                <td className='text-center'>{item.clinic_id}</td>
                                <td className='text-center'>{item.clinic_name}</td>
                                <td className='text-center'>{item.clinic_address}</td>
                                <td className='text-center'>{item.clinic_state}</td>
                                <td className='text-center'>{item.clinic_city}</td>
                                <td className='text-center'>{item.clinic_pincode}</td>
                                <td className='text-center'>{item.clinic_mobileno}</td>
                                <td className='text-center'>{item.clinic_email}</td>
                                <td className='text-center'>{item.mr_code}</td>
                                <td className='text-center'>{item.mr_name}</td>
                                <td className='text-center'>{item.mr_mobileno}</td>
                                <td className='text-center'>{item.mr_email}</td>

                                <td className='text-center'>{item.rm_name}</td>
                                <td className='text-center'>{item.rm_email}</td>
                                <td className='text-center'>{item.rm_mobileno}</td>
                                <td className='text-center'>{item.zm_name}</td>
                                <td className='text-center'>{item.zm_email}</td>
                                <td className='text-center'>{item.zm_mobileno}</td>
                                <td className='text-center'>{item.nsm_name}</td>
                                <td className='text-center'>{item.nsm_email}</td>
                                <td className='text-center'>{item.nsm_mobileno}</td>
                                <td className='text-center'>{item.bu_name}</td>
                                <td className='text-center'>{item.bu_email}</td>
                                <td className='text-center'>{item.bu_mobileno}</td>

                                <td className='text-center'>{item.createdDate}</td>
                                <td className='text-center'>{item.updatedDate}</td>



                            </tr>
                        ))
                        : null}



                </tbody>
            </table>

        )
    }
}

export default CustomDatatable