import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from '../../../../components/Reusable/WithRouter';
// import config from '../../../config/emrok.config';
import Common from '../../../../hoc/Common.hoc';
// import axios from 'axios';
// import moment from 'moment';
import AdminHeader from "../../../../components/layout/Admin/AdminHeader";
import AdminSidebar from "../../../../components/layout/Admin/AdminSidebar";
import AdminFooter from "../../../../components/layout/Admin/AdminFooter";
import '../../../../assets/css/admin/style.css';
import '../../../../assets/css/admin/responsive.css';
// import DropdownDoctorRuntime from '../../../components/SearchableDropdown/DropdownDoctorRuntime.component';
import AdminService from '../../../../services/Admin.service';
import CustomDatatable from "../../../../components/Datatable/CustomDatatable.component";
import WarehouseUserAction from '../../../../redux/actions/WarehouseUser.action';

class WarehouseUserManagmentDashboard extends Component {
    constructor(props) {
        super(props)

        this.state = {
            warehouseUserList: []
        }

    }


    componentDidMount() {
        this.getWarehouseUserDetails();
    }

    getWarehouseUserDetails = async () => {
        try {
            this.setState({ warehouseUserList: [] })

            let res = await AdminService.getallWareHouseUsers()
            // console.log(res, 123333)
            if (res.data.success) {
                // console.log(res.data.data.data, "lasjdhjkash");
                this.setState({ warehouseUserList: res.data.data.data });
            }

        } catch (error) {

        }
    }

    handleEdit = async (event) => {
        event.preventDefault();
        const { warehouseUserList } = this.state;
        const adminuserID = event.target.id;
        const selectedWarehouseUser = warehouseUserList.filter(item => item._id == adminuserID)
        // console.log(selectedCallcenter, "selectedCallcenter");
        if (selectedWarehouseUser.length) {

            // let order_details
            //  selectedPatientDetails[0].customer['order_id'] = selectedPatientDetails[0].order_i

            // console.log(this.props.adminUserDetails, "callcender reducer")
            // this.props.setWarehouseUser(callCenterDetails);
            this.props.setWarehouseUser(selectedWarehouseUser[0])
        } else {
            // this.props.setCustomer(selectedPatientDetails)
        }

        this.props.navigate('/admin/user-managment/warehouse-user/details');
    }

    setWarehouseUsers = (data) => {
        let resp = []
        resp = data?.map((item) => ({
            _id: item._id,
            name: item.name,
            email: item.email,
            mobileno: item.mobileno,
            status: item.status || "ACTIVE",
        }))
        return resp
    }
    handleClickHome = () => {
        this.props.navigate('/admin/user-managment')
    }
    handleAdd = () => {
        this.props.navigate('/admin/user-managment/warehouse-user/add');
    }

    handleChangeStatus = (event) => {
        console.log(event.target.id);
        if (event.target.id != null) {
            let status = (event.target.dataset.status || 'ACTIVE') == 'ACTIVE' ? 'INACTIVE' : 'ACTIVE',
                statusStr = (event.target.dataset.status || 'ACTIVE') == 'ACTIVE' ? 'Inactive' : 'Active';
            console.log(status)
            this.props.hoc.customAlert('Are you sure you want to ' + (statusStr) + ' this account?', false, "", true, true, async () => {
                let payload = {
                    id: event.target.id,
                    status: status
                }
                let res = await AdminService.adminUserChangeStatus(payload)
                console.log(res, 123333)
                if (res.data.success) {
                    this.props.hoc.customAlert('Status updated successfully', true);
                    this.getWarehouseUserDetails();
                }
            }, () => {

            })
        } else {
            this.props.hoc.customAlert('Something went wrong', false)
        }


    }

    render = () => {
        const { warehouseUserList } = this.state;
        let _data = warehouseUserList
        // console.log(_data, "aksdhklsah");
        return (
            <>
                <div className="layout-wrapper">
                    <AdminHeader />
                    <AdminSidebar />
                    <div className="main-content">
                        <div className="page-content">
                            <div className="container-fluid">
                                <div className="row g-4">
                                    <div className='col-md-6'>
                                        <h5 className='mb-20 headingstyle'>Warehouse Management</h5>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className="btn-home flotright mx-4" onClick={this.handleClickHome}>
                                            <a href={void (0)} >
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg>
                                                Back
                                            </a>
                                        </div>
                                        <div className="btn-home flotright mx-4" onClick={this.handleAdd}>
                                            <a href={void (0)}>
                                                Add
                                            </a>
                                        </div>
                                    </div>
                                    {/* Row end */}
                                    <div className="row g-4">
                                        <div className="table-responsive">
                                            {
                                                _data && _data.length ?
                                                    <CustomDatatable
                                                        pagination={true}
                                                        columns={[
                                                            {
                                                                name: 'Name',
                                                                // selector: row => row.mr_name,

                                                            },
                                                            {
                                                                name: 'Email',
                                                                // selector: row => row.mr_name,

                                                            },
                                                            {
                                                                name: 'Mobile No.',
                                                                // selector: row => row.mr_name,

                                                            },
                                                            {
                                                                name: 'Status',
                                                                // selector: row => row.status,

                                                            },

                                                            {
                                                                name: 'Action',
                                                                // selector: row => row.createdAt,

                                                            }
                                                        ]}
                                                        data={this.setWarehouseUsers(warehouseUserList)}
                                                        pageName="admin_user_managment"
                                                        handleEditCallCenter={this.handleEdit}
                                                        handleChangeStatus={this.handleChangeStatus}
                                                    />
                                                    : <p className="norecord text-center"><strong>No Records Found</strong></p>
                                            }
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>

                        <AdminFooter />
                    </div>
                </div>

            </>
        )

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        // dispatching plain actions
        setWarehouseUser: (adminUserDetails) => dispatch(WarehouseUserAction.setWarehouseUser(adminUserDetails))
    }
}

const mapStateToProps = (state) => {
    return {
        OrderReducer: state.OrderReducer,
        CustomerReducer: state.CustomerReducer,
        CommonReducer: state.CommonReducer,
        WarehouseUserReducer: state.WarehouseUserReducer
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Common(WarehouseUserManagmentDashboard)))