import React, { Component } from 'react';
import { format, subYears, parseISO } from 'date-fns';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import { withRouter } from '../../../components/Reusable/WithRouter';
import CustomerAction from "../../../redux/actions/Customer.action";
import CustomerHeader from "../../../components/layout/CustomerHeader";
import CustomerFooter from '../../../components/layout/CustomerFooter';
import ProductList from '../../../components/Customer/ProductList';
import ProductService from '../../../services/ProductService';
import OrderList from '../../../components/Order/OrderList';
import CustomerService from '../../../services/Customer.service';
import OrderAction from '../../../redux/actions/Order.action';
import config from '../../../config/emrok.config';
import OtherService from '../../../services/OtherService';
import ModalCheckIcon from '../../../assets/Svg/ModalCheckIcon';
import Common from '../../../hoc/Common.hoc';

class PlaceOrder extends Component {
    constructor(props) {
        super(props)
        this.state = {
            responseSampleBooking: null,
            paymentOrder: {
                totalPayment: "",
                partialMinPayment: "",
                deliveryPayment: "",
                totalItem: ""
            },
            productQuantity: {

            },
            products: [
                {
                    _id: "1",
                    name: "MYOWNN",
                    description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the",
                    price: "40000.00",
                    quantity: "1",
                    product_img: ""
                }
            ],
            order: {
                rx: "",
                serialNo: "",
                customer: "",
                products: [{
                    product: "",
                    quantity: "",
                    price: ""
                }],
                total: "",
                deliveryStatus: "",
                orderStatus: ""
            },
            orderId: ""
        }
        this.productDecrement = this.productDecrement.bind(this);
        this.productIncrement = this.productIncrement.bind(this);
        this.placeOrder = this.placeOrder.bind(this);
    }

    handelModel = () => {
        const modal = new window.bootstrap.Modal(document.getElementById('paymentSuccessModal'));
        modal.hide();

        const backdrop = document.querySelector('.modal-backdrop');
        if (backdrop) {
            backdrop.remove();
        }

        const body = document.body;

        // Modify the style
        body.style.overflow = 'auto';
        this.props.navigate('/customer/track-order');

    }

    productPriceCalCulate = () => {
        const { products } = this.state;
        let totalPayment = 0;
        let totalItem = 0;
        products.forEach(product => {
            const price = parseFloat(product.price);
            totalPayment = totalPayment + price * product.quantity;
            totalItem = totalItem + product.quantity;

        })
        console.log(totalItem, '<<<<<<<<<<');

        const partialMinPayment = totalPayment * (20 / 100);
        const deliveryPayment = totalPayment - partialMinPayment;
        this.setState({ paymentOrder: { totalPayment, partialMinPayment, deliveryPayment, totalItem } })
    }
    componentDidUpdate(prevProps, prevState) {

        if (prevState.products != this.state.products) {
            this.productPriceCalCulate();
        }
    }

    componentDidMount() {
        const orderDetails = this.props.OrderReducer;
        console.log(orderDetails, "Order details");
        const { productQuantity } = this.state;
        const newProductQuantity = { ...productQuantity }
        ProductService.getMyownnProducts().then(res => {
            console.log(res, "myownnproducts")
            if (res && res.data && res.data.success) {
                if (res.data.data) {
                    const products = res.data.data;
                    products.forEach((product) => {
                        newProductQuantity[product._id] = product.quantity;
                    });

                    this.setState({ products: products, productQuantity: newProductQuantity })
                }

            }

        }).catch(e => {
            console.log("e>>>>>>>>>>>>", e)
        })



    }

    navigateToPrevious() {
        this.props.navigate(-1);
    }

    calculateDOB = (age) => {
        const givenAge = age; // Example age
        const currentDate = new Date();
        const dob = subYears(currentDate, givenAge);
        const formattedDOB = format(dob, 'dd-MMM-yyyy');
        return formattedDOB;
    };

    collectionDateFromDate = (collectiondate, collectionslot) => {
        const datestring = collectiondate + " " + collectionslot;
        const dateObject = new Date(datestring);
        const formattedDate = format(dateObject, 'dd/MM/yyyy H:m');
        return formattedDate;
    };

    generateRandomOrderId = (no) => {
        const randomNumber = Math.floor(Math.random() * 10000000000); // Generates a random number between 0 and 9999999999
        const orderId = randomNumber.toString().padStart(no, '0'); // Pads the number to have exactly 10 digits
        return orderId;
    };

    orderPlaced = (e) => {
        e.preventDefault()
        const customer = this.props.CustomerReducer.customer;

        console.log(customer,"allcustomer12313213132");
        const orderDetails = this.props.OrderReducer.currentOrder;
        const currentOrderId = Object.keys(orderDetails).length;

        console.log(orderDetails, "check order details");
        console.log(currentOrderId, "check order details id");

        if (customer) {

            let details = {
                customer_id: customer._id
            }
            try {
                CustomerService.getOrders(details).then(res => {

                    console.log(res, "orderresponse");
                    if (res && res.data && res.data.success && res.data.data) {


                        if (res.data.data.order.length > 0) {

                            this.props.hoc.customAlert('You already placed order.', false);

                        } else {

                            if (currentOrderId === 0) {
                                this.props.hoc.customAlert('Are you sure you want to proceed?', true, "", true, true, () => {
                                    console.log(this.state.paymentOrder.totalItem, '!!!!!!!!!!!!!!!!!!!!!!')
                                    const customer_id = this.props.CustomerReducer.customer?._id;
                                    const customer = this.props.CustomerReducer.customer;
                                    const { paymentOrder, products } = this.state;
                                    const newProducts = products.map((product) => {
                                        return {
                                            product_id: product._id,
                                            price: product.price,
                                            quantity: product.quantity,
                                            name: product.name
                                        }
                                    })
                                    const _products = newProducts.filter(product => product.quantity != 0)
                                    const doctorid = customer ? customer.prescribedDoctor : "";
                                    const collection_date = customer ? customer.collection_date : null;
                                    const collection_slot = customer ? customer.collection_slot : null;
                    
                                    console.log(doctorid, "doctorid");
                    
                    
                                    console.log(customer, "prescribedoctor");
                    
                                    const submittedData = {
                                        customer_id,
                                        total: products[0].price,
                                        products: _products,
                                        doctor_id: doctorid,
                                        collection_date: collection_date,
                                        collection_slot : collection_slot
                                    }
                                    console.log(submittedData, 'bbbbbbbbbbbbbbbbb>>>>><<<<<<')
                    
                                    CustomerService.createOrder(submittedData).then(res => {
                                        if (res && res.data && res.data.success && res.data.data) {

                                            console.log(res.data.data[0],"order details after create order");
                                            this.props.placeOrder(res.data.data[0]);
                                            // this.props.navigate('/customer/order-placed');
                    
                                            console.log(res.data.data[0].order_id);
                                            // call sample booking
                                            const customerDetails = this.props.CustomerReducer;
                                            console.log(customerDetails, "customer details");
                                            const address = this.props.CustomerReducer.customer.address;
                    
                                            // get current date
                                            const today = new Date();
                                            const year = today.getFullYear();
                                            const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
                                            const day = String(today.getDate()).padStart(2, '0');
                                            const currentDate = `${year}-${month}-${day}`;
                    
                                            const fullname = customerDetails.customer.name;
                                            const fullNameArr = fullname.split(' ');
                                            const first_name = fullNameArr ? fullNameArr[0] : "";
                                            const last_name = fullNameArr ? fullNameArr[1] : "";
                                            const dob = this.calculateDOB(customerDetails.customer.age);
                                            let gender = "M";
                                            if (customerDetails.customer.gender == "male") {
                                                gender = "M";
                                            } else {
                                                gender = "F";
                                            }
                    
                                            const collectionDateFrom = this.collectionDateFromDate(customerDetails.customer.collection_date, customerDetails.customer.collection_slot);
                    
                                            // const orderId = this.generateRandomOrderId(7);
                                            const orderId = res.data.data[0].order_id;
                                            this.setState({ orderId: orderId })
                                            const patient_id = customerDetails.customer.patient_id == null ? orderId : customerDetails.customer.patient_id;
                                            console.log(customer_id, "customerDetailsnnnnn");
                                            let body = {
                                                FLAG: "I",
                                                ORDERID: orderId,		//Pass unique Order ID 10
                                                HISORDERID: "",
                                                ORDER_DT: currentDate,
                                                PTNT_CD: "WHP" + patient_id,		//Pass unique Patient ID 
                                                HISCLIENTID: "",
                                                TITLE: customerDetails.customer.salutaion,
                                                FIRST_NAME: first_name,
                                                LAST_NAME: last_name,
                                                PTNTNM: fullname,
                                                DOB: dob,
                                                PTNT_GNDR: gender,
                                                DOB_ACT_FLG: "Y",			//If DOB if Actual Pass - Y else N
                                                MOBILE_NO: customerDetails.customer.mobileno.toString(),
                                                COLL_CONTACT: "",
                                                EMAIL_ID: customerDetails.customer.email,
                                                ADDRESS: customerDetails.customer.address.addressLine2!=''?customerDetails.customer.address.addressLine1+", "+customerDetails.customer.address.addressLine2:customerDetails.customer.address.addressLine1,
                                                LOCATION: customerDetails.customer.address.addressLine1,
                                                CITY: customerDetails.customer.address.town,
                                                STATE: customerDetails.customer.address.state,
                                                COUNTRY: customerDetails.customer.address.country,
                                                ZIP: customerDetails.customer.address.pin.toString(),
                                                COLL_DATE_FROM: collectionDateFrom,	//Sample Collection From Date & time
                                                COLL_DATE_TO: collectionDateFrom,	//Sample Collection To Date & time
                                                TESTS: "1302",
                                                COLL_TYPE: "H",			// H- Home collection
                                                ORDER_SOURCE: "WH",		//Pass this value
                                                CREATED_BY: "C000147984" //in dev C000000614 in uat C000147984 
                    
                                            };
                                            console.log(body, "requestbody");
                                            OtherService.createBooking(body).then(res => {
                                                console.log(res, "response")
                                                if (res && res.data && res.data.success) {
                                                    if (res.data.data) {
                    
                                                        console.log(res, "response from sample booking");
                                                        const srlDate = {
                    
                                                            customer_id: customer_id,
                                                            order_id: orderId,
                                                            response_type: "sample_booking",
                                                            response_data: res.data.data
                                                        };
                                                        // save order response 
                                                        OtherService.saveSrlResponse(srlDate).then(res => {
                    
                                                            if (res && res.data && res.data.success) {
                    
                                                                console.log(res.data, "save response status");
                                                            }
                    
                    
                                                        }).catch(e => {
                                                            console.log("exception in save srl response", e)
                                                        })
                    
                                                        let sendData = {
                                                            event: "SAMPLE_BOOKING",
                                                            customer_name: this.props.CustomerReducer.customer.name,
                                                            customer_code: this.props.CustomerReducer.customer.customer_code,
                                                            mobileno: this.props.CustomerReducer.customer.mobileno,
                                                            stockist_email: this.props.OrderReducer.currentOrder.stockist.email,
                                                            mr_email: this.props.OrderReducer.currentOrder.mr[0].email,
                                                            doctorname: this.props.OrderReducer.currentOrder.doctor.name,
                                                            mrname: this.props.OrderReducer.currentOrder.mr[0].name,
                                                            order_id: this.props.OrderReducer.currentOrder.order_id

                                                        }
                    
                                                        OtherService.sendLoginSMS(sendData).then(res => {
                                                            // //console.log(res, 7878);
                    
                                                            if (res && res.data && res.data.success) {
                                                                //console.log(res, 123456);
                                                            }
                    
                                                        }).catch(err => {
                                                            //console.log(err)
                                                        })


                                                        OtherService.getCustomerOut().then(res => {
                                                            // console.log(res, 7878);
                                
                                                            if (res && res.data && res.data.success) {
                                                                console.log(res, 123456);
                                                            }
                                
                                                        }).catch(err => {
                                                            console.log(err)
                                                        })
                                                        // 
                                                        // const products = res.data.data;
                                                        // products.forEach((product) => {
                                                        //     newProductQuantity[product._id] = product.quantity;
                                                        // });
                    
                                                        this.setState({ responseSampleBooking: res.data })
                                                        const modal = new window.bootstrap.Modal(document.getElementById('paymentSuccessModal'));
                                                        modal.show();
                    
                    
                                                    }
                    
                                                }
                    
                                            }).catch(e => {
                                                console.log("e>>>>>>>>>>>>", e)
                                            })
                    
                                            // OtherService.getCustomerOut().then(res => {
                                            //     // console.log(res, 7878);
                    
                                            //     if (res && res.data && res.data.success) {
                                            //         console.log(res, 123456);
                                            //     }
                    
                                            // }).catch(err => {
                                            //     console.log(err)
                                            // })
                    
                    
                                            let orderContent = {
                    
                                                _id: res.data.data._id
                                            }
                                            // OtherService.getOrderDetailsOut(orderContent).then(res => {
                                            //     // console.log(res, 7878);
                    
                                            //     if (res && res.data && res.data.success) {
                                            //         console.log(res, "order csv response");
                                            //     }
                    
                                            // }).catch(err => {
                                            //     console.log(err)
                                            // })
                                        }
                    
                                    }).catch(err => {
                                        console.log(err)
                                    })
                                }, () => {
                    
                                })
                    
                            } else {
                                this.props.hoc.customAlert("You already placed order.", false);
                            }
                        }

                       

                    } else {

                        this.props.hoc.customAlert('You dont have any order.', false);
                    }

                })
            } catch (error) {
                console.log(error);
            }


        } else {
            this.props.hoc.customAlert('Customer not found.', false);
        }






       

       

        // alert();



    };

    placeOrder() {

        const customer_id = this.props.CustomerReducer.customer?._id;
        const customerDetails = this.props.CustomerReducer;
        console.log(customerDetails, "customerDetails");


        // const {paymentOrder,products } = this.state;
        // const newProducts = products.map((product)=>{
        //     return {
        //         product_id: product._id,
        //         price: product.price,
        //         quantity: product.quantity,
        //         name: product.name
        //     }
        // })
        // const _products = newProducts.filter(product => product.quantity != 0)
        // const submittedData = {    
        //     customer_id,
        //     total: paymentOrder.totalPayment,
        //     products: _products
        // }
        // console.log(submittedData , 'bbbbbbbbbbbbbbbbb>>>>><<<<<<')
        // CustomerService.createOrder(submittedData).then(res=>{
        //     if(res && res.data && res.data.success && res.data.data)
        //     {
        //         this.props.placeOrder(res.data.data);
        //         this.props.navigate('/customer/order-placed');
        //     }

        // }).catch(err=>{
        //     console.log(err)
        // })
        //this.props.navigate('/customer/order-placed');
    }



    productIncrement = (productId) => {
        const { products, productQuantity } = this.state;
        const newProducts = [...products]
        const productIndex = products.findIndex((product) => {
            return product._id == productId
        })
        console.log("productIndex", products[productIndex].quantity, productQuantity, ">>>>>>")
        if (products[productIndex].quantity < productQuantity[productId]) {
            newProducts[productIndex].quantity += 1;
            this.setState({ products: newProducts })
        }
    }

    productDecrement = (productId) => {
        const { products } = this.state;
        console.log(products, 'aaaaaaaaaaaa')
        const newProducts = [...products]
        const productIndex = products.findIndex((product) => {
            return product._id == productId
        })
        if (products[productIndex].quantity > 0) {
            console.log(products[productIndex], '>>>>>>>>>>')
            newProducts[productIndex].quantity -= 1;
            this.setState({ products: newProducts })
        }
    }


    render = () => {
        const { products, productQuantity, paymentOrder } = this.state;
        return (
            <>
                {/* <section className="place-order-container">
                    <div className="container">
                        <CustomerHeader/> */}
                {/* Row End*/}
                {/* <div className="row">
                            <div className="col-lg-6"> */}

                {/* Title end */}

                {/* Product details content box end*/}

                {/* Product details content box end*/}
                {/* <ProductList  paymentOrder={paymentOrder} products={products} productQuantity={productQuantity} productIncrement={this.productIncrement} productDecrement={this.productDecrement}/>
                            </div> */}

                {/* <div className="col-lg-6">
                                <OrderList productListCompRef={this.productListCompRef} products={products} paymentOrder={paymentOrder} placeOrder={this.placeOrder} />
                            </div>
                        </div> */}
                {/* Row end*/}
                {/* </div>
                </section> */}
                {/* Logout btn top section end */}
                {/* <CustomerFooter/> */}

                {/*  ======================== New added by smarajit 31_07_2023  ==================================== */}

                <section className="regstr-form emr-cart-page">
                    <div className="container">
                        <CustomerHeader />
                        {/* <div className="row">
                        <div className="w-100 logo-top-box mb-0">
                            <div className="d-flex justify-content-between align-items-center">
                            <div className="logo-wrap">
                                <a href="index.html">
                                <img src="images/logo.png" alt="logo" />
                                </a>
                            </div>
                            <div>
                                <button type="submit" className="btn-logout">
                                <svg
                                    width={16}
                                    height={16}
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                    className="logout-icon"
                                    d="M14.6666 7.99992H6.74992M12.9999 10.4999L15.4999 7.99992L12.9999 5.49992M8.83325 3.83325V2.99992C8.83325 2.55789 8.65766 2.13397 8.3451 1.82141C8.03254 1.50885 7.60861 1.33325 7.16659 1.33325H2.99992C2.55789 1.33325 2.13397 1.50885 1.82141 1.82141C1.50885 2.13397 1.33325 2.55789 1.33325 2.99992V12.9999C1.33325 13.4419 1.50885 13.8659 1.82141 14.1784C2.13397 14.491 2.55789 14.6666 2.99992 14.6666H7.16659C7.60861 14.6666 8.03254 14.491 8.3451 14.1784C8.65766 13.8659 8.83325 13.4419 8.83325 12.9999V12.1666"
                                    stroke=""
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    />
                                </svg>
                                Logout
                                </button>
                            </div>
                            </div>
                        </div>
                        </div> */}
                        <div className="reg-frm-dta">
                            <div className="reg-text-ft">
                                <h2 />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 cart-image">
                                {/* <img src="images/cart-image-bg.png" className="" alt="" /> */}
                                <img src={`${config.serviceUrl}/images/myownn/cart-image-bg.png`} className="" alt="" />
                            </div>
                            <div className="col-md-6 cart-image-details">
                                <h6>Medicine</h6>
                                <h2>MYOWNN</h2>
                                <div className="products-details">
                                    <h5>Product Details</h5>
                                    <p>
                                        MyOwnn Face Serum is the world’s first autologous GFC topical face serum for skin anti-aging, which is prepared from an individual’s own growth factors present in the blood. It is a new-age formulation which utilizes your body’s inherent natural powers and capacity for regulation and self-healing, resulting in the ultimate biological skin rejuvenation.</p>

                                    <p>MyOwnn Face Serum, when applied, delivers those growth factors at a high concentration to the skin layers, thereby resulting in their stimulation, subsequent regeneration and repair. Thus, revitalizing skin firmness and elasticity, giving rise to anti-aging benefits.</p>

                                    <p>Now experience an anti-aging power like never before, as your skin transforms with every drop of MyOwnn Face Serum.
                                    </p>
                                    {/* <div className="cart-review-mr mb-5 mt-3">
                                        <a href={config.knowMoreLink} className="" target='_blank'>
                                            Know more
                                        </a>
                                    </div> */}
                                    <button
                                        type=""
                                        className="place-ordr-itm"

                                        onClick={this.orderPlaced}
                                    >
                                        Place Order

                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* modal */}

                    <div
                        className="modal modal-xs fade"
                        id="paymentSuccessModal"
                        aria-hidden="true"
                        aria-labelledby="paymentSuccessModalLabel"
                        tabIndex={-1}
                        backdrop="static"
                    >
                        <div className="modal-dialog modal-dialog-centered modal-payment-success">
                            <div className="modal-content border-0">
                                <div className="modal-check-icon">
                                    <ModalCheckIcon />
                                </div>
                                <div className="modal-header border-0 pt-5 pb-2 mx-auto">
                                    <h1
                                        className="modal-title fs-5 text-center fw-bold"
                                        id="paymentSuccessModalLabel"
                                    >
                                        <h1 class="modal-title fs-5 text-center fw-bold" id="paymentSuccessModalLabel">
                                            Your Order Has been placed!
                                        </h1>
                                    </h1>
                                    {/* <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                                </div>
                                <div className="modal-body">

                                    {/* <p class="text-center">
                                        You’ll get confirmation Message soon. Your item are now ready for
                                        shipment.
                                    </p> */}
                                    <h1 class="modal-title fs-5 text-center fw-bold mx-auto" id="paymentSuccessModalLabel">
                                        Order ID-{this.state.orderId}
                                    </h1>
                                </div>
                                <div className="modal-footer border-0">
                                    <button className="btn bg-primary w-100"

                                        data-bs-target="#paymentSuccessModal"
                                        onClick={this.handelModel}>
                                        Ok
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>
                <CustomerFooter />

            </>
        )

    }

}

const mapDispatchToProps = (dispatch) => {
    return {
        // dispatching plain actions
        placeOrder: (order) => dispatch(OrderAction.placeOrder(order))
    }
}

const mapStateToProps = (state) => {
    return {
        // dispatching plain actions
        CustomerReducer: state.CustomerReducer,
        CommonReducer: state.CommonReducer,
        OrderReducer: state.OrderReducer,
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Common(PlaceOrder)))