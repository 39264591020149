import React from "react";
import { withRouter } from "../../components/Reusable/WithRouter";
import { connect } from "react-redux";
import Common from "../../hoc/Common.hoc";
import CustomerFooter from "../../components/layout/CustomerFooter";
import MrHeader from "../../components/layout/MR/MrHeader";
import DoctorHeader from "../../components/layout/Doctor/DoctorHeader";
import MrAction from "../../redux/actions/Mr.action";
import MrService from "../../services/Mr.service";
import DoctorService from "../../services/Doctor.service";
import DoctorAction from "../../redux/actions/Doctor.action";
import { format } from 'date-fns';
import $ from "jquery";
import CustomDatatable from "../../components/Datatable/CustomDatatable.component";
import moment from "moment";
import OtherService from "../../services/OtherService";
import OrderAction from "../../redux/actions/Order.action";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; // Import the CSS
import CountryService from '../../services/Country.service';
import Dropdown from '../../components/SearchableDropdown/Dropdown.component';
import CommonAction from "../../redux/actions/Common.action";
import Summary from "../../components/Summary/Summary.component";
import { ExportToExcel } from "../../components/Excel/ExportToExcel";
import OrderService from "../../services/Order.service";
import StockistService from "../../services/Stockist.service";
class PatientDashboard extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            doctordetails: null,
            doctorid: "",
            doctordetailsList: [],
            doctorinfo: "",
            countries: [],
            states: [],
            towns: [],
            showTowns: false,
            selectedState: "",
            selectedTown: "",
            selectedDoctor: "",
            fromdate: "",
            todate: "",
            currentDate: this.getCurrentDate(),
            summaryCols: ["Total Orders", "Orders Under Process", "Orders Pending", "Sample Received at Koshin Clinic", "XO Face Serum received By doctor",],


            summaryDetails: null,
            setorderStatus: "",
            exportData: null,
            fileName: "Order Details",
            orders: [],
            personalDetails: [],
            stockistList: [],
            selectedStockist: '',
            doctorList: [],
            selectedDoctor: ""
        }
    }

    static getDerivedStateFromProps(props, state) {
        return {
            doctordetails: props._id || null,
            summaryDetails: props.summaryDetails || null,
        }
    }

    formatData(auditdata) {

        console.log(auditdata, auditdata.length, "auditdata")

        let formatedArr = [];
        let allfeedback = [];
        let feedbackString = "";
        for (let i = 0; i < auditdata.length; i++) {


            formatedArr.push({

                "Order No": auditdata[i].order.order_no,
                "Order Date": auditdata[i].order ? moment(auditdata[i].order.createdAt).format('DD-MM-YYYY') : "",
                "Doctor Name": auditdata[i].doctor ? auditdata[i].doctor?.name : "",
                "Stockist Name": auditdata[i].stockist ? auditdata[i].stockist[0]?.name : "",
                "Vacutainer Serial No": auditdata[i].serial_no ? 'WH' + auditdata[i].serial_no : "",

                "Status": auditdata[i].order ? auditdata[i].order.orderStatus : '',


            })
        }
        return formatedArr;


    }

    getCurrentDate() {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const day = String(currentDate.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    getSummary = async (patient_id = null) => {
        if (patient_id) {
            // let payload = {
            //     type: "DOCTOR",
            //     id: doctor_id
            // }

            const { fromdate, todate, selectedState, selectedTown, setorderStatus, selectedDoctor_id, selectedStockist_id } = this.state;

            const selectedMomentFrom = moment(fromdate);
            const selectedOnlyDateFrom = selectedMomentFrom.format('YYYY-MM-DD');
            // Calculate the next day using moment
            const nextDayMomentFrom = selectedMomentFrom.clone().add(1, 'day');
            const nextDayOnlyDateFrom = nextDayMomentFrom.format('YYYY-MM-DD');

            const selectedMomentTo = moment(todate);
            const selectedOnlyDateTo = selectedMomentTo.format('YYYY-MM-DD');
            // Calculate the next day using moment
            const nextDayMomentTo = selectedMomentTo.clone().add(1, 'day');
            const nextDayOnlyDateTo = nextDayMomentTo.format('YYYY-MM-DD');


            let payload = {
                type: "PATIENT",
                id: patient_id,
                fromDate: fromdate ? selectedOnlyDateFrom : "",
                toDate: todate ? selectedOnlyDateTo : "",
                selectedDoctor: selectedDoctor_id,
                selectedStockist: selectedStockist_id
                // town: selectedTown,
                // orderStatus: setorderStatus
            };
            OtherService.getSummary(payload).then(data => {
                console.log(data, "getSummary");
            }).catch(err => {
                console.log(err, "getSummary err");
            })
        }
    }

    clickFn = async (index) => {
        console.log(index, "index");
        const patientdetails = this.props.patientdetails;
        let payload = {};
        let orderstatus = ""
        if (patientdetails) {
            // let payload = await {

            // }

            const { fromdate, todate, selectedState, selectedTown, selectedDoctor_id, selectedStockist_id, setorderStatus } = this.state;
            const selectedOnlyDateFrom = fromdate ? moment(fromdate).format('YYYY-MM-DD') : "";
            const selectedOnlyDateTo = todate ? moment(todate).format('YYYY-MM-DD') : "";

            if (index === 0) {
                payload = {
                    fromDate: fromdate ? selectedOnlyDateFrom : "",
                    toDate: todate ? selectedOnlyDateTo : "",
                    stateName: selectedState,
                    town: selectedTown,
                    doctor_id: selectedDoctor_id,
                    selectedStockist: selectedStockist_id,
                    orderStatus: setorderStatus,
                    patient_id: patientdetails._id
                }
            } else {
                payload = {
                    fromDate: fromdate ? selectedOnlyDateFrom : "",
                    toDate: todate ? selectedOnlyDateTo : "",
                    stateName: selectedState,
                    town: selectedTown,
                    doctor_id: selectedDoctor_id,
                    selectedStockist: selectedStockist_id,
                    orderStatus: setorderStatus,
                    patient_id: patientdetails._id
                }
            }

            if (index === 0) {
                payload["orderStatus"] = ""
                orderstatus = ""
                this.setState({ setorderStatus: "" })
            }
            else if (index === 1) {
                payload["orderStatus"] = await "underprocess"
                orderstatus = "underprocess"
                this.setState({ setorderStatus: "underprocess" })
            }
            else if (index === 2) {
                payload["orderStatus"] = await "pending"
                orderstatus = "pending"
                this.setState({ setorderStatus: "pending" })
            }
            else if (index === 3) {
                payload["orderStatus"] = await "received_at_clinic"
                orderstatus = "received_at_clinic"
                this.setState({ setorderStatus: "received_at_clinic" })
            }
            else if (index === 4) {
                payload["orderStatus"] = await "delivered"
                orderstatus = "delivered"
                this.setState({ setorderStatus: "delivered" })
            }
            else if (index === 5) {
                payload["orderStatus"] = await "completed"
                orderstatus = "completed"
                this.setState({ setorderStatus: "completed" })

            }
            // else if (index === 2) {
            //     payload["orderStatus"] = await "underprocess"
            //     orderstatus = "underprocess"
            //     this.setState({ setorderStatus: "underprocess" })
            // }

            // else if (index === 4) {
            //     payload["orderStatus"] = await "Pickup Initiated"
            //     orderstatus = "Pickup Initiated"
            //     this.setState({ setorderStatus: "Pickup Initiated" })
            // }
            // else if (index === 5) {
            //     payload["deliveryStatus"] = await "delivered"
            //     orderstatus = "delivered"
            //     this.setState({ setorderStatus: "delivered" })
            // }



            console.log(payload, 222222);
            this.getOrderList(payload)
        }

    }

    getDoctorDetails = (details) => {
        this.setState({ doctordetailsList: [] }, () => {
            DoctorService.getdoctorInfo(details).then(async data => {
                console.log(data.data, "doctor info");

                if (data.data.success && data.data.data.mrinfo !== '') {
                    console.log(data.data.data, "okkkkk");

                    this.setState({ doctordetailsList: data.data.data });
                    const formatedData = await this.formatData(data.data.data);
                    console.log(formatedData, "formatedData")
                    this.setState({ exportData: formatedData });
                }



                // this.setState({mrdetailsList:data.data.data.mrdetails});

            }).catch(err => {

                console.log(err, "err")
            })
        });
    }

    componentDidMount() {

        const patientdetails = this.props.patientdetails;

        console.log(patientdetails, "patientdetails list");
        // console.log(currentorder, "currentorder list");
        if (patientdetails) {
            const patient_id = patientdetails._id;
            let details = {
                patient_id: patient_id
            };
            // console.log(details, "doctor details",);


            // this.getDoctorDetails(details)
            this.getSummary(patient_id)

            // DoctorService.getdoctorDetails(details).then(data => {
            //     console.log(data.data, "doctor details");

            //     if (data.data.success && data.data.data !== '') {
            //         console.log(data.data, "okkkkk doctor details");

            //         this.setState({ doctorinfo: data.data.data[0] });
            //     }



            //     // this.setState({mrdetailsList:data.data.data.mrdetails});

            // }).catch(err => {

            //     console.log(err, "err")
            // })


            this.getOrderList(details);
            let payload = {
                type: "PATIENT",
                id: patient_id
            }
            this.getInfo(payload);
            this.populateStockist();
            this.populateDoctor(patient_id);

            setTimeout(() => {
                this.populateStates("IN")
                // this.populateStates(countryObj.isoCode)	
            }, 500);
            // } else {


            // }



        }
    }

    populateStates = (isoCode = null) => {
        if (isoCode) {
            CountryService.getStates(isoCode).then(data => {
                //console.log(data, "states")
                if (data.success) {
                    this.setState({ states: data.states, towns: [] }, async () => {
                        this.setState({ showTowns: false })
                        let _state = this.state.selectedState || null
                        console.log(_state, this.state.states, "jhgjhgjhg");
                        if (_state) {
                            let stateObj = null
                            for (let i = 0; i < this.state.states.length; i++) {
                                if (this.state.states[i].name === _state) {
                                    stateObj = this.state.states[i]
                                }
                            }
                            console.log(stateObj, _state, 1234545677);
                            console.log("stateObj found", stateObj)
                            // if (stateObj) {
                            setTimeout(() => {
                                this.populateTowns(stateObj.countryCode, stateObj.isoCode)
                            }, 500);
                            // }
                        }
                    })

                    setTimeout(() => {
                        let stateDetails = this.state.states;
                        const allOption = {
                            "name": "All",
                            "isoCode": null,
                            "countryCode": null,
                            "latitude": null,
                            "longitude": null,
                            "label": "All",
                            "value": "all"
                        };
                        console.log(this.state.states, "details state")
                        // Create a new array with the "All" option at the beginning
                        let optionsWithAll = [allOption, ...stateDetails];
                        console.log(optionsWithAll, "details state all")
                        this.setState({ states: optionsWithAll })
                    }, 500);
                }
            })
        }
    }

    populateTowns = (countryCode = null, stateCode = null) => {
        console.log(countryCode, stateCode, "countryCode stateCode")
        if (countryCode && stateCode) {
            CountryService.getTowns(countryCode, stateCode).then(data => {
                console.log(data, "towns")
                // setTimeout(() => {
                if (data.success) {
                    this.setState({ towns: data.towns }, () => this.setState({ showTowns: true }))

                    setTimeout(() => {
                        let stateDetails = this.state.towns;
                        const allOption1 = {
                            "name": "All",
                            "isoCode": null,
                            "countryCode": null,
                            "latitude": null,
                            "longitude": null,
                            "label": "All",
                            "value": "all"
                        };
                        console.log(this.state.towns, "details state")
                        // Create a new array with the "All" option at the beginning
                        let optionsWithAll1 = [allOption1, ...stateDetails];
                        console.log(optionsWithAll1, "details state all")
                        this.setState({ towns: optionsWithAll1 })
                    }, 500);
                }
                // }, 2000);
            })
        }
    }

    populateStockist = () => {
        StockistService.getAllStockist().then(data => {
            console.log(data, 999999);
            if (data.data.success) {
                console.log(data.data.data.stockistlist, 9999999);
                const formatedData = this.formatedData(data.data.data.stockistlist);

                console.log(formatedData, 8888888);

                setTimeout(() => {

                    this.setState({ stockistList: formatedData });

                }, 500);



            }
        })


    }

    populateDoctor = (patient_id = null) => {
        if (patient_id) {
            console.log(patient_id, 8888888);
            let payload = {
                patient_id: patient_id
            };

            DoctorService.getAllDoctorListByPatientId(payload).then(data => {
                console.log(data, 99999925);
                if (data.data.success) {
                    console.log(data.data.data, 999999256);
                    const formatedData = this.formatedData(data.data.data);

                    console.log(formatedData, 8888888);

                    setTimeout(() => {

                        this.setState({ doctorList: formatedData });

                    }, 500);

                }
            })

            // console.log(stockist_id,255);
            // const { alldoctorList } = this.state;
            // const doctorList = alldoctorList.filter(item => item.stockist_id == stockist_id);
            // this.setState({doctorList:doctorList});
        }
    }

    formatedData = (payload) => {
        let newData = [];
        if (payload) {

            payload.map((item) => {

                let itemData = {
                    "name": item.doctor?.name,
                    "_id": item.doctor?._id,
                    "label": item.doctor?.name,
                    "value": item.doctor?.name
                };
                newData.push(itemData);
            })


        }

        return newData;

    }

    changeValFromDate = async (date) => {

        const formatedDate = moment(date).format('DD-MM-YYYY');
        console.log(date, "fromdate");
        this.setState({ fromdate: date });
        this.setState({ todate: "" });
    }

    changeValToDate = async (date) => {

        const formatedDate = moment(date).format('DD-MM-YYYY');
        console.log(date, "todate");
        this.setState({ todate: date });
    }

    searchFilter = (event) => {
        this.setState({ orders: [] });
        const patientdetails = this.props.patientdetails;

        console.log(patientdetails, "doctordetails list");
        const patient_id = patientdetails._id;
        const { fromdate, todate, selectedState, selectedTown, setorderStatus, selectedDoctor_id, selectedStockist_id } = this.state;

        const selectedMomentFrom = moment(fromdate);
        const selectedOnlyDateFrom = selectedMomentFrom.format('YYYY-MM-DD');
        // Calculate the next day using moment
        const nextDayMomentFrom = selectedMomentFrom.clone().add(1, 'day');
        const nextDayOnlyDateFrom = nextDayMomentFrom.format('YYYY-MM-DD');

        const selectedMomentTo = moment(todate);
        const selectedOnlyDateTo = selectedMomentTo.format('YYYY-MM-DD');
        // Calculate the next day using moment
        const nextDayMomentTo = selectedMomentTo.clone().add(1, 'day');
        const nextDayOnlyDateTo = nextDayMomentTo.format('YYYY-MM-DD');


        let details = {
            patient_id: patient_id,
            fromDate: fromdate ? selectedOnlyDateFrom : "",
            toDate: todate ? selectedOnlyDateTo : "",
            selectedDoctor: selectedDoctor_id,
            selectedStockist: selectedStockist_id,
            orderStatus: setorderStatus
        };

        if (fromdate != "" && todate == "") {
            this.props.hoc.customAlert("Please select both fromdate and todate", false);
        } else if (fromdate == "" && todate != "") {
            this.props.hoc.customAlert("Please select both fromdate and todate", false);
        } else {

            this.getOrderList(details);
            this.getSummary(patient_id)

            // DoctorService.getdoctorInfo(details).then(async data => {
            //     console.log(data.data, "doctor info");

            //     if (data.data.success && data.data.data.mrinfo !== '') {
            //         console.log(data.data.data, "okkkkk");

            //         this.setState({ doctordetailsList: data.data.data });
            //         const formatedData = await this.formatData(data.data.data);
            //         console.log(formatedData, "formatedData")
            //         this.setState({ exportData: formatedData });
            //     }

            //     this.getSummary(doctor_id)



            //     // this.setState({mrdetailsList:data.data.data.mrdetails});

            // }).catch(err => {

            //     console.log(err, "err")
            // })
        }




        console.log("search filter");
    }

    backToPrevious = (event) => {

        this.props.navigate('/doctor/landing');
    }
    setOrderHistoryData = (data) => {
        let resp = []
        // console.log(data[0],66666)
        for (let i = 0; i < data.length; i++) {
            let completedStatus = data[i].order_status.filter(item => {
                return item.patient_order_id == data[i].order._id && item.status == "completed"
            })


            resp.push({
                _id: data[i]._id,
                order_no: data[i].order.order_no,
                order_date: data[i].order.createdAt ? moment(data[i].order.createdAt).format('DD-MM-YYYY') : "",
                order_status: data[i].order.orderStatus ? data[i].order.orderStatus.toUpperCase() : "",
                stockist_name: data[i].stockist[0].name,
                patient_name: data[i].patient.name,
                patient_mobile: data[i].patient.mobileno,
                vacutainer_code: data[i].unique_qr_id,
                serial_no: data[i].serial_no ? 'WH' + data[i].serial_no : '',
                doctor_name: data[i].doctor.name,
                is_completed: completedStatus.length > 0 ? 'yes' : 'no'

            })
        }
        return resp


    }

    componentWillUnmount() {
        if (this.dataTable) {
            this.dataTable.destroy(true);
        }
    }

    handleTrackOrder = async (event) => {

        // const allOrder = this.props.OrderReducer.allOrders;
        const targetId = event.target.name;
        console.log(targetId, "target ID");
        const { orders } = this.state;
        console.log(orders, 7777);
        try {
            const selectedOrder = orders.filter(item => item._id == targetId)
            console.log(selectedOrder, 'selectedOrder');
            this.props.saveSelectedOrders(selectedOrder)
            this.props.navigate('/customer/track-info-order');
            this.props.setUserType('patient');


            // OtherService.getOrderDetails({ _id: targetId }).then(data => {
            //     console.log(data.data, "order details 123456789");
            //     if (data.data.success) {
            //         console.log(data.data.data.order, "okkkkk 123456789");
            //         this.props.saveSelectedOrders(data.data.data.order)
            //         this.props.navigate('/patient/track-info-order');
            //         this.props.setUserType('patient');
            //         // this.setState({mrdetailsList:data.data.data.mrdetails});
            //     }
            // })
        } catch (error) {

        }

    }

    clearFilter = (event) => {
        const patientdetails = this.props.patientdetails;
        console.log(patientdetails, "patientdetails list");
        const patient_id = patientdetails._id;

        let details = {
            patient_id: patient_id
        };

        this.getOrderList(details);
        this.setState({ fromdate: "" });
        this.setState({ todate: "" });
        this.setState({ selectedDoctor: "" });
        this.setState({ selectedStockist: "" });
        this.setState({ selectedStockist_id: "" });
        this.setState({ selectedDoctor_id: "" });
        this.setState({ stockistList: "" });
        this.setState({ doctorList: "" });
        this.getSummary(patient_id)
    }

    // getOrderList = (payload) => {

    //     this.setState({orders:[]})
    //     this.setState({ exportData: null });

    //     OrderService.getAllOrders(payload).then( async (data) =>{
    //         console.log(data,10000)
    //         if(data.data.success){
    //             console.log(data.data.data.order,6666666)

    //             this.setState({orders:data.data.data.order})

    //             const formatedData = await this.formatData(data.data.data.order);
    //             console.log(formatedData, "formatedData")
    //             this.setState({ exportData: formatedData });
    //         }


    //     }).catch(err => {
    //     })

    // }

    getOrderList = (payload) => {

        this.setState({ orders: [] })
        this.setState({ exportData: null });

        OrderService.getAllOrders(payload).then(async (data) => {
            console.log(data, 10000)
            if (data.data.success) {
                console.log(data.data.data.order, 6666666)

                this.setState({ orders: data.data.data.order })

                const formatedData = await this.formatData(data.data.data.order);
                console.log(formatedData, "formatedData")
                this.setState({ exportData: formatedData });

                this.getDoctors(data.data.data.order)
            }


        }).catch(err => {
        })

    }

    getInfo = (payload) => {
        this.setState({ personalDetails: [] })

        OrderService.getPersonalDetails(payload).then(async (data) => {
            console.log(data, 100001)
            if (data.data.success) {
                // console.log(data.data.data.order,6666666)

                this.setState({ personalDetails: data.data.data[0] })

            }


        }).catch(err => {
        })
    }

    handleProductReceived = (event) => {
        let order_no = event.target.name;
        let payload = {
            order_no: order_no
        };
        this.props.hoc.customAlert('Have you received the order?', true, "", false, true, async () => {



            OrderService.updateStatus(payload).then(async (data) => {
                console.log(data, 100001)
                if (data.data.success) {
                    console.log(data.data.data, 'gfcstatus');
                    const patientdetails = this.props.patientdetails;
                    console.log(patientdetails, "patientdetails list");
                    const patient_id = patientdetails._id;

                    let details = {
                        patient_id: patient_id
                    };
                    this.getSummary()
                    this.getOrderList(details)
                    // this.setState({ cityTAT: alltats.data.data });
                    this.props.hoc.customAlert("Product Received", true);

                } else {
                    this.props.hoc.customAlert("Something went wrong.", false);
                }


            }).catch(err => {
            })


            // alert(orderNo);
            // this.props.navigate('/admin/doctor-master');
        }, () => {

        })


    }

    render = () => {
        const { countries, states, towns, isLoading, showTowns, selectedState, selectedTown, fromdate, todate, summaryCols, summaryDetails, exportData, fileName, orders, personalDetails, stockistList, selectedStockist, doctorList, selectedDoctor } = this.state;
        console.log(orders, 555555)
        // console.log(personalDetails,"personalDetails")
        let _data = this.setOrderHistoryData(orders)
        console.log(_data, "_data")
        return (

            <>

                <section className="landing-page-pt">
                    <div className="container">
                        <DoctorHeader />
                        <div className="mr-landing-page mt-20">
                            <div className="landing-head-pt">
                                {/* <h2>Doctor Landing page</h2> */}
                            </div>
                        </div>
                        <div className="landing-form-dtls">

                            <div className="row">
                                <div className="col-md-6">
                                    <h5 className="mt-10 mb-20">Welcome {personalDetails?.name} to XO Application (Customer)</h5>
                                </div>

                                <div className="col-md-6">

                                    <div class="flotright marginright d-flex">
                                        {/* <button class="btn-home me-3" onClick={(event) => this.backToPrevious(event)}>Back</button> */}
                                        <ExportToExcel apiData={exportData} fileName={fileName} />
                                    </div>
                                </div>

                            </div>


                            <div className="row mt-30 mb-20 ">
                                <div className="col-md-2 mb-3">
                                    <div className="date-picker-wrap">

                                        <label htmlFor className="form-label lvl-clr-hd">From Date</label>
                                        {/* <input type="date" format="dd-mm-yyyy" value={fromdate} name="fromdate" onChange={(event) => this.changeVal(event)} id className="form-control name-pt-ft" placeholder /> */}
                                        <DatePicker
                                            selected={fromdate}
                                            onChange={(date) => this.changeValFromDate(date)}
                                            dateFormat="dd-MM-yyyy" // Set the desired display format
                                            className="form-control name-pt-ft"
                                            placeholderText="DD-MM-YYYY"
                                        />

                                    </div>
                                </div>
                                <div className="col-md-2 mb-3">
                                    <div>
                                        <label htmlFor className="form-label lvl-clr-hd">To Date</label>
                                        {/* <input type="date" format="dd-mm-yyyy" min={this.state.fromdate} value={todate} name="todate" onChange={(event) => this.changeVal(event)} id className="form-control name-pt-ft" placeholder /> */}
                                        <DatePicker
                                            selected={todate}
                                            onChange={(date) => this.changeValToDate(date)}
                                            dateFormat="dd-MM-yyyy" // Set the desired display format
                                            className="form-control name-pt-ft"
                                            placeholderText="DD-MM-YYYY"
                                            minDate={this.state.fromdate}
                                        />

                                    </div>
                                </div>
                                {/* <div className="col-md-2 mb-3">
                                    <label htmlFor="disabledTextInput" className="form-label lvl-clr-hd">Stockist </label>
                                    {
                                        stockistList.length ?
                                            <Dropdown
                                                items={stockistList}
                                                onSelect={(item) => {
                                                    if (item) {
                                                        console.log(item.stockist_id, '2222');

                                                        this.setState({ selectedStockist: item.name })
                                                        this.setState({ selectedStockist_id: item._id })
                                                        //console.log(item, "item");
                                                        if (item.name != "All") {
                                                            this.populateDoctor(item._id)
                                                        } else {
                                                            this.setState({ doctorList: [] })
                                                            this.setState({ selectedDoctor: "" })
                                                        }
                                                    }
                                                }}
                                                value={selectedStockist}
                                            /> :
                                            <Dropdown
                                                items={stockistList}
                                                value={selectedStockist}
                                            />
                                    }
                                    
                                </div> */}
                                <div className="col-md-2 mb-3">
                                    <label htmlFor="disabledTextInput" className="form-label lvl-clr-hd">Doctor </label>
                                    {
                                        doctorList.length ?
                                            <Dropdown
                                                items={doctorList}
                                                onSelect={(item) => {
                                                    if (item) {

                                                        this.setState({ selectedDoctor: item.name })
                                                        this.setState({ selectedDoctor_id: item._id })
                                                        //console.log(item, "item");
                                                        if (item.name != "All") {
                                                            // this.populateDoctor(item._id)
                                                        }
                                                    }
                                                }}
                                                value={selectedDoctor}
                                            /> :
                                            <Dropdown
                                                items={doctorList}
                                                value={selectedDoctor}
                                            />
                                    }
                                    {/* <input type="text" value={state} onChange={(event) => this.changeAddr(event)} name="state" id className="form-control name-pt-ft" placeholder /> */}
                                </div>


                                {/* <div className="col-md-4 mb-3">
                                    <label htmlFor="disabledTextInput" className="form-label lvl-clr-hd">State </label>
                                    {
                                        states.length ?
                                            <Dropdown
                                                items={states}
                                                onSelect={(item) => {
                                                    if (item) {
                                                       
                                                        this.setState({ selectedState: item.name })
                                                        //console.log(item, "item");
                                                        if (item.name != "All") {
                                                            this.populateTowns(item.countryCode, item.isoCode)
                                                        } else {
                                                            this.setState({ towns: [] })
                                                            this.setState({ selectedTown: "" })
                                                        }
                                                    }
                                                }}
                                                value={selectedState}
                                            /> :
                                            <Dropdown
                                                items={states}
                                                value={selectedState}
                                            />
                                    }
                                   
                                </div> */}
                                {/* <div className="col-md-4 mb-3">
                                    <label htmlFor className="form-label lvl-clr-hd">City/Town </label>
                                    {
                                        towns.length ?
                                            <Dropdown
                                                items={towns}
                                                onSelect={(item) => {
                                                    if (item) {
                                                        // alert(item.name);
                                                        // let _registration = this.state.registration
                                                        // _registration.address["town"] = item.name
                                                        this.setState({ selectedTown: item.name })
                                                        //console.log(item, "item")
                                                    }
                                                }}
                                                value={selectedTown}
                                            /> :
                                            <Dropdown
                                                items={towns}
                                                value={selectedTown}
                                            />
                                    }
                                   
                                </div> */}
                                <div className="">
                                    <button type="button" className="landing-success-btn" onClick={(event) => this.searchFilter(event)}>
                                        Search</button>
                                    <button type="button" className="landing-clear-btn" onClick={(event) => this.clearFilter(event)}>
                                        Clear Filter</button>
                                </div>

                            </div>
                            <Summary
                                columns={summaryCols}
                                data={[{
                                    totalOrderCount: summaryDetails ? summaryDetails.totalOrderCount || 0 : 0,
                                    totalOrderUnderProcessCount: summaryDetails ? summaryDetails.totalOrderUnderProcessCount || 0 : 0,
                                    totalOrderPending: summaryDetails ? summaryDetails.totalOrderPendingCount || 0 : 0,
                                    totalOrderReceivedAtKoshinClinic: summaryDetails ? summaryDetails.totalOrderReceivedAtKoshinClinicCount || 0 : 0,
                                    totalOrderDelivered: summaryDetails ? summaryDetails.totalOrderDeliveredCount || 0 : 0,
                                    // totalOrderCompleted: summaryDetails ? summaryDetails.totalOrderCompletedCount || 0 : 0,
                                }]}
                                clickFn={this.clickFn}
                            />
                            {/* Summary table start */}
                            {/* <div className="summary-table mt-32 mb-4">
                                <h5 className="mb-20">Summary</h5>
                                <div className="table-responsive">
                                    <table className="table table-bordered-custom">
                                        <thead>
                                            <tr>
                                                <th scope="col">No of Orders</th>
                                                <th scope="col">Stage Blood Collection Completed</th>
                                                <th scope="col">Recevied - Manufacturing</th>
                                                <th scope="col">Dispatched</th>
                                                <th scope="col">Received doctor</th>
                                                <th scope="col">Received by Patient</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Lorem</td>
                                                <td>Lorem</td>
                                                <td>Lorem</td>
                                                <td>Lorem</td>
                                                <td>Lorem</td>
                                                <td>Lorem</td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div> */}
                            {/* Summary table end */}


                            <h5 className="mb-20 headingstyle">Details</h5>

                            <div className="table-responsive">
                                {
                                    _data && _data.length ?
                                        <CustomDatatable
                                            pagination={true}
                                            columns={[
                                                {
                                                    name: 'Order No',
                                                    // selector: row => row.stockist_name,

                                                },
                                                {
                                                    name: 'Order Date',
                                                    // selector: row => row.stockist_name,

                                                },
                                                {
                                                    name: 'Doctor Name',
                                                    // selector: row => row.stockist_name,

                                                },

                                                // {
                                                //     name: 'Stockist Name',
                                                //     // selector: row => row.stockist_name,

                                                // },
                                                {
                                                    name: 'Vacutainer Serial No',
                                                    // selector: row => row.stockist_name,

                                                },

                                                {
                                                    name: 'Status',
                                                    // selector: row => row.createdAt,

                                                },
                                                {
                                                    name: 'Track Order',
                                                    // selector: row => row.createdAt,

                                                },
                                                {
                                                    name: 'Product Received',
                                                    // selector: row => row.createdAt,

                                                }
                                            ]}
                                            data={this.setOrderHistoryData(orders)}
                                            pageName="patientlist"
                                            handleTrackOrder={this.handleTrackOrder}
                                            handleProductReceived={this.handleProductReceived}
                                        />
                                        : <p className="text-center">No Records Found</p>
                                }
                            </div>


                        </div>

                        <CustomerFooter />
                    </div>
                </section>


            </>

        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setUserType: (userType) => dispatch(CommonAction.setUserType(userType)),
        // dispatching plain actions
        // setMr: (mrdetails) => dispatch(MrAction.setMr(mrdetails))
        saveSelectedOrders: (selectedOrders) => dispatch(OrderAction.saveSelectedOrders(selectedOrders))
    }
}

const mapStateToProps = ({ CustomerReducer, OrderReducer, DoctorReducer, CommonReducer, PatientReducer }) => {
    const { customer } = CustomerReducer
    const { orders, currentOrder } = OrderReducer
    const { doctordetails } = DoctorReducer
    const { summaryDetails } = CommonReducer
    const { patientdetails } = PatientReducer

    console.log(currentOrder, 123);

    return {
        // dispatching plain actions
        // OrderListReducer: state.OrderListReducer,
        customer,
        orders,
        doctordetails,
        patientdetails,
        currentOrder,
        summaryDetails
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Common(PatientDashboard)))