import React from "react";
import config from "../../config/emrok.config";
import { Link } from "react-router-dom";
export default function CustomerFooter() {
  return (
    <>
      <footer>
        <div className="container">
          <div className="row">
            <div className="footer">
              <div className="d-flex flex-lg-row flex-column justify-content-between align-items-center">
                <div className="">
                  <p className="m-0 footer-copyright-text">
                    Copyright © 2023 Wockhardt Regenerative. All Rights
                    Reserved.
                  </p>
                </div>
                <div className="footer-end-content-wrap">
                  <div className="d-flex admin-footer-content-box-end">
                    <div className="footer-customer-care-content">
                      <p className="privacy-policy-text customer_caretext">
                        Customer Care: {config.myownnCustomerSupport}
                      </p>
                    </div>
                    <div className="d-flex footer-privacy-policy-terms-wrap">
                      <div className="footer-privacy-policy">
                      <Link to="/customer/privacy-policy" target="_blank" className="privacy-policy-text me-2">Privacy Policy</Link>
                      </div>
                      <div className="footer-terms-condition">
                      <Link to="/customer/terms" target="_blank" className="privacy-policy-text me-2">Terms &amp; Conditions</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>{" "}
          {/* Footer end*/}
        </div>
      </footer>
    </>
  );
}
