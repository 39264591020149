import React, { Component } from 'react';
import Select from 'react-select';

export default class Dropdown extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedOption: null,
      items: [],
      onSelect: undefined,
      isDisabled: false
    }
    console.log(this.props, 123)
  }
  static getDerivedStateFromProps(props, state) {
    console.log(props, state, 22);
    const getItem = (val) => {
      if (props.items && props.items.length) {
        let item = props.items.find(i => i.name === val)
        return item ? item : null
      }
    }
    return {
      items: props.items || [],
      onSelect: props.onSelect || undefined,
      selectedOption: props.value ? getItem(props.value) : null,
      isDisabled: props.isDisabled || false
    }
  }

  handleChange = selectedOption => {
    this.state.onSelect && this.state.onSelect(selectedOption)
  }

  render = () => {
    const { selectedOption, items, isDisabled } = this.state;
    console.log(this.state, items, 56565656);
    return (
      <div>
        <Select
          value={selectedOption}
          onChange={this.handleChange}
          options={items}
          isSearchable
          placeholder="Please Select"
          isDisabled={isDisabled}
        />
      </div>
    );
  }
}
