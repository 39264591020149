import {
  SET_ADMIN_USER,
  CLEAR_STATE,
  SET_SELECTED_DETAILS,
  SET_ADMIN_USER_CUSTOMER,
} from '../types/AdminUser.type'

class AdminUserAction {
  setAdminUser = (data) => {
    console.log(">>>>>>>>>>>>>>>>", data)
    return { type: SET_ADMIN_USER, payload: data }
  }
  setSelectedDetails = (data) => {
    console.log(">>>>>>>>>>>>>>>>", data)
    return { type: SET_SELECTED_DETAILS, payload: data }
  }

  setCallCenterCustomer = (data) => {
    console.log(">>>>>>>>>>>>>>>>", data)

    return { type: SET_ADMIN_USER_CUSTOMER, payload: data }
  }

  logout = () => {
    return { type: CLEAR_STATE, payload: null }
  }
}
export default new AdminUserAction()