import {
  SET_WAREHOUSE_USER,
  CLEAR_STATE,
  SET_SELECTED_DETAILS,
  SET_WAREHOUSE_USER_CUSTOMER,
} from '../types/Warehouse.type'

class WareHouseUserAction {
  setWarehouseUser = (data) => {
    console.log(">>>>>>>>>>>>>>>>", data)
    return { type: SET_WAREHOUSE_USER, payload: data }
  }
  setSelectedDetails = (data) => {
    console.log(">>>>>>>>>>>>>>>>", data)
    return { type: SET_SELECTED_DETAILS, payload: data }
  }

  setCallCenterCustomer = (data) => {
    console.log(">>>>>>>>>>>>>>>>", data)
    return { type: SET_WAREHOUSE_USER_CUSTOMER, payload: data }
  }

  logout = () => {
    return { type: CLEAR_STATE, payload: null }
  }
}
export default new WareHouseUserAction()