
export const CUSTOMER_DETAIL = "/customer/myownn/customer-detail";
export const UPDATE_CUSTOMER = "/customer/myownn/update-customer";
export const UPDATE_CUSTOMER_CALLCENTER = "/customer/myownn/update-customer-callcenter";
export const GET_PRODUCT = "/product/myownn/get-myown-product";
export const UPLOAD_PRESCRIPTION = "/customer/myownn/prescription-upload";
export const GET_SLOTS_DETAILS = "/srl/myownn/get-slots-by-pincode";
export const SRL_SAMPLE_BOOKING = "/srl/myownn/create-sample-booking";
export const GET_MYOWN_PRODUCT = "/product/myownn/get-myown-product";
export const SRL_RESPONSE_SAVE = "/srl/myownn/save-response";
export const SAVE_SRL_ORDER_STATUS = "/srl/myownn/sample-status";
export const GET_CUSTOMER_OUT = "/sap/make-order-customer-xlsx";
export const GET_CUSTOMER_DETAILS_OUT = "/sap/make-customer-xlsx";
export const GET_ORDER_DETAILS_OUT = "/sap/make-order-xlsx";
export const GET_SRL_SAMPLE_STATUS = "/srl/myownn/get-sample-status";
export const MR_LOGIN_API = "/mr/login";
export const MR_DETAIL = "/mr/list";
export const MR_INFO = "/mr/info";
export const GET_DOCTOR_BY_MR = "/mr/get-doctor-by-mr";
export const DOCTOR_LOGIN_API = "/doctor/login";
export const DOCTOR_DETAIL = "/doctor/details";
export const DOCTOR_INFO = "/doctor/info";
export const DOCTOR_VERIFY_OTP = "/doctor/verify-otp";
export const GET_ORDERS = "/customer/get-orders";
export const SEND_LOGIN_SMS = "/customer/myownn/send-sms";
export const OTHER_LOGIN_API = "/myownn/other-user-login";
export const STOCKIST_INFO = "/stockist/info";
export const STOCKIST_DETAIL = "/stockist/list";
export const CALL_CENTER_INFO = "/callcenter/info";
export const CALL_CENTER_DETAIL = "/callcenter/list";
export const MANUFACTUR_INFO = "/manufactur/info";
export const MANUFACTUR_DETAIL = "/manufactur/list";
export const CHANGE_GRN_STATUS = "/manufactur/change-grn-status";
export const GET_ORDER_DETAILS = "/myownn/get-order-details";
export const CUSTOMER_LOGIN_BY_QR = "/myownn/customer-login-by-qr";
export const UPDATE_DELIVERY_STATUS = "/myownn/update-delivery-status";
export const GET_ORDER_DETAILS_BY_ID = "/myownn/get-order-details-by-id";
export const CREATE_FEEDBACK = "/customer/myownn/create-feedback";
export const FEEDBACK_LIST = "/customer/myownn/feedback-list";
export const CUSTOMER_LOGIN_TRACKING_API = "/customer-login-tracking";

export const GET_AUDIT_LIST = "/callcenter/fetch-audit-list"
export const POST_INITIATE_RTO = "/callcenter/initiate-rto"
export const GET_STATUS_TRACK = "/order/get-status-track-myownn"
export const ADMIN_INFO = "/admin/info"
export const GET_CITY_TAT = "/default/tat-srl-myownn"
//saisei
export const GENERATE_QR_CODE = "/admin/generate-qr"
export const GET_ALL_QR_CODES = "admin/get-all-qr-codes"
export const ZIP_QR_CODES = "admin/zip-qr-codes"
export const CHECK_AVAILABLE_VACUTAINERS_SERIES = "admin/check-available-vacutainers-series"
export const CREATE_VACUTAINERS_RECORD = "admin/create-vacutainers-record"
export const GET_ALL_VACUTAINERS_RECORD = "admin/get-all-vacutainers-record"

export const GET_ALL_CALL_CENTER = "/admin/get-all-call-center"
export const UPDATE_CALL_CENTER = "/admin/update-call-center"
export const CREATE_CALL_CENTER = "/admin/create-call-center"
export const REMOVE_CALL_CENTER = "/admin/remove-call-center"

export const GET_ALL_ADMIN_USERS = "/admin/admin-users"
export const UPDATE_ADMIN_USER = "/admin/admin-user/update"
export const CREATE_ADMIN_USER = "/admin/admin-user/create"
export const ADMIN_USER_UPDATE_STATUS = "/admin/admin-user/update-status"

export const GET_ALL_WAREHOUSE_USERS = "/admin/warehouse-users"
export const UPDATE_WAREHOUSE_USER = "/admin/warehouse-user/update"
export const CREATE_WAREHOUSE_USER = "/admin/warehouse-user/create"
export const WAREHOUSE_USER_UPDATE_STATUS = "/admin/warehouse-user/update-status"

export const ORDER_LIST_PATH = "/order/order-list";
export const UPDATE_ORDER_STATUS = "/order/update-satatus";
export const CREATE_DOCTOR_MASTER = "admin/create-doctor-master";
export const FETCH_DOCTOR_LIST = "admin/fetch-doctor-list";
export const STOCKIST_LIST = "stockist/featch-all-stockist";
export const CHECK_DR_QR_MAPPING = "doctor/check-dr-qr-mapping-exist";
export const ALL_DOCTOR_LIST_BY_STOCKIST = "doctor/get-all-doctor-by-stockist";
export const CREATE_ORDER = "/order/create";
export const ORDER_LIST = "order/order-list";
export const GET_SUMMARY = "/default/summary";
export const GET_PERSONAL_INFO = "/default/get-personal-info";
export const DASHBOARD_TOTAL_COUNT = "default/dashboard-total-count"
export const CUSTOMER_LOGIN_API = "/customer/myownn/customer-login";
export const GENERATE_OTP = "/myownn/save-otp";
export const MATCH_OTP = "/myownn/match-otp";
export const GFC_RECEIVED = "/clinic/gfc-received";
export const SERUM_READY_TO_DISPATCH = "/clinic/serum-ready-to-dispatch";
export const ALL_DOCTOR_LIST_BY_PATIENT = "doctor/get-all-doctor-by-patient";
export const PRODUCT_RECEIVED = "/customer/product-received";
export const DOCTOR_BULK_UPLOAD = "/doctor/bulk-upload";
export const GET_QR_SERIES_NUMBER = "/admin/get-qr-series-number"
export const GET_UNIQUE_CUSTOMER = "/admin/get-unique-customer"
export const GET_USER_BY_USER_TYPE = "/admin/get-user-by-user-type"
export const CREATE_DELIVERY_PERSON = "/admin/create-delivery-person"
export const REMOVE_DELIVERY_PERSON = "/admin/remove-delivery-person"
export const GET_CLINIC_LIST = "/admin/get-clinic-details"
export const GET_DELIVERY_PERSON = "/admin/get-delivery-person"
export const ASSIGN_JOB_TO_DELIVERY_PERSON = "/admin/assign-job-to-delivery-person"
export const GET_ASSIGN_JOB_HISTORY = "/admin/get-assign-job-history"
export const CHANGE_ORDER_STATUS = "/order/change-order-status"
export const DOCTOR_BULK_UPLOAD_ADDRESS = "/doctor/bulk-upload-address"
export const GET_DOCTOR_ALL_ADDRESS = "/doctor/get-doctor-all-address"









