import {
  LOADER_ACTION,
  TOKEN,
  LOGOUT,
  SET_USER_TYPE,
  SET_SUMMARY_DETAILS,
  SET_JOB_TYPE
} from '../types/Common.type'
class CommonAction {
  getLoaderAction = (data) => {
    return { type: LOADER_ACTION, payload: data }
  }
  setToken = (data)=>{
    return { type: TOKEN, payload: data }
  }
  setUserType = (data)=>{
    return { type: SET_USER_TYPE, payload: data }
  }
  setSummaryDetails = (data)=>{
    return { type: SET_SUMMARY_DETAILS, payload: data }
  }
  logout = ()=>{
    return { type: LOGOUT, payload: null }
  }
  setJobType = (data)=>{
    return { type: SET_JOB_TYPE, payload: data }
  }

}
export default new CommonAction()