import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from '../../../components/Reusable/WithRouter';
import CustomerHeader from "../../../components/layout/CustomerHeader";
import TrackShipment from '../../../components/Order/TrackShipment';
import ShippingAddress from '../../../components/Order/OrderShippingAddress';
import config from '../../../config/emrok.config';
class OrderInformation extends Component {
    constructor(props) {
        super(props)

        this.state = {
            quantity: ""
        }
    }

    checkQuantity(){
        const { currentOrder } = this.props.OrderReducer;
        let quantity = 0;
        currentOrder.products.forEach(order=>{
            quantity = quantity + order.quantity;
        })
        this.setState({quantity})
    }
    componentDidMount() {
        this.checkQuantity();
    }

    orderItemListJsx = () => {
        const { currentOrder } = this.props.OrderReducer;
        return (
            <>
                {
                    currentOrder.products.map((product) => {
                        return (
                            <>
                                <h6>
                                    <div className="tablts-dtls">
                                        <div>{product.name}</div>
                                        <div>₹{product.price}</div>
                                    </div>
                                </h6>
                            </>
                        )
                    })
                }

            </>
        )
    }
    render = () => {
        const { currentOrder } = this.props.OrderReducer;
        const { quantity } = this.state;
        const { customer } = this.props.CustomerReducer;
        console.log("?>>>>>>",customer)
        return (
            <>
                <section className="order-infrmtn-pt pt-0 order-information-container">
                    <div className="container">
                        <CustomerHeader />
                        {/* Row end */}
                        <div className="row">
                            <div className="ordr-info-part">
                                <h2>Order Information</h2>
                                <p>
                                    <span className="ordr-fst-id">Order ID</span>
                                    <span className="ordr-num">{currentOrder._id}</span>
                                </p>
                            </div>
                            <div className="col-md-6 price-details-pt">
                                <div className="price-details-dtls mt-4">
                                    <h5>Price Details</h5>
                                    <h4>Total Item ({quantity})</h4>
                                    {
                                        this.orderItemListJsx ()
                                    }
                                    <hr />
                                    <h6>
                                        <div className="tablts-dtls">
                                            <div>Grand Total</div>
                                            <div>₹{currentOrder.total}</div>
                                        </div>
                                    </h6>
                                    <h6>
                                        <div className="tablts-dtls">
                                            <div>Tax</div>
                                            <div>₹00</div>
                                        </div>
                                    </h6>
                                    <hr />
                                    <h6>
                                        <div className="tablts-dtls">
                                            <div>Amount Paid</div>
                                            <div>₹00</div>
                                        </div>
                                    </h6>
                                    <h6>
                                        <div className="tablts-dtls">
                                            <div>Due Amount</div>
                                            <div>₹{currentOrder.total}</div>
                                        </div>
                                    </h6>
                                    <hr />
                                </div>
                                <ShippingAddress customer={customer} />
                            </div>
                            <TrackShipment  />
                            <div className="dwn-btn-trk mt-4">
                                <div className="dwn-align-prft">
                                    <button
                                        type="button"
                                        className="feedbk-wrt"
                                        onClick={() => {
                                            console.log(this.props,898);
                                            this.props.navigate("/customer/feedback-info")
                                        }}
                                    >
                                        Write Feedback
                                    </button>
                                </div>
                                {/* <div>
                                    <a href={config.knowMoreLink} className="knw-mr-track" target="_blank" rel="noopener noreferrer">
                                        Know more
                                    </a>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        // dispatching plain actions
        // setCustomer: (customer) => dispatch(CustomerAction.setCustomer(customer))
    }
}

const mapStateToProps = (state) => {
    return {
        OrderReducer: state.OrderReducer,
        CustomerReducer: state.CustomerReducer
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(OrderInformation))