import {
    SET_WAREHOUSE_USER,
    CLEAR_STATE,
    SET_SELECTED_DETAILS,
    SET_WAREHOUSE_USER_CUSTOMER
} from '../types/Warehouse.type'

const initState = {
    warehouseUserDetails: {},
    token: null,
    selecteddetails: {},
    callcentercustomer: {}
}

export default (state = initState, action) => {
    const { type, payload } = action
    switch (type) {
        case SET_WAREHOUSE_USER:
            return Object.assign({}, state, { warehouseUserDetails: { ...payload } })
        case SET_SELECTED_DETAILS:
            return Object.assign({}, state, { selecteddetails: { ...payload } })
        case SET_WAREHOUSE_USER_CUSTOMER:
            return Object.assign({}, state, { warehouseUserDetails: { ...payload } })
        case CLEAR_STATE:
            return initState;
        default:
            return state
    }
}