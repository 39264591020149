import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from '../../../components/Reusable/WithRouter';
import config from '../../../config/emrok.config';
import Common from '../../../hoc/Common.hoc';
import axios from 'axios';
import moment from 'moment';
import AdminHeader from "../../../components/layout/Admin/AdminHeader";
import AdminSidebar from "../../../components/layout/Admin/AdminSidebar";
import AdminFooter from "../../../components/layout/Admin/AdminFooter";
import '../../../assets/css/admin/style.css';
import '../../../assets/css/admin/responsive.css';
import DropdownDoctorRuntime from '../../../components/SearchableDropdown/DropdownDoctorRuntime.component';
import AdminService from '../../../services/Admin.service';
import CustomDatatable from "../../../components/Datatable/CustomDatatable.component";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; // Import the CSS
import AdminAction from '../../../redux/actions/Admin.action';
class QrCodeGeneration extends Component {
    constructor(props) {
        super(props)

        this.state = {
            quantity: "",
            selectOrder: [],
            bankrefno: "",
            rtochangeDate: "",
            refundchangeDate: "",
            qrList: [],
            selectedDoctor: "",
            searchDoctor: "",
            currentPage: 1,
            itemsPerPage: 20,
            fromdate: "",
            todate: "",
        }

    }


    componentDidMount() {

        this.fetchRecords();

    }

    fetchRecords = (payload = null) => {
        let newpayload = {
            fetchtype: 'all',
            startDate: payload != null ? payload.startDate : '',
            endDate: payload != null ? payload.endDate : ''
        };

        console.log(newpayload, 9999);

        AdminService.getAllQRCodes(newpayload).then(data => {
            console.log(data.data.data.data, "all qr codes");
            if (data.data.success) {
                // console.log(data.data.data.data[0].totalCustomer, "10001");
                this.setState({ qrList: data.data.data.data });
            }
        }).catch(err => {

            console.log(err, "err")
        })


    }


    componentWillUnmount() {
        if (this.dataTable) {
            this.dataTable.destroy(true);
        }
    }

    grGenarationHistory = () => {
        // const usertype = this.props.CommonReducer.userType;
        this.props.navigate('/admin/qr-generation-history');
    };


    downloadQR = async (event) => {
        // alert(event.target.id);

        // console.log(event.target.name,55555)

        let payload = {
            serial_no: event.target.id
        };

        console.log(payload, 55555);

        AdminService.zipQrCodes(payload).then(async (data) => {
            console.log(data.data, "zip qr codes");
            if (data.data.success) {
                console.log('in success');

                const downloadUrl = config.serviceUrl + '/images/qr_codes/saisei/zip/' + data.data.data.zippath;
                const a = document.createElement('a');
                a.href = downloadUrl;
                a.target = '_blank';
                a.download = data.data.data.zippath;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                // console.log(data.data.data.data[0].totalCustomer, "10001");
                // this.setState({ qrList: data.data.data.data});
            }
        }).catch(err => {
            this.props.hoc.customAlert("Something went wrong.", false)
            console.log(err, "err")
        })



        console.log(payload, 55555);

    }

    changeVal = async (event) => {
        const name = event.target.name;
        const value = event.target.value;
        if (value > 1000) {
            this.setState({ quantity: '' });
        } else {
            this.setState({ quantity: value });
        }


    }
    generateQR = (event) => {
        const { quantity } = this.state;

        console.log(quantity, 'quantity');

        if (quantity == "" || quantity <= 0) {

            this.props.hoc.customAlert('Please Enter Quantity.', false)
            return false
        } else {
            let payload = {
                quantity: quantity
            }
            this.generateQRService(payload);
        }
        // alert(searchDoctor);
    }

    generateQRService = async (payload) => {
        console.log(payload, 'quantity1');
        const { quantity } = this.state;
        try {
            this.setState({ qrList: [] })
            let res = await AdminService.generateQR(payload)
            console.log(res, 'quantity2')
            if (res.data.success) {
                this.fetchRecords();
                if (quantity == 1) {
                    this.props.hoc.customAlert(quantity + ' QR Code is Generated.', true)

                } else {
                    this.props.hoc.customAlert(quantity + ' QR Codes are Generated.', true)
                }
            }
        } catch (error) {
            this.props.hoc.customAlert('Something went wrong.', false)
        }
    }

    setOrderHistoryData = (data) => {
        let resp = []
        for (let i = 0; i < data.length; i++) {
            resp.push({
                _id: moment(data[i].fullDate).local().format('YYYY-MM-DD HH:mm'),
                count: data[i].count,
                startSerialNo: data[i].startSerialNo,
                endSerialNo: data[i].endSerialNo,
                qrIDs: data[i].qrIDs.length>0?data[i].qrIDs.join(', '):''


            })
        }
        return resp
    }
    changeValFromDate = async (date) => {

        // const formatedDate = moment(date).format('DD-MM-YYYY');
        console.log(date, "fromdate");
        this.setState({ fromdate: date });
    }

    changeValToDate = async (date) => {

        // const formatedDate = moment(date).format('DD-MM-YYYY');
        console.log(date, "todate");
        this.setState({ todate: date });
    }

    clearSearch = (event) => {
        this.setState({ doctorList: [], searchDoctor: "" })
    }
    searchBy = (event) => {
        const { fromdate, todate } = this.state;
        let payload = {
            startDate: fromdate,
            endDate: todate
        };
        console.log(payload, 55555);

        this.fetchRecords(payload);

    }

    clearFilter = (event) => {
        this.setState({ fromdate: null, todate: null });
        this.fetchRecords();

    }
    goToVacutainerMapping = () => {
        let data = "vr";
        this.props.setActiveMenuItem(data);
        // let res = AdminAction.setActiveMenuItem(data));
        this.props.navigate('/admin/vacutainer-record');
    }

    render = () => {
        const { qrList, quantity, fromdate, todate } = this.state;
        let _data = this.setOrderHistoryData(qrList)
        console.log(_data, "aksdhklsah");
        return (
            <>
                <div className="layout-wrapper">
                    <AdminHeader />
                    <AdminSidebar />
                    <div className="main-content">
                        <div className="page-content">
                            <div className="container-fluid">
                                <div className="row g-4">
                                    <div className='col-md-12'>
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <h5 className='mb-20 headingstyle'>Generate QR Codes</h5>
                                            </div>
                                            {/* <div className='col-md-6 text-end'>
                                                <button className='btn btn-primary me-3' type='button' onClick={(event) => this.goToVacutainerMapping(event)}>Vacutainer Mapping</button>
                                            </div> */}
                                        </div>
                                    </div>


                                    {/* Row end */}
                                    <div className="row">
                                        <form className="mt-2 registration-form">
                                            <div className='row mb-3'>
                                                <div className="col-md-3 mb-3">
                                                    <label htmlFor="disabledTextInput" className="form-label lvl-clr-hd">Quantity <span className='requiredFld'> * </span></label>
                                                    <input type='number' className='form-control' onInput={(e) => e.target.value = e.target.value.slice(0, 4)} onChange={(event) => this.changeVal(event)} value={quantity} name='quantity' />
                                                    <p className='error'>*You can generate max 1000 QR Codes at a time.</p>
                                                    {/* {
                                                        doctorList.length ?
                                                            <DropdownDoctorRuntime
                                                                items={doctorList}
                                                                onSelect={(item) => {
                                                                    if (item) {
                                                                      
                                                                    }
                                                                }}
                                                                value={selectedDoctor}
                                                            /> :
                                                            <DropdownDoctorRuntime
                                                                items={doctorList}
                                                                value={selectedDoctor}
                                                            />
                                                    } */}
                                                    {/* <input type="text" value={state} onChange={(event) => this.changeAddr(event)} name="state" id className="form-control name-pt-ft" placeholder /> */}
                                                </div>
                                                <div className="col-md-1 mt-4">
                                                    <button className='btn btn-primary' type='button' onClick={(event) => this.generateQR(event)}>Generate</button>
                                                </div>

                                            </div>
                                            <div className='row'>
                                                {/* <div className='col-md-6'> */}
                                                <h5 className='mb-20 headingstyle'>QR Code Generation Records </h5>
                                                {/* </div> */}


                                                <div className="col-md-2 mb-3">
                                                    <label htmlFor className="form-label lvl-clr-hd">From Date</label>
                                                    {/* <input type="date" format="dd-mm-yyyy" value={fromdate} name="fromdate" onChange={(event) => this.changeVal(event)} id className="form-control name-pt-ft" placeholder /> */}
                                                    <DatePicker
                                                        selected={fromdate}
                                                        onChange={(date) => this.changeValFromDate(date)}
                                                        dateFormat="dd-MM-yyyy" // Set the desired display format
                                                        className="form-control name-pt-ft"
                                                        placeholderText="DD-MM-YYYY"
                                                    />
                                                </div>
                                                <div className="col-md-2 mb-3">
                                                    <label htmlFor className="form-label lvl-clr-hd">To Date</label>
                                                    {/* <input type="date" format="dd-mm-yyyy" min={this.state.fromdate} value={todate} name="todate" onChange={(event) => this.changeVal(event)} id className="form-control name-pt-ft" placeholder /> */}
                                                    <DatePicker
                                                        selected={todate}
                                                        onChange={(date) => this.changeValToDate(date)}
                                                        dateFormat="dd-MM-yyyy" // Set the desired display format
                                                        className="form-control name-pt-ft"
                                                        placeholderText="DD-MM-YYYY"
                                                        minDate={fromdate}
                                                    />
                                                </div>

                                                <div className="col-md-4 mt-4">
                                                    <button className='btn btn-primary' type='button' onClick={(event) => this.searchBy(event)}>Search</button>
                                                    <button className='btn btn-primary ms-2' type='button' onClick={(event) => this.clearFilter(event)}>Clear Filter</button>
                                                </div>

                                                <div className="table-responsive mt-4">


                                                    {
                                                        _data && _data.length ?
                                                            <CustomDatatable
                                                                pagination={true}
                                                                columns={[
                                                                    {
                                                                        name: 'QR Code Generation Date',
                                                                        // selector: row => row.mr_name,

                                                                    },
                                                                    {
                                                                        name: 'QR Code Generation Time',
                                                                        // selector: row => row.mr_name,

                                                                    },
                                                                    {
                                                                        name: 'QR Code Name',
                                                                        // selector: row => row.mr_name,

                                                                    },
                                                                    {
                                                                        name: 'Generated Quantity',
                                                                        // selector: row => row.mr_name,

                                                                    },

                                                                    {
                                                                        name: 'Download QR Code',
                                                                        // selector: row => row.createdAt,

                                                                    }
                                                                ]}
                                                                data={this.setOrderHistoryData(qrList)}
                                                                pageName="qrcodegeneration"
                                                                downloadQR={this.downloadQR}
                                                            />
                                                            : <p className="norecord"><strong>No Records Found</strong></p>
                                                    }
                                                </div>
                                            </div>

                                        </form>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <AdminFooter />
                    </div>
                </div>

            </>
        )

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        // dispatching plain actions
        setActiveMenuItem: (activeItem) => dispatch(AdminAction.setActiveMenuItem(activeItem))
    }
}

const mapStateToProps = (state) => {
    return {
        OrderReducer: state.OrderReducer,
        CustomerReducer: state.CustomerReducer,
        CommonReducer: state.CommonReducer,
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Common(QrCodeGeneration)))