import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from '../../../components/Reusable/WithRouter';
// import config from '../../../config/emrok.config';
import Common from '../../../hoc/Common.hoc';
// import axios from 'axios';
import moment from 'moment';
import AdminHeader from "../../../components/layout/Admin/AdminHeader";
import AdminSidebar from "../../../components/layout/Admin/AdminSidebar";
import AdminFooter from "../../../components/layout/Admin/AdminFooter";
import '../../../assets/css/admin/style.css';
import '../../../assets/css/admin/responsive.css';
// import DropdownDoctorRuntime from '../../../components/SearchableDropdown/DropdownDoctorRuntime.component';
import AdminService from '../../../services/Admin.service';
import CustomDatatable from "../../../components/Datatable/CustomDatatable.component";
// import CallCenterAction from '../../../redux/actions/CallCenter.action';
import AdminAction from '../../../redux/actions/Admin.action';
import { read, utils, write, writeFile } from 'xlsx';
import * as FileSaver from 'file-saver';
import DoctorService from '../../../services/Doctor.service';

class DoctorMasterDashboard extends Component {
    constructor(props) {
        super(props)

        this.state = {
            doctorList: [],
            excelRows: null,
            selectedFileName: []
        }

    }


    componentDidMount() {
        this.getDoctorDetails();
    }

    getDoctorDetails = async () => {
        try {
            this.setState({ doctorList: [] })

            let res = await AdminService.fetchDoctorList()
            console.log(res, 123333)
            if (res.data.success) {
                console.log(res.data.data.data, "lasjdhjkash");
                this.setState({ doctorList: res.data.data.data });
            }

        } catch (error) {

        }
    }

    handleEditDoctor = async (event) => {
        event.preventDefault();
        const { doctorList } = this.state;
        const doctor_id = event.target.id;
        const selectedDoctor = await doctorList.filter(item => item._id == doctor_id)
        console.log(selectedDoctor, "selectedDoctor");
        if (selectedDoctor.length) {
            let actionType = {
                type: "Edit"
            };

            // let order_details
            //  selectedPatientDetails[0].customer['order_id'] = selectedPatientDetails[0].order_i

            // console.log(this.props.callcenterdetails, "callcender reducer")
            // this.props.setCallCenter(callCenterDetails);
            this.props.setDoctorDetails(selectedDoctor[0])
            this.props.setActionType(actionType)
        } else {
            // this.props.setCustomer(selectedPatientDetails)
        }

        this.props.navigate('/admin/doctor-master/add');
    }

    setOrderHistoryData = (data) => {
        let resp = []
        for (let i = 0; i < data.length; i++) {
            resp.push({
                _id: data[i]._id,
                division_code: data[i].division_code,
                division: data[i].division,
                zone: data[i].zone,
                region: data[i].region,
                location: data[i].location,
                uid: data[i].uid,
                doctor_code: data[i].doctor_code,
                name: data[i] ? data[i].name : '',
                speciality: data[i].speciality,
                classification: data[i].classification,
                beat: data[i].beat,
                is_dispensing: data[i].is_dispensing,
                town_type: data[i].address.town_type,
                email: data[i].email,
                mobileno: data[i].mobileno,
                state: data[i].address.state,
                town: data[i].address.town,
                addressLine1: data[i].address.addressLine1,
                addressLine2: data[i].address.addressLine2,
                pin: data[i].address.pin,
                stockist_id: data[i].stockist.plant_id,
                stockist_name: data[i].stockist.name,
                stockist_mobileno: data[i].stockist.mobileno,
                stockist_email: data[i].stockist.email,
                clinic_id: data[i].clinic.clinic_id,
                clinic_name: data[i].clinic.name,
                clinic_address: data[i].clinic.address.address1,
                clinic_state: data[i].clinic.address.state,
                clinic_city: data[i].clinic.address.city,
                clinic_pincode: data[i].clinic.address.zip,
                clinic_mobileno: data[i].clinic.mobileno,
                clinic_email: data[i].clinic.email,
                bu_name: data[i].bu ? data[i].bu.name : '',
                bu_email: data[i].bu ? data[i].bu.email : '',
                bu_mobileno: data[i].bu ? data[i].bu.mobileno : '',
                nsm_name: data[i].nsm ? data[i].nsm.name : '',
                nsm_email: data[i].nsm ? data[i].nsm.email : '',
                nsm_mobileno: data[i].nsm ? data[i].nsm.mobileno : '',
                zm_name: data[i].zm ? data[i].zm.name : '',
                zm_email: data[i].zm ? data[i].zm.email : '',
                zm_mobileno: data[i].zm ? data[i].zm.mobileno : '',
                rm_name: data[i].rm ? data[i].rm.name : '',
                rm_email: data[i].rm ? data[i].rm.email : '',
                rm_mobileno: data[i].rm ? data[i].rm.mobileno : '',
                mr_name: data[i].mr.name,
                mr_email: data[i].mr.email,
                mr_mobileno: data[i].mr.mobileno,
                mr_code: data[i].mr.mr_code,

                createdDate: moment(data[i].createdAt).format("DD-MM-YYYY h:m a"),
                updatedDate: moment(data[i].updatedAt).format("DD-MM-YYYY h:m a"),


            })
        }
        return resp
    }
    handleAdd = () => {
        let actionType = {
            type: "Add"
        };
        this.props.setActionType(actionType)
        this.props.navigate('/admin/doctor-master/add');
    }

    bulkUpload = (event) => {
        // alert('bulk upload');
        this.setState({ excelRows: null });
        this.setState({ selectedFileName: "" });
        const files = event.target.files;

        console.log(files[0].name, 6666);

        if (files.length) {
            this.setState({ selectedFileName: files[0].name });
            const file = files[0];
            // this.setState({selectedFileName:files[0].name});
            const reader = new FileReader();
            reader.onload = (event) => {
                const wb = read(event.target.result);
                const sheets = wb.SheetNames;

                if (sheets.length) {
                    const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);

                    if (rows.length > 0) {
                        console.log(rows, 262626);
                        this.setState({ excelRows: rows });
                    } else {
                        this.props.hoc.customAlert('Uploaded excel sheet is empty.', false)
                    }

                    // setMovies(rows)
                }
            }
            reader.readAsArrayBuffer(file);
        }
    }

    // Utility function to convert s to array buffer
    s2ab = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
        return buf;
    }
    // Function to handle file download
    bulkExport = (event) => {
        event.preventDefault();

        const { doctorList } = this.state;

        // Create a new workbook
        const wb = utils.book_new();
        let excelRows = doctorList?.map((item) => {
            return {
                "Division Code": item.division_code,
                "Division": item.division,
                "Zone": item.zone,
                "Region": item.region,
                "Location": item.location,
                "UID": item.uid,
                "Doctor Code": item.doctor_code,
                "Doctor Name": item.name ? item.name : '',
                "Speciality": item.speciality,
                "Classification": item.classification,
                "Beat": item.beat,
                "Is Dispensing": item.is_dispensing,
                "Town Type": item?.address?.town_type || '',
                "Email ID": item.email,
                "Mobile": item.mobileno,
                "State": item?.address?.state || '',
                "Town": item?.address?.town || '',
                "Address Line 1": item?.address?.addressLine1 || '',
                "Address Line 2": item?.address?.addressLine2 || '',
                "Pin": item?.address?.pin || '',
                "Stockist ID": item?.stockist?.plant_id || '',
                "Stockist Name": item?.stockist?.name || '',
                "Stockist Mob No": item?.stockist?.mobileno || '',
                "Stockist Email": item?.stockist?.email || '',
                "Koshin Clinic ID": item?.clinic?.clinic_id || '',
                "Koshin Clinic Name": item?.clinic?.name || '',
                "Koshin Clinic Address": item?.clinic?.address?.address1 || '',
                "Koshin Clinic State": item?.clinic?.address?.state || '',
                "Koshin Clinic City": item?.clinic?.address?.city || '',
                "Koshin Clinic Pin Code": item?.clinic?.address?.zip || '',
                "Koshin Clinic Mobile": item?.clinic?.mobileno || '',
                "Koshin Clinic Email": item?.clinic?.email || '',
                "MR Code": item.mr ? item.mr.name : '',
                "MR Name": item.mr ? item.mr.email : '',
                "MR Mobile": item.mr ? item.mr.mobileno : '',
                "MR Email": item.mr ? item.mr.mr_code : '',
                "RM Name": item.rm ? item.rm.name : '',
                "RM Email": item.rm ? item.rm.email : '',
                "RM Mobile No": item.rm ? item.rm.mobileno : '',
                "ZM Name": item.zm ? item.zm.name : '',
                "ZM Email": item.zm ? item.zm.email : '',
                "ZM Mobile No": item.zm ? item.zm.mobileno : '',
                "NSM Name": item.nsm ? item.nsm.name : '',
                "NSM Email": item.nsm ? item.nsm.email : '',
                "NSM Mobile No": item.nsm ? item.nsm.mobileno : '',
                "BU Head Name": item.bu ? item.bu.name : '',
                "BU Head Email": item.bu ? item.bu.email : '',
                "BU Head Mobile No": item.bu ? item.bu.mobileno : '',
            };
        })

        console.log(excelRows)
        const ws = utils.json_to_sheet(excelRows);

        // Add the worksheet to the workbook
        utils.book_append_sheet(wb, ws, 'Sheet1');

        // Generate XLSX file and trigger download
        const wbout = write(wb, { bookType: 'xlsx', type: 'binary' });

        // Convert to Blob and save file
        const blob = new Blob([this.s2ab(wbout)], { type: 'application/octet-stream' });
        FileSaver.saveAs(blob, 'Doctor_Master_Export.xlsx');
    };

    handleSubmit = (event) => {
        const { excelRows } = this.state;
        let payload = {
            data: excelRows
        }
        console.log(payload, 666666);
        DoctorService.bulkUpload(payload).then(data => {
            console.log(data.data, "zip qr codes");
            if (data.data.success) {
                this.getDoctorDetails();
                this.setState({ selectedfilename: "" })
                this.props.hoc.customAlert("Successfully upload", true)
            }
        }).catch(err => {
            this.props.hoc.customAlert("Something went wrong.", false)
            console.log(err, "err")
        })
    }

    handleSubmitAddress = (event) => {
        const { excelRows } = this.state;
        let payload = {
            data: excelRows
        }
        console.log(payload, 66666656);
        // return
        DoctorService.bulkUploadAddress(payload).then(data => {
            console.log(data.data, "zip qr codes");
            if (data.data.success) {
                // this.getDoctorDetails();
                this.setState({ selectedfilename: "" })
                this.props.hoc.customAlert("Successfully upload", true)
            }
        }).catch(err => {
            this.props.hoc.customAlert("Something went wrong.", false)
            console.log(err, "err")
        })
    }

    downloadSampleFIle = () => {
        const link = document.createElement('a');
        link.href = require("../../../assets/sample_file/Doctor Master Template.xlsx");  // Replace with your file URL
        console.log(link.href);
        link.download = 'Doctor_Master_Template.xlsx';  // Specify the filename to be downloaded
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    downloadSampleFIleAddress = () => {
        const link = document.createElement('a');
        link.href = require("../../../assets/sample_file/Doctor Address Template.xlsx");  // Replace with your file URL
        console.log(link.href);
        link.download = 'Doctor_Address_Template.xlsx';  // Specify the filename to be downloaded
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }



    render = () => {
        const { doctorList, selectedFileName } = this.state;
        let _data = this.setOrderHistoryData(doctorList)
        console.log(_data, "aksdhklsah");
        return (
            <>
                <div className="layout-wrapper">
                    <AdminHeader />
                    <AdminSidebar />
                    <div className="main-content">
                        <div className="page-content">
                            <div className="container-fluid">
                                <div className="row g-4">
                                    <div className='col-md-6'>
                                        <h5 className='mb-20 headingstyle'>Doctor Master</h5>
                                    </div>
                                </div>

                                <div className='row mt-4'>

                                    <div className='col-md-10'>
                                        <div className='row'>
                                            <div className='col-md-2'>
                                                <div className="btn-home w-home-btn-doctor mb_ad_doc" onClick={this.handleAdd}>
                                                    <a href={void (0)} >
                                                        Add Single Doctor
                                                    </a>
                                                </div>
                                            </div>
                                            <div className='col-md-5 d-flex doctorbulkupload'>
                                                <div class="d-flex align-items-center position-relative ms-12">

                                                    <div class="upload-btn-wrapper">
                                                        <button class="btn-upload">Doctor Bulk Upload</button>
                                                        <input type="file" name="myfile" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" onChange={this.bulkUpload} />
                                                    </div>
                                                    <a href={void (0)} onClick={this.downloadSampleFIle} class="info-icon"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24"><path fill="currentColor" d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10s10-4.48 10-10S17.52 2 12 2m0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8s8 3.59 8 8s-3.59 8-8 8" /></svg></a>
                                                </div>
                                                <div className="btn-home flotright mx-2 file-add-btn" onClick={this.handleSubmit} >

                                                    <a href={void (0)} >
                                                        Submit
                                                    </a>
                                                </div>
                                            </div>
                                            <div className='col-md-5 d-flex addressbulkupload'>
                                                <div class="d-flex align-items-center position-relative ms-12">

                                                    <div class="upload-btn-wrapper">
                                                        <button class="btn-upload">Address Bulk Upload</button>
                                                        <input type="file" name="myfile" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" onChange={this.bulkUpload} />
                                                    </div>
                                                    <a href={void (0)} onClick={this.downloadSampleFIleAddress} class="info-icon"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24"><path fill="currentColor" d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10s10-4.48 10-10S17.52 2 12 2m0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8s8 3.59 8 8s-3.59 8-8 8" /></svg></a>
                                                </div>
                                                <div className="btn-home flotright mx-2 file-add-btn" onClick={this.handleSubmitAddress} >

                                                    <a href={void (0)} >
                                                        Submit
                                                    </a>
                                                </div>

                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                            {selectedFileName != "" ? <span className='selectedfilename'> Selected File: {selectedFileName} </span> : ""}
                                            </div>

                                        </div>

                                    </div>
                                    <div className='col-md-2'>
                                        <div class="upload-btn-wrapper bulk-export">
                                            <button class="btn-upload" style={{ paddingRight: 20, marginRight: 10 }} onClick={this.bulkExport}>Export</button>
                                        </div>
                                    </div>


                                    

                                    {/* <div><p>{{ selectedFileName }}</p></div> */}
                                </div>
                                <div className='row'>
                                    {/* Row end */}
                                    <div className="row g-4">

                                        <div className="table-responsive">


                                            {
                                                _data && _data.length ?
                                                    <CustomDatatable
                                                        pagination={true}
                                                        columns={[
                                                            {
                                                                name: 'Action',
                                                                // selector: row => row.createdAt,

                                                            },
                                                            {
                                                                name: 'Division Code',
                                                                // selector: row => row.mr_name,

                                                            },
                                                            {
                                                                name: 'Division',
                                                                // selector: row => row.mr_name,

                                                            },
                                                            {
                                                                name: 'Zone',
                                                                // selector: row => row.mr_name,

                                                            },
                                                            {
                                                                name: 'Region',
                                                                // selector: row => row.mr_name,

                                                            },
                                                            {
                                                                name: 'Location',
                                                                // selector: row => row.mr_name,

                                                            },
                                                            {
                                                                name: 'UID',
                                                                // selector: row => row.mr_name,

                                                            },
                                                            {
                                                                name: 'Doctor Code',
                                                                // selector: row => row.mr_name,

                                                            },
                                                            {
                                                                name: 'Doctor Name',
                                                                // selector: row => row.mr_name,

                                                            },
                                                            {
                                                                name: 'Speciality',
                                                                // selector: row => row.mr_name,

                                                            },
                                                            {
                                                                name: 'Classification',
                                                                // selector: row => row.mr_name,

                                                            },
                                                            {
                                                                name: 'Beat',
                                                                // selector: row => row.mr_name,

                                                            },
                                                            {
                                                                name: 'Is Dispensing',
                                                                // selector: row => row.mr_name,

                                                            },
                                                            {
                                                                name: 'Town Type',
                                                                // selector: row => row.mr_name,

                                                            },
                                                            {
                                                                name: 'Email ID',
                                                                // selector: row => row.mr_name,

                                                            },
                                                            {
                                                                name: 'Mobile',
                                                                // selector: row => row.mr_name,

                                                            },
                                                            {
                                                                name: 'State',
                                                                // selector: row => row.mr_name,

                                                            },
                                                            {
                                                                name: 'Town',
                                                                // selector: row => row.mr_name,

                                                            },
                                                            {
                                                                name: 'Address Line 1',
                                                                // selector: row => row.mr_name,

                                                            },
                                                            {
                                                                name: 'Address Line 2',
                                                                // selector: row => row.mr_name,

                                                            },
                                                            {
                                                                name: 'Pin',
                                                                // selector: row => row.mr_name,

                                                            },

                                                            {
                                                                name: 'Stockist ID',
                                                                // selector: row => row.mr_name,

                                                            },
                                                            {
                                                                name: 'Stockist Name',
                                                                // selector: row => row.mr_name,

                                                            },
                                                            {
                                                                name: 'Stockist Mob No',
                                                                // selector: row => row.mr_name,

                                                            },
                                                            {
                                                                name: 'Stockist Email',
                                                                // selector: row => row.mr_name,

                                                            },

                                                            {
                                                                name: 'Koshin Clinic ID',
                                                                // selector: row => row.mr_name,

                                                            },
                                                            {
                                                                name: 'Koshin Clinic Name',
                                                                // selector: row => row.mr_name,

                                                            },
                                                            {
                                                                name: 'Koshin Clinic Address',
                                                                // selector: row => row.mr_name,

                                                            },
                                                            {
                                                                name: 'Koshin Clinic State',
                                                                // selector: row => row.mr_name,

                                                            },
                                                            {
                                                                name: 'Koshin Clinic City',
                                                                // selector: row => row.mr_name,

                                                            },
                                                            {
                                                                name: 'Koshin Clinic Pin Code',
                                                                // selector: row => row.mr_name,

                                                            },
                                                            {
                                                                name: 'Koshin Clinic Mobile',
                                                                // selector: row => row.mr_name,

                                                            },
                                                            {
                                                                name: 'Koshin Clinic Email',
                                                                // selector: row => row.mr_name,

                                                            },
                                                            {
                                                                name: 'MR Code',
                                                                // selector: row => row.mr_name,

                                                            },
                                                            {
                                                                name: 'MR Name',
                                                                // selector: row => row.mr_name,

                                                            },
                                                            {
                                                                name: 'MR Mobile',
                                                                // selector: row => row.mr_name,

                                                            },
                                                            {
                                                                name: 'MR Email',
                                                                // selector: row => row.mr_name,

                                                            },
                                                            {
                                                                name: 'RM Name',
                                                                // selector: row => row.mr_name,

                                                            },
                                                            {
                                                                name: 'RM Email',
                                                                // selector: row => row.mr_name,

                                                            },
                                                            {
                                                                name: 'RM Mobile No',
                                                                // selector: row => row.mr_name,

                                                            },
                                                            {
                                                                name: 'ZM Name',
                                                                // selector: row => row.mr_name,

                                                            },
                                                            {
                                                                name: 'ZM Email',
                                                                // selector: row => row.mr_name,

                                                            },
                                                            {
                                                                name: 'ZM Mobile No',
                                                                // selector: row => row.mr_name,

                                                            },
                                                            {
                                                                name: 'NSM Name',
                                                                // selector: row => row.mr_name,

                                                            },
                                                            {
                                                                name: 'NSM Email',
                                                                // selector: row => row.mr_name,

                                                            },
                                                            {
                                                                name: 'NSM Mobile No',
                                                                // selector: row => row.mr_name,

                                                            },
                                                            {
                                                                name: 'BU Head Name',
                                                                // selector: row => row.mr_name,

                                                            },
                                                            {
                                                                name: 'BU Head Email',
                                                                // selector: row => row.mr_name,

                                                            },
                                                            {
                                                                name: 'BU Head Mobile No',
                                                                // selector: row => row.mr_name,

                                                            },

                                                            {
                                                                name: 'Created On',
                                                                // selector: row => row.mr_name,

                                                            },
                                                            {
                                                                name: 'Updated On',
                                                                // selector: row => row.mr_name,

                                                            },

                                                        ]}
                                                        data={this.setOrderHistoryData(doctorList)}
                                                        pageName="doctormaster"
                                                        handleEditDoctor={this.handleEditDoctor}



                                                    /> : <p className="norecord"><strong>No Records Found</strong></p>

                                            }
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>

                        <AdminFooter />
                    </div>
                </div>

            </>
        )

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        // dispatching plain actions
        setDoctorDetails: (doctorDetails) => dispatch(AdminAction.setDoctorDetails(doctorDetails)),
        setActionType: (actiontype) => dispatch(AdminAction.setActionType(actiontype))
    }
}

const mapStateToProps = (state) => {
    return {
        OrderReducer: state.OrderReducer,
        CustomerReducer: state.CustomerReducer,
        CommonReducer: state.CommonReducer,
        CallCenterReducer: state.CallCenterReducer,
        AdminReducer: state.AdminReducer
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Common(DoctorMasterDashboard)))