
export const SET_ADMIN = "SET_ADMIN";
export const CLEAR_STATE = "CLEAR_COMMON_STATE";
export const SET_ACTIVE_MENU = "SET_ACTIVE_MENU"
export const STORE_SEARCH_VALUE = "STORE_SEARCH_VALUE"
export const STORE_DOCTOR_VALUE = "STORE_DOCTOR_VALUE"
export const SET_ACTION_TYPE = "SET_ACTION_TYPE"
export const SET_DELIVERY_PERSON = "SET_DELIVERY_PERSON"





