import React from "react";
import { withRouter } from "../../../components/Reusable/WithRouter";
import { connect } from "react-redux";
import Common from "../../../hoc/Common.hoc";
import CustomerFooter from "../../../components/layout/CustomerFooter";
import MrHeader from "../../../components/layout/MR/MrHeader";
import MrAction from "../../../redux/actions/Mr.action";
import OrderAction from "../../../redux/actions/Order.action";
import MrService from "../../../services/Mr.service";
import OtherService from "../../../services/OtherService";
import CountryService from '../../../services/Country.service';
import Dropdown from '../../../components/SearchableDropdown/Dropdown.component';
import { format } from 'date-fns';
import $ from "jquery";
import CustomDatatable from "../../../components/Datatable/CustomDatatable.component";
import moment from "moment";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; // Import the CSS
import CommonAction from "../../../redux/actions/Common.action";
import DropdownDoctor from "../../../components/SearchableDropdown/DropdownDoctor.component";
import Summary from "../../../components/Summary/Summary.component";
import CallCenterService from "../../../services/CallCenter.service";
import { ExportToExcel } from "../../../components/Excel/ExportToExcel";
import OrderService from "../../../services/Order.service";
class MrDetails extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      mrdetails: null,
      mrid: "",
      mrdetailsList: [],
      mrinfo: "",
      countries: [],
      states: [],
      towns: [],
      showTowns: false,
      selectedState: "",
      selectedTown: "",
      selectedDoctor: "",
      fromdate: "",
      todate: "",
      currentDate: this.getCurrentDate(),
      doctorList: [],
      summaryCols: [
        "Total Orders",
        "Orders Under Process",
        "Orders Pending",
        "Sample Received at Koshin Clinic",
        "XO Face Serum received By doctor",
      ],
      summaryDetails: null,
      setorderStatus: "",
      exportData: null,
      fileName: "Order_Details",
      cityTAT: [],
      orders: []
    }
  }



  static getDerivedStateFromProps(props, state) {
    return {
      mrdetails: props._id || null,
      summaryDetails: props.summaryDetails || null,
    }
  }

  getCurrentDate() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(currentDate.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  getSummary = async (mr_id = null) => {
    const {
      fromdate,
      todate,
      selectedState,
      selectedTown,
      selectedDoctor,
      setorderStatus,
    } = this.state;
    const mrdetails = this.props.mrdetails;
    const admin_id = mrdetails._id;
    const selectedOnlyDateFrom = fromdate
      ? moment(fromdate).format("YYYY-MM-DD")
      : "";
    const selectedOnlyDateTo = todate
      ? moment(todate).format("YYYY-MM-DD")
      : "";
    let payload = {
      type: "MR",
      id: mr_id,
      fromDate: fromdate ? selectedOnlyDateFrom : "",
      toDate: todate ? selectedOnlyDateTo : "",
      stateName: selectedState,
      town: selectedTown,
      doctor_id: selectedDoctor,
      orderStatus: setorderStatus,
    };

    console.log(payload, "payload");
    OtherService.getSummary(payload)
      .then((data) => {
        console.log(data, "getSummary");
      })
      .catch((err) => {
        console.log(err, "getSummary err");
      });
  };

  clickFn = async (index) => {
    console.log(index, "index");
    const callcenterdetails = this.props.mrdetails;
    let payload = {};
    let orderstatus = "";
    if (callcenterdetails) {
      // let payload = await {

      // }

      const {
        fromdate,
        todate,
        selectedState,
        selectedTown,
        selectedDoctor,
        setorderStatus,
      } = this.state;
      const selectedOnlyDateFrom = fromdate
        ? moment(fromdate).format("YYYY-MM-DD")
        : "";
      const selectedOnlyDateTo = todate
        ? moment(todate).format("YYYY-MM-DD")
        : "";

      if (index === 0) {
        payload = {
          fromDate: fromdate ? selectedOnlyDateFrom : "",
          toDate: todate ? selectedOnlyDateTo : "",
          stateName: selectedState,
          town: selectedTown,
          doctor_id: selectedDoctor,
          orderStatus: setorderStatus,
          mr_id: callcenterdetails._id
        };
      } else {
        payload = {
          fromDate: fromdate ? selectedOnlyDateFrom : "",
          toDate: todate ? selectedOnlyDateTo : "",
          stateName: selectedState,
          town: selectedTown,
          doctor_id: selectedDoctor,
          orderStatus: setorderStatus,
          mr_id: callcenterdetails._id
        };
      }

      if (index === 0) {
        payload["orderStatus"] = "";
        orderstatus = "";
        this.setState({ setorderStatus: "" });
      } else if (index === 1) {
        payload["orderStatus"] = await "underprocess";
        orderstatus = "underprocess";
        this.setState({ setorderStatus: "underprocess" });
      } else if (index === 2) {
        payload["orderStatus"] = await "pending";
        orderstatus = "pending";
        this.setState({ setorderStatus: "pending" });
      } else if (index === 3) {
        payload["orderStatus"] = await "received_at_clinic";
        orderstatus = "received_at_clinic";
        this.setState({ setorderStatus: "received_at_clinic" });
      } else if (index === 4) {
        payload["orderStatus"] = await "delivered";
        orderstatus = "delivered";
        this.setState({ setorderStatus: "delivered" });
      } else if (index === 5) {
        payload["orderStatus"] = await "completed";
        orderstatus = "completed";
        this.setState({ setorderStatus: "completed" });
      }
      // else if (index === 2) {
      //     payload["orderStatus"] = await "underprocess"
      //     orderstatus = "underprocess"
      //     this.setState({ setorderStatus: "underprocess" })
      // }

      // else if (index === 4) {
      //     payload["orderStatus"] = await "Pickup Initiated"
      //     orderstatus = "Pickup Initiated"
      //     this.setState({ setorderStatus: "Pickup Initiated" })
      // }
      // else if (index === 5) {
      //     payload["deliveryStatus"] = await "delivered"
      //     orderstatus = "delivered"
      //     this.setState({ setorderStatus: "delivered" })
      // }

      //   const storeSearchData = this.state;
      //   storeSearchData.setorderStatus = orderstatus;
      //   storeSearchData.callcenterdetailsList = [];
      //   this.props.setSearchValue(storeSearchData);

      console.log(payload, 222222);
      this.getOrderList(payload);
    }
  };

  formatData(auditdata) {
    console.log(auditdata, auditdata.length, "auditdata");

    let formatedArr = [];
    let allfeedback = [];
    let feedbackString = "";
    for (let i = 0; i < auditdata.length; i++) {
      formatedArr.push({
        "Order No": auditdata[i].order.order_no,
        "Order Date": auditdata[i].order
          ? moment(auditdata[i].order.createdAt).format("DD-MM-YYYY")
          : "",
        "Customer Name": auditdata[i].patient ? auditdata[i].patient?.name : "",
        "Customer Mobile No.": auditdata[i].patient
          ? auditdata[i].patient?.mobileno
          : "",

        "Doctor Name": auditdata[i].doctor ? auditdata[i].doctor?.name : "",
        "Stockist Name": auditdata[i].stockist
          ? auditdata[i].stockist[0]?.name
          : "",
        "Koshin Clinic": auditdata[i].clinic
          ? auditdata[i].clinic?.name
          : "",
        //   "TM Name": auditdata[i].mr ? auditdata[i].mr?.name : "",


        Status: auditdata[i].order ? auditdata[i].order.orderStatus : "",
      });
    }
    return formatedArr;
  }

  getOrderList = (payload) => {
    this.setState({ orders: [] });
    this.setState({ exportData: null });

    OrderService.getAllOrders(payload)
      .then(async (data) => {
        console.log(data, 10000);
        if (data.data.success) {
          console.log(data.data.data.order, 6666666);

          this.setState({ orders: data.data.data.order });

          const formatedData = await this.formatData(data.data.data.order);
          console.log(formatedData, "formatedData");
          this.setState({ exportData: formatedData });

          // this.getDoctors(data.data.data.order);
        }
      })
      .catch((err) => { });
  };

  getmrDetails = (details) => {
    this.setState({ mrdetailsList: [] }, () => {
      MrService.getmrDetails(details).then(async data => {
        console.log(data.data, "mr details 2");

        if (data.data.success && data.data.data.mrdetails !== '') {
          console.log(data.data, "okkkkk");

          // const doctors = data.data.data.mrdetails.map(item => item.doctor);
          // console.log(doctors, "alldoctors list");

          const formatedData = await this.formatData(data.data.data.mrdetails);
          console.log(formatedData, "formatedData")
          this.setState({ exportData: formatedData });
          this.setState({ mrdetailsList: data.data.data.mrdetails });

          // this.setState({ doctorList: doctors });
        }



        // this.setState({mrdetailsList:data.data.data.mrdetails});

      }).catch(err => {

        console.log(err, "err")
      })
    });
  }

  componentDidMount() {

    const mrdetails = this.props.mrdetails;
    if (mrdetails) {
      const mr_id = mrdetails._id;
      let details = {
        mr_id: mr_id
      };
      console.log(details, "mr details 1",);


      MrService.getmrInfo(details).then(data => {
        console.log(data.data, "mr info");

        if (data.data.success && data.data.data.mrinfo !== '') {
          console.log(data.data.data.mrinfo[0], "okkkkk");

          this.setState({ mrinfo: data.data.data.mrinfo[0] });
        }



        // this.setState({mrdetailsList:data.data.data.mrdetails});

      }).catch(err => {

        console.log(err, "err")
      })


      this.getOrderList(details)
      this.getSummary(mr_id)
      // this.getCityTAT();
      let detailsBody = {
        id: mr_id,
        type: "mr",
      };
      this.allDoctors(detailsBody);

      setTimeout(() => {
        this.populateStates("IN")
        // this.populateStates(countryObj.isoCode)	
      }, 500);
    } else {


    }



  }

  allDoctors = (detailsBody) => {
    MrService.getDoctorsByMr(detailsBody).then(data => {
      console.log(data.data, "doctordetailsbymr123");

      if (data.data.success && data.data.data.doctorinfo !== '') {
        console.log(data.data.data.doctorinfo, "doctorokkk1233");

        // const doctors = data.data.data.mrdetails.map(item => item.doctor);
        // console.log(doctors, "alldoctors list");

        // this.setState({ mrdetailsList: data.data.data.mrdetails });
        this.getDoctors(data.data.data.doctorinfo);
        // this.setState({ doctorList: data.data.data.doctorinfo });
      }



      // this.setState({mrdetailsList:data.data.data.mrdetails});

    }).catch(err => {

      console.log(err, "err")
    })
  }

  populateStates = (isoCode = null) => {
    if (isoCode) {
      CountryService.getStates(isoCode).then(data => {
        //console.log(data, "states")
        if (data.success) {
          this.setState({ states: data.states, towns: [] }, async () => {
            this.setState({ showTowns: false })
            let _state = this.state.selectedState || null
            console.log(_state, this.state.states, "jhgjhgjhg");
            if (_state) {
              let stateObj = null
              for (let i = 0; i < this.state.states.length; i++) {
                if (this.state.states[i].name === _state) {
                  stateObj = this.state.states[i]
                }
              }
              console.log(stateObj, _state, 1234545677);
              console.log("stateObj found", stateObj)
              // if (stateObj) {
              setTimeout(() => {
                this.populateTowns(stateObj.countryCode, stateObj.isoCode)
              }, 500);
              // }
            }
          })

          setTimeout(() => {
            let stateDetails = this.state.states;
            const allOption = {
              "name": "All",
              "isoCode": null,
              "countryCode": null,
              "latitude": null,
              "longitude": null,
              "label": "All",
              "value": "all"
            };
            console.log(this.state.states, "details state")
            // Create a new array with the "All" option at the beginning
            let optionsWithAll = [allOption, ...stateDetails];
            console.log(optionsWithAll, "details state all")
            this.setState({ states: optionsWithAll })
          }, 500);
        }
      })
    }
  }

  populateTowns = (countryCode = null, stateCode = null) => {
    console.log(countryCode, stateCode, "countryCode stateCode")
    if (countryCode && stateCode) {
      CountryService.getTowns(countryCode, stateCode).then(data => {
        console.log(data, "towns")
        // setTimeout(() => {
        if (data.success) {

          this.setState({ towns: data.towns }, () => this.setState({ showTowns: true }))

          setTimeout(() => {
            let stateDetails = this.state.towns;
            const allOption1 = {
              "name": "All",
              "isoCode": null,
              "countryCode": null,
              "latitude": null,
              "longitude": null,
              "label": "All",
              "value": "all"
            };
            console.log(this.state.towns, "details state")
            // Create a new array with the "All" option at the beginning
            let optionsWithAll1 = [allOption1, ...stateDetails];
            console.log(optionsWithAll1, "details state all")
            this.setState({ towns: optionsWithAll1 })
          }, 500);
        }
        // }, 2000);
      })
    }
  }

  setOrderHistoryData = (data) => {
    let resp = [];
    // console.log(data[0],66666)
    for (let i = 0; i < data.length; i++) {
      resp.push({
        _id: data[i]._id,
        order_no: data[i].order.order_no,
        order_date: data[i].order.createdAt
          ? moment(data[i].order.createdAt).format("DD-MM-YYYY")
          : "",
        order_status: data[i].order.orderStatus
          ? data[i].order.orderStatus.toUpperCase()
          : "",
        stockist_name: data[i].stockist[0].name,
        patient_name: data[i].patient.name,
        patient_mobile: data[i].patient.mobileno,
        vacutainer_code: data[i].unique_qr_id,
        serial_no: data[i].serial_no ? "WH" + data[i].serial_no : "",
        mr_name: data[i].mr.name,
        doctor_naame: data[i].doctor.name,
        clinic_name: data[i].clinic.name
      });
    }
    return resp;
  };

  componentWillUnmount() {
    if (this.dataTable) {
      this.dataTable.destroy(true);
    }
  }

  getDoctors = (doctors) => {
    // Create an array to store the grouped data
    const groupedData = [];
    const finalData = [];

    // Create an object to keep track of seen doctor._id values
    const seenDoctorIds = {};

    // Use forEach to iterate through the data
    doctors.forEach((currentValue) => {
      const doctorId = currentValue._id;

      // Check if we've already seen this doctor._id
      if (!seenDoctorIds[doctorId]) {
        // Add the current value to the groupedData array
        groupedData.push(currentValue);

        // Mark the doctor._id as seen
        seenDoctorIds[doctorId] = true;
      }
    });

    for (let index = 0; index < doctors.length; index++) {
      finalData[index] = {
        "name": groupedData[index].name,
        "label": groupedData[index].name,
        "value": groupedData[index]._id,
        "id": groupedData[index]._id,
      };


    }

    const allOption = {
      "name": "All",
      "label": "All",
      "value": "All",
      "id": "All"
    };

    let optionsWithAll = [allOption, ...finalData];


    console.log(finalData, "finalData");
    // Now 'groupedData' contains unique doctors with a regular index

    this.setState({ doctorList: optionsWithAll });

    console.log(groupedData, "groupedData")

    // Now 'groupedData' contains the data grouped by doctor._id


  }

  handleTrackOrder = (event) => {
    // const allOrder = this.props.OrderReducer.allOrders;
    const targetId = event.target.name;
    console.log(targetId, "target ID");
    const { orders } = this.state;
    console.log(orders, 7777);
    try {
      const selectedOrder = orders.filter((item) => item._id == targetId);
      console.log(selectedOrder, "selectedOrder");
      this.props.saveSelectedOrders(selectedOrder);
      this.props.navigate("/mr/track-info-order");
      this.props.setUserType("mr");

      // OtherService.getOrderDetails({ _id: targetId }).then(data => {
      //     console.log(data.data, "order details 123456789");
      //     if (data.data.success) {
      //         console.log(data.data.data.order, "okkkkk 123456789");
      //         this.props.saveSelectedOrders(data.data.data.order)
      //         this.props.navigate('/patient/track-info-order');
      //         this.props.setUserType('patient');
      //         // this.setState({mrdetailsList:data.data.data.mrdetails});
      //     }
      // })
    } catch (error) { }
  };

  changeValFromDate = async (date) => {

    const formatedDate = moment(date).format('DD-MM-YYYY');
    console.log(date, "fromdate");
    this.setState({ fromdate: date });
    this.setState({ todate: "" });


  }
  changeValToDate = async (date) => {

    const formatedDate = moment(date).format('DD-MM-YYYY');
    console.log(date, "todate");
    this.setState({ todate: date });

  }

  searchFilter = (event) => {
    this.setState({ mrdetailsList: [] });
    const mrdetails = this.props.mrdetails;
    const mr_id = mrdetails._id;
    const { fromdate, todate, selectedState, selectedTown, selectedDoctor, setorderStatus } = this.state;

    const selectedMomentFrom = moment(fromdate);
    const selectedOnlyDateFrom = selectedMomentFrom.format('YYYY-MM-DD');
    // Calculate the next day using moment
    const nextDayMomentFrom = selectedMomentFrom.clone().add(1, 'day');
    const nextDayOnlyDateFrom = nextDayMomentFrom.format('YYYY-MM-DD');

    const selectedMomentTo = moment(todate);
    const selectedOnlyDateTo = selectedMomentTo.format('YYYY-MM-DD');
    // Calculate the next day using moment
    const nextDayMomentTo = selectedMomentTo.clone().add(1, 'day');
    const nextDayOnlyDateTo = nextDayMomentTo.format('YYYY-MM-DD');


    let details = {
      mr_id: mr_id,
      fromDate: fromdate ? selectedOnlyDateFrom : "",
      toDate: todate ? selectedOnlyDateTo : "",
      stateName: selectedState,
      town: selectedTown,
      doctor_id: selectedDoctor,
      orderStatus: setorderStatus
    };

    if (fromdate != "" && todate == "") {
      this.props.hoc.customAlert("Please select both fromdate and todate", false);
    } else if (fromdate == "" && todate != "") {
      this.props.hoc.customAlert("Please select both fromdate and todate", false);
    } else {

      console.log(details, "details 1234455")
      // MrService.getmrDetails(details).then(data => {
      //     console.log(data.data, "mr details after filter");

      //     if (data.data.success && data.data.data.mrdetails !== '') {
      //         console.log(data.data, "data after filter");

      //         this.setState({ mrdetailsList: data.data.data.mrdetails });
      //         const formatedData = this.formatData(data.data.data.mrdetails);
      //         console.log(formatedData, "formatedData")
      //         this.setState({ exportData: formatedData });
      //     }

      this.getOrderList(details);
      this.getSummary(mr_id)

      // }).catch(err => {

      //     console.log(err, "err")
      // })


    }




    console.log("search filter");
  }

  changeDoctor = (event) => {
    const doctorId = event.target.value;
    this.setState({ selectedDoctor: doctorId });
    console.log(doctorId, "doctorId");
  }


  handleRTOAction = (event) => {
    const callCenterDetails = this.props.mrdetails;
    const order_id = event.target.name;
    const selectedOrderDetails = callCenterDetails.filter(item => item.order_id == order_id)

    let details = {

      order_id: order_id,
      user_id: selectedOrderDetails[0]._id,
      customer_id: selectedOrderDetails[0].customer._id,
      user_type: "mr"
    }

    this.props.hoc.customAlert('Are you sure you want to proceed?', true, "", true, true, () => {

      CallCenterService.getInitiateRTO(details).then(data => {

        const mrdetails = this.props.mrdetails;

        const mr_id = mrdetails._id;
        let details = {
          mr_id: mr_id
        };


        this.getmrDetails(details)

        console.log(data, "alldata");
        // this.setState({mrdetailsList:data.data.data.mrdetails});

      }).catch(err => {

        console.log(err, "err")
      })

      console.log(selectedOrderDetails, "selectedOrderDetails")

    }, () => {

    })




    // alert(order_id);
  }

  clearFilter = () => {

    const mrdetails = this.props.mrdetails;

    const mr_id = mrdetails._id;
    console.log(mr_id, 55555);

    let details = {
      mr_id: mr_id
    };
    this.setState({ selectedState: "", selectedTown: "", fromdate: "", todate: "", selectedDoctor: "" })




    let detailsBody = {
      id: mr_id,
      type: "mr",
    };
    this.allDoctors(detailsBody);
    this.getOrderList(details)

    setTimeout(() => {
      this.getSummary(mr_id)
      // this.populateStates(countryObj.isoCode)	
    }, 300);


  }

  render = () => {

    const { countries, states, towns, isLoading, showTowns, selectedState, selectedTown, fromdate, todate, selectedDoctor, doctorList, summaryCols, summaryDetails, exportData, fileName, orders } = this.state;
    let _data = this.setOrderHistoryData(orders)
    // const formatFromDate = moment(fromdate).format('DD-MM-YYYY');
    // const formatToDate = moment(todate).format('DD-MM-YYYY');
    console.log(_data, "mrdetailsListsfsd");
    return (

      <>

        <section className="landing-page-pt landing-profl-detls">
          <div className="container">
            <MrHeader />
            <div className="mr-landing-page mt-20">
              <div className="landing-head-pt">
                {/* <h2>MR Landing page</h2> */}
              </div>
            </div>
            <div className="landing-form-dtls mt-10">


              <div className="row">
                <div className="col-md-6">
                  <h5>Welcome {this.state.mrinfo.name} to XO Application (MR)</h5>
                </div>

                <div className="col-md-6">

                  <div className="flotright marginright">
                    <ExportToExcel apiData={exportData} fileName={fileName} />
                  </div>
                </div>

              </div>

              <div className="row mt-30 mb-20 ">

                <div className="col-md-2 mb-3">
                  <label htmlFor className="form-label lvl-clr-hd">Order Date From</label>
                  {/* <input type="date" format="dd-mm-yyyy" value={fromdate} name="fromdate" onChange={(event) => this.changeVal(event)} id className="form-control name-pt-ft" placeholder /> */}
                  <DatePicker
                    selected={fromdate}
                    onChange={(date) => this.changeValFromDate(date)}
                    dateFormat="dd-MM-yyyy" // Set the desired display format
                    className="form-control name-pt-ft"
                    placeholderText="DD-MM-YYYY"
                  />
                </div>
                <div className="col-md-2 mb-3">
                  <label htmlFor className="form-label lvl-clr-hd">Order Date To</label>
                  {/* <input type="date" format="dd-mm-yyyy" min={this.state.fromdate} value={todate} name="todate" onChange={(event) => this.changeVal(event)} id className="form-control name-pt-ft" placeholder /> */}
                  <DatePicker
                    selected={todate}
                    onChange={(date) => this.changeValToDate(date)}
                    dateFormat="dd-MM-yyyy" // Set the desired display format
                    className="form-control name-pt-ft"
                    placeholderText="DD-MM-YYYY"
                    minDate={this.state.fromdate}
                  />
                </div>
                <div className="col-md-2 mb-3">
                  <label htmlFor="disabledTextInput" className="form-label lvl-clr-hd">State </label>
                  {
                    states.length ?
                      <Dropdown
                        items={states}
                        onSelect={(item) => {
                          if (item) {
                            // let _registration = this.state.registration
                            // _registration.address["state"] = item.name
                            // _registration.address["town"] = ""
                            this.setState({ selectedState: item.name })
                            //console.log(item, "item");
                            if (item.name != "All") {
                              this.populateTowns(item.countryCode, item.isoCode)
                            } else {
                              this.setState({ towns: [] })
                              this.setState({ selectedTown: "" })
                            }
                          }
                        }}
                        value={selectedState}
                      /> :
                      <Dropdown
                        items={states}
                        value={selectedState}
                      />
                  }
                  {/* <input type="text" value={state} onChange={(event) => this.changeAddr(event)} name="state" id className="form-control name-pt-ft" placeholder /> */}
                </div>
                <div className="col-md-2 mb-3">
                  <label htmlFor className="form-label lvl-clr-hd">City/Town </label>
                  {
                    towns.length ?
                      <Dropdown
                        items={towns}
                        onSelect={(item) => {
                          if (item) {
                            // alert(item.name);
                            // let _registration = this.state.registration
                            // _registration.address["town"] = item.name
                            this.setState({ selectedTown: item.name })
                            //console.log(item, "item")
                          }
                        }}
                        value={selectedTown}
                      /> :
                      <Dropdown
                        items={towns}
                        value={selectedTown}
                      />
                  }
                  {/* <input type="text" value={town} onChange={(event) => this.changeAddr(event)} name="town" id className="form-control name-pt-ft" placeholder /> */}
                </div>
                <div className="col-md-2 mb-3">
                  <label htmlFor className="form-label lvl-clr-hd">Doctors </label>
                  {/* <select name="doctorname" className="form-control name-pt-ft" onChange={(event) => this.changeDoctor(event)} value={selectedDoctor}>
                                        <option value="">Please Select</option>
                                        {doctorList.length > 0 ? doctorList.map(doctor => (
                                            <option value={doctor._id}>{doctor.name}</option>

                                        )) : <option></option>}

                                    </select> */}

                  {
                    doctorList.length ?
                      <DropdownDoctor
                        items={doctorList}
                        onSelect={(item) => {
                          if (item) {
                            // alert(item.name);
                            // let _registration = this.state.registration
                            // _registration.address["town"] = item.name
                            this.setState({ selectedDoctor: item.id })
                            //console.log(item, "item")
                          }
                        }}
                        value={selectedDoctor}
                      /> :
                      <DropdownDoctor
                        items={doctorList}
                        value={selectedDoctor}
                      />
                  }

                </div>
                <div className="">
                  <button type="button" className="landing-success-btn" onClick={(event) => this.searchFilter(event)}>
                    Search</button>
                  <button type="button" className="landing-clear-btn" onClick={(event) => this.clearFilter()}>
                    Clear Filter</button>
                </div>

              </div>
              <Summary
                columns={summaryCols}
                data={[
                  {
                    totalOrderCount: summaryDetails
                      ? summaryDetails.totalOrderCount || 0
                      : 0,
                    totalOrderUnderProcessCount: summaryDetails
                      ? summaryDetails.totalOrderUnderProcessCount || 0
                      : 0,
                    totalOrderPending: summaryDetails
                      ? summaryDetails.totalOrderPendingCount || 0
                      : 0,
                    totalOrderReceivedAtKoshinClinic: summaryDetails
                      ? summaryDetails.totalOrderReceivedAtKoshinClinicCount ||
                      0
                      : 0,
                    totalOrderDelivered: summaryDetails
                      ? summaryDetails.totalOrderDeliveredCount || 0
                      : 0,
                    // totalOrderCompleted: summaryDetails
                    //   ? summaryDetails.totalOrderCompletedCount || 0
                    //   : 0,
                    // totalRevenue: totalRevenue ? '₹' + totalRevenue || 0 : 0,
                  },
                ]}
                clickFn={this.clickFn}
              />

              <div className="table-responsive">

                {
                  _data && _data.length ?
                    <CustomDatatable
                      pagination={true}
                      columns={[
                        {
                          name: "Order No",
                          // selector: row => row.mr_name,
                        },
                        {
                          name: "Order Date",
                          // selector: row => row.doctor_name,
                        },
                        {
                          name: "Customer Name",
                          // selector: row => row.mr_name,
                        },
                        {
                          name: "Customer Mobile No.",
                          // selector: row => row.mr_name,
                        },

                        {
                          name: "Doctor Name",
                          // selector: row => row.mr_name,
                        },
                        {
                          name: "Stockist Name",
                          // selector: row => row.mr_name,
                        },
                        {
                          name: "Koshin Clinic",
                          // selector: row => row.doctor_name,
                        },
                        // {
                        //   name: "TM Name",
                        //   // selector: row => row.mr_name,
                        // },

                        {
                          name: "Status",
                          // selector: row => row.createdAt,
                        },
                        {
                          name: "Track Order",
                          // selector: row => row.createdAt,
                        },
                      ]}
                      data={this.setOrderHistoryData(orders)}
                      handleTrackOrder={this.handleTrackOrder}
                      handleRTOAction={this.handleRTOAction}
                      pageName="mrlanding"
                    />
                    : null
                }
              </div>

            </div>

            <CustomerFooter />
          </div>
        </section>


      </>

    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setUserType: (userType) => dispatch(CommonAction.setUserType(userType)),
    // dispatching plain actions
    saveSelectedOrders: (selectedOrders) => dispatch(OrderAction.saveSelectedOrders(selectedOrders))
  }
}

const mapStateToProps = ({ CustomerReducer, OrderReducer, MrReducer, CommonReducer }) => {
  const { customer } = CustomerReducer
  const { orders } = OrderReducer
  const { mrdetails } = MrReducer
  const { userType, summaryDetails } = CommonReducer
  console.log(customer, 123);
  console.log(mrdetails, 123456);
  return {
    // dispatching plain actions
    // OrderListReducer: state.OrderListReducer,
    customer,
    orders,
    mrdetails,
    userType,
    summaryDetails
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Common(MrDetails)))