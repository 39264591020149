import React from 'react'
import { BrowserRouter, Outlet, Route, Routes, useRoutes } from 'react-router-dom'
import Login from '../screens/Login/Login.screen';
import Registration from '../screens/Customer/Registration/Registration';
import RegistrationScreenCallCenter from '../screens/CallCenter/List/Registration';
import PageNotFound from '../screens/PageNotFound/PageNotFound.screen';
import OrderPlaced from '../screens/Order/OrderPlaced/OrderPlaced';
import PlaceOrderModal from '../screens/Order/PlaceOrderModal/PlaceOrderModal';
import PlaceOrder from '../screens/Order/PlaceOrder/PlaceOrder';
import { loginGuard, customerLoginGuard, mrLoginGuard, doctorLoginGuard, stockistLoginGuard } from '../middleware/guard';
import OrderInformation from '../screens/Order/OrderInformation/OrderInformation';
import {
  useGuardedRoutes,
  GuardConfigProvider,
  GuardProvider
} from 'react-router-guarded-routes'
import FeedbackScreen from '../screens/Feedback/Feedback.screen';
import FeedbackSubmitScreen from '../screens/Feedback/FeedbackSubmit.screen';
import OrderHistory from '../screens/Order/OrderHistory/OrderHistory.screen';
import OrderHistoryTracking from '../screens/Order/OrderHistory/OrderHistoryTracking.screen';
import TrackOrder from '../screens/Order/TrackOrder/TrackOrder';
import OrderHistoryNew from '../screens/Order/OrderHistory/OrderHistoryNew';
import LoginMR from '../screens/MR/Login/Login.screen';
import MrDetailsScreen from '../screens/MR/List/MrDetails.screen';
import DoctorDetailsScreen from '../screens/Doctor/List/DoctorDetails.screen'
import DoctorLandingScreen from '../screens/Doctor/List/DoctorLanding.screen'


import LoginDoctor from '../screens/Doctor/Login/LoginDoctor.screen';
import TrackOrderIndividual from '../screens/Order/TrackOrder/TrackOrderIndividual';
import TrackingOrder from '../screens/Order/TrackOrder/TrackingOrder';
import LoginBaseScreen from '../screens/Login/LoginBase.screen';
import LoginCustomertrackingScreen from '../screens/Login/LoginCustomerTracking.screen';
import StockistListScreen from '../screens/Stockist/List/StockistList.screen'
import CallCenterListScreen from '../screens/CallCenter/List/CallCenterList.screen'
import ManufacturListScreen from '../screens/Manufactur/List/ManufacturList.screen'
import TrackOrderotpScreen from '../screens/TrackOrderByOtp/TrackOrderotp.screen';
import TrackOrderIndividualByQr from '../screens/TrackOrderByOtp/TrackOrderIndividualByQr';
import TrackInfoOrder from '../screens/Order/TrackOrder/TrackInfoOrder';
import AuditPageScreen from '../screens/CallCenter/List/AuditList.screen';
import AdminAuditPageScreen from '../screens/Admin/AuditListAdmin.screen';
import AdminDashboardScreen from '../screens/Admin/Dashboard.screen';
import QrCodeGenerationScren from "../screens/Admin/Qr/QrCodeGeneration.screen";
import QrCodeGenerationHistoryScren from "../screens/Admin/Qr/QrCodeGenerationHistory.screen";
import VacutainerRecordScreen from "../screens/Admin/VacutainerRecord/VacutainerRecordHistory.screen";

import CallCenterManagementScreen from "../screens/Admin/UserManagment/CallCenterManagementDashboard.screen";
import CallCenterEditScreen from "../screens/Admin/UserManagment/CallCenterEdit.screen";
import CallCenterAddScreen from "../screens/Admin/UserManagment/CallCenterAdd.screen";

import AdminUserManagementScreen from "../screens/Admin/UserManagment/AdminUser/AdminUserManagmentDashboard.screen";
import AdminUserEditScreen from "../screens/Admin/UserManagment/AdminUser/AdminUserEdit.screen";
import AdminUserAddScreen from "../screens/Admin/UserManagment/AdminUser/AdminUserAdd.screen";

import WarehouseUserManagementScreen from "../screens/Admin/UserManagment/WarehouseUser/WarehouseUserManagmentDashboard.screen";
import WarehouseUserEditScreen from "../screens/Admin/UserManagment/WarehouseUser/WarehouseUserEdit.screen";
import WarehouseUserAddScreen from "../screens/Admin/UserManagment/WarehouseUser/WarehouseUserAdd.screen";


import OrderManagment from "../screens/Admin/OrderManagment/OrderManagment.screen";
import TrackOrderAdmin from "../screens/Admin/OrderManagment/TrackOrder.screen";
import PatientDetailsAdmin from "../screens/Admin/OrderManagment/PatientDetails.screen";
import UserManagmentScreen from "../screens/Admin/UserManagment/UserManagmentDashboard.screen";
import DoctorMasterAdmin from "../screens/Admin/DoctorMasterManagment/DoctorMasterDashboard.screen";
import DoctorMasterAddAdmin from "../screens/Admin/DoctorMasterManagment/DoctorMasterCreate.screen";
import PatientDashboardScreen from "../screens/Patient/PatientDashboard.screen";
import ClinicDashboardScreen from "../screens/Clinic/ClinicDashboard.screen";
import BUDetailsScreen from "../screens/BU/List/BUDetails.screen";
import NSMDetailsScreen from "../screens/NSM/List/NSMDetails.screen";
import ZMDetailsScreen from "../screens/ZM/List/ZMDetails.screen";
import RMDetailsScreen from "../screens/RM/List/RMDetails.screen";
import QrMappingScreen from '../screens/Admin/Qr/QrMapping.screen';
import CustomerListScreen from '../screens/Admin/UserManagment/CustomerList.screen';
import PrivacyPolicyScreen from '../screens/Admin/PrivacyPolicy.screen';
import TermsAndConditionScreen from '../screens/Admin/TermsAndCondition.screen';
import DeliveryPersonScreen from '../screens/Admin/UserManagment/DeliveryPerson/DeliveryPersonList.screen';
import DeliveryPersonAddScreen from '../screens/Admin/UserManagment/DeliveryPerson/DeliveryPersonAdd.screen';
// import DeliveryPersonEditScreen from '../screens/Admin/UserManagment/DeliveryPerson/DeliveryPersonEdit.screen';
import AssignJobScreen from '../screens/Clinic/AssignJob.screen';
import DeliveryPersonDashboardScreen from '../screens/DeliveryPerson/DeliveryPersonDashboard.screen';


// const Test = () => <><div>test</div></>
const RouterArr = () => {
  const routes = [

    {
      path: "/customer/",
      exact: true,

      children: [
        { path: "registration", exact: true, guards: [customerLoginGuard], element: <Registration /> },
        { path: "order-placed", exact: true, guards: [customerLoginGuard], element: <OrderPlaced /> },
        { path: "place-order", exact: true, guards: [customerLoginGuard], element: <PlaceOrder /> },
        { path: "place-order-modal", exact: true, guards: [customerLoginGuard], element: <PlaceOrderModal /> },
        { path: "track-order", exact: true, guards: [customerLoginGuard], element: <TrackOrder /> },
        { path: "order-history-new", exact: true, guards: [customerLoginGuard], element: <OrderHistoryNew /> },
        { path: "order-information", exact: true, guards: [customerLoginGuard], element: <OrderInformation /> },
        { path: "login/:prescribedDoctor", guards: [loginGuard], exact: true, element: <Login /> },
        { path: "feedback-info", exact: true, guards: [customerLoginGuard], element: <FeedbackScreen /> },
        { path: "feedback-submit", exact: true, guards: [customerLoginGuard], element: <FeedbackSubmitScreen /> },
        { path: "orders", exact: true, guards: [customerLoginGuard], element: <OrderHistory /> },
        { path: "order-history", exact: true, guards: [customerLoginGuard], element: <OrderHistoryTracking /> },
        { path: "track-info", exact: true, guards: [customerLoginGuard], element: <TrackOrderIndividual /> },
        { path: "tracking-info", exact: true, guards: [customerLoginGuard], element: <TrackingOrder /> },
        { path: "track-info-individual", exact: true, guards: [customerLoginGuard], element: <TrackOrderIndividualByQr /> },
        { path: "privacy-policy", exact: true, guards: [mrLoginGuard], element: <PrivacyPolicyScreen /> },
        { path: "terms", exact: true, guards: [mrLoginGuard], element: <TermsAndConditionScreen /> },
        { path: "dashboard", exact: true, guards: [stockistLoginGuard], element: <PatientDashboardScreen /> },
        { path: "track-info-order", exact: true, guards: [stockistLoginGuard], element: <TrackInfoOrder /> },
      ]
    },
    {
      path: "/mr/",
      exact: true,

      children: [

        { path: "list", exact: true, guards: [mrLoginGuard], element: <MrDetailsScreen /> },
        { path: "login", guards: [mrLoginGuard], exact: true, element: <LoginMR /> },
        { path: "track-info-order", guards: [mrLoginGuard], exact: true, element: <TrackInfoOrder /> },
      ]
    },
    {
      path: "/bu/",
      exact: true,

      children: [
        {
          path: "dashboard",
          exact: true,
          guards: [mrLoginGuard],
          element: <BUDetailsScreen />,
        },
        {
          path: "track-info-order",
          guards: [mrLoginGuard],
          exact: true,
          element: <TrackInfoOrder />,
        },
      ],
    },

    {
      path: "/nsm/",
      exact: true,

      children: [
        {
          path: "dashboard",
          exact: true,
          guards: [mrLoginGuard],
          element: <NSMDetailsScreen />,
        },
        {
          path: "track-info-order",
          guards: [mrLoginGuard],
          exact: true,
          element: <TrackInfoOrder />,
        },
      ],
    },
    {
      path: "/zm/",
      exact: true,

      children: [
        {
          path: "dashboard",
          exact: true,
          guards: [mrLoginGuard],
          element: <ZMDetailsScreen />,
        },
        {
          path: "track-info-order",
          guards: [mrLoginGuard],
          exact: true,
          element: <TrackInfoOrder />,
        },
      ],
    },
    {
      path: "/rm/",
      exact: true,

      children: [
        {
          path: "dashboard",
          exact: true,
          guards: [mrLoginGuard],
          element: <RMDetailsScreen />,
        },
        {
          path: "track-info-order",
          guards: [mrLoginGuard],
          exact: true,
          element: <TrackInfoOrder />,
        },
      ],
    },
    {
      path: "/doctor/",
      exact: true,

      children: [

        { path: "list", exact: true, guards: [doctorLoginGuard], element: <DoctorDetailsScreen /> },
        { path: "landing", exact: true, guards: [doctorLoginGuard], element: <DoctorLandingScreen /> },
        { path: "login", guards: [doctorLoginGuard], exact: true, element: <LoginDoctor /> },
        { path: "track-info-order", guards: [mrLoginGuard], exact: true, element: <TrackInfoOrder /> },
      ]
    },
    {
      path: "/stockist/",
      exact: true,

      children: [

        { path: "list", exact: true, guards: [stockistLoginGuard], element: <StockistListScreen /> },
        { path: "track-info-order", guards: [mrLoginGuard], exact: true, element: <TrackInfoOrder /> },

      ]
    },

    // {
    //   path: "/patient/",
    //   exact: true,

    //   children: [

    //     { path: "dashboard", exact: true, guards: [stockistLoginGuard], element: <PatientDashboardScreen /> },
    //     { path: "track-info-order", exact: true, guards: [stockistLoginGuard], element: <TrackInfoOrder /> },


    //   ]
    // },
    {
      path: "/clinic/",
      exact: true,

      children: [

        { path: "dashboard", exact: true, guards: [stockistLoginGuard], element: <ClinicDashboardScreen /> },
        { path: "track-info-order", exact: true, guards: [stockistLoginGuard], element: <TrackInfoOrder /> },
        { path: "assign-job", exact: true, guards: [stockistLoginGuard], element: <AssignJobScreen /> },


      ]
    },
    {
      path: "/delivery-person/",
      exact: true,

      children: [

        { path: "dashboard", exact: true, guards: [stockistLoginGuard], element: <DeliveryPersonDashboardScreen /> },
        { path: "track-info-order", exact: true, guards: [stockistLoginGuard], element: <TrackInfoOrder /> },


      ]
    },

    {
      path: "/callcenter/",
      exact: true,

      children: [
        { path: "list", exact: true, guards: [stockistLoginGuard], element: <CallCenterListScreen /> },
        { path: "track-info-order", guards: [mrLoginGuard], exact: true, element: <TrackInfoOrder /> },
        { path: "patient-details", guards: [mrLoginGuard], exact: true, element: <RegistrationScreenCallCenter /> },
        { path: "audit-page", guards: [mrLoginGuard], exact: true, element: <AuditPageScreen /> },

      ]
    },
    {
      path: "/manufactur/",
      exact: true,

      children: [

        { path: "list", exact: true, guards: [stockistLoginGuard], element: <ManufacturListScreen /> },
        { path: "track-info-order", guards: [stockistLoginGuard], exact: true, element: <TrackInfoOrder /> },

      ]
    },
    {
      path: "/order/",
      exact: true,

      children: [

        { path: ":orderid", exact: true, element: <TrackOrderotpScreen /> },

      ]
    },
    {
      path: "/admin/",
      exact: true,

      children: [

        { path: "dashboard", exact: true, guards: [mrLoginGuard], element: <AdminDashboardScreen /> },
        {
          path: "qr-code-generation",
          exact: true,
          guards: [mrLoginGuard],
          element: <QrCodeGenerationScren />,
        },
        {
          path: "qr-generation-history",
          exact: true,
          guards: [mrLoginGuard],
          element: <QrCodeGenerationHistoryScren />,
        },
        {
          path: "qr-mapping",
          exact: true,
          guards: [mrLoginGuard],
          element: <QrMappingScreen />,
        },


        {
          path: "vacutainer-record",
          exact: true,
          guards: [mrLoginGuard],
          element: <VacutainerRecordScreen />,
        },
        {
          path: "user-managment",
          exact: true,
          guards: [mrLoginGuard],
          element: <UserManagmentScreen />,
        },
        {
          path: "user-managment/callcenter",
          exact: true,
          guards: [mrLoginGuard],
          element: <CallCenterManagementScreen />,
        },
        {
          path: "user-managment/callcenter/details",
          exact: true,
          guards: [mrLoginGuard],
          element: <CallCenterEditScreen />,
        },
        {
          path: "user-managment/callcenter/add",
          exact: true,
          guards: [mrLoginGuard],
          element: <CallCenterAddScreen />,
        },

        {
          path: "user-managment/admin-users",
          exact: true,
          guards: [mrLoginGuard],
          element: <AdminUserManagementScreen />,
        },
        {
          path: "user-managment/admin-user/details",
          exact: true,
          guards: [mrLoginGuard],
          element: <AdminUserEditScreen />,
        },
        {
          path: "user-managment/admin-user/add",
          exact: true,
          guards: [mrLoginGuard],
          element: <AdminUserAddScreen />,
        },

        {
          path: "user-managment/warehouse-users",
          exact: true,
          guards: [mrLoginGuard],
          element: <WarehouseUserManagementScreen />,
        },
        {
          path: "user-managment/warehouse-user/details",
          exact: true,
          guards: [mrLoginGuard],
          element: <WarehouseUserEditScreen />,
        },
        {
          path: "user-managment/warehouse-user/add",
          exact: true,
          guards: [mrLoginGuard],
          element: <WarehouseUserAddScreen />,
        },

        {
          path: "user-managment/delivery-person",
          exact: true,
          guards: [mrLoginGuard],
          element: <DeliveryPersonScreen />,
        },
        // {
        //   path: "user-managment/delivery-person/details",
        //   exact: true,
        //   guards: [mrLoginGuard],
        //   element: <DeliveryPersonEditScreen />,
        // },
        {
          path: "user-managment/delivery-person/add",
          exact: true,
          guards: [mrLoginGuard],
          element: <DeliveryPersonAddScreen />,
        },
        {
          path: "user-managment/customer-list",
          exact: true,
          guards: [mrLoginGuard],
          element: <CustomerListScreen />,
        },
        {
          path: "order-managment",
          exact: true,
          guards: [mrLoginGuard],
          element: <OrderManagment />,
        },
        {
          path: "track-order",
          exact: true,
          guards: [mrLoginGuard],
          element: <TrackOrderAdmin />,
        },
        {
          path: "patient-details",
          exact: true,
          guards: [mrLoginGuard],
          element: <PatientDetailsAdmin />,
        },

        {
          path: "doctor-master",
          exact: true,
          guards: [mrLoginGuard],
          element: <DoctorMasterAdmin />,
        },
        {
          path: "doctor-master/add",
          exact: true,
          guards: [mrLoginGuard],
          element: <DoctorMasterAddAdmin />,
        },
        { path: "audit-page", guards: [mrLoginGuard], exact: true, element: <AdminAuditPageScreen /> },
        { path: "track-info-order", exact: true, guards: [mrLoginGuard], element: <TrackInfoOrder /> },
        { path: "privacy-policy", exact: true, guards: [mrLoginGuard], element: <PrivacyPolicyScreen /> },
        { path: "terms", exact: true, guards: [mrLoginGuard], element: <TermsAndConditionScreen /> },



      ]
    },
    {
      path: "/warehouse/",
      exact: true,
      children: [
        { path: "dashboard", exact: true, guards: [mrLoginGuard], element: <AdminDashboardScreen /> },
        {
          path: "qr-code-generation",
          exact: true,
          guards: [mrLoginGuard],
          element: <QrCodeGenerationScren />,
        },
        {
          path: "qr-generation-history",
          exact: true,
          guards: [mrLoginGuard],
          element: <QrCodeGenerationHistoryScren />,
        },
        {
          path: "qr-mapping",
          exact: true,
          guards: [mrLoginGuard],
          element: <QrMappingScreen />,
        },
        {
          path: "vacutainer-record",
          exact: true,
          guards: [mrLoginGuard],
          element: <VacutainerRecordScreen />,
        }
      ]
    },
    {
      path: "/",
      exact: true,
      element: <LoginBaseScreen />,

      children: [

        { path: "/:uniqueid", exact: true, element: <LoginBaseScreen /> }
      ]
    },
    {
      path: "/login",
      exact: true,
      element: <LoginCustomertrackingScreen />,

      children: [

        // { path: "/test", exact: true, element: <Test /> }
      ]
    },
    {
      path: "*", element: <PageNotFound />
    }
  ];
  // let element = useGuardedRoutes([routes]);

  return useGuardedRoutes(routes);
};




const Router = (props) => {
  return (
    <BrowserRouter>
      <GuardConfigProvider>
        <GuardProvider>
          <RouterArr />
        </GuardProvider>

      </GuardConfigProvider>

      {/* <Routes>
        <Route exact path="/" element={<Login />} />
        <Route element={<PageNotFound />} />
      </Routes>  */}
      {/* <Outlet />*/}
    </BrowserRouter>
  )
}

export default Router