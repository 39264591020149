import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from '../../../components/Reusable/WithRouter';
import config from '../../../config/emrok.config';
import Common from '../../../hoc/Common.hoc';
import axios from 'axios';
import moment from 'moment';
import AdminHeader from "../../../components/layout/Admin/AdminHeader";
import AdminSidebar from "../../../components/layout/Admin/AdminSidebar";
import AdminFooter from "../../../components/layout/Admin/AdminFooter";
import '../../../assets/css/admin/style.css';
import '../../../assets/css/admin/responsive.css';
import AdminService from '../../../services/Admin.service';
import CustomDatatable from "../../../components/Datatable/CustomDatatable.component";
import Dropdown from '../../../components/SearchableDropdown/Dropdown.component';
import StockistService from '../../../services/Stockist.service';
import DoctorService from '../../../services/Doctor.service';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"; // Import the CSS
class VacutainerRecordHistory extends Component {
    constructor(props) {
        super(props)

        this.state = {
            quantity: "",
            selectOrder: [],
            bankrefno: "",
            rtochangeDate: "",
            refundchangeDate: "",
            stockistList: [],
            selectedStockist: '',
            alldoctorList: [{
                "name": "Doctor 1",
                "stockist_id": 1,
                "doctor_id": 1,
                "label": "Doctor 1",
                "value": "d1"
            },
            {
                "name": "Doctor 2",
                "stockist_id": 1,
                "doctor_id": 2,
                "label": "Doctor 2",
                "value": "d2"
            },
            {
                "name": "Doctor 3",
                "stockist_id": 2,
                "doctor_id": 3,
                "label": "Doctor 3",
                "value": "d3"
            }],
            allStockistList: [{
                "name": "Stockist 1",
                "stockist_id": 1,
                "label": "Stockist 1",
                "value": "s1"
            },
            {
                "name": "Stockist 2",
                "stockist_id": 2,
                "label": "Stockist 2",
                "value": "s2"
            },
            {
                "name": "Stockist 3",
                "stockist_id": 3,
                "label": "Stockist 3",
                "value": "s3"
            }],
            doctorList: [],
            selectedDoctor: "",
            vacutainerRecords: [],
            searchDoctor: "",
            currentPage: 1,
            itemsPerPage: 20,
            availableseries: '',
            seriesCount: '',
            selectedStockist_id: '',
            selectedDoctor_id: '',
            allRecords: [],
            fromdate: "",
            todate: "",
            fromatedFromDate:"",
            fromatedToDate:""


        }

    }


    componentDidMount() {
       let payload = {
            stockist_id: null,
            doctor_id: null,
            quantity: null,
            availableseries: null,
            fromdate:null,
            todate:null
        };
        this.populateStockist();
        this.checkAvailableVacutainers();
        this.getAllrecords(payload);

    }

    componentWillUnmount() {
        if (this.dataTable) {
            this.dataTable.destroy(true);
        }
    }

    goPreviousPage = () => {
        // const usertype = this.props.CommonReducer.userType;
        this.props.navigate('/admin/qr-code-generation');
    };

    downloadQR = async (event) => {

        let payload = {
            serial_no: event.target.id
        };

        console.log(payload, 55555);

        AdminService.zipQrCodes(payload).then(data => {
            console.log(data.data, "zip qr codes");
            if (data.data.success) {
                console.log('in success');

                const downloadUrl = config.serviceUrl + '/images/qr_codes/saisei/zip/' + data.data.data.zippath;
                console.log(downloadUrl, 'downloadUrl');

                // const downloadUrl = config.serviceUrl + "/images/invoice/" + order_id + ".pdf"; // Replace with the actual URL or path of the file
                const a = document.createElement('a');
                a.href = downloadUrl;
                a.target = '_blank';
                a.download = data.data.data.zippath;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            }
        }).catch(err => {
            this.props.hoc.customAlert("Something went wrong.", false)
            console.log(err, "err")
        })



        console.log(payload, 55555);


    }

    changeVal = async (event) => {
        const { seriesCount } = this.state;
        const name = event.target.name;
        const value = event.target.value;

        if (value > seriesCount) {
            this.setState({ quantity: '' });
            this.props.hoc.customAlert("Maximum 500 QR Codes can be mapped at a time.", false)
        } else {
            this.setState({ quantity: value });
        }


    }

    setOrderHistoryData = (data) => {
        const { allStockistList, alldoctorList } = this.state;
        console.log(allStockistList, 'stockistList1');
        console.log(alldoctorList, 'doctorList1');
        let resp = []
        for (let i = 0; i < data.length; i++) {
            // let stockistListData = allStockistList.filter(item => item.stockist_id == data[i].stockist_id);
            // let doctorListData = alldoctorList.filter(item => item.doctor_id == data[i].doctor_id);

            // console.log(stockistListData[0],'stockistListData123');
            resp.push({
                _id: moment(data[i].fullDate).local().format('YYYY-MM-DD HH:mm'),
                count: data[i].data[0].count,
                startSerialNo: data[i].data[0].startSerialNo,
                endSerialNo: data[i].data[0].endSerialNo,
                // doctorName: data[i].data[0].doctor.name,
                stockistName: data[i].data[0].stockist.name,
                fullDate: data[i].data[0].fullDate,
                qrIds: data[i].data[0].qrIDs.length>0?data[i].data[0].qrIDs.join(', '):''


            })
        }
        return resp
    }

    formatedData = (payload) => {
        let newData = [];
        if (payload) {

            payload.map((item) => {

                let itemData = {
                    "name": item.name,
                    "_id": item._id,
                    "label": item.name,
                    "value": item.name
                };
                newData.push(itemData);
            })


        }

        return newData;

    }

    populateStockist = () => {
        StockistService.getAllStockist().then(data => {
            console.log(data, 999999);
            if (data.data.success) {
                console.log(data.data.data.stockistlist, 9999999);
                const formatedData = this.formatedData(data.data.data.stockistlist);

                console.log(formatedData, 8888888);

                setTimeout(() => {

                    this.setState({ stockistList: formatedData });

                }, 500);



            }
        })


    }

    populateDoctor = (stockist_id = null) => {
        if (stockist_id) {
            console.log(stockist_id, 8888888);
            let payload = {
                stockist_id: stockist_id
            };

            DoctorService.getAllDoctorList(payload).then(data => {
                console.log(data, 999999);
                if (data.data.success) {
                    // console.log(data.data.data.stockistlist,9999999);
                    const formatedData = this.formatedData(data.data.data);

                    console.log(formatedData, 8888888);

                    setTimeout(() => {

                        this.setState({ doctorList: formatedData });

                    }, 500);

                }
            })

            // console.log(stockist_id,255);
            // const { alldoctorList } = this.state;
            // const doctorList = alldoctorList.filter(item => item.stockist_id == stockist_id);
            // this.setState({doctorList:doctorList});
        }
    }

    checkAvailableVacutainers = () => {
        AdminService.checkVacutainersAvailable().then(data => {
            console.log(data.data.data.data, "availableseries");
            if (data.data.success) {
                if (data.data.data.data.length > 0) {
                    console.log(data.data.data.data[0].firstSerialNo, 'in3');
                    let series = config.seriesName + data.data.data.data[0].firstSerialNo + '-' + config.seriesName + data.data.data.data[0].lastSerialNo;

                    this.setState({ availableSeries: series });
                    this.setState({ seriesCount: data.data.data.data[0].count });
                } else {
                    this.setState({ availableSeries: '' });
                    this.setState({ seriesCount: '' });
                }


                // if(data.data.data.data.length>0){

                // }
                // console.log(data.data.data.data[0].totalCustomer, "10001");

            }
        }).catch(err => {
            this.props.hoc.customAlert("Something went wrong.", false)
            console.log(err, "err")
        })
    }

    getAllrecords = (payload) => {
        console.log(payload,5555);
        this.setState({ vacutainerRecords: [] })
        AdminService.getAllVacutainersRecords(payload).then(data => {
            console.log(data.data.data.data, "allrecords");
            if (data.data.success) {
                this.setState({ vacutainerRecords: data.data.data.data })
                console.log(data, 'in3');

            }
        }).catch(err => {

            console.log(err, "err")
        })

    }

    allotVacutainer = () => {
        const { selectedStockist_id, selectedDoctor_id, quantity, availableSeries, fromdate, todate ,fromatedFromDate, fromatedToDate} = this.state;
        if (selectedStockist_id == '') {
            this.props.hoc.customAlert("Please Choose Stockist.", false)
            return false;
        }else if (selectedStockist_id != '' && (fromdate != '' && todate =='')) {
            this.props.hoc.customAlert("Please Choose From Date and To Date.", false)
            return false;
        }else if((fromdate != '' && todate =='') ||  (fromdate == '' && todate !='')){
            this.props.hoc.customAlert("Please Choose From Date and To Date.", false)
            return false;
        }
        // else if(quantity==''){
        //     this.props.hoc.customAlert("Please Choose Quantity.", false)
        //     return false;
        // }else if(quantity>500){
        //     this.props.hoc.customAlert("Maximum 500 QR Codes can be mapped at a time.", false)
        //     return false;
        // }
        else {
            let payload = {
                stockist_id: selectedStockist_id,
                doctor_id: selectedDoctor_id,
                quantity: quantity,
                availableseries: availableSeries,
                fromdate:fromatedFromDate,
                todate:fromatedToDate
            };
            console.log(payload, 80000);

            this.getAllrecords(payload);

            // AdminService.createVacutainersRecord(payload).then(data => {
            //     console.log(data.data, "zip qr codes");
            //     if (data.data.success) {
            //         this.checkAvailableVacutainers();
            //         this.getAllrecords();
            //         this.props.hoc.customAlert("QR Codes Mapped Successfully.", true)
            //     }
            // }).catch(err => {
            //     this.props.hoc.customAlert("Something went wrong.", false)
            //     console.log(err, "err")
            // })



        }
        console.log(selectedStockist_id, 'selectedStockist');

    }
    clearSearch = (event) => {
        this.setState({ selectedStockist: '', selectedDoctor: "", quantity: '', doctorList: [], fromdate:"",todate:"" });
        let payload = {
            stockist_id: null,
            doctor_id: null,
            quantity: null,
            availableseries: null,
            fromdate:null,
            todate:null
        };
        this.getAllrecords(payload);


    }
    changeValFromDate = async (date) => {
        const formatedDate = moment(date).format('YYYY-MM-DD');
        console.log(date, "fromdate");
        this.setState({ fromdate: date, fromatedFromDate:formatedDate });
        // const storeSearchData = this.state.storeSearchData;
        // storeSearchData.fromdate = date;
        // this.props.setSearchValue(storeSearchData);
    };

    changeValToDate = async (date) => {
        const formatedDate = moment(date).format('YYYY-MM-DD');
        console.log(date, "todate");
        this.setState({ todate: date, fromatedToDate:formatedDate });

        // const storeSearchData = this.state.storeSearchData;
        // storeSearchData.todate = date;
        // this.props.setSearchValue(storeSearchData);
    };



    render = () => {
        const { stockistList, selectedStockist, doctorList, selectedDoctor, vacutainerRecords, quantity, availableSeries, seriesCount, fromdate, todate } = this.state;
        console.log(availableSeries, 'availableseries');
        console.log(vacutainerRecords, 'vacutainerRecords');
        let _data = this.setOrderHistoryData(vacutainerRecords)
        console.log(_data, "aksdhklsah");
        return (
            <>
                <div className="layout-wrapper">
                    <AdminHeader />
                    <AdminSidebar />
                    <div className="main-content">
                        <div className="page-content">
                            <div className="container-fluid">
                                <div className="row g-4">
                                    <div className='col-md-6'>
                                    <h5 className='mb-20 headingstyle'>X-beau Kit Mapping Records</h5>
                                    </div>
                                    <div className='col-md-6 text-end'>
                                        {/* <button className='btn btn-primary' type='button' onClick={(event) => this.goPreviousPage(event)}>Back</button> */}
                                    </div>
                                    {/* Row end */}
                                    <div className="row">
                                        <form className="mt-3 registration-form">
                                            <div className='row'>
                                                <div className="col-md-3 mb-3">
                                                    <label htmlFor="disabledTextInput" className="form-label lvl-clr-hd">Stockist </label>
                                                    {
                                                        stockistList.length ?
                                                            <Dropdown
                                                                items={stockistList}
                                                                onSelect={(item) => {
                                                                    if (item) {
                                                                        console.log(item.stockist_id, '2222');

                                                                        this.setState({ selectedStockist: item.name })
                                                                        this.setState({ selectedStockist_id: item._id })
                                                                        //console.log(item, "item");
                                                                        if (item.name != "All") {
                                                                            // this.populateDoctor(item._id)
                                                                        } else {
                                                                            this.setState({ doctorList: [] })
                                                                            this.setState({ selectedDoctor: "" })
                                                                        }
                                                                    }
                                                                }}
                                                                value={selectedStockist}
                                                            /> :
                                                            <Dropdown
                                                                items={stockistList}
                                                                value={selectedStockist}
                                                            />
                                                    }

                                                </div>
                                                {/* <div className="col-md-3 mb-3">
                                                    <label htmlFor="disabledTextInput" className="form-label lvl-clr-hd">Doctor<span className='requiredFld'> * </span> </label>
                                                    {
                                                        doctorList.length ?
                                                            <Dropdown
                                                                items={doctorList}
                                                                onSelect={(item) => {
                                                                    if (item) {

                                                                        this.setState({ selectedDoctor: item.name })
                                                                        this.setState({ selectedDoctor_id: item._id })

                                                                        if (item.name != "All") {
                                                                            this.populateDoctor(item._id)
                                                                        }
                                                                    }
                                                                }}
                                                                value={selectedDoctor}
                                                            /> :
                                                            <Dropdown
                                                                items={doctorList}
                                                                value={selectedDoctor}
                                                            />
                                                    }

                                                </div> */}
                                                <div className="col-md-3 mb-3">
                                                    <label htmlFor className="form-label lvl-clr-hd">
                                                        QR Mapping From Date
                                                    </label>

                                                    <DatePicker
                                                        selected={fromdate}
                                                        onChange={(date) => this.changeValFromDate(date)}
                                                        dateFormat="dd-MM-yyyy" // Set the desired display format
                                                        className="form-control name-pt-ft"
                                                        placeholderText="DD-MM-YYYY"
                                                    />
                                                </div>
                                                <div className="col-md-3 mb-3">
                                                    <label htmlFor className="form-label lvl-clr-hd">
                                                        QR Mapping To Date
                                                    </label>

                                                    <DatePicker
                                                        selected={todate}
                                                        onChange={(date) => this.changeValToDate(date)}
                                                        dateFormat="dd-MM-yyyy" // Set the desired display format
                                                        className="form-control name-pt-ft"
                                                        placeholderText="DD-MM-YYYY"
                                                        minDate={fromdate}
                                                    />
                                                </div>
                                                {/* <div className="col-md-2 mb-3">
                                                <label htmlFor="disabledTextInput" className="form-label lvl-clr-hd">Quantity<span className='requiredFld'> * </span> </label>
                                                <input type="text" value={quantity} onChange={(event) => this.changeVal(event)} name="quantity" id className="form-control" placeholder />
                                                </div> */}
                                               
                                                {/* <p className='info_msg'>Note: Maximum 500 QR Codes can be mapped at a time. Available QR Code Series for Mapping is <span className='ml-5'>{availableSeries?availableSeries:'No series available'} (Total Count - {seriesCount})</span></p> */}
                                            </div>
                                            <div className='row mb-5'>

                                            <div className="">
                                            <button
                                            type="button"
                                            className="landing-success-btn"
                                            onClick={(event) => this.allotVacutainer(event)}
                                            >
                                            Search
                                            </button>
                                            <button
                                            type="button"
                                            className="landing-clear-btn"
                                            onClick={(event) => this.clearSearch()}
                                            >
                                            Clear Filter
                                            </button>
                                            </div>
                                           {/* <div className="col-md-1">
                                                    <button className='btn btn-primary' type='button' onClick={(event) => this.allotVacutainer(event)}>Filter</button>
                                                </div>
                                                <div className="col-md-1">
                                                    <button className='btn btn-primary' type='button' onClick={(event) => this.clearSearch(event)}>Clear</button>
                                                </div> */}

                                            </div> 



                                           

                                            <div className='row'>
                                                <div className="table-responsive">


                                                    {
                                                        _data && _data.length > 0 ?
                                                            <CustomDatatable
                                                                pagination={true}
                                                                columns={[
                                                                    {
                                                                        name: 'QR Code Mapping Date & Time',
                                                                        // selector: row => row.mr_name,

                                                                    },
                                                                    {
                                                                        name: 'Stockist',
                                                                        // selector: row => row.mr_name,

                                                                    },
                                                                    // {
                                                                    //     name: 'Doctor',
                                                                    //     // selector: row => row.mr_name,

                                                                    // },
                                                                    {
                                                                        name: 'Scanned QR Codes Count',
                                                                        // selector: row => row.mr_name,

                                                                    },
                                                                    {
                                                                        name: 'QR Code Names',
                                                                        // selector: row => row.mr_name,

                                                                    },
                                                                    {
                                                                        name: 'Download QR Codes',
                                                                        // selector: row => row.createdAt,

                                                                    }
                                                                ]}
                                                                data={this.setOrderHistoryData(vacutainerRecords)}
                                                                pageName="vacutainerrecord"
                                                                downloadQR={this.downloadQR}
                                                            />
                                                            : <p className="norecord"><strong>No Records Found</strong></p>
                                                    }
                                                </div>
                                            </div>

                                        </form>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <AdminFooter />
                    </div>
                </div>

            </>
        )

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        // dispatching plain actions
        // setCustomer: (customer) => dispatch(CustomerAction.setCustomer(customer))
    }
}

const mapStateToProps = (state) => {
    return {
        OrderReducer: state.OrderReducer,
        CustomerReducer: state.CustomerReducer,
        CommonReducer: state.CommonReducer,
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Common(VacutainerRecordHistory)))