import React, { useState } from "react";
// import config from "../../../config/emrok.config";
// import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CallCenterAction from "../../../redux/actions/CallCenter.action";
import CommonAction from "../../../redux/actions/Common.action";
import CustomerAction from "../../../redux/actions/Customer.action";
// import FeedbackAction from "../../../redux/actions/Feedback.action";
import OrderAction from "../../../redux/actions/Order.action";
import MrAction from "../../../redux/actions/Mr.action";
import DoctorAction from "../../../redux/actions/Doctor.action";
import StockistAction from "../../../redux/actions/Stockist.action";
import AdminAction from "../../../redux/actions/Admin.action";

export default function AdminSidebar() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const [activeItem, setActiveItem] = useState("dashboard");
  const activeItem = useSelector((state) => state.AdminReducer.activeItem);
  const admin = useSelector((state) => state.AdminReducer.admin);
  const userRouteUrl = admin.userType == 'WARE_HOUSE' ? 'warehouse' : 'admin'
  const navigateTo = (data) => {
    if (data != "") {
      let url = "";
      if (data == "ordermanagment") {
        url = `/${userRouteUrl}/order-managment`;
      }

      if (data == "audit") {
        url = `/${userRouteUrl}/audit-page`;
      }

      if (data == "dashboard") {
        url = `/${userRouteUrl}/dashboard`;
      }

      if (data == "qr") {
        url = `/${userRouteUrl}/qr-code-generation`;
      }

      if (data == "vr") {
        url = `/${userRouteUrl}/vacutainer-record`;
      }

      if (data == "user_managment") {
        url = `/${userRouteUrl}/user-managment`;
      }

      if (data == "doctor_master") {
        // alert(data);
        url = `/${userRouteUrl}/doctor-master`;
      }
      if (data == "qr_mapping") {
        url = `/${userRouteUrl}/qr-mapping`;
      }

      dispatch(AdminAction.setActiveMenuItem(data));
      // setActiveItem(data);
      // alert();
      // const id = customer && customer.prescribedDoctor && customer.prescribedDoctor._id;
      navigate(url);

    }
  };

  const logout = () => {
    dispatch(CallCenterAction.logout());
    dispatch(CommonAction.logout());
    dispatch(CustomerAction.clearState());
    // dispatch(FeedbackAction.clearState());
    dispatch(OrderAction.clearState());
    dispatch(MrAction.logout());
    dispatch(DoctorAction.logout());
    dispatch(StockistAction.logout());
    dispatch(AdminAction.logout());
    // const id = customer && customer.prescribedDoctor && customer.prescribedDoctor._id;
    const url = "/";
    navigate(url);
  };

  const returnDashboard = () => {
    navigate(`/${userRouteUrl}/dashboard`);
  }


  return (
    <>
      <div className="sidebar toggle-xs-sidebar">
        <div className="sidebar-brand">
          {/* Light Logo*/}
          <a href={void (0)} onClick={(event) => returnDashboard(event)} className="logo logo-light">
            <span className="logo-lg">
              <img
                src={require("../../../assets/images/Logo.png")}
                alt="Logo Large"
                height={50}
              />
            </span>
          </a>
        </div>
        {/* Logo end*/}
        <div className="sidebar-menu-wrap">
          <div className="scrollbar scrollbar--sidebar">
            <div className="sidebar-menu-content">
              <ul className="nav flex-column sidebar-nav">
                <li className="nav-item">
                  <a
                    className={
                      (activeItem === "dashboard" || activeItem === undefined)
                        ? "nav-link active"
                        : "nav-link"
                    }
                    href={void (0)}
                    onClick={() => {
                      navigateTo("dashboard");
                    }}
                  >
                    <svg
                      xmlns=""
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="currentColor"
                        d="M6 19h3v-6h6v6h3v-9l-6-4.5L6 10v9Zm-2 2V9l8-6l8 6v12h-7v-6h-2v6H4Zm8-8.75Z"
                      />
                    </svg>
                    <div className="sidebar-nav-menu-box">
                      <span className="sidebar-menu-text">Dashboard</span>
                    </div>
                  </a>
                </li>
                {admin.userType == 'ADMIN' ?
                  <li className="nav-item">
                    <a
                      className={
                        activeItem === "ordermanagment"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      href={void (0)}
                      onClick={() => {
                        navigateTo('ordermanagment')
                      }}
                    >
                      <svg
                        xmlns=""
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                      >
                        <g
                          fill="currentColor"
                          fillRule="evenodd"
                          clipRule="evenodd"
                        >
                          <path d="M22.775 8A9 9 0 0 1 23 10h-9V1a9 9 0 0 1 8.775 7Zm-2.067 0A6.999 6.999 0 0 0 16 3.292V8h4.708Z" />
                          <path d="M1 14a9 9 0 0 1 11-8.775V12h6.775A9 9 0 1 1 1 14Zm15.803 0H10V7.196A6.804 6.804 0 1 0 16.803 14Z" />
                        </g>
                      </svg>
                      <div className="sidebar-nav-menu-box">
                        <span className="sidebar-menu-text">
                          Order Management
                        </span>
                      </div>
                    </a>
                  </li>
                  : null}
                <li className="nav-item">
                  <a
                    className={
                      activeItem === "qr" ? "nav-link active" : "nav-link"
                    }
                    href={void (0)}
                    onClick={() => {
                      navigateTo("qr");
                    }}
                  >
                    <svg
                      xmlns=""
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="currentColor"
                        d="M3 11V3h8v8H3Zm2-2h4V5H5v4ZM3 21v-8h8v8H3Zm2-2h4v-4H5v4Zm8-8V3h8v8h-8Zm2-2h4V5h-4v4Zm4 12v-2h2v2h-2Zm-6-6v-2h2v2h-2Zm2 2v-2h2v2h-2Zm-2 2v-2h2v2h-2Zm2 2v-2h2v2h-2Zm2-2v-2h2v2h-2Zm0-4v-2h2v2h-2Zm2 2v-2h2v2h-2Z"
                      />
                    </svg>
                    <div className="sidebar-nav-menu-box">
                      <span className="sidebar-menu-text">
                        QR Code Generation
                      </span>
                    </div>
                  </a>
                </li>

                <li className="nav-item">
                  <a
                    className={
                      activeItem === "qr_mapping" ? "nav-link active" : "nav-link"
                    }
                    href={void (0)}
                    onClick={() => {
                      navigateTo("qr_mapping");
                    }}
                  >
                    <svg xmlns="" width="24" height="24" viewBox="0 0 56 56"><path fill="currentColor" d="M8.383 21.402c1.219 0 1.875-.68 1.875-1.922v-5.53c0-2.438 1.312-3.68 3.656-3.68h5.672c1.219 0 1.898-.68 1.898-1.899c0-1.195-.68-1.875-1.898-1.875h-5.742c-4.875 0-7.36 2.414-7.36 7.242v5.743c0 1.242.68 1.921 1.899 1.921m39.234 0c1.242 0 1.899-.68 1.899-1.922v-5.742c0-4.828-2.438-7.242-7.36-7.242h-5.719c-1.242 0-1.921.68-1.921 1.875c0 1.219.68 1.899 1.921 1.899h5.672c2.297 0 3.633 1.242 3.633 3.68v5.53c0 1.243.68 1.922 1.875 1.922m-20.625 4.57v-8.179c0-.562-.445-1.031-1.031-1.031h-8.156c-.586 0-1.032.468-1.032 1.031v8.18c0 .562.446 1.008 1.032 1.008h8.156c.586 0 1.031-.446 1.031-1.008m4.055-7.171h6.14v6.14h-6.14Zm4.36 4.36v-2.556h-2.555v2.555Zm-12.235 0v-2.556h-2.578v2.555Zm-4.36 7.898h6.141v6.14h-6.14Zm20.11.796v-2.554h-2.555v2.555Zm-7.055 0v-2.554h-2.555v2.555Zm-8.695 3.54V32.84h-2.578v2.554Zm12.234 0V32.84h-2.578v2.554Zm1.031 14.109h5.72c4.921 0 7.359-2.438 7.359-7.266V36.52c0-1.243-.68-1.922-1.899-1.922c-1.219 0-1.875.68-1.875 1.922v5.53c0 2.438-1.336 3.68-3.633 3.68h-5.672c-1.242 0-1.921.68-1.921 1.899c0 1.195.68 1.875 1.921 1.875m-22.593 0h5.742c1.219 0 1.898-.68 1.898-1.875c0-1.219-.68-1.898-1.898-1.898h-5.672c-2.344 0-3.656-1.243-3.656-3.68V36.52c0-1.243-.68-1.922-1.875-1.922c-1.242 0-1.899.68-1.899 1.922v5.718c0 4.852 2.485 7.266 7.36 7.266m18.023-10.57v-2.555h-2.555v2.555Zm7.055 0v-2.555h-2.555v2.555Zm.305-12.961v-8.18c0-.562-.446-1.031-1.032-1.031H30.04c-.586 0-1.031.468-1.031 1.031v8.18c0 .562.445 1.008 1.03 1.008h8.157c.586 0 1.032-.446 1.032-1.008M18.812 18.8h6.141v6.14h-6.14Zm8.18 19.406v-8.18c0-.562-.445-1.007-1.031-1.007h-8.156c-.586 0-1.032.445-1.032 1.007v8.18c0 .563.446 1.031 1.032 1.031h8.156c.586 0 1.031-.468 1.031-1.031" /></svg>
                    <div className="sidebar-nav-menu-box">
                      <span className="sidebar-menu-text">QR Code Mapping</span>
                    </div>
                  </a>
                </li>

                <li className="nav-item">
                  <a
                    className={
                      activeItem === "vr" ? "nav-link active" : "nav-link"
                    }
                    href={void (0)}
                    onClick={() => {
                      navigateTo("vr");
                    }}
                  >
                    <svg xmlns="" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M8.267 1.618a.75.75 0 0 1 1.027-.264l.832.492l9.247 5.307a.75.75 0 1 1-.747 1.301l-.843-.484l-1.505 2.598l-.002-.002l-2.558-1.471a.75.75 0 1 0-.748 1.3l2.556 1.47l-.961 1.66l-.002-.001l-4.203-2.418a.75.75 0 1 0-.748 1.3l4.2 2.417l-.885 1.529l-.002-.002l-2.613-1.503a.75.75 0 0 0-.748 1.3l2.611 1.502l-1.12 1.932a4.862 4.862 0 0 1-6.628 1.77a4.827 4.827 0 0 1-1.776-6.605L9.373 3.143l-.006-.003l-.836-.494a.75.75 0 0 1-.264-1.028M20 17c1.105 0 2-.933 2-2.083c0-.72-.783-1.681-1.37-2.3a.862.862 0 0 0-1.26 0c-.587.619-1.37 1.58-1.37 2.3c0 1.15.895 2.083 2 2.083" /></svg>
                    <div className="sidebar-nav-menu-box">
                      <span className="sidebar-menu-text">
                        X-Beau Kit Records
                      </span>
                    </div>
                  </a>
                </li>
                {admin.userType == 'ADMIN' ?
                  <li className="nav-item">
                    <a
                      className={
                        activeItem === "user_managment"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      href={void (0)}
                      onClick={() => {
                        navigateTo("user_managment");
                      }}
                    >
                      <svg
                        xmlns=""
                        width={24}
                        height={24}
                        viewBox="0 0 32 32"
                      >
                        <path
                          fill="currentColor"
                          d="M8 5c-3.3 0-6 2.7-6 6c0 2 1 3.8 2.5 4.8C1.8 17.2 0 19.9 0 23h2c0-3.3 2.7-6 6-6s6 2.7 6 6h2c0-3.2 2.6-5.9 5.8-6h.2c2.5 0 4.6-1.5 5.5-3.6c0 0 0-.1.1-.1c.1-.1.1-.3.1-.4c0-.1 0-.1.1-.2c0-.1.1-.3.1-.4c0-.1 0-.2.1-.3c0-.1 0-.2.1-.3v-.6c0-3.3-2.7-6-6-6s-6 2.7-6 6c0 2 1 3.8 2.5 4.8c-1.5.7-2.7 1.9-3.5 3.3c-.8-1.4-2-2.6-3.5-3.3C13 14.8 14 13 14 11c0-3.3-2.7-6-6-6zm0 2c2.2 0 4 1.8 4 4s-1.8 4-4 4s-4-1.8-4-4s1.8-4 4-4zm14 0c2.2 0 4 1.8 4 4s-1.8 4-4 4s-4-1.8-4-4s1.8-4 4-4zm2.1 11v2.1c-.6.1-1.2.4-1.7.7l-1.5-1.5l-1.4 1.4l1.5 1.5c-.4.5-.6 1.1-.7 1.8H18v2h2.1c.1.6.4 1.2.7 1.8l-1.5 1.5l1.4 1.4l1.5-1.5c.5.3 1.1.6 1.7.7V32h2v-2.1c.6-.1 1.2-.4 1.7-.7l1.5 1.5l1.4-1.4l-1.5-1.5c.4-.5.6-1.1.7-1.8H32v-2h-2.1c-.1-.6-.4-1.2-.7-1.8l1.5-1.5l-1.4-1.4l-1.5 1.5c-.5-.3-1.1-.6-1.7-.7V18h-2zm.9 4c1.7 0 3 1.3 3 3s-1.3 3-3 3s-3-1.3-3-3s1.3-3 3-3zm0 2a.872.872 0 0 0-.367.086a1.138 1.138 0 0 0-.32.227a1.138 1.138 0 0 0-.227.32A.872.872 0 0 0 24 25c0 .125.031.25.086.367c.055.117.133.227.227.32c.093.094.203.172.32.227A.872.872 0 0 0 25 26c.5 0 1-.5 1-1s-.5-1-1-1z"
                        />
                      </svg>
                      <div className="sidebar-nav-menu-box">
                        <span className="sidebar-menu-text">User Management</span>
                      </div>
                    </a>
                  </li>
                  : null}
                {admin.userType == 'ADMIN' ?
                  <li className="nav-item">
                    <a
                      className={
                        activeItem === "doctor_master"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      href={void (0)}
                      onClick={() => {
                        navigateTo("doctor_master");
                      }}
                    >
                      <svg xmlns="" width="24px" height="24px" viewBox="0 0 48 48"><g fill="currentColor"><path fill-rule="evenodd" d="M33.834 13.81c0 3.461-.86 7.975-2.183 7.29a8.001 8.001 0 0 1-15.611-1.54c-1.313-2.297-3.035-6.9 1.392-10.488c.08-.026.128-.242.2-.56c.274-1.203.881-3.877 4.71-3.366c2.953.393 11.492 1.918 11.492 8.665m-3.806 2.182s-.452 1.322-.028 2.795a6 6 0 0 1-11.996.197c.145-.55.145-1.481.144-2.516c-.001-1.867-.003-4.07.852-4.968c5.989 3.989 11.028 4.492 11.028 4.492" clip-rule="evenodd" /><path d="M13 36c0-1.082.573-2.03 1.433-2.558a11.58 11.58 0 0 1-.092-.375a21.61 21.61 0 0 1-.355-2.068a19.63 19.63 0 0 1-.155-2.006C9.61 30.65 6 33.538 6 36.57V42h36v-5.43c0-2.904-3.31-5.675-7.298-7.36v.028c.018.61-.016 1.31-.082 1.983c-.06.624-.149 1.246-.256 1.779H35a1 1 0 0 1 .894.553l1 2c.07.139.106.292.106.447v2a1 1 0 0 1-1 1h-2v-2h1v-.764L34.382 35h-2.764L31 36.236V37h1v2h-2a1 1 0 0 1-1-1v-2c0-.155.036-.308.106-.447l1-2A1 1 0 0 1 31 33h1.315c.022-.086.044-.181.066-.286c.1-.471.189-1.068.249-1.685c.06-.618.088-1.231.073-1.735a5.18 5.18 0 0 0-.049-.624c-.022-.142-.044-.207-.048-.221c-.001-.003-.002-.004 0-.002l.003-.001A21.732 21.732 0 0 0 31 28.013c-.503-.115-1.023.577-1.25 1.01H18a9.88 9.88 0 0 1-.086-.168c-.212-.422-.473-.943-.914-.842c-.385.087-.77.187-1.155.297a6.55 6.55 0 0 0-.016.527c.004.553.057 1.23.142 1.914c.085.682.2 1.346.32 1.87c.034.151.068.287.1.404A3 3 0 1 1 13 36" /><path d="M17 36c0 .574-.462 1.015-1 1.015s-1-.44-1-1.015c0-.574.462-1.015 1-1.015s1 .44 1 1.015" /></g></svg>
                      <div className="sidebar-nav-menu-box">
                        <span className="sidebar-menu-text">Doctor  Master</span>
                      </div>
                    </a>
                  </li>
                  : null}
                {admin.userType == 'ADMIN' ?
                  <li className="nav-item">
                    <a
                      className={
                        activeItem === "audit" ? "nav-link active" : "nav-link"
                      }
                      href={void (0)}
                      onClick={() => {
                        navigateTo("audit");
                      }}
                    >
                      <svg
                        xmlns=""
                        width={22}
                        height={22}
                        viewBox="0 0 2048 2048"
                      >
                        <path
                          fill="currentColor"
                          d="M1033 1280q-14 30-21 62t-13 66H384v-128h649zm-649 384v-128h613q5 33 14 65t23 63H384zm-128 256h1349l127 128H128V0h1115l549 549v568q-29-26-61-47t-67-37V640h-512V128H256v1792zM1280 219v293h293l-293-293zm24 805q-109 41-187 128H384v-128h920zM384 512h640v128H384V512zm1152 256v128H384V768h1152zm512 1216q0 26-19 45t-45 19q-26 0-45-19l-291-290q-39 26-84 39t-92 14q-66 0-124-25t-102-68t-69-102t-25-125q0-66 25-124t68-101t102-69t125-26q66 0 124 25t101 69t69 102t26 124q0 47-13 92t-40 84l290 291q19 19 19 45zm-768-512q0 40 15 75t41 61t61 41t75 15q40 0 75-15t61-41t41-61t15-75q0-40-15-75t-41-61t-61-41t-75-15q-40 0-75 15t-61 41t-41 61t-15 75z"
                        />
                      </svg>
                      <div className="sidebar-nav-menu-box">
                        <span className="sidebar-menu-text">Audit Trial</span>
                      </div>
                    </a>
                  </li> : null
                }
              </ul>
            </div>
          </div>
          {/* logout Menu start */}
          <div className="d-flex btn-logout-wrap">
            <div className="logout-btn-small d-none">
              <button className="border-0 bg-transparent" type="submit">
                <svg
                  xmlns=""
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-log-out"
                >
                  <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" />
                  <polyline points="16 17 21 12 16 7" />
                  <line x1={21} y1={12} x2={9} y2={12} />
                </svg>
              </button>
            </div>
            <button
              className="btn sidebar-btn-logout d-none d-lg-block"
              type="submit"
              onClick={() => {
                logout();
              }}
            >
              Logout
            </button>
            {/* <div className="mobile-logout-btn d-lg-none d-flex align-item-center justify-content-center text-center mx-auto">
            <svg xmlns="" width="1em" height="1em" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><path d="M14 8V6a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2v-2"/><path d="M9 12h12l-3-3m0 6l3-3"/></g></svg>
            </div> */}
          </div>
          {/* logout Menu end */}
        </div>
      </div>
    </>
  );
}
