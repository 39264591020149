// src/Scanner.js
import React, { useState, useEffect, Component  } from 'react';
import { QrReader } from 'react-qr-reader';

class Scanner extends Component {
    constructor(props) {
        super(props)
        this.videoRef = React.createRef();
        this.state = {
            scanResult: "",
            error: "",
            allScanResult: [],
            getScanResultData: undefined,
            allselectedSeries: ""
        }
    }
    static getDerivedStateFromProps(props, state) {
        return { 
            getScanResultData: props.getScanResultData ? props.getScanResultData : undefined,  
            allselectedSeries: props.allselectedSeries ? props.allselectedSeries: ""
        }
    }

    componentDidMount =()=> {
      if (window.location.protocol === 'https:' || window.location.hostname === 'localhost') {
        this.getCameraStream();
      } else {
        this.setState({ error: 'Camera access requires a secure context (HTTPS).' });
        
      }
      
    }

    getCameraStream = async () => {
      try {

        navigator.permissions.query({ name: 'microphone' }).then(function(result) {
          if (result.state === 'granted') {
            console.log('Microphone access granted');
          } else if (result.state === 'prompt') {
            console.log('Microphone access prompt');
          } else {
            console.log('Microphone access denied');
          }
        });
        
        navigator.permissions.query({ name: 'camera' }).then(function(result) {
          if (result.state === 'granted') {
            console.log('Camera access granted');
          } else if (result.state === 'prompt') {
            console.log('Camera access prompt');
          } else {
            console.log('Camera access denied');
          }
        });
        
        navigator.permissions.query({ name: 'geolocation' }).then(function(result) {
          if (result.state === 'granted') {
            console.log('Geolocation access granted');
          } else if (result.state === 'prompt') {
            console.log('Geolocation access prompt');
          } else {
            console.log('Geolocation access denied');
          }
        });


        const stream = await navigator.mediaDevices.getUserMedia({ 
          video: { 
            facingMode: { exact: "environment" }
          } 
        }).then(stream => {
          // If permissions are granted, stop the stream (we just needed permissions)
          stream.getTracks().forEach(track => track.stop());
          // this.setState({error: 'access granted'});
        })
        .catch(err => {
          console.error(err);
          this.setState({error: 'Camera access denied or not available'});
        });
        // if (this.videoRef.current) {
        //   this.videoRef.current.srcObject = stream;
        // }

      } catch (err) {
        if (err.name === 'NotAllowedError') {
          this.setState({ error: 'Camera access denied. Please allow access to the camera.' });
        } else if (err.name === 'NotFoundError') {
          this.setState({ error: 'No camera found. Please ensure a camera is connected.' });
        } else {
          this.setState({ error: `Error accessing camera: ${err.message}` });
        }
      }
    };

    // componentDidMount = async () => {
        // this.setState({scanResult:""});
        // console.log(navigator, "navigator");
        // if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        //     // Request camera permissions
        //     navigator.mediaDevices.getUserMedia({ 
        //       video: { 
        //         facingMode: { exact: "environment" }
        //       } 
        //     })
        //       .then(stream => {
        //         // If permissions are granted, stop the stream (we just needed permissions)
        //         stream.getTracks().forEach(track => track.stop());
        //         // this.setState({error: 'access granted'});
        //       })
        //       .catch(err => {
        //         console.error(err);
        //         this.setState({error: 'Camera access denied or not available'});
        //       });
            
        //     // Check for camera devices
        //     navigator.mediaDevices.enumerateDevices()
        //       .then(devices => {
        //         const videoDevices = devices.filter(device => device.kind === 'videoinput');
        //         if (videoDevices.length === 0) {
        //           this.setState({error: 'No camera devices found'});
        //         }
        //       })
        //       .catch(err => {
        //         console.error(err);
        //         this.setState({error: err.message});
        //       });
        //   } else {
        //     this.setState({error: 'MediaDevices API or getUserMedia not supported'});
        //   }

    // }

    handleScan = async (data) => {
        if (data) {
            const { scanResult } = this.state;
            const splitArray = data.split('/');
            // alert(splitArray);
            const uniqueCode = splitArray.length > 4 ? splitArray[4] : data;

            if (!scanResult.includes(uniqueCode)) {
                this.setState(prevState => ({
                    scanResult: [...prevState.scanResult, uniqueCode],
                    error: ""
                }));
                
                await this.state.getScanResultData && this.state.getScanResultData(data);

            } else {
                this.setState({ error: "QR code already scanned" });
            }
        }
      };
    
    handleError = (err) => {
        console.error(err,'scanerror');
        this.setState({error:err.message});
      };
      chunkArray(array, chunkSize) {
        const result = [];
        for (let i = 0; i < array.length; i += chunkSize) {
          result.push(array.slice(i, i + chunkSize));
        }
        return result;
      }

    render = () => {
        const {scanResult, error} = this.state
        const chunkedSeries = this.chunkArray(this.state.allselectedSeries, 6);
        return (
            <div>
               <div>
        {/* <h1>Camera Component</h1> */}
        {/* <video ref={this.videoRef} autoPlay style={{ width: '100%' }} /> */}
      </div>
              {/* <h1>QR Code Scanner</h1> */}
              <QrReader
                //facingMode="environment"
                onResult={(result, error) => {
                    console.log(result, "result");
                    console.log(error, "error");
                  if (result) {
                    this.handleScan(result?.text);
                  }
                  if (error) {
                    this.handleError(error);
                  }
                }}
                constraints={{ facingMode: 'environment' }}
                style={{width: '100px', height: '100px'}}
              />
              {/* <p className='mt-5'>{this.state.allselectedSeries.length>0?this.state.allselectedSeries.join(','):'' }</p> */}
              <div className='mt-5'>
        {chunkedSeries.map((seriesChunk, chunkIndex) => (
          <div key={chunkIndex}>
            {seriesChunk.join(', ')}
          </div>
        ))}
      </div>

              {error && <p style={{ color: 'red' }}>{error}</p>} 
            </div>
          );
    }
}



export default Scanner;
