import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from '../../components/Reusable/WithRouter'
import CustomerHeader from '../../components/layout/CustomerHeader'
import CustomerFooter from '../../components/layout/CustomerFooter'
import config from '../../config/emrok.config'
import { Link } from 'react-router-dom'
import Common from '../../hoc/Common.hoc'
import FeedbackService from '../../services/FeedbackService'
import ProductService from '../../services/ProductService'
import FeedbackRating from '../../components/FeedbackRating/FeedbackRating.component'
import ProductAction from '../../redux/actions/Product.action';





class Feedback extends Component {
  constructor(props) {
    super(props)
    this.state = {
      products: [],
      message: "",
      message_1: "",
      customer: null,
      currentOrder: null,
      rating: 0,
      rating_1: 0,
      category: '',
      details: null // Default rating

    }
  }

  componentDidMount = () => {
    this.getProducts()

    const customer = this.props.CustomerReducer;
    const order = this.props.OrderReducer;

    console.log(customer, "customer from feedback screen");
    console.log(order, "order from feedback screen");
  }

  getProducts = () => {
    ProductService.getMyownnProducts().then(data => {

      this.setState({ products: data.data.data })
      this.props.setProducts(data.data.data);
      
      console.log(data.data.data[0], "getProducts")
    })
  }
  handleStarClick = (value) => {
    const customer = this.props.CustomerReducer;
    const order = this.props.OrderReducer;

    console.log(customer, "customer from feedback screen");
    console.log(order, "order from feedback screen");
  }
  handleSubmit = (e) => {
    e.preventDefault()
    const { message_1, category, products, rating_1 } = this.state
    const customer = this.props.CustomerReducer.customer;
    const order = this.props.OrderReducer.currentOrder;
    // const orders = this.props.OrderReducer;
    //  console.log(currentOrder,"order");
    // return
    // alert(category.length);
    if (category.length == 0) {
      this.props.hoc.customAlert("Please choose category", false, "Warning");
    } else if (rating_1 == '') {
      this.props.hoc.customAlert("Please provide ratings.", false, "Warning");
    } else if (message_1 == '') {
      this.props.hoc.customAlert("Please add comments.", false, "Warning");
    } else {
      let dataToSubmit = {
        customer_id: customer._id || null,
        order_id: order._id || null,
        feedbackType: category,
        products: [
          {
            product_id: products[0]._id,
          comment: message_1,
           rating: rating_1
          }
          
        
        ]
      }

      // console.log(dataToSubmit, "dataToSubmit")
      // return
      FeedbackService.createFeedback(dataToSubmit).then(data => {
        console.log(data.data, "feedback");

        // if (data.data.success && data.data.data.mrinfo !== '') {
        //   console.log(data.data.data.mrinfo[0], "okkkkk");

        //   this.setState({ mrinfo: data.data.data.mrinfo[0] });
        // }



        // this.setState({mrdetailsList:data.data.data.mrdetails});
        this.props.hoc.customAlertWithClick("Thank you for your feedback", true, "Thank you");

      }).catch(err => {

        this.props.hoc.customAlertWithClick("Thank you for your feedback", true, "Thank you");

        console.log(err, "err")
      })
    }



  }
  // handelSubmit = () =>{

  //   this.props.hoc.customAlertWithClick("Thank you for your feedback",true,"Thank you");
  // }

  handleRatingChange = (e) => {
    this.setState({ rating: e.target.value });

  };
  handleRatingChange1 = (e) => {
    this.setState({ rating1: e.target.value });

  };

  handleInputChange = (e) => {
    this.setState({ category: e.target.value });
  };


  handleMessageChange = (e, index) => {
    e.preventDefault()

    const messageData = e.target.value;

    if (messageData.length <= config.feedbackCommentCharLimit) {
      if (index === 1) {
        this.setState({ message_1: e.target.value });
      }
      else {
        this.setState({ message_2: e.target.value });
      }
    } else {
      this.props.hoc.customAlert("You exced charecter limit", false, "Warning");
    }


  };

  handleMessageChange1 = (e) => {
    this.setState({ message1: e.target.value });
  };

  render = () => {
    const { rating, message, products, message_1, rating_1,category } = this.state;
    return (
      <>
        <section className="feedback-form">
          <div className="container">
            <CustomerHeader />

            {/* Row End*/}
            <div className="row">
              <div className="col-md-6 regfrm-bag-image d-lg-none">
                {/* <img src="images/fdbk-image.png" className="" alt="" /> */}
                <img src={`${config.serviceUrl}/images/myownn/fdbk-image.png`} className="" alt="" />
              </div>
            </div>
            <div className="row mt-4">
              <div className="page-title">
                <h2 className="lg-title text-capitalize mb-20">Feedback Form</h2>
              </div>
            </div>
            {/* Row End*/}
            <div className="row" />
            {/* Row End*/}
          </div>
        </section>


        <section className="write-review-pt">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="col-md-12">
                  <div className="feedback-select-box">
                    <form>
                      <div className="">
                        <label htmlFor="" className="form-label">
                          Select Category
                        </label>
                        <select
                          className="form-select feedBackSelect"
                          data-placeholder="Select an option"
                          name="selectedOption"
                          value={this.state.selectedOption}
                          onChange={this.handleInputChange}
                        >
                          <option value="" disabled selected hidden>Please Select</option>
                          <option value="complaint">Complaint</option>
                          <option value="order">Order</option>
                          <option value="query">Query</option>
                        </select>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="review-part-ft mt-5">
                  <h2>Write a Review</h2>
                </div>
                <div className="review-start-pt">
                  {
                    products && products.length && products.map((product, i) => {
                      return (
                        <div className="row mt-3" key={i}>
                            <div className="col-3 col-md-2 product-image-col">
                          <div className="product-dtls-image-box new-fdbc-part">
                            {/* <img src="images/medicine-emr.png" alt="" /> */}
                            <img src={`${config.serviceUrl}/images/myownn/medicine-emr.png`} className="" alt="" />
                          </div>
                        </div>
                          <div className="col-8 col-md-10 col-lg-11 emrok-pro-detls">
                            <h5>{product?.name}</h5>
                            <div id="half-stars-example">
                              <div className="rating-group">
                                <FeedbackRating
                                  rating={rating_1}
                                  changeRating={(rating) => {
                                   
                                      this.setState({ rating_1: Math.round(rating) })
                                   
                                   
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="textarea-msg-submt mt-4">
                            <textarea placeholder="Type your query..." defaultValue={""} value={ message_1}
                              onChange={(e) => this.handleMessageChange(e, i + 1)} />
                          </div>
                        </div>
                      )
                    })
                  }
                  <div className="foot-wse-btn-pt mt-4">
                    <div className="foot-info-fdbk">
                      {/* <div className="know-more-text">
                        <a
                          href={config.knowMoreLink}
                          className="kow-more-link text-underline"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Know more
                        </a>
                      </div> */}
                    </div>
                    <div className="footer-bottom-btn-group">
                      <button type="button" className="back-to-main me-3" onClick={() => this.props.navigate('/customer/registration')}>
                        Back to Main
                      </button>
                      <button
                        type="submit"
                        //  data-bs-target="#orderPlaced"
                        //  data-bs-toggle="modal" 
                        className="new-sbmt-off" onClick={this.handleSubmit}>
                        Submit
                      </button>
                    </div>
                  </div>
                 
                </div>
              </div>
              
            </div>
          </div>
        </section>
        <CustomerFooter />


      

      </>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    // dispatching plain actions
    setProducts: (products) => dispatch(ProductAction.setProducts(products))
  }
}

const mapStateToProps = (state) => {
  return {
    OrderReducer: state.OrderReducer,
    CustomerReducer: state.CustomerReducer
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Common(Feedback)))