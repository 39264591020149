import React from "react";
import { withRouter } from "../../components/Reusable/WithRouter";
import { connect } from "react-redux";
import Common from "../../hoc/Common.hoc";
import CustomerFooter from "../../components/layout/CustomerFooter";
import moment from "moment";
import CallCenterHeader from "../../components/layout/CallCenter/CallCenterHeader";
import CallCenterService from "../../services/CallCenter.service";
import { ExportToExcel } from '../../components/Excel/ExportToExcel'
import AdminHeader from "../../components/layout/Admin/AdminHeader";
import AdminSidebar from "../../components/layout/Admin/AdminSidebar";
import AdminFooter from "../../components/layout/Admin/AdminFooter";
import 'react-datepicker/dist/react-datepicker.css'; // Import the CSS
import DatePicker from 'react-datepicker';
import '../../assets/css/admin/style.css';
import '../../assets/css/admin/responsive.css';
import StockistHeader from "../../components/layout/Stockist/StockistHeader";
class AuditListAdmin extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            callcenterDetails: null,
            scallcenterid: "",
            callcenterList: [],
            callcenterinfo: "",
            callcenterdetailsList: [],
            countries: [],
            states: [],
            towns: [],
            showTowns: false,
            selectedState: "",
            selectedTown: "",
            selectedDoctor: "",
            fromdate: "",
            todate: "",
            currentDate: this.getCurrentDate(),
            doctorList: [],
            isSearch: false,
            isShowCancelModal: false,
            cancelOrderId: null,
            cancelReason: "",
            mobileno: "",
            summaryCols: ["Total Rx generated", "Total Rx Cancel", "Total Rx Fulfilled", "Total Rx Under process"],
            summaryDetails: null,
            setorderStatus: "",
            info: null,
            auditList: [],
            auditListNew: [],
            exportData: null,
            fileName: "Audit_Trial",
            searchMonth: "",
            selectedRole: "",
            newarray: [],
            selectedUser: "",
            fromdate: "",
            todate: "",
            addressField: [
                { name: 'country' },
                { name: 'addressLine1' },
                { name: 'addressLine2' },
                { name: 'town' },
                { name: 'state' },
                { name: 'pin' }
            ],
            usertype:""
        }
    }

    static getDerivedStateFromProps(props, state) {
        return {
            callcenterdetails: props._id || null,
            summaryDetails: props.summaryDetails || null,
        }
    }

    getCurrentDate() {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const day = String(currentDate.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

   



    componentDidMount() {

        const callcenterdetails = this.props.callcenterdetails;
        console.log(callcenterdetails,666660000);
        if( Object.keys(callcenterdetails).length>0){
            this.setState({usertype:"callcenter"});
        }else{
            this.setState({usertype:""});
        }

        let payload = {
            type:"admin" 
        }

        this.getAuditList(payload);

       



    }

    getAuditList = (payload) => {
        this.setState({ auditList: [] }, () => {
            CallCenterService.getAuditList(payload).then(data => {
                console.log(data.data, "audit details 2");

                if (data.data.success && data.data.data.response !== '') {
                    console.log(data.data.data.response, "okkkkk12");

                    this.sortBydescindingOrder(data.data.data.response);

                    this.setState({ auditList: data.data.data.response });

                    // this.getDoctors(data.data.data.callcenterdetails);
                    const formatedData = this.formatData(data.data.data.response);

                    console.log(formatedData, "formatedData")

                    this.setState({ exportData: formatedData });

                   
                }

            }).catch(err => {

                console.log(err, "err")
            })
        });
    }

    sortBydescindingOrder(data){
        // data.map()
        data.map((item1, index1) => (
            console.log(item1._id,222)

        ))


    }

    formatData(auditdata) {

        console.log(auditdata,88888);

        let formatedArr = [];
        const {addressField} = this.state;
       

        for (let i = 0; i < auditdata.length; i++) {

            for (let j = 0; j < auditdata[i].data.length; j++) {

                let changeField = "";
                const foundItem = addressField.find((item) => item.name === auditdata[i].data[j].change_field);

                if (foundItem) {

                    changeField = auditdata[i].data[j].change_field!=null?auditdata[i].data[j].customer.address[auditdata[i].data[j].change_field]:''

                    // console.log(`${itemToCheck} exists in the array.`);
                } else {
                    console.log(auditdata[i].data[j],88888);

                    changeField =auditdata[i].data[j].change_field!=null? auditdata[i].data[j].customer[auditdata[i].data[j].change_field]:''
                    // console.log(`${itemToCheck} does not exist in the array.`);
                }
                formatedArr.push({

                    "User Name": auditdata[i].data[j].user?.name,
                    "User Number": auditdata[i].data[j].user?.mobileno,
                    "Customer Name": auditdata[i].data[j].customer?.name,
                    "Customer ID": auditdata[i].data[j].customer?.customer_code,
                    "Field Changed": auditdata[i].data[j].change_field,
                    "Old Record": auditdata[i].data[j].customer_old_value,
                    "New Record": changeField,
                    "Change Date & Time": moment(auditdata[i].data[j].createdAt).format("DD-MM-YYYY h:m a")
                })
            }


        }

        return formatedArr;


    }

    changeValFromDate = async (date) => {

        const formatedDate = moment(date).format('DD-MM-YYYY');
        console.log(date, "fromdate");
        this.setState({ fromdate: date });
    }

    searchAudit = (event) => {
        // alert();
        const { selectedRole, selectedUser, fromdate, todate, } = this.state;
        console.log(fromdate,"fromdate123");
        
        const selectedMomentFrom = moment(fromdate);
        const selectedOnlyDateFrom = fromdate?selectedMomentFrom.format('YYYY-MM-DD'):null;

        const selectedMomentTo = moment(todate);
        const selectedOnlyDateTo = todate?selectedMomentTo.format('YYYY-MM-DD'):null;


        console.log(selectedOnlyDateFrom, selectedOnlyDateTo, 90000);
        let payload = {
            type:"admin",
            searchRole: selectedRole,
            searchUser: selectedUser, 
            fromdate: selectedOnlyDateFrom, 
            todate: selectedOnlyDateTo

        }
        

        this.getAuditList(payload);
    }

    changeValToDate = async (date) => {

        const formatedDate = moment(date).format('DD-MM-YYYY');
        console.log(date, "todate");
        this.setState({ todate: date });
    }
    clearFilter = (event) => {
        this.setState({ selectedRole: "", selectedUser: "", fromdate: "", todate: "", auditListNew: [] });
        this.getAuditList();
    }

    handleClickHome = () => {

        this.props.navigate('/callcenter/list')
    }

    render = () => {
        const { auditList, exportData, fileName , addressField ,info, searchMonth, selectedRole, newarray, selectedUser, fromdate, todate, usertype} = this.state;



        console.log(usertype,999999);
        return (

            <>

<div className="layout-wrapper">
                    {usertype!=""?<StockistHeader/>:<AdminHeader />}
                   {usertype!=""?'':<AdminSidebar />}
                    <div className="main-content">
                        <div className="page-content">
                            <div className="container-fluid">
                                <div className="row g-4">
                                    <div className="landing-form-dtls">
                                        <div className="row mb-25 align-items-center">
                                            <div className="col-md-6 col-lg-5 col-xxl-4">
                                                <h5 className="mb-20 headingstyle">Audit Trial Report</h5>
                                            </div>

                                            <div className="col-md-6">
                                                {/* <div className="row align-items-center"> */}
                                                {/* <div className="col-md-10">
                                                        <div className="d-flex flex-column flex-md-row justify-content-md-end align-items-md-center">
                                                            <div className="col-md-3 pe-0 text-md-end">
                                                                <label className="me-3 form-label mb-md-0">Select Month: </label>
                                                            </div>
                                                            <div className="col-md-5">
                                                                <input className="form-control" type="month" onChange={(event) => this.searchByMonth(event)} value={searchMonth} />
                                                            </div>
                                                        </div>
                                                    </div> */}
                                                {/* <div className="col-md-8"> */}
                                                <div className="floatright mt-4 mt-md-0">
                                                    <ExportToExcel apiData={exportData} fileName={fileName} />
                                                </div>
                                                {/* </div> */}
                                                {/* </div> */}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-2 mb-2">
                                                <label htmlFor className="form-label lvl-clr-hd">From Date</label>
                                                {/* <input type="date" format="dd-mm-yyyy" value={fromdate} name="fromdate" onChange={(event) => this.changeVal(event)} id className="form-control name-pt-ft" placeholder /> */}
                                                <DatePicker
                                                    selected={fromdate}
                                                    onChange={(date) => this.changeValFromDate(date)}
                                                    dateFormat="dd-MM-yyyy" // Set the desired display format
                                                    className="form-control name-pt-ft"
                                                    placeholderText="DD-MM-YYYY"
                                                />
                                            </div>
                                            <div className="col-md-2 mb-2">
                                                <label htmlFor className="form-label lvl-clr-hd">To Date</label>
                                                {/* <input type="date" format="dd-mm-yyyy" min={this.state.fromdate} value={todate} name="todate" onChange={(event) => this.changeVal(event)} id className="form-control name-pt-ft" placeholder /> */}
                                                <DatePicker
                                                    selected={todate}
                                                    onChange={(date) => this.changeValToDate(date)}
                                                    dateFormat="dd-MM-yyyy" // Set the desired display format
                                                    className="form-control name-pt-ft"
                                                    placeholderText="DD-MM-YYYY"
                                                    minDate={this.state.fromdate}
                                                />
                                            </div>
                                            {/* <div className="col-md-2">

                                                <label htmlFor className="form-label lvl-clr-hd">Select Role:</label>

                                                <select className="form-control" name="selectedRole" onChange={(event) => this.changeData(event)} value={selectedRole}>
                                                    <option value="">Select Role</option>
                                                    <option value="MR">MR</option>
                                                    <option value="STOCKIST">Stockist</option>
                                                    <option value="BU">BU</option>
                                                    <option value="NSM">NSM</option>
                                                    <option value="ZM">ZM</option>
                                                    <option value="RM">RM</option>
                                                    <option value="DOCTOR">DOCTOR</option>
                                                    <option value="CALLCENTER">CALL CENTER</option>

                                                </select>


                                            </div> */}
                                            {selectedRole != "" ? <div className="col-md-2">

                                                <label htmlFor className="form-label lvl-clr-hd">Select User:</label>

                                                <select className="form-control" name="selectedUser" onChange={(event) => this.changeUser(event)} value={selectedUser}>
                                                    <option value="">Please Select</option>
                                                    {newarray.map((item, index) => (
                                                        <option key={item.id} value={item.id}>{item.name}</option>
                                                    ))}

                                                </select>


                                            </div> : ""}

                                           

                                        </div>
                                        <div className="d-flex mb-2 w-100">
                                            <div className="mt-2 me-2 btn-admin-search-box">
                                                {/* <div className="d-flex flex-column flex-md-row"> */}

                                                {/* <div className="col-md-5"> */}
                                                <button className="btn btn-primary searchbtn" name="search" value="Search" onClick={(event) => this.searchAudit(event)}>Search</button>
                                                {/* </div> */}
                                                {/* </div> */}

                                            </div>
                                            <div className="mt-2 clearfiltermargin btn-admin-success-box">
                                                {/* <div className="d-flex flex-column flex-md-row"> */}

                                                {/* <div className="col-md-5"> */}
                                                <button className="btn btn-success clearfilterdata" name="search" value="Search" onClick={(event) => this.clearFilter(event)}>Clear Filter</button>
                                                {/* </div> */}
                                                {/* </div> */}

                                            </div>
                                        </div>

                                        <div className="">

                                            {auditList.map((item, index) => (

                                                <div className="row" key={item._id}>
                                                    <div className="col-md-12 auditdateheading mt-4">
                                                        <h6 className="fw-bold">{item._id ? moment(item._id).format("DD-MM-YYYY") : ""}</h6>
                                                    </div>
                                                    <hr className="horizanlatrule" />
                                                    {item.data.map((item1, index1) => (



                                                        <div className="row auditrowstyle" key={item1._id}>
                                                            <div className="col-md-2">
                                                                {item1.createdAt ? moment(item1.createdAt).format("h:mm a") : ""}
                                                            </div>

                                                            {item1.action_type == "Modify" ? (
                                                                <div className="col-md-10">

                                                                    {addressField.find((item) => item.name === item1.change_field) ?
                                                                        <p className="messagestyle">{item1.user ? item1.user.name : ""} ({item1.user_type == "callcenter" ? "CALL CENTER" : (item1.user_type ? item1.user_type.toUpperCase() : "")}) changed {item1.change_field} of Customer: {item1.customer ? item1.customer.name : ""} ({item1.customer ? item1.customer.customer_code : ""}). Old Record: {item1.customer_old_value} , New Record: {item1.customer ? item1.customer.address[item1.change_field] : ""} </p> :

                                                                        <p className="messagestyle">{item1.user ? item1.user.name : ""} ({item1.user_type == "callcenter" ? "CALL CENTER" : (item1.user_type ? item1.user_type.toUpperCase() : "")}) changed {item1.change_field} of Customer: {item1.customer ? item1.customer.name : ""} ({item1.customer ? item1.customer.customer_code : ""}). Old Record: {item1.customer_old_value} , New Record: {item1.customer ? item1.customer[item1.change_field] : ""}</p>

                                                                    }
                                                                </div>

                                                            ) : (


                                                                item1.action_type == "Login" ? (

                                                                    <div className="col-md-10">


                                                                        {item1.user_type != "doctor" ? <p className="messagestyle">{item1.user ? item1.user.name : ""} ({item1.user_type == "callcenter" ? "CALL CENTER" : (item1.user_type == 'bu'?'BU HEAD':(item1.user_type == 'clinic' ?'KOSHIN CLINIC' : (item1.user_type?item1.user_type.toUpperCase():'')))}) Logged in </p> : <p className="messagestyle">{item1.doctor ? item1.doctor.name : ""} (DOCTOR) Logged in </p>}


                                                                    </div>) : (

                                                                        item1.action_type == "GFC" || item1.action_type == "Serum_Dispatch" ?(
                                                                            <div className="col-md-10">

                                                                                <p className="messagestyle"> {item1.action_type == "GFC"?'GFC Received':'Serum Dispatch'} done for Order No.{ item1.order?item1.order.order_no:''} by { item1.user?item1.user.name:''} (KOSHIN CLINIC) </p>
        
        
                                                                            {/* <p className="messagestyle">{item1.user ? item1.user.name : ""} ({item1.user_type == "callcenter" ? "CALL CENTER" : (item1.user_type ? item1.user_type.toUpperCase() : "")}) has {item1.action_type} the order of {item1.customer ? item1.customer.name : ""} ({item1.customer ? item1.customer.customer_code : ""}) with order number {item1.order ? item1.order.order_no : ""} for {item1.reject_reason}. </p> */}
    
    
                                                                        </div>

                                                                        ):(

                                                                            <div className="col-md-10">
        
        
                                                                                <p className="messagestyle">{item1.user ? item1.user.name : ""} ({item1.user_type == "callcenter" ? "CALL CENTER" : (item1.user_type ? item1.user_type.toUpperCase() : "")}) has {item1.action_type} the order of {item1.customer ? item1.customer.name : ""} ({item1.customer ? item1.customer.customer_code : ""}) with order number {item1.order ? item1.order.order_no: ""} for {item1.reject_reason}. </p>
        
        
                                                                            </div>
                                                                        )
                                                                )

                                                            )}


                                                        </div>

                                                    ))}


                                                </div>


                                            ))}


                                            {auditList.length > 0 ? "" : <p className="align-items-center norecord"><b>No Record Found</b></p>}



                                        </div>

                                    </div>
                                </div>
                                {/* Row end*/}
                            </div>
                        </div>
                        <AdminFooter />
                    </div>

                </div>
                  



            </>

        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        // dispatching plain actions
        // setCustomer: (customer) => dispatch(CustomerAction.setCustomer(customer)),
        // setUserType: (userType) => dispatch(CommonAction.setUserType(userType)),
        // saveSelectedOrders: (selectedOrders) => dispatch(OrderAction.saveSelectedOrders(selectedOrders)),
        // setCallCenter: (callcenterdetails) => dispatch(CallCenterAction.setCallCenter(callcenterdetails))
    }
}

const mapStateToProps = ({ CustomerReducer, OrderReducer, CallCenterReducer, CommonReducer }) => {
    const { customer } = CustomerReducer
    const { orders } = OrderReducer
    const { callcenterdetails } = CallCenterReducer
    const { summaryDetails } = CommonReducer

    console.log(customer, 123);
    console.log(callcenterdetails, 123456);
    return {
        // dispatching plain actions
        // OrderListReducer: state.OrderListReducer,
        customer,
        orders,
        callcenterdetails,
        summaryDetails
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Common(AuditListAdmin)))