import React from "react";
import { withRouter } from "../../../components/Reusable/WithRouter";
import { connect } from "react-redux";
import Common from "../../../hoc/Common.hoc";
import CustomerFooter from "../../../components/layout/CustomerFooter";
import MrHeader from "../../../components/layout/MR/MrHeader";
import DoctorHeader from "../../../components/layout/Doctor/DoctorHeader";
import MrAction from "../../../redux/actions/Mr.action";
import MrService from "../../../services/Mr.service";
import DoctorService from "../../../services/Doctor.service";
import DoctorAction from "../../../redux/actions/Doctor.action";
import { format } from 'date-fns';
import $ from "jquery";
import CustomDatatable from "../../../components/Datatable/CustomDatatable.component";
import moment from "moment";
import OtherService from "../../../services/OtherService";
import OrderAction from "../../../redux/actions/Order.action";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; // Import the CSS
import CountryService from '../../../services/Country.service';
import Dropdown from '../../../components/SearchableDropdown/Dropdown.component';
import CommonAction from "../../../redux/actions/Common.action";
import Summary from "../../../components/Summary/Summary.component";
import { ExportToExcel } from "../../../components/Excel/ExportToExcel";
import config from "../../../config/emrok.config";
import OrderService from "../../../services/Order.service";
class DoctorLanding extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            doctordetails: null,
            doctorid: "",
            doctordetailsList: [],
            doctorinfo: "",
            countries: [],
            states: [],
            towns: [],
            showTowns: false,
            selectedState: "",
            selectedTown: "",
            selectedDoctor: "",
            fromdate: "",
            todate: "",
            currentDate: this.getCurrentDate(),
            summaryCols: ["No of Orders", "Blood Collection Completed", "Recevied at Manufacturing", "Ready to Dispatch", "Received by doctor", "Received by Patient"],
            summaryDetails: null,
            setorderStatus: "",
            exportData: null,
            fileName: "Doctor_Data",
            registration: [],
            orderDetails: [],
            isUniqueQrCode: null,
            doctorAddress: []
        }
    }

    static getDerivedStateFromProps(props, state) {
        return {
            doctordetails: props._id || null,
            summaryDetails: props.summaryDetails || null,
        }
    }

    componentDidMount() {

        const doctordetails = this.props.doctordetails;

        const uniqueqrcode = this.props.uniqueqrcode;

        this.setState({ isUniqueQrCode: uniqueqrcode });
        console.log(uniqueqrcode, 'uniqueqrcode');

        console.log(doctordetails, "doctordetails list");
        if (doctordetails) {
            const doctor_id = doctordetails._id;
            let details = {
                doctor_id: doctor_id
            };
            // console.log(details, "doctor details",);

            DoctorService.getdoctorDetails(details).then(data => {
                console.log(data.data, "doctor details");

                if (data.data.success && data.data.data !== '') {
                    console.log(data.data, "okkkkk doctor details");

                    this.setState({ doctorinfo: data.data.data[0] });
                }



                // this.setState({mrdetailsList:data.data.data.mrdetails});

            }).catch(err => {

                console.log(err, "err")
            })

            this.getMultipleAddress(details);

        }
    }

    getMultipleAddress = (payload) => {
        console.log(payload, 699999);
        DoctorService.getdoctorAddress(payload).then(data => {
            console.log(data.data, "doctor details");

            if (data.data.success && data.data.data !== '') {
                console.log(data.data, "okkkkk doctor details");

                this.setState({ doctorAddress: data.data.data });
            }



            // this.setState({mrdetailsList:data.data.data.mrdetails});

        }).catch(err => {

            console.log(err, "err")
        })
    }

    getCurrentDate() {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const day = String(currentDate.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    changeVal = async (event) => {
        const name = event.target.name;
        let value = null;
        if (name == 'concent') {
            if (event.target.checked) {
                value = event.target.value;
            } else {
                value = null;
            }
        } else {
            value = event.target.value;
        }

        const registration = { ...this.state.registration };
        registration[name] = value;
        this.setState({ registration: registration });
    }

    changeAddress = async (event) => {
        const name = event.target.name;
        const value = event.target.value;

        this.setState({ selectedAddress: value });
    }

    orderHistory = () => {

        this.props.navigate('/doctor/list');
    }

    next = async (event) => {
        // alert("ok");
        const { registration, isUniqueQrCode, selectedAddress } = this.state;
        const doctordetails = this.props.doctordetails;
        const uniqueqrcode = this.props.uniqueqrcode;


        console.log(doctordetails, 6565);

        registration['doctor_id'] = doctordetails._id;
        registration['unique_qr'] = uniqueqrcode;
        registration['address_id'] = selectedAddress;

        console.log(registration, 111111);
        // return
        if (registration.name == '') {
            this.props.hoc.customAlert("Please enter name.", false)
            return false;
        } else if (registration.mobileno == '') {
            this.props.hoc.customAlert("Please enter mobileno.", false)
            return false;
        } else if (registration.mobileno.length != 10) {
            this.props.hoc.customAlert("Please enter 10 digits mobileno.", false)
            return false;

        } else if (registration.email == "" || (registration.email !== "" && !this.props.hoc.verifyEmail(registration.email))) {
            this.props.hoc.customAlert('Please Provide a valid email.', false)
            return false

        } else if (selectedAddress == '') {
            this.props.hoc.customAlert("Please enter address.", false)
            return false;
        } else {

            if (isUniqueQrCode != null) {

                OrderService.createOrder(registration).then(data => {
                    console.log(data, "orderDetails");

                    if (data.data.success) {
                        console.log(data.data, "okkkkk doctor details");
                        let msg = 'Order No. ' + data.data.data[0].order_no + ' placed successfully'
                        this.props.placeOrder(data.data.data[0]);
                        this.setState({ orderDetails: data.data.data[0] });
                        this.props.hoc.customAlert(msg, true, "", false, false, async () => {
                            this.props.navigate('/doctor/list');
                        }, () => {
                        })

                    } else {


                        this.props.hoc.customAlert(data.data.message, false)
                    }

                }).catch(err => {
                    console.log(err.message, 6666);
                    console.log(JSON.stringify(err, null, 2), "full error object");
                    this.props.hoc.customAlert('Something Went Wrong ', false)
                    console.log(err, "err123444")
                })

            }


        }
        console.log(registration, 66666);
    }
    render = () => {

        const { name, mobile, email } = this.state.registration;
        const { isUniqueQrCode, doctorAddress } = this.state;

        return (

            <>

                <section className="landing-page-pt">
                    <div className="container">
                        <DoctorHeader />
                        <div className="mr-landing-page mt-20">
                            <div className="landing-head-pt">
                                {/* <h2>Doctor Landing page</h2> */}
                            </div>
                        </div>
                        <div className="landing-form-dtls">

                            <div className="row">
                                <div className="col-md-6">
                                    <h5 className="mt-10 mb-20">Welcome Dr. {this.state.doctorinfo?.name} to XO</h5>
                                </div>

                                <div className="col-md-6">

                                    {/* <div className="flotright marginright">
                                        <button type="button"  className="back-to-main me-3">Order History</button>
                                    </div> */}
                                </div>

                            </div>
                            <div className="reg-frm-dta">
                                <div className="reg-text-ft">
                                    <h5 className="headingstyle">Customer Details</h5>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <form className="mt-3 registration-form">
                                        <div className="row">
                                            <div className="col-md-6 mb-3">
                                                <label htmlFor className="form-label lvl-clr-hd">Name <span className='requiredFld'> * </span></label>
                                                <input type="text" value={name} name="name" onChange={(event) => this.changeVal(event)} id className="form-control name-pt-ft" placeholder="Select Name" />
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <label htmlFor="disabledTextInput" className="form-label lvl-clr-hd">Mobile <span className='requiredFld'> * </span></label>
                                                <input type="number" value={mobile} onInput={(e) => e.target.value = e.target.value.slice(0, 10)} onChange={this.changeVal} name='mobileno' className="form-control name-pt-ft" placeholder="Select Mobile no" />
                                            </div>

                                            <div className="col-md-6 mb-3">
                                                <label htmlFor="disabledTextInput" className="form-label lvl-clr-hd">Email <span className='requiredFld'> * </span></label>
                                                <input type="text" value={email} onChange={this.changeVal} name='email' className="form-control name-pt-ft" placeholder="Select Email" />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <label htmlFor="chooseAddress" className="form-label lvl-clr-hd">Choose Clinic Address <span className='requiredFld'> * </span></label>
                                                <select id="chooseAddress" className="form-control name-pt-ft" name="selectedAddress" onChange={(event) => this.changeAddress(event)}>
                                                    <option value=""></option>
                                                    {doctorAddress.map((option, index) => (
                                                        <option key={index} value={option._id}>{option.addressLine1 + ', ' + option.addressLine2 + ', ' + option.town}</option>
                                                    ))}

                                                </select>

                                            </div>

                                        </div>
                                        <div className="row mt-5">
                                            <div className="col-md-1 col-1">
                                                <input type="checkbox" className="form-controll" name="concent" value="yes" onChange={(event) => this.changeVal(event)} />
                                            </div>
                                            <div className="col-md-11 col-11">
                                                <p className="consentstyle">I (Dr. {this.state.doctorinfo?.name}) is enrolling this Patient in the “XO Clinical Data Assessment”. Patient Consent (voluntary & informed) has been taken.</p>
                                            </div>
                                        </div>



                                        <div className="row mt-5">
                                            <div className="col-md-6 col-6">
                                                <div className="">
                                                    <button type="button" className="next-btn-crcl" id onClick={() => this.next()} disabled={isUniqueQrCode == null ? true : false}>
                                                        Place order
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-6">

                                                <div className="flotright marginright">
                                                    <button type="button" className="next-btn-crcl" onClick={() => this.orderHistory()}>Order History</button>
                                                </div>
                                            </div>
                                        </div>


                                    </form>
                                </div>

                                <div className="col-md-6 regfrm-bag-image mt-3 mt-lg-0 justify-content-end">
                                    <img src={`${config.serviceUrl}/images/myownn/register-bg-image.jpg`} className="img_resize" alt="" />
                                    {/* <img src={`${config.serviceUrl}/images/myownn/register-bg-image-old.png`} className="img_resize" alt="Product-1" /> */}
                                    {/* <img src={`${config.serviceUrl}/prescription/${imagename}`} alt="Product-1" className='img_resize'/> */}
                                </div>
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="foot-wse-btn-pt mt-4 mb-4">
                                            <div className="foot-info-fdbk">
                                                {/* <div>
                                                    <a href={config.knowMoreLink} className="knw-mr-track" target="_blank" rel="noopener noreferrer">
                                                        Know more
                                                    </a>
                                                </div> */}
                                            </div>

                                            {/* <div className="footer-bottom-btn-group">
                                          
                                            <button type="button" className="next-btn-crcl" id onClick={() => this.next()}>
                                                Next
                                            </button>
                                        </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>



                        </div>

                        <CustomerFooter />
                    </div>
                </section>


            </>

        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setUserType: (userType) => dispatch(CommonAction.setUserType(userType)),
        // dispatching plain actions
        // setMr: (mrdetails) => dispatch(MrAction.setMr(mrdetails))
        saveSelectedOrders: (selectedOrders) => dispatch(OrderAction.saveSelectedOrders(selectedOrders)),
        placeOrder: (currentOrder) => dispatch(OrderAction.placeOrder(currentOrder))
    }
}

const mapStateToProps = ({ CustomerReducer, OrderReducer, DoctorReducer, CommonReducer }) => {
    const { customer } = CustomerReducer
    const { orders } = OrderReducer
    const { doctordetails, uniqueqrcode } = DoctorReducer
    const { summaryDetails } = CommonReducer

    console.log(uniqueqrcode, 123);

    return {
        // dispatching plain actions
        // OrderListReducer: state.OrderListReducer,
        customer,
        orders,
        doctordetails,
        uniqueqrcode,
        uniqueqrcode,
        summaryDetails
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Common(DoctorLanding)))