import {
    SET_DOCTOR,
    LOGOUT,
    UNIQUE_QR_CODE
    } from '../types/Doctor.type'
    class DoctorAction {
      setDoctor = (data) => {
        console.log(">>>>>>>>>>>>>>>>",data)
        return { type: SET_DOCTOR, payload: data }
      }

      setUniqueQR = (data) => {
        console.log(">>>>>>>>>>>>>>>>",data)
        return { type: UNIQUE_QR_CODE, payload: data }
      }
      
      logout = ()=>{
        return { type: LOGOUT, payload: null }
      }
     
      
    }
    export default new DoctorAction()