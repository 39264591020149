import React from "react";
import { withRouter } from "../../components/Reusable/WithRouter";
import { connect } from "react-redux";
import Common from "../../hoc/Common.hoc";
import CustomerFooter from "../../components/layout/CustomerFooter";
import MrHeader from "../../components/layout/MR/MrHeader";
import MrAction from "../../redux/actions/Mr.action";
import MrService from "../../services/Mr.service";
import { format } from 'date-fns';
import $ from "jquery";
import CustomDatatable from "../../components/Datatable/CustomDatatable.component";
import moment from "moment";
import StockistHeader from "../../components/layout/Stockist/StockistHeader";
import StockistService from "../../services/Stockist.service";
import CallCenterService from "../../services/CallCenter.service";
import OrderAction from "../../redux/actions/Order.action";
import OtherService from "../../services/OtherService";
import CustomerAction from "../../redux/actions/Customer.action";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; // Import the CSS
import CountryService from '../../services/Country.service';
import Dropdown from '../../components/SearchableDropdown/Dropdown.component';
import DropdownDoctor from '../../components/SearchableDropdown/DropdownDoctor.component';
import CommonAction from "../../redux/actions/Common.action";
import CallCenterAction from "../../redux/actions/CallCenter.action";
import Summary from "../../components/Summary/Summary.component";
import { ExportToExcel } from "../../components/Excel/ExportToExcel";
import OrderService from "../../services/Order.service";
import ClinicService from "../../services/Clinic.service";
class ClinicDashboard extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            callcenterdetails: null,
            scallcenterid: "",
            callcenterList: [],
            mrdetails: [],
            callcenterinfo: "",
            callcenterdetailsList: [],
            countries: [],
            states: [],
            towns: [],
            showTowns: false,
            selectedState: "",
            selectedTown: "",
            selectedDoctor: "",
            fromdate: "",
            todate: "",
            currentDate: this.getCurrentDate(),
            doctorList: [],
            summaryCols: ["Total Orders", "Orders Under Process", "Orders Pending", "Sample Received at Koshin Clinic", "XO Face Serum received By doctor"],
            summaryDetails: null,
            setorderStatus: "",
            exportData: null,
            fileName: "Clinic_Data",
            cityTAT: [],
            orders: [],
            personalDetails: []
        }
    }

    static getDerivedStateFromProps(props, state) {
        return {
            callcenterdetails: props._id || null,
            summaryDetails: props.summaryDetails || null,
        }
    }

    formatData(auditdata) {

        console.log(auditdata, auditdata.length, "auditdata")

        let formatedArr = [];
        let allfeedback = [];
        let feedbackString = "";
        for (let i = 0; i < auditdata.length; i++) {


            formatedArr.push({

                "Order No": auditdata[i].order.order_no,
                "Order Date": auditdata[i].order ? moment(auditdata[i].order.createdAt).format('DD-MM-YYYY') : "",
                "QR Code Name": auditdata[i].unique_qr_id ?? "",
                "Customer Name": auditdata[i].patient ? auditdata[i].patient?.name : "",
                "Customer Mobile": auditdata[i].patient ? auditdata[i].patient?.mobileno : "",
                "MR Name": auditdata[i].mr ? auditdata[i].mr?.name : "",
                "Doctor Name": auditdata[i].doctor ? auditdata[i].doctor?.name : "",
                "Doctor Mobile": auditdata[i].doctor ? auditdata[i].doctor?.mobileno : "",
                "Stockist Name": auditdata[i].stockist ? auditdata[i].stockist[0]?.name : "",
                // "Vacutainer Serial No": auditdata[i].serial_no?'WH'+auditdata[i].serial_no:"",

                "Status": auditdata[i].order ? auditdata[i].order.orderStatus : '',


            })
        }
        return formatedArr;


    }

    getCurrentDate() {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const day = String(currentDate.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    getSummary = async () => {
        const { fromdate, todate, selectedState, selectedTown, selectedDoctor, setorderStatus } = this.state;
        const admindetails = this.props.clinicdetails;
        const admin_id = admindetails._id;
        const selectedOnlyDateFrom = fromdate ? moment(fromdate).format('YYYY-MM-DD') : "";
        const selectedOnlyDateTo = todate ? moment(todate).format('YYYY-MM-DD') : "";
        let payload = {
            type: "CLINIC",
            id: admin_id,
            fromDate: fromdate ? selectedOnlyDateFrom : "",
            toDate: todate ? selectedOnlyDateTo : "",
            stateName: selectedState,
            town: selectedTown,
            doctor_id: selectedDoctor,
            orderStatus: setorderStatus
        }

        console.log(payload, "payload")
        OtherService.getSummary(payload).then(data => {
            console.log(data, "getSummary");
        }).catch(err => {
            console.log(err, "getSummary err");
        })
    }



    clickFn = async (index) => {
        console.log(index, "index");
        const callcenterdetails = this.props.clinicdetails;
        const clinic_id = callcenterdetails._id;
        let payload = {};
        let orderstatus = ""
        if (callcenterdetails) {
            // let payload = await {

            // }

            const { fromdate, todate, selectedState, selectedTown, selectedDoctor, setorderStatus } = this.state;
            const selectedOnlyDateFrom = fromdate ? moment(fromdate).format('YYYY-MM-DD') : "";
            const selectedOnlyDateTo = todate ? moment(todate).format('YYYY-MM-DD') : "";

            if (index === 0) {
                payload = {
                    fromDate: fromdate ? selectedOnlyDateFrom : "",
                    toDate: todate ? selectedOnlyDateTo : "",
                    stateName: selectedState,
                    town: selectedTown,
                    doctor_id: selectedDoctor,
                    orderStatus: setorderStatus,
                    user_id: clinic_id
                }
            } else {
                payload = {
                    fromDate: fromdate ? selectedOnlyDateFrom : "",
                    toDate: todate ? selectedOnlyDateTo : "",
                    stateName: selectedState,
                    town: selectedTown,
                    doctor_id: selectedDoctor,
                    orderStatus: setorderStatus,
                    user_id: clinic_id
                }
            }

            if (index === 0) {
                payload["orderStatus"] = ""
                orderstatus = ""
                this.setState({ setorderStatus: "" })
            }
            else if (index === 1) {
                payload["orderStatus"] = await "underprocess"
                orderstatus = "underprocess"
                this.setState({ setorderStatus: "underprocess" })
            }
            else if (index === 2) {
                payload["orderStatus"] = await "pending"
                orderstatus = "pending"
                this.setState({ setorderStatus: "pending" })
            }
            else if (index === 3) {
                payload["orderStatus"] = await "received_at_clinic"
                orderstatus = "received_at_clinic"
                this.setState({ setorderStatus: "received_at_clinic" })
            }
            else if (index === 4) {
                payload["orderStatus"] = await "delivered"
                orderstatus = "delivered"
                this.setState({ setorderStatus: "delivered" })
            }
            else if (index === 5) {
                payload["orderStatus"] = await "completed"
                orderstatus = "completed"
                this.setState({ setorderStatus: "completed" })

            }


            console.log(payload, 222222);
            this.getOrderList(payload)

        }
    }



    componentDidMount() {

        const clinicdetails = this.props.clinicdetails;
        console.log(clinicdetails, 555);
        const clinic_id = clinicdetails._id;
        let details = {
            user_id: clinic_id
        };
        console.log(details, "callcenter details 1",);
        if (clinicdetails) {

            let infopayload = {
                type: "USER",
                id: clinic_id
            }
            this.getInfo(infopayload)

            this.getSummary()
            this.getOrderList(details)
            // this.getCityTAT();

            setTimeout(() => {
                this.populateStates("IN")
                // this.populateStates(countryObj.isoCode)	
            }, 500);

            // let detailsBody = {
            //     id: clinic_id,
            //     type: "callcenter"
            // };



        } else {


        }



    }

    getInfo = (payload) => {
        this.setState({ personalDetails: [] })

        OrderService.getPersonalDetails(payload).then(async (data) => {
            console.log(data, 100001)
            if (data.data.success) {
                // console.log(data.data.data.order,6666666)
                this.setState({ personalDetails: data.data.data[0] })
            }
        }).catch(err => {
        })
    }

    // getCityTAT = async () => {
    //     const alltats = await OtherService.getCitytat();
    //     if (alltats.data.success) {
    //         console.log(alltats.data.data, '123tat');
    //         this.setState({ cityTAT: alltats.data.data });

    //     }

    // }

    getDoctors = (doctors) => {

        // Create an array to store the grouped data
        const groupedData = [];
        const finalData = [];

        // Create an object to keep track of seen doctor._id values
        const seenDoctorIds = {};

        // Use forEach to iterate through the data
        doctors.forEach((currentValue) => {
            const doctorId = currentValue.doctor._id;

            // Check if we've already seen this doctor._id
            if (!seenDoctorIds[doctorId]) {
                // Add the current value to the groupedData array
                groupedData.push(currentValue.doctor);

                // Mark the doctor._id as seen
                seenDoctorIds[doctorId] = true;
            }
        });

        for (let index = 0; index < groupedData.length; index++) {
            finalData[index] = {
                "name": groupedData[index].name,
                "label": groupedData[index].name,
                "value": groupedData[index]._id,
                "id": groupedData[index]._id,
            };


        }


        const allOption = {
            "name": "All",
            "label": "All",
            "value": "All",
            "id": "All"
        };

        let optionsWithAll = [allOption, ...finalData];


        console.log(finalData, "finalData");
        // Now 'groupedData' contains unique doctors with a regular index

        this.setState({ doctorList: optionsWithAll });

        console.log(groupedData, "groupedData")

        // Now 'groupedData' contains the data grouped by doctor._id


    }

    populateStates = (isoCode = null) => {
        if (isoCode) {
            CountryService.getStates(isoCode).then(data => {
                //console.log(data, "states")
                if (data.success) {

                    this.setState({ states: data.states, towns: [] }, async () => {
                        this.setState({ showTowns: false })
                        let _state = this.state.selectedState || null
                        let _states = this.state.states || []
                        console.log(_state, this.state.states, "jhgjhgjhg");
                        if (_state) {
                            alert("ok");
                            let stateObj = null
                            let stateObjAll = null
                            for (let i = 0; i < this.state.states.length; i++) {

                                if (this.state.states[i].name === _state) {
                                    stateObj = this.state.states[i]
                                }
                            }


                            console.log(stateObj, _state, 1234545677);
                            console.log("stateObj found", stateObj)
                            // if (stateObj) {
                            setTimeout(() => {
                                if (stateObj.countryCode)
                                    this.populateTowns(stateObj.countryCode, stateObj.isoCode)
                            }, 500);
                            // }
                        }
                    })


                    setTimeout(() => {
                        let stateDetails = this.state.states;
                        const allOption = {
                            "name": "All",
                            "isoCode": null,
                            "countryCode": null,
                            "latitude": null,
                            "longitude": null,
                            "label": "All",
                            "value": "all"
                        };
                        console.log(this.state.states, "details state")
                        // Create a new array with the "All" option at the beginning
                        let optionsWithAll = [allOption, ...stateDetails];
                        console.log(optionsWithAll, "details state all")
                        this.setState({ states: optionsWithAll })
                    }, 500);


                }
            })
        }
    }

    populateTowns = (countryCode = null, stateCode = null) => {
        console.log(countryCode, stateCode, "countryCode stateCode")
        if (countryCode && stateCode) {
            CountryService.getTowns(countryCode, stateCode).then(data => {
                console.log(data, "towns")
                // setTimeout(() => {
                if (data.success) {
                    this.setState({ towns: data.towns }, () => this.setState({ showTowns: true }))

                    setTimeout(() => {
                        let stateDetails = this.state.towns;
                        const allOption = {
                            "name": "All",
                            "isoCode": null,
                            "countryCode": null,
                            "latitude": null,
                            "longitude": null,
                            "label": "All",
                            "value": "all"
                        };
                        console.log(this.state.towns, "details state")
                        // Create a new array with the "All" option at the beginning
                        let optionsWithAll = [allOption, ...stateDetails];
                        console.log(optionsWithAll, "details state all")
                        this.setState({ towns: optionsWithAll })
                    }, 500);
                }
                // }, 2000);
            })
        }
    }

    setOrderHistoryData = (data) => {
        let resp = []
        // console.log(data[0],66666)
        for (let i = 0; i < data.length; i++) {

            let checkisGFC = data[i].order_status.filter(item => {
                return item.patient_order_id == data[i].order._id && item.status == "received_at_clinic"
            })

            let serumDispatch = data[i].order_status.filter(item => {
                return item.patient_order_id == data[i].order._id && item.status == "dispatch_from_clinic"
            })

            let status1C = data[i].order_status.filter(item => {
                return item.patient_order_id == data[i].order._id && item.status == "X-care Tubes delivered to Koshin Clinic"
            })


            resp.push({
                _id: data[i]._id,
                order_no: data[i].order.order_no,
                order_date: data[i].order.createdAt ? moment(data[i].order.createdAt).format('DD-MM-YYYY') : "",
                order_status: data[i].order.orderStatus ? data[i].order.orderStatus.toUpperCase() : "",
                stockist_name: data[i].stockist[0].name,
                patient_name: data[i].patient.name,
                patient_mobile: data[i].patient.mobileno,
                vacutainer_code: data[i].unique_qr_id ?? "",
                serial_no: data[i].serial_no ? 'WH' + data[i].serial_no : '',
                mr_name: data[i].mr.name,
                doctor_naame: data[i].doctor.name,
                doctor_mobile: data[i].doctor.mobileno,
                gfc_received: checkisGFC.length > 0 ? 'yes' : 'no',
                serum_dispatch: serumDispatch.length > 0 ? 'yes' : 'no',
                status1C: status1C.length > 0 ? 'yes' : 'no'


            })
        }
        return resp


    }

    componentWillUnmount() {
        if (this.dataTable) {
            this.dataTable.destroy(true);
        }
    }

    handleTrackOrder = (event) => {

        // const allOrder = this.props.OrderReducer.allOrders;
        const targetId = event.target.name;
        console.log(targetId, "target ID");
        const { orders } = this.state;
        console.log(orders, 7777);
        try {

            const selectedOrder = orders.filter(item => item._id == targetId)
            console.log(selectedOrder, 'selectedOrder');
            this.props.saveSelectedOrders(selectedOrder)
            this.props.navigate('/clinic/track-info-order');
            this.props.setUserType('clinic');

        } catch (error) {

        }

    }

    changeValFromDate = async (date) => {

        const formatedDate = moment(date).format('DD-MM-YYYY');
        console.log(date, "fromdate");
        this.setState({ fromdate: date });
    }

    changeValToDate = async (date) => {

        const formatedDate = moment(date).format('DD-MM-YYYY');
        console.log(date, "todate");
        this.setState({ todate: date });
    }


    searchFilter = (event) => {
        this.setState({ callcenterdetailsList: [] });
        const callcenterdetails = this.props.clinicdetails;
        const callcenter_id = callcenterdetails._id;
        const { fromdate, todate, selectedState, selectedTown, selectedDoctor, setorderStatus } = this.state;

        const selectedMomentFrom = moment(fromdate);
        const selectedOnlyDateFrom = selectedMomentFrom.format('YYYY-MM-DD');
        // Calculate the next day using moment
        const nextDayMomentFrom = selectedMomentFrom.clone().add(1, 'day');
        const nextDayOnlyDateFrom = nextDayMomentFrom.format('YYYY-MM-DD');

        const selectedMomentTo = moment(todate);
        const selectedOnlyDateTo = selectedMomentTo.format('YYYY-MM-DD');
        // Calculate the next day using moment
        const nextDayMomentTo = selectedMomentTo.clone().add(1, 'day');
        const nextDayOnlyDateTo = nextDayMomentTo.format('YYYY-MM-DD');


        let details = {
            callcenter_id: callcenter_id,
            fromDate: fromdate ? selectedOnlyDateFrom : "",
            toDate: todate ? selectedOnlyDateTo : "",
            stateName: selectedState,
            town: selectedTown,
            doctor_id: selectedDoctor,
            orderStatus: setorderStatus
        };

        if (fromdate != "" && todate == "") {
            this.props.hoc.customAlert("Please select both fromdate and todate", false);
        } else if (fromdate == "" && todate != "") {
            this.props.hoc.customAlert("Please select both fromdate and todate", false);
        } else {

            this.getOrderList(details)
            this.getSummary()
        }




        console.log("search filter");
    }

    changeDoctor = (event) => {
        const doctorId = event.target.value;
        this.setState({ selectedDoctor: doctorId });
        console.log(doctorId, "doctorId");
    }

    getOrderList = (payload) => {

        console.log(payload, 'payload333');

        this.setState({ orders: [] })
        this.setState({ exportData: null });

        OrderService.getAllOrders(payload).then(async (data) => {
            console.log(data, 1000022)
            if (data.data.success) {
                console.log(data.data.data.order, 6666666)

                this.setState({ orders: data.data.data.order })

                const formatedData = await this.formatData(data.data.data.order);
                console.log(formatedData, "formatedData")
                this.setState({ exportData: formatedData });

                this.getDoctors(data.data.data.order)
            }


        }).catch(err => {
        })

    }

    clearFilter = () => {

        let details = {

        };
        this.setState({ selectedState: "", selectedTown: "", fromdate: "", todate: "", selectedDoctor: "" })

        this.getSummary()
        this.getOrderList(details)

    }

    handleGFC = async (event) => {

        let order_no = event.target.name;
        let payload = {
            order_no: order_no
        };

        this.props.hoc.customAlert('Are you sure you want to proceed?', true, "", true, false, async () => {
            const status = await ClinicService.changegfcReceivedStatus(payload);
            if (status.data.success) {
                console.log(status.data.data, 'gfcstatus');
                const clinicdetails = this.props.clinicdetails;
                console.log(clinicdetails, 555);
                const clinic_id = clinicdetails._id;
                let details = {
                    user_id: clinic_id
                };
                this.getSummary()
                this.getOrderList(details)
                // this.setState({ cityTAT: alltats.data.data });
                this.props.hoc.customAlert("GFC received status changes.", true);

            } else {
                this.props.hoc.customAlert("Something went wrong.", false);
            }


            // alert(order_no);
        }, () => {

        })

        // alert(order_no);
    }

    handleSerumDispatch = async (event) => {
        let order_no = event.target.name;
        let payload = {
            order_no: order_no
        };

        const { orders } = this.state;
        const selectedOrders = orders.filter(item => item.order.order_no == order_no)

        console.log(selectedOrders, 'selectedOrders');

        if (selectedOrders.length > 0 && selectedOrders[0].order.orderStatus == 'received_at_clinic') {
            this.props.hoc.customAlert('Are you sure you want to proceed?', true, "", true, true, async () => {

                const status = await ClinicService.serumDispatched(payload);
                if (status.data.success) {
                    console.log(status.data.data, 'gfcstatus');
                    const clinicdetails = this.props.clinicdetails;
                    console.log(clinicdetails, 555);
                    const clinic_id = clinicdetails._id;
                    let details = {
                        user_id: clinic_id
                    };
                    this.getSummary()
                    this.getOrderList(details)
                    // this.setState({ cityTAT: alltats.data.data });
                    this.props.hoc.customAlert("Serum dispatched successfully.", true);

                } else {
                    this.props.hoc.customAlert("Something went wrong.", false);
                }
                // this.setState({ cityTAT: alltats.data.data });
                // this.props.hoc.customAlert("Serum ready to dispatch.", true);




                // alert(order_no);
            }, () => {

            })
        } else {
            this.props.hoc.customAlert("GFC Received should be done first.", false);
        }




    }
    handleAssignDeliveryPerson = (event) => {
        let order_no = event.target.name;
        let type = event.target.id;
        // alert(type);
        let payload = {
            order_no: order_no
        };

        const { orders } = this.state;
        const selectedOrders = orders.filter(item => item.order.order_no == order_no)

        console.log(selectedOrders, 'selectedOrders');
        this.props.saveSelectedOrders(selectedOrders[0])
        this.props.navigate('/clinic/assign-job');
        this.props.setUserType('clinic');
        this.props.setJobType(type);
    }

    render = () => {

        const { countries, states, towns, isLoading, showTowns, selectedState, selectedTown, fromdate, todate, doctorList, selectedDoctor, summaryCols, summaryDetails, exportData, fileName, orders, personalDetails } = this.state;
        let _data = this.setOrderHistoryData(orders)
        console.log(_data, 'tat1234');
        console.log(orders, "callcenterdetailsList");
        console.log(doctorList, "doctorList");
        return (

            <>

                <section className="landing-profl-detls">
                    <div className="container">
                        <StockistHeader />
                        {/* Row end */}
                        <div className="row">
                            <div className="landing-page-pt pt-3 pb-4 px-0">
                                <div className="container">
                                    <div className="mr-landing-page">
                                        <div className="landing-head-pt">
                                            {/* <h2>Call Center</h2> */}
                                            <h5>Welcome {personalDetails?.name} to XO (Koshin Clinic)</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>{" "}
                        {/* Row end*/}
                        <div className="landing-form-dtls p-5">
                            <div className="row">
                                <div className="col-md-6">
                                    <h5 className="mb-20 headingstyle">Order Details</h5>
                                </div>
                                <div className="col-md-6">

                                    {/* <div className="flotright">
                                        <button className="btn btn-success floatright" onClick={() => this.props.navigate('/callcenter/audit-page')}>Audit Trial</button>
                                    </div> */}
                                    <div className="flotright marginright">
                                        <ExportToExcel apiData={exportData} fileName={fileName} />
                                    </div>
                                </div>

                            </div>
                            <div className="row mb-20 ">
                                <div className="col-md-6 col-lg-3 mb-3">
                                    <div className="date-picker-wrap dt-pick-container">
                                        <label htmlFor className="form-label lvl-clr-hd">Order From Date</label>
                                        {/* <input type="date" format="dd-mm-yyyy" value={fromdate} name="fromdate" onChange={(event) => this.changeVal(event)} id className="form-control name-pt-ft" placeholder /> */}
                                        <DatePicker
                                            selected={fromdate}
                                            onChange={(date) => this.changeValFromDate(date)}
                                            dateFormat="dd-MM-yyyy" // Set the desired display format
                                            className="form-control name-pt-ft"
                                            placeholderText="DD-MM-YYYY"
                                        />
                                    </div>

                                </div>
                                <div className="col-md-6 col-lg-3 mb-3">
                                    <div className="date-picker-wrap dt-pick-container">
                                        <label htmlFor className="form-label lvl-clr-hd">Order To Date</label>
                                        {/* <input type="date" format="dd-mm-yyyy" min={this.state.fromdate} value={todate} name="todate" onChange={(event) => this.changeVal(event)} id className="form-control name-pt-ft" placeholder /> */}
                                        <DatePicker
                                            selected={todate}
                                            onChange={(date) => this.changeValToDate(date)}
                                            dateFormat="dd-MM-yyyy" // Set the desired display format
                                            className="form-control name-pt-ft"
                                            placeholderText="DD-MM-YYYY"
                                            minDate={this.state.fromdate}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-3 mb-3">
                                    <label htmlFor="disabledTextInput" className="form-label lvl-clr-hd">State </label>
                                    {
                                        states.length ?
                                            <Dropdown
                                                items={states}
                                                onSelect={(item) => {
                                                    if (item) {
                                                        // let _registration = this.state.registration
                                                        // _registration.address["state"] = item.name
                                                        // _registration.address["town"] = ""
                                                        this.setState({ selectedState: item.name })
                                                        //console.log(item, "item");
                                                        if (item.name != "All") {
                                                            this.populateTowns(item.countryCode, item.isoCode)
                                                        } else {
                                                            this.setState({ towns: [] })
                                                            this.setState({ selectedTown: "" })
                                                        }
                                                    }
                                                }}
                                                value={selectedState}
                                            /> :
                                            <Dropdown
                                                items={states}
                                                value={selectedState}
                                            />
                                    }
                                    {/* <input type="text" value={state} onChange={(event) => this.changeAddr(event)} name="state" id className="form-control name-pt-ft" placeholder /> */}
                                </div>
                                <div className="col-md-6 col-lg-3 mb-3">
                                    <label htmlFor className="form-label lvl-clr-hd">City/Town </label>
                                    {
                                        towns.length ?
                                            <Dropdown
                                                items={towns}
                                                onSelect={(item) => {
                                                    if (item) {
                                                        // alert(item.name);
                                                        // let _registration = this.state.registration
                                                        // _registration.address["town"] = item.name
                                                        this.setState({ selectedTown: item.name })
                                                        //console.log(item, "item")
                                                    }
                                                }}
                                                value={selectedTown}
                                            /> :
                                            <Dropdown
                                                items={towns}
                                                value={selectedTown}
                                            />
                                    }
                                    {/* <input type="text" value={town} onChange={(event) => this.changeAddr(event)} name="town" id className="form-control name-pt-ft" placeholder /> */}
                                </div>
                                <div className="col-md-3 mb-3">
                                    <label htmlFor className="form-label lvl-clr-hd">Doctors </label>
                                    {/* <select name="doctorname" className="form-control name-pt-ft" onChange={(event) => this.changeDoctor(event)} value={selectedDoctor}>
                                        <option value="">Please Select</option>
                                        {doctorList.length > 0 ? doctorList.map(doctor => (
                                            <option value={doctor._id}   >{doctor.name}</option>

                                        )) : <option></option>} */}

                                    {
                                        doctorList.length ?
                                            <DropdownDoctor
                                                items={doctorList}
                                                onSelect={(item) => {
                                                    if (item) {
                                                        // alert(item.name);
                                                        // let _registration = this.state.registration
                                                        // _registration.address["town"] = item.name
                                                        this.setState({ selectedDoctor: item.id })
                                                        //console.log(item, "item")
                                                    }
                                                }}
                                                value={selectedDoctor}
                                            /> :
                                            <DropdownDoctor
                                                items={doctorList}
                                                value={selectedDoctor}
                                            />
                                    }

                                    {/* </select> */}

                                </div>
                                <div className="">
                                    <button type="button" className="landing-success-btn" onClick={(event) => this.searchFilter(event)}>
                                        Search</button>
                                    <button type="button" className="landing-clear-btn" onClick={(event) => this.clearFilter()}>
                                        Clear Filter</button>
                                </div>

                            </div>
                            <Summary
                                columns={summaryCols}
                                data={[{
                                    totalOrderCount: summaryDetails ? summaryDetails.totalOrderCount || 0 : 0,
                                    totalOrderUnderProcessCount: summaryDetails ? summaryDetails.totalOrderUnderProcessCount || 0 : 0,
                                    totalOrderPending: summaryDetails ? summaryDetails.totalOrderPendingCount || 0 : 0,
                                    totalOrderReceivedAtKoshinClinic: summaryDetails ? summaryDetails.totalOrderReceivedAtKoshinClinicCount || 0 : 0,
                                    totalOrderDelivered: summaryDetails ? summaryDetails.totalOrderDeliveredCount || 0 : 0,
                                    // totalOrderCompleted: summaryDetails ? summaryDetails.totalOrderCompletedCount || 0 : 0,
                                }]}
                                clickFn={this.clickFn}
                            />
                            <div className="table-responsive">

                                {

                                    _data && _data.length ?
                                        <CustomDatatable
                                            pagination={true}
                                            columns={[
                                                {
                                                    name: 'Order No',
                                                    // selector: row => row.mr_name,

                                                },
                                                {
                                                    name: 'Order Date',
                                                    // selector: row => row.doctor_name,

                                                },
                                                {
                                                    name: 'QR Code',
                                                    // selector: row => row.doctor_name,

                                                },
                                                {
                                                    name: 'Customer Name',
                                                    // selector: row => row.mr_name,

                                                },
                                                {
                                                    name: 'Customer Mobile',
                                                    // selector: row => row.mr_name,

                                                },
                                                {
                                                    name: 'MR Name',
                                                    // selector: row => row.mr_name,

                                                },
                                                {
                                                    name: 'Doctor Name',
                                                    // selector: row => row.mr_name,

                                                },
                                                {
                                                    name: 'Doctor Mobile',
                                                    // selector: row => row.mr_name,

                                                },
                                                {
                                                    name: 'Stockist Name',
                                                    // selector: row => row.mr_name,

                                                },
                                                // {
                                                //     name: 'Payment Status',
                                                //     // selector: row => row.doctor_name,

                                                // },
                                                {
                                                    name: 'Status',
                                                    // selector: row => row.createdAt,

                                                },
                                                {
                                                    name: 'Track Order',
                                                    // selector: row => row.createdAt,

                                                },
                                                {
                                                    name: 'Collection Job',
                                                    // selector: row => row.createdAt,

                                                },
                                                {
                                                    name: 'GFC Received',
                                                    // selector: row => row.createdAt,

                                                },
                                                {
                                                    name: 'Delivery Job',
                                                }
                                                // {
                                                //     name: 'Dispatch Serum',
                                                //     // selector: row => row.createdAt,

                                                // }
                                            ]}
                                            data={this.setOrderHistoryData(orders)}
                                            pageName="cliniclanding"
                                            handleTrackOrder={this.handleTrackOrder}
                                            handleGFC={this.handleGFC}
                                            handleSerumDispatch={this.handleSerumDispatch}
                                            handleAssignDeliveryPerson={this.handleAssignDeliveryPerson}
                                        />
                                        : <p className="norecord"><strong>No Records Found</strong></p>

                                }
                            </div>
                        </div>
                    </div>
                </section>
                <CustomerFooter />



            </>

        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        // dispatching plain actions
        setCustomer: (customer) => dispatch(CustomerAction.setCustomer(customer)),
        setUserType: (userType) => dispatch(CommonAction.setUserType(userType)),
        setJobType: (jobType) => dispatch(CommonAction.setJobType(jobType)),
        saveSelectedOrders: (selectedOrders) => dispatch(OrderAction.saveSelectedOrders(selectedOrders)),
        saveIndividualOrders: (individualOrders) => dispatch(OrderAction.saveIndividualOrders(individualOrders)),
        setSrlDetails: (srldetails) => dispatch(OrderAction.setSrlDetails(srldetails)),
        setSelectedDetails: (selecteddetails) => dispatch(CallCenterAction.setSelectedDetails(selecteddetails)),
        setMr: (mrdetails) => dispatch(MrAction.setMr(mrdetails)),
        setCallCenterCustomer: (callcentercustomer) => dispatch(CallCenterAction.setCallCenterCustomer(callcentercustomer))
    }
}

const mapStateToProps = ({ CustomerReducer, OrderReducer, CallCenterReducer, CommonReducer, MrReducer, ClinicReducer }) => {
    const { customer } = CustomerReducer
    const { orders } = OrderReducer
    const { callcenterdetails } = CallCenterReducer
    const { summaryDetails } = CommonReducer
    const { mrdetails } = MrReducer
    const { clinicdetails } = ClinicReducer

    console.log(clinicdetails, 123);
    console.log(callcenterdetails, 123456);
    return {
        // dispatching plain actions
        // OrderListReducer: state.OrderListReducer,
        customer,
        orders,
        callcenterdetails,
        mrdetails,
        summaryDetails,
        clinicdetails
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Common(ClinicDashboard)))