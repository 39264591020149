import React from "react";
import { withRouter } from "../../components/Reusable/WithRouter";
import { connect } from "react-redux";
import Common from "../../hoc/Common.hoc";
import CustomerFooter from "../../components/layout/CustomerFooter";
import MrHeader from "../../components/layout/MR/MrHeader";
import MrAction from "../../redux/actions/Mr.action";
import MrService from "../../services/Mr.service";
import { format } from 'date-fns';
import $ from "jquery";
import CustomDatatable from "../../components/Datatable/CustomDatatable.component";
import moment from "moment";
import StockistHeader from "../../components/layout/Stockist/StockistHeader";
import StockistService from "../../services/Stockist.service";
import CallCenterService from "../../services/CallCenter.service";
import OrderAction from "../../redux/actions/Order.action";
import OtherService from "../../services/OtherService";
import CustomerAction from "../../redux/actions/Customer.action";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; // Import the CSS
import CountryService from '../../services/Country.service';
import Dropdown from '../../components/SearchableDropdown/Dropdown.component';
import DropdownDoctor from '../../components/SearchableDropdown/DropdownDoctor.component';
import CommonAction from "../../redux/actions/Common.action";
import CallCenterAction from "../../redux/actions/CallCenter.action";
import Summary from "../../components/Summary/Summary.component";
import { ExportToExcel } from "../../components/Excel/ExportToExcel";
import OrderService from "../../services/Order.service";
import ClinicService from "../../services/Clinic.service";
import AdminService from "../../services/Admin.service";
class AssignJob extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            orderDetails: [],
            assignjob: "",
            deliveryPersonDetails: [],
            selectedDeliveryPerson: "",
            selectedDeliveryPerson_new: "",
            assignJobHistory: [],
            isGFCReceived: '',
            jobtype_data: ""
        }
    }


    componentDidMount() {

        const jobtype = this.props.jobType;
        // alert(jobtype);

        const selectedOrderDetails = this.props.individualOrders;
        if (selectedOrderDetails) {

            console.log(selectedOrderDetails, 696969);

            let checkisGFC = selectedOrderDetails.order_status.filter(item => {
                return item.status == "received_at_clinic"
            })
            if (checkisGFC.length > 0) {
                this.setState({ isGFCReceived: 'yes' });
            } else {
                this.setState({ isGFCReceived: 'no' });
            }


            this.setState({ orderDetails: selectedOrderDetails });
            this.getDeliveryPerson();


        }
        const { jobtype_data } = this.state;
        let payload = {
            order_id: selectedOrderDetails.order ? selectedOrderDetails.order._id : null,
            jobtype_data: jobtype
        }

        console.log(payload, 9999);
        this.AssignJobhistory(payload)
        console.log(selectedOrderDetails, 56666);
        if (jobtype == 'collection') {

            this.setState({ jobtype_data: jobtype, assignJob: "Collection Job" });
        } else {
            this.setState({ jobtype_data: jobtype, assignJob: "Delivery Job" });
        }

    }

    AssignJobhistory = async (payload) => {

        try {
            this.setState({ assignJobHistory: [] })

            let res = await AdminService.getAssignJobHistory(payload)
            console.log(res.data.data, 123333)
            // return
            if (res.data.success) {
                console.log(res.data.data.data, "lasjdhjkash");
                this.setState({ assignJobHistory: res.data.data.data });
            }

        } catch (error) {

        }
    }

    getDeliveryPerson = async () => {

        const { orderDetails } = this.state;
        try {
            this.setState({ deliveryPersonDetails: [] })
            const clinicDetails = this.props.clinicdetails;
            let payload = {
                state: orderDetails.doctor ? orderDetails.doctor.address.state : '',
                clinic_id: clinicDetails._id
            };

            console.log(payload, 300011);

            let res = await AdminService.getDeliveryPersonDetails(payload)
            console.log(res, 123333000)
            if (res.data.success) {
                console.log(res.data.data.data, "lasjdhjkash222");
                let newData = [];

                for (let i = 0; i < res.data.data.data.length; i++) {
                    let dataStruct = {
                        "name": res.data.data.data[i].name,
                        "label": res.data.data.data[i].name,
                        "value": res.data.data.data[i]._id
                    };
                    newData.push(dataStruct);
                }
                this.setState({ deliveryPersonDetails: newData });
            }

        } catch (error) {

        }
    }

    componentWillUnmount() {
        if (this.dataTable) {
            this.dataTable.destroy(true);
        }
    }

    changeVal = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        this.setState({ assignjob: value });
    }

    handleAssignJob = async (event) => {

        const { assignjob, selectedDeliveryPerson, orderDetails, selectedDeliveryPerson_new, jobtype_data } = this.state

        if (assignjob && assignjob == '') {
            this.props.hoc.customAlert('Please Assign Job Type.', false)
            return false
        } else if (selectedDeliveryPerson && selectedDeliveryPerson == "") {
            this.props.hoc.customAlert('Please select delivery person.', false)
            return false
        } else {
            let payload = {
                assignjob: assignjob,
                selectedDeliveryPerson: selectedDeliveryPerson,
                order_id: orderDetails.order._id,
                jobtype_data: jobtype_data
            }

            console.log(payload, 1706);
            //return
            // return
            let res = await AdminService.assignJobToDeliveryPerson(payload)
            console.log(res, 123333)
            // return
            if (res.data.data.data.success) {

                let message = "Delivery Person : " + selectedDeliveryPerson_new + " Assigned Successfully";
                this.props.hoc.customAlert(message, true);
                let finalpayload = {
                    order_id: orderDetails.order ? orderDetails.order._id : null,
                    jobtype_data: jobtype_data
                }
                this.AssignJobhistory(finalpayload)

            } else {
                this.props.hoc.customAlert('Already assigned with same job on this order', false);
            }
        }
    }

    handleClickHome = () => {

        this.props.navigate('/clinic/dashboard')
    }

    setOrderHistoryData = (data) => {
        let resp = []
        for (let i = 0; i < data.length; i++) {
            resp.push({
                _id: data[i]._id,
                order_no: data[i].order ? data[i].order.order_no : '',
                order_date: data[i].order ? moment(data[i].order.createdAt).format('DD-MM-YYYY') : '',
                assignJob: data[i].assignJob,
                deliveryPersonName: data[i].user ? data[i].user.name : '',
                deliveryPersonPhoneNo: data[i].user ? data[i].user.mobileno : '',
                qrname: data[i].qrcode ? data[i].qrcode.unique_qr_id : '',
                drname: data[i].doctor ? data[i].doctor.name : '',
                // drmobileno: data[i].doctor ? data[i].doctor.mobileno : '',
                patientname: data[i].patient ? data[i].patient.name : '',
                assignDate: data[i].createdAt ? moment(data[i].createdAt).format('DD-MM-YYYY') : '',

            })
        }
        return resp
    }



    render = () => {
        const { orderDetails, deliveryPersonDetails, selectedDeliveryPerson, selectedDeliveryPerson_new, assignJobHistory, isGFCReceived, jobtype_data, assignjob } = this.state;
        // alert(jobtype_data);

        let _data = this.setOrderHistoryData(assignJobHistory)
        console.log(orderDetails, 6666);

        return (

            <>

                <section className="landing-profl-detls">
                    <div className="container">
                        <StockistHeader />
                        {/* Row end */}

                        {/* Row end*/}
                        <div className="landing-form-dtls p-5">
                            <div className="row">
                                <div className="col-md-6">
                                    <h5 className="mb-20 headingstyle">Assign Delivery Person</h5>
                                </div>
                                <div className='col-md-6 '>
                                    <div className="btn-home flotright mx-4" onClick={this.handleClickHome}>
                                        <a href="javascript:void(0);" >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg>
                                            Back
                                        </a>
                                    </div>
                                </div>


                            </div>
                            {/* <div className="row mb-20 ">
                                <div className="col-md-6">
                                    <p>Order No: {orderDetails.order?.order_no}</p>
                                    <p>Order Date: {orderDetails.order ? moment(orderDetails.ordercreatedAt).format('DD-MM-YYYY') : ''}</p>
                                    <p>Patient Name: {orderDetails.patient?.name}</p>
                                    <p>Doctor Name: {orderDetails.doctor?.name}</p>

                                </div>
                            </div> */}
                            <div className="row mt-5 mb-5">

                                {
                                    jobtype_data == 'collection' ?
                                        <div className="col-md-3">
                                            <label htmlFor className="form-label lvl-clr-hd">Job Type<span className='requiredFld'> * </span></label>
                                            <select className="form-control" name="assignjob" onChange={(event) => this.changeVal(event)}>

                                                <option value="Collection Job" selected>Collection Job</option>

                                            </select>
                                        </div> :
                                        <div className="col-md-3">
                                            <label htmlFor className="form-label lvl-clr-hd">Job Type<span className='requiredFld'> * </span></label>
                                            <select className="form-control" name="assignjob" onChange={(event) => this.changeVal(event)}>

                                                <option value="Delivery Job" >Delivery Job</option>
                                            </select>
                                        </div>


                                }


                                <div className="col-md-3 mb-3">
                                    <label htmlFor className="form-label lvl-clr-hd">Delivery Person<span className='requiredFld'> * </span></label>

                                    {
                                        deliveryPersonDetails.length ?
                                            <Dropdown
                                                items={deliveryPersonDetails}
                                                onSelect={(item) => {
                                                    if (item) {

                                                        this.setState({ selectedDeliveryPerson: item.value, selectedDeliveryPerson_new: item.name })

                                                        //console.log(item, "item")
                                                    }
                                                }}
                                                value={selectedDeliveryPerson_new}
                                            /> :
                                            <Dropdown
                                                items={deliveryPersonDetails}
                                                value={selectedDeliveryPerson_new}
                                            />
                                    }
                                </div>
                                <div className="col-md-3 mt-4 mx-5">

                                    <button type="button" className="landing-success-btn mt-1 assignbtn" onClick={(event) => this.handleAssignJob(event)}>
                                        Assign</button>
                                    {/* <button type="button" className="landing-clear-btn" onClick={(event) => this.clearFilter()}>
                                        Clear Filter</button> */}
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6">
                                    <h5 className="mb-20 headingstyle">{jobtype_data == 'collection' ? 'Collection' : 'Delivery'} Job Assignment Records</h5>
                                </div>

                            </div>

                            <div className="table-responsive">

                                {

                                    _data && _data.length ?
                                        <CustomDatatable
                                            pagination={true}
                                            columns={[
                                                {
                                                    name: 'Order No',
                                                    // selector: row => row.mr_name,

                                                },
                                                {
                                                    name: 'Order Date',
                                                    // selector: row => row.doctor_name,

                                                },
                                                {
                                                    name: 'QR Code',
                                                    // selector: row => row.mr_name,

                                                },
                                                {
                                                    name: 'Doctor Name',
                                                    // selector: row => row.doctor_name,

                                                },

                                                {
                                                    name: 'Customer Name',
                                                    // selector: row => row.doctor_name,

                                                },
                                                {
                                                    name: 'Assigned Date',
                                                    // selector: row => row.doctor_name,

                                                },
                                                {
                                                    name: 'Job Type',
                                                    // selector: row => row.mr_name,

                                                },
                                                {
                                                    name: 'Delivery Person',
                                                    // selector: row => row.mr_name,

                                                },
                                                {
                                                    name: 'Delivery Person Mobile No',
                                                    // selector: row => row.mr_name,

                                                }




                                            ]}
                                            data={this.setOrderHistoryData(assignJobHistory)}
                                            pageName="assignjobhistory"

                                        />
                                        : <p className="norecord"><strong>No Records Found</strong></p>

                                }
                            </div>


                        </div>
                    </div>
                </section>
                <CustomerFooter />



            </>

        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        // dispatching plain actions
        setCustomer: (customer) => dispatch(CustomerAction.setCustomer(customer)),
        setUserType: (userType) => dispatch(CommonAction.setUserType(userType)),
        saveSelectedOrders: (selectedOrders) => dispatch(OrderAction.saveSelectedOrders(selectedOrders)),
        saveIndividualOrders: (individualOrders) => dispatch(OrderAction.saveIndividualOrders(individualOrders)),
        setSrlDetails: (srldetails) => dispatch(OrderAction.setSrlDetails(srldetails)),
        setSelectedDetails: (selecteddetails) => dispatch(CallCenterAction.setSelectedDetails(selecteddetails)),
        setMr: (mrdetails) => dispatch(MrAction.setMr(mrdetails)),
        setCallCenterCustomer: (callcentercustomer) => dispatch(CallCenterAction.setCallCenterCustomer(callcentercustomer))
    }
}

const mapStateToProps = ({ CustomerReducer, OrderReducer, CallCenterReducer, CommonReducer, MrReducer, ClinicReducer }) => {
    const { customer } = CustomerReducer
    const { individualOrders } = OrderReducer
    const { callcenterdetails } = CallCenterReducer
    const { jobType } = CommonReducer
    const { mrdetails } = MrReducer
    const { clinicdetails } = ClinicReducer

    console.log(clinicdetails, 123);
    console.log(individualOrders, 123456);
    return {
        // dispatching plain actions
        // OrderListReducer: state.OrderListReducer,
        customer,
        individualOrders,
        callcenterdetails,
        mrdetails,
        jobType,
        clinicdetails
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Common(AssignJob)))