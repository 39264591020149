import React, { Component } from 'react';
import { connect } from 'react-redux';
import CustomerFooter from '../../../components/layout/CustomerFooter';
import { withRouter } from '../../../components/Reusable/WithRouter';
import CustomerHeader from '../../../components/layout/CustomerHeader';
import config from '../../../config/emrok.config';
import { Link } from 'react-router-dom';
import OtherService from '../../../services/OtherService';
import Common from '../../../hoc/Common.hoc';
import { format } from 'date-fns';
import moment from 'moment';
import MrHeader from "../../../components/layout/MR/MrHeader";




class TrackInfoOrder extends Component {

    constructor(props) {
        super(props)
        this.canvasRef = React.createRef();
        this.state = {
            orderId: "",
            customerId: "",
            orderDetails: undefined,
            orderDate: "",
            samplePickedUp: "",
            samplePickedUpDate: "",
            bloodRequestDate: "",
            rtochangeDate: "",
            checkSTATUS: ['RTO', 'rejected'],
            orderData: {},
            readyToDispatch: [],
            receivedAtClinic: [],
            dispatchFromClinic: [],
            shipmentdate: "",
            completedDetails: [],
            uniqueQrId: "",
            qrImagePath: "",
            status1A: "",
            status2A: "",
            status3A: "",
            status1B: "",
            status2B: "",



        }
    }



    componentDidMount = async () => {
        const orderDetails = this.props.OrderReducer.individualOrders;
        console.log(orderDetails, 20000);
        console.log(orderDetails._id, 'orderDetails._id');

        if (orderDetails) {
            console.log(orderDetails[0].unique_qr_id, 5000);
            this.setState({ orderDetails: orderDetails[0] })
            this.setState({ uniqueQrId: orderDetails[0].unique_qr_id, qrImagePath: orderDetails[0].qr_image_path })
            const allstatus = orderDetails[0].order_status;
            const awbtracksStatus = orderDetails[0].awbtrack;
            let awbtracks = [];

            if (allstatus) {
                console.log(allstatus, 'allstatus');
                const readyToDispatchDetails = await allstatus.filter(
                    (item) => item.status == 'Pickup Initiated'
                );

                const status1AData = await allstatus.filter(
                    (item) => item.status == 'Out for X-care tubes collection'
                );
                const status2AData = await allstatus.filter(
                    (item) => item.status == 'X-care Tubes Picked-up from Doctor’s clinic and Out for delivery of X-care tubes to Koshin Clinic'
                );
                const status3AData = await allstatus.filter(
                    (item) => item.status == 'X-care Tubes delivered to Koshin Clinic'
                );
                const status1BData = await allstatus.filter(
                    (item) => item.status == 'XO Face serum picked up from Koshin Clinic and out for delivery to doctor’s clinic'
                );
                const status2BData = await allstatus.filter(
                    (item) => item.status == 'XO Face serum Delivered to Doctor’s clinic'
                );

                console.log(status1AData, 3636361);
                console.log(status1AData, 3636362);
                console.log(status1AData, 3636363);
                console.log(status1AData, 3636364);
                console.log(status1AData, 3636365);



                if (awbtracksStatus) {

                    awbtracks = await awbtracksStatus.filter(
                        (item) => item.orderId == orderDetails[0]._id
                    );
                }

                //   console.log(awbtracks[0].ShipmentPickupDate,'awbtracks');



                const receivedAtClinic = await allstatus.filter(
                    (item) => item.status == 'received_at_clinic'
                );

                const dispatchFromClinic = await allstatus.filter(
                    (item) => item.status == 'dispatch_from_clinic' && item.AWBNo != null
                );

                const isCompleted = await allstatus.filter(
                    (item) => item.status == 'completed'
                );

                console.log(dispatchFromClinic, 'dispatchFromClinic');

                this.setState({ readyToDispatch: readyToDispatchDetails, receivedAtClinic: receivedAtClinic, dispatchFromClinic: dispatchFromClinic, shipmentdate: (awbtracks.length > 0 ? awbtracks[0] : ''), completedDetails: isCompleted, status1A: status1AData, status2A: status2AData, status3A: status3AData, status1B: status1BData, status2B: status2BData });

            }

        }

        console.log(orderDetails[0], "order details 123");


        this.drawImages();
    }
    componentDidUpdate(prevProps) {
        if (prevProps.qrImagePath !== this.props.qrImagePath || prevProps.config !== this.props.config) {
            this.drawImages();
        }
    }

    drawImages = () => {
        const { qrImagePath } = this.state;
        const downloadUrl = config.serviceUrl + '/images/qr_codes/saisei/' + qrImagePath;
        const canvas = this.canvasRef.current;
        const ctx = canvas.getContext('2d');
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.imageSmoothingEnabled = true;
        ctx.imageSmoothingQuality = 'high';
        // Load the image
        const img = new Image();
        img.crossOrigin = 'Anonymous'; // Set crossOrigin to Anonymous
        img.src = downloadUrl;
        img.onload = () => {
            // Draw the image 4 times horizontally
            for (let i = 0; i < 4; i++) {
                // ctx.drawImage(img, i * 100, 0, 100, 100);
                ctx.drawImage(img, i * 70, 0, 70, 70);
            }
        };
    };

    handleDownload = () => {
        try {
            const { qrImagePath } = this.state;
            const canvas = this.canvasRef.current;
            const link = document.createElement('a');
            link.download = qrImagePath + '.png';
            link.href = canvas.toDataURL('image/png');
            link.click();
        } catch (err) {
            console.error('Error downloading canvas:', err);
        }
    };

    getOrderDateFormat = (datestring) => {


        const date = new Date(datestring);

        const formattedDate = format(date, "hh:mm a, dd MMMM yyyy");

        return formattedDate;
    }

    checkWithCurrentDate = (givenDate) => {

        console.log(givenDate, "givenDate");

        if (givenDate != undefined) {
            const timestampMatches = givenDate.match(/\d+/);
            if (timestampMatches && timestampMatches.length > 0) {

                const timestamp = parseInt(timestampMatches[0]);

                // Create a moment object from the extracted timestamp
                const givenMoment = moment(timestamp);

                // Get the current date and time
                const now = moment();

                // Compare the current date with the given date
                const isSameOrGreater = now.isSameOrAfter(givenMoment, 'day');

                // Format the dates for display
                const formattedGivenDate = givenMoment.format('D MMM YYYY, h:mm a');
                const formattedCurrentDateTime = now.format('D MMM YYYY, h:mm a');

                // Update the state with the formatted dates
                // setCurrentDateTime(formattedCurrentDateTime);

                console.log('Is current date same or greater:', isSameOrGreater);


                // Get the current date and time
                if (isSameOrGreater) {
                    return true;
                } else {
                    return false;
                }


                // return true;
            }
        } else {
            return false;
        }


    }

    checkWithCurrentDateRequest = (givenDateValue) => {

        console.log(givenDateValue, "givenDate");

        if (givenDateValue != undefined) {
            const givenDateString = givenDateValue;

            // Parse the given date string using moment
            const givenDate = moment(givenDateString, 'hh:mm A, DD MMMM YYYY');

            // Get the current date and time using moment
            const currentDate = moment();

            // Compare the current date with the given date
            const isSameOrGreater = currentDate.isSameOrAfter(givenDate);

            console.log('Is current date same or greater:', isSameOrGreater);


            // Get the current date and time
            if (isSameOrGreater) {
                return true;
            } else {
                return false;
            }


            // return true;

        } else {
            return false;
        }


    }

    getOrderDateFormatInISO = (datestring) => {

        const changeDate = moment(datestring).toDate();
        const parsedDate = moment(changeDate, 'hh:mm A, DD MMMM YYYY');

        // Format the parsed date as ISODate format
        const isoDate = parsedDate.toISOString();

        const date = new Date(isoDate);

        const formattedDate = format(date, "hh:mm a, dd MMMM yyyy");

        return formattedDate;
    }

    goBackPrevious = () => {
        const usertype = this.props.CommonReducer.userType;

        if (usertype == "callcenter") {
            this.props.navigate('/callcenter/list');
        }
        if (usertype == "mr") {
            this.props.navigate('/mr/list');
        }
        if (usertype == "doctor") {
            this.props.navigate('/doctor/list');
        }
        if (usertype == "manufactur") {
            this.props.navigate('/manufactur/list');
        }
        if (usertype == "stockist") {
            this.props.navigate('/stockist/list');
        }
        if (usertype == "patient") {
            this.props.navigate('/customer/dashboard');
        }
        if (usertype == "admin") {
            this.props.navigate('/admin/order-managment');
        }
        if (usertype == "clinic") {
            this.props.navigate('/clinic/dashboard');
        }
        if (usertype == "bu") {
            this.props.navigate('/bu/dashboard');
        }
        if (usertype == "nsm") {
            this.props.navigate('/nsm/dashboard');
        }
        if (usertype == "zm") {
            this.props.navigate('/zm/dashboard');
        }
        if (usertype == "rm") {
            this.props.navigate('/rm/dashboard');
        }
        if (usertype == "delivery_person") {
            this.props.navigate('/delivery-person/dashboard');
        }

        // this.props.history.goBack();
        // this.props.navigate(this.props.backurl);
    };

    downloadImage = (imageUrl, imageName) => {
        // Fetch the image
        fetch(imageUrl)
            .then(response => response.blob())
            .then(blob => {
                // Create a blob URL for the image
                const url = window.URL.createObjectURL(new Blob([blob]));

                // Create a temporary link element
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', imageName);

                // Append the link to the body and click it
                document.body.appendChild(link);
                link.click();

                // Clean up: remove the temporary link and revoke the blob URL
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            })
            .catch(error => {
                console.error('Error downloading image:', error);
            });
    };

    downloadQr = async () => {

        try {
            const { uniqueQrId, qrImagePath } = this.state;
            const downloadUrl = config.serviceUrl + '/images/qr_codes/saisei/' + qrImagePath;
            // Fetch the image as a blob
            const response = await fetch(downloadUrl);

            console.log(response);
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const blob = await response.blob();
            console.log(blob);  // Log the blob to ensure it contains data

            if (blob.size === 0) {
                throw new Error('Blob size is 0');
            }
            // Create an object URL for the blob
            const url = URL.createObjectURL(blob);

            // Create an anchor element
            const link = document.createElement('a');
            link.href = url;
            link.download = qrImagePath; // Set the download attribute with a filename

            // Append the link to the body
            document.body.appendChild(link);

            // Programmatically click the link to trigger the download
            link.click();

            // Clean up the URL object and remove the link from the document
            URL.revokeObjectURL(url);
            document.body.removeChild(link);

        } catch (err) {
            this.props.hoc.customAlert("Something went wrong.", false)
            console.log(err, "err")
        }


    }

    render = () => {
        const { orderDetails, readyToDispatch, receivedAtClinic, dispatchFromClinic, shipmentdate, completedDetails, uniqueQrId, qrImagePath, status1A, status2A, status3A, status1B, status2B } = this.state;

        console.log(orderDetails, 6666);
        console.log(receivedAtClinic, 6666663);
        console.log(shipmentdate, 6666662);
        console.log(dispatchFromClinic, 1111);
        // console.log(orderDetails.qr_image_path,20222);


        return (
            <>
                <section className="order-infrmtn-pt pt-0 order-information-container">
                    <div className="container">
                        <MrHeader />

                        <div className="row">
                            <div className="ordr-info-part position-relative">
                                <div className='row mt-20'>
                                    <div className='col-6'>
                                        <h4 className='headingstyle'>Track Shipment</h4>
                                    </div>
                                    <div className='col-6'>
                                        <div className="btn-home flotright" onClick={this.goBackPrevious}>
                                            <a href="javascript:void(0);" >
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg>
                                                Back
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-md-4'>
                                        <div className="d-flex align-items-center justify-content-between mt-10">
                                            <div className="title">
                                                <div className='ml-20'>
                                                    <p><b>Order Id</b> - {orderDetails ? orderDetails.order?.order_no : ''}</p>
                                                    <p><b>Customer Name</b> - {orderDetails ? orderDetails.patient?.name : ''} </p>
                                                    <p><b>Customer Mobile No.</b> - {orderDetails ? orderDetails.patient?.mobileno : ''}</p>
                                                    <p><b>Customer Email</b> - {orderDetails ? (orderDetails.patient ? (orderDetails.patient.email ? orderDetails.patient.email : '') : '') : ''}</p>
                                                    <br></br>


                                                    <p><b>Doctor Name</b> - {orderDetails ? orderDetails.doctor?.name : ''}</p>
                                                    <p><b>Doctor Mobile No.</b> - {orderDetails ? orderDetails.doctor?.mobileno : ''}</p>
                                                    <p><b>Doctor Email</b> - {orderDetails ? orderDetails.doctor?.email : ''}</p>
                                                    <p><b>Doctor Address</b> - {orderDetails ? (orderDetails.doctoraddress ? orderDetails.doctoraddress.addressLine1 + ', ' + orderDetails.doctoraddress.addressLine2 + ', ' + orderDetails.doctoraddress.town + ', ' + orderDetails.doctoraddress.state + ', ' + orderDetails.doctoraddress.pin : orderDetails.doctor.address.addressLine1 + ', ' + orderDetails.doctor.address.addressLine2 + ', ' + orderDetails.doctor.address.town + ', ' + orderDetails.doctor.address.state + ', ' + orderDetails.doctor.address.pin) : ''}</p>
                                                    {/* <p>Patient Address - </p> */}
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div className='col-md-6'>

                                        <div className="d-flex align-items-center justify-content-center position-relative">

                                            {/* <div className='doqnload_qr_btn'>
                                                <a onClick={this.downloadQr} href="javascript:void(0)" download className=''>
                                                    <svg xmlns="" width="40px" height="40px" viewBox="0 0 24 24"><g stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><path fill="none" stroke-dasharray="14" stroke-dashoffset="14" d="M6 19h12"><animate fill="freeze" attributeName="stroke-dashoffset" dur="0.4s" values="14;0" /></path><path fill="currentColor" d="M12 4 h2 v6 h2.5 L12 14.5M12 4 h-2 v6 h-2.5 L12 14.5"><animate attributeName="d" calcMode="linear" dur="1.5s" keyTimes="0;0.7;1" repeatCount="indefinite" values="M12 4 h2 v6 h2.5 L12 14.5M12 4 h-2 v6 h-2.5 L12 14.5;M12 4 h2 v3 h2.5 L12 11.5M12 4 h-2 v3 h-2.5 L12 11.5;M12 4 h2 v6 h2.5 L12 14.5M12 4 h-2 v6 h-2.5 L12 14.5" /></path></g></svg>
                                                </a>
                                            </div>
                                            <div className="qr-image-box">
                                                <img
                                                    className='qr_image'
                                                    src={config.serviceUrl + '/images/qr_codes/saisei/' + qrImagePath}
                                                    alt="Logo Large"
                                                    height={100}
                                                />
                                               
                                            </div> */}
                                            <canvas ref={this.canvasRef} width={300} height={100} />
                                            <a className="download_qr" onClick={this.handleDownload}> <svg xmlns="" width="40px" height="40px" viewBox="0 0 24 24"><g stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><path fill="none" stroke-dasharray="14" stroke-dashoffset="14" d="M6 19h12"><animate fill="freeze" attributeName="stroke-dashoffset" dur="0.4s" values="14;0" /></path><path fill="currentColor" d="M12 4 h2 v6 h2.5 L12 14.5M12 4 h-2 v6 h-2.5 L12 14.5"><animate attributeName="d" calcMode="linear" dur="1.5s" keyTimes="0;0.7;1" repeatCount="indefinite" values="M12 4 h2 v6 h2.5 L12 14.5M12 4 h-2 v6 h-2.5 L12 14.5;M12 4 h2 v3 h2.5 L12 11.5M12 4 h-2 v3 h-2.5 L12 11.5;M12 4 h2 v6 h2.5 L12 14.5M12 4 h-2 v6 h-2.5 L12 14.5" /></path></g></svg></a>
                                        </div>

                                    </div>

                                </div>


                            </div>
                            <div className="col-md-6 track-shipping-ft mt-4">
                                <div className="track-point-pt">
                                    {/* <h5>Track Shipment</h5> */}
                                    <div className="col-md-12 col-lg-12">
                                        <div id="tracking-pre" />
                                        <div id="tracking">
                                            <div className="tracking-list">

                                                {<div>

                                                    <div className="tracking-item current-tracking-item">
                                                        <div className="tracking-icon status-current status-intransit">
                                                            <svg
                                                                className="svg-inline--fa fa-circle fa-w-16"
                                                                aria-hidden="true"
                                                                data-prefix="fas"
                                                                data-icon="circle"
                                                                role="img"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 512 512"
                                                                data-fa-i2svg=""
                                                            >
                                                                <path
                                                                    fill="currentColor"
                                                                    d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"
                                                                />
                                                            </svg>
                                                        </div>
                                                        <div className="tracking-content">
                                                            Order Placed<span>{orderDetails ? this.getOrderDateFormat(orderDetails.order.createdAt) : ''}</span>
                                                        </div>
                                                    </div>
                                                    <div className={(status2A.length > 0) ? "tracking-item current-tracking-item" : "tracking-item pending"}>
                                                        <div className={(status1A.length > 0) ? "tracking-icon status-current status-intransit" : "tracking-icon status-intransit"}>
                                                            <svg
                                                                className="svg-inline--fa fa-circle fa-w-16"
                                                                aria-hidden="true"
                                                                data-prefix="fas"
                                                                data-icon="circle"
                                                                role="img"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 512 512"
                                                                data-fa-i2svg=""
                                                            >
                                                                <path
                                                                    fill="currentColor"
                                                                    d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"
                                                                />
                                                            </svg>
                                                        </div>
                                                        <div className={(status1A.length > 0) ? "tracking-content" : "tracking-content text-muted"}>
                                                            Out for X-care tubes collection<span>
                                                                {status1A.length > 0 ? moment(status1A[0].createdAt).format('hh:mm A, DD MMMM YYYY') : ''}

                                                            </span>

                                                        </div>
                                                    </div>
                                                    <div className={(status3A.length > 0) ? "tracking-item current-tracking-item" : "tracking-item pending"}>
                                                        <div className={(status2A.length > 0) ? "tracking-icon status-current status-intransit" : "tracking-icon status-intransit"}>
                                                            <svg
                                                                className="svg-inline--fa fa-circle fa-w-16"
                                                                aria-hidden="true"
                                                                data-prefix="fas"
                                                                data-icon="circle"
                                                                role="img"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 512 512"
                                                                data-fa-i2svg=""
                                                            >
                                                                <path
                                                                    fill="currentColor"
                                                                    d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"
                                                                />
                                                            </svg>
                                                        </div>
                                                        <div className={(status2A.length > 0) ? "tracking-content" : "tracking-content text-muted"}>
                                                            X-care Tubes Picked-up from Doctor’s clinic and Out for delivery of X-care tubes to Koshin Clinic<span>
                                                                {status2A.length > 0 ? moment(status2A[0].createdAt).format('hh:mm A, DD MMMM YYYY') : ''}

                                                            </span>

                                                        </div>
                                                    </div>
                                                    <div className={(status1B.length > 0) ? "tracking-item current-tracking-item" : "tracking-item pending"}>
                                                        <div className={(status3A.length > 0) ? "tracking-icon status-current status-intransit" : "tracking-icon status-intransit"}>
                                                            <svg
                                                                className="svg-inline--fa fa-circle fa-w-16"
                                                                aria-hidden="true"
                                                                data-prefix="fas"
                                                                data-icon="circle"
                                                                role="img"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 512 512"
                                                                data-fa-i2svg=""
                                                            >
                                                                <path
                                                                    fill="currentColor"
                                                                    d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"
                                                                />
                                                            </svg>
                                                        </div>
                                                        <div className={(status3A.length > 0) ? "tracking-content" : "tracking-content text-muted"}>
                                                            X-care Tubes delivered to Koshin Clinic
                                                            <span>{status3A.length > 0 ? moment(status3A[0].createdAt).format('hh:mm A, DD MMMM YYYY') : ''}</span>


                                                        </div>
                                                    </div>
                                                    <div className={(status2B.length > 0) ? "tracking-item current-tracking-item" : "tracking-item pending"}>
                                                        <div className={(status1B.length > 0) ? "tracking-icon status-current status-intransit" : "tracking-icon status-intransit"}>
                                                            <svg
                                                                className="svg-inline--fa fa-circle fa-w-16"
                                                                aria-hidden="true"
                                                                data-prefix="fas"
                                                                data-icon="circle"
                                                                role="img"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 512 512"
                                                                data-fa-i2svg=""
                                                            >
                                                                <path
                                                                    fill="currentColor"
                                                                    d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"
                                                                />
                                                            </svg>
                                                        </div>
                                                        <div className={(status1B.length > 0) ? "tracking-content" : "tracking-content text-muted"}>
                                                            XO Face serum picked up from Koshin Clinic and out for delivery to doctor’s clinic
                                                            <span>{status1B.length > 0 ? moment(status1B[0].createdAt).format('hh:mm A, DD MMMM YYYY') : ''}</span>

                                                        </div>
                                                    </div>
                                                    <div className="tracking-item pending">
                                                        <div className={(status2B.length > 0) ? "tracking-icon status-current status-intransit" : "tracking-icon status-intransit"}>
                                                            <svg
                                                                className="svg-inline--fa fa-circle fa-w-16"
                                                                aria-hidden="true"
                                                                data-prefix="fas"
                                                                data-icon="circle"
                                                                role="img"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 512 512"
                                                                data-fa-i2svg=""
                                                            >
                                                                <path
                                                                    fill="currentColor"
                                                                    d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"
                                                                />
                                                            </svg>
                                                        </div>
                                                        <div className={(status1B.length > 0) ? "tracking-content" : "tracking-content text-muted"}>
                                                            XO Face serum Delivered to Doctor’s clinic
                                                            <span>{status2B.length > 0 ? moment(status2B[0].createdAt).format('hh:mm A, DD MMMM YYYY') : ''}</span>

                                                        </div>
                                                    </div>


                                                </div>


                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-order-track-side-banner mt-5">
                                <div className="order-info-bg-box">
                                    {/* <img src="images/order-info-bg.png" alt="Order Info" /> */}
                                    <img src={`${config.serviceUrl}/images/myownn/register-bg-image.jpg`} className="trackinfoImage" alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="dwn-btn-trk mt-4">

                                <div>

                                </div>
                                {/* <div>
                                    <a href={config.knowMoreLink} className="knw-mr-track" target="_blank" rel="noopener noreferrer">
                                        Know more
                                    </a>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </section>

                <CustomerFooter />

            </>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        // dispatching plain actions
        // setCustomer: (customer) => dispatch(CustomerAction.setCustomer(customer))
    }
}

const mapStateToProps = (state) => {
    return {
        OrderReducer: state.OrderReducer,
        CustomerReducer: state.CustomerReducer,
        CommonReducer: state.CommonReducer
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Common(TrackInfoOrder)))