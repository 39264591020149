import {
    SET_CLINIC,
    CLEAR_STATE
    
    } from '../types/Clinic.type'
    class ClinicAction {
      setClinic = (data) => {
        console.log(">>>>>>>>>>>>>>>>",data)
        return { type: SET_CLINIC, payload: data }
      }
      logout = ()=>{
        return { type: CLEAR_STATE, payload: null }
      }
     
      
    }
    export default new ClinicAction()