import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from '../../../../components/Reusable/WithRouter';
// import config from '../../../config/emrok.config';
import Common from '../../../../hoc/Common.hoc';
// import axios from 'axios';
// import moment from 'moment';
import AdminHeader from "../../../../components/layout/Admin/AdminHeader";
import AdminSidebar from "../../../../components/layout/Admin/AdminSidebar";
import AdminFooter from "../../../../components/layout/Admin/AdminFooter";
import '../../../../assets/css/admin/style.css';
import '../../../../assets/css/admin/responsive.css';
// import DropdownDoctorRuntime from '../../../components/SearchableDropdown/DropdownDoctorRuntime.component';
import AdminService from '../../../../services/Admin.service';
// import CustomDatatable from "../../../components/Datatable/CustomDatatable.component";
import WarehouseUserAction from '../../../../redux/actions/WarehouseUser.action';
// import WarehouseUserReducer from '../../../../redux/reducers/WarehouseUser.reducer';

class WarehouseUserEdit extends Component {
    constructor(props) {
        super(props)

        this.state = {
            // callCenterList: [],
            name: "",
            email: "",
            mobileno: "",
            status: "",
            id: ""
        }

    }


    componentDidMount() {
        const selectedWarehouseUser = this.props.WarehouseUserReducer.warehouseUserDetails;
        if (selectedWarehouseUser) {
            this.setState({ name: selectedWarehouseUser.name, email: selectedWarehouseUser.email, mobileno: selectedWarehouseUser.mobileno, id: selectedWarehouseUser._id, status: selectedWarehouseUser.status });
        }
        // console.log(selectedWarehouseUser, 5555);
    }

    changeVal = async (event) => {
        const name = event.target.name;
        const value = event.target.value;
        if (name == 'name') {
            this.setState({ name: value });
        }
        if (name == 'email') {
            this.setState({ email: value });
        }
        if (name == 'status') {
            this.setState({ status: value });
        }
    }

    setNumber = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        const reg = new RegExp('^[0-9]+$');
        if (!value || (reg.test(value) && value.length <= 10)) {
            this.setState({ mobileno: value })
        }
    }
    next = async () => {
        const { name, email, mobileno, id, status } = this.state;
        if (email && email !== "" && !this.props.hoc.verifyEmail(email)) {
            this.props.hoc.customAlert('Please Provide a valid email.', false)
            return false
        } else if (name && name == "") {
            // alert('3');
            this.props.hoc.customAlert('Please Provide Name.', false)
            return false
        } else if (mobileno && mobileno.length != 10) {
            // alert('4');
            this.props.hoc.customAlert('Please Provide Mobile No.', false)
            return false
        } else {
            let payload = {
                name,
                email,
                mobileno,
                id,
                status
            }
            let res = await AdminService.updateWareHouseUser(payload)
            console.log(res, 123333)
            if (res.data.success) {
                let user = res.data.data.data
                this.setState({ name: user.name, email: user.email, mobileno: user.mobileno, id: user._id, status: user.status });
                this.props.setWarehouseUser(user);
                this.props.hoc.customAlert('Warehouse Updated Successfully', true);
                this.props.navigate('/admin/user-managment/warehouse-users')
            }

        }

    }
    handleClickHome = () => {
        this.props.navigate('/admin/user-managment/warehouse-users')
    }
    render = () => {
        const { name, email, mobileno, status } = this.state;
        return (
            <>
                <div className="layout-wrapper">
                    <AdminHeader />
                    <AdminSidebar />
                    <div className="main-content">
                        <div className="page-content">
                            <div className="container-fluid">
                                <div className="row g-4">
                                    <div className='col-md-6'>
                                        <h5 className='mb-20 headingstyle'>Warehouse Edit</h5>
                                    </div>

                                    <div className='col-md-6 '>
                                        <div className="btn-home flotright mx-4" onClick={this.handleClickHome}>
                                            <a href="javascript:void(0);" >
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg>
                                                Back
                                            </a>
                                        </div>
                                    </div>



                                    {/* Row end */}
                                    <div className="row g-4">
                                        <form className="mt-3 registration-form">
                                            <div className="row">
                                                <div className="col-md-4 mb-3">
                                                    <label htmlFor className="form-label lvl-clr-hd">Name <span className='requiredFld'> * </span></label>
                                                    <input type="text" value={name} name="name" onChange={(event) => this.changeVal(event)} id className="form-control name-pt-ft" placeholder />
                                                </div>

                                                <div className="col-md-4 mb-3">
                                                    <label htmlFor className="form-label lvl-clr-hd">Email <span className='requiredFld'> * </span></label>
                                                    <input type="text" value={email} name="email" onChange={(event) => this.changeVal(event)} id className="form-control name-pt-ft" placeholder />
                                                </div>


                                                <div className="col-md-4 mb-3">
                                                    <label htmlFor="disabledTextInput" className="form-label lvl-clr-hd">Mobile Number <span className='requiredFld'> * </span>
                                                    </label>
                                                    <input type="text" value={mobileno} onChange={(event) => this.setNumber(event)} name="mobileno" id className="form-control name-pt-ft" placeholder />
                                                </div>
                                                <div className="col-md-3 mb-3">
                                                    <label htmlFor className="form-label lvl-clr-hd">Status<span className='requiredFld'> * </span></label>
                                                    <select className='form-control' name="status" onChange={(event) => this.changeVal(event)} >
                                                        <option value='ACTIVE' selected={status === 'ACTIVE' ? 'selected' : ''}>Active</option>
                                                        <option value='INACTIVE' selected={status === 'INACTIVE' ? 'selected' : ''}>In Active</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="row mt-4">
                                                <div className="end-bck-btn-pt">
                                                    <div className>
                                                        <button type="button" className="next-btn-crcl" id onClick={() => this.next()}>
                                                            Submit
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <AdminFooter />
                    </div>
                </div>

            </>
        )

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        // dispatching plain actions
        setWarehouseUser: (warehouseUserDetails) => dispatch(WarehouseUserAction.setWarehouseUser(warehouseUserDetails))
    }
}

const mapStateToProps = (state) => {
    return {
        OrderReducer: state.OrderReducer,
        CustomerReducer: state.CustomerReducer,
        CommonReducer: state.CommonReducer,
        WarehouseUserReducer: state.WarehouseUserReducer
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Common(WarehouseUserEdit)))