import React from "react";
import { withRouter } from "../../../components/Reusable/WithRouter";
import { connect } from "react-redux";
import Common from "../../../hoc/Common.hoc";
import { format } from "date-fns";
import $ from "jquery";
import CustomDatatable from "../../../components/Datatable/CustomDatatable.component";
import moment from "moment";
import CallCenterService from "../../../services/CallCenter.service";
import OrderAction from "../../../redux/actions/Order.action";
import OtherService from "../../../services/OtherService";
import CustomerAction from "../../../redux/actions/Customer.action";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"; // Import the CSS
import CountryService from "../../../services/Country.service";
import Dropdown from "../../../components/SearchableDropdown/Dropdown.component";
import DropdownDoctor from "../../../components/SearchableDropdown/DropdownDoctor.component";
import CommonAction from "../../../redux/actions/Common.action";
import OrderCancelModal from "../../../components/Order/OrderCancelModal";
import Summary from "../../../components/Summary/Summary.component";
import CallCenterAction from "../../../redux/actions/CallCenter.action";
import { ExportToExcel } from "../../../components/Excel/ExportToExcel";
import AdminHeader from "../../../components/layout/Admin/AdminHeader";
import AdminSidebar from "../../../components/layout/Admin/AdminSidebar";
import AdminFooter from "../../../components/layout/Admin/AdminFooter";
import "../../../assets/css/admin/style.css";
import "../../../assets/css/admin/responsive.css";
import config from "../../../config/emrok.config";
import AdminAction from "../../../redux/actions/Admin.action";
import OrderService from "../../../services/Order.service";
class OrderManagment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      callcenterdetails: null,
      admin: null,
      totalRevenue: 0,
      storeSearchData: null,
      customerid: "",
      scallcenterid: "",
      callcenterList: [],
      callcenterinfo: "",
      callcenterdetailsList: [],
      countries: [],
      states: [],
      towns: [],
      showTowns: false,
      selectedState: "",
      selectedTown: "",
      selectedDoctor: "",
      fromdate: "",
      todate: "",
      currentDate: this.getCurrentDate(),
      doctorList: [],
      isSearch: false,
      isShowCancelModal: false,
      cancelOrderId: null,
      cancelReason: "",
      mobileno: "",
      summaryCols: [
        "Total Orders",
        "Orders Under Process",
        "Orders Pending",
        "Sample Received at Koshin Clinic",
        "XO Face Serum received By doctor",
      ],
      summaryDetails: null,
      setorderStatus: "",
      exportData: null,
      fileName: "Order Details",
      orders: {},
    };
    this.copyState = this.state;
  }

  static getDerivedStateFromProps(props, state) {
    return {
      callcenterdetails: props._id || null,
      admin: props._id || null,
      summaryDetails: props.summaryDetails || null,
      storeSearchData: props.storeSearchData || null,
    };
  }

  getCurrentDate() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(currentDate.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  getSummary = async () => {
    const {
      fromdate,
      todate,
      selectedState,
      selectedTown,
      selectedDoctor,
      setorderStatus,
    } = this.state.storeSearchData;
    const admindetails = this.props.admin;
    const admin_id = admindetails._id;
    const selectedOnlyDateFrom = fromdate
      ? moment(fromdate).format("YYYY-MM-DD")
      : "";
    const selectedOnlyDateTo = todate
      ? moment(todate).format("YYYY-MM-DD")
      : "";
    let payload = {
      type: "ADMIN",
      id: admin_id,
      fromDate: fromdate ? selectedOnlyDateFrom : "",
      toDate: todate ? selectedOnlyDateTo : "",
      stateName: selectedState,
      town: selectedTown,
      doctor_id: selectedDoctor,
      orderStatus: setorderStatus,
    };

    console.log(payload, "payload");
    OtherService.getSummary(payload)
      .then((data) => {
        console.log(data, "getSummary");
      })
      .catch((err) => {
        console.log(err, "getSummary err");
      });
  };

  clickFn = async (index) => {
    console.log(index, "index");
    const callcenterdetails = this.props.admin;
    let payload = {};
    let orderstatus = "";
    if (callcenterdetails) {
      // let payload = await {

      // }

      const {
        fromdate,
        todate,
        selectedState,
        selectedTown,
        selectedDoctor,
        setorderStatus,
      } = this.state.storeSearchData;
      const selectedOnlyDateFrom = fromdate
        ? moment(fromdate).format("YYYY-MM-DD")
        : "";
      const selectedOnlyDateTo = todate
        ? moment(todate).format("YYYY-MM-DD")
        : "";

      if (index === 0) {
        payload = {
          fromDate: fromdate ? selectedOnlyDateFrom : "",
          toDate: todate ? selectedOnlyDateTo : "",
          stateName: selectedState,
          town: selectedTown,
          doctor_id: selectedDoctor,
          orderStatus: setorderStatus,
        };
      } else {
        payload = {
          fromDate: fromdate ? selectedOnlyDateFrom : "",
          toDate: todate ? selectedOnlyDateTo : "",
          stateName: selectedState,
          town: selectedTown,
          doctor_id: selectedDoctor,
          orderStatus: setorderStatus,
        };
      }

      if (index === 0) {
        payload["orderStatus"] = "";
        orderstatus = "";
        this.setState({ setorderStatus: "" });
      } else if (index === 1) {
        payload["orderStatus"] = await "underprocess";
        orderstatus = "underprocess";
        this.setState({ setorderStatus: "underprocess" });
      } else if (index === 2) {
        payload["orderStatus"] = await "pending";
        orderstatus = "pending";
        this.setState({ setorderStatus: "pending" });
      } else if (index === 3) {
        payload["orderStatus"] = await "received_at_clinic";
        orderstatus = "received_at_clinic";
        this.setState({ setorderStatus: "received_at_clinic" });
      } else if (index === 4) {
        payload["orderStatus"] = await "delivered";
        orderstatus = "delivered";
        this.setState({ setorderStatus: "delivered" });
      } else if (index === 5) {
        payload["orderStatus"] = await "completed";
        orderstatus = "completed";
        this.setState({ setorderStatus: "completed" });
      }
      // else if (index === 2) {
      //     payload["orderStatus"] = await "underprocess"
      //     orderstatus = "underprocess"
      //     this.setState({ setorderStatus: "underprocess" })
      // }

      // else if (index === 4) {
      //     payload["orderStatus"] = await "Pickup Initiated"
      //     orderstatus = "Pickup Initiated"
      //     this.setState({ setorderStatus: "Pickup Initiated" })
      // }
      // else if (index === 5) {
      //     payload["deliveryStatus"] = await "delivered"
      //     orderstatus = "delivered"
      //     this.setState({ setorderStatus: "delivered" })
      // }

      const storeSearchData = this.state.storeSearchData;
      storeSearchData.setorderStatus = orderstatus;
      storeSearchData.callcenterdetailsList = [];
      this.props.setSearchValue(storeSearchData);

      console.log(payload, 222222);
      this.getOrderList(payload);
    }
  };

  formatData(auditdata) {
    console.log(auditdata, auditdata.length, "auditdata");

    let formatedArr = [];
    let allfeedback = [];
    let feedbackString = "";
    for (let i = 0; i < auditdata.length; i++) {
      formatedArr.push({
        "Order No": auditdata[i].order.order_no,
        "Order Date": auditdata[i].order
          ? moment(auditdata[i].order.createdAt).format("DD-MM-YYYY")
          : "",
        "QR Code": auditdata[i]
          ? auditdata[i].unique_qr_id
          : "",
        "Customer Name": auditdata[i].patient ? auditdata[i].patient?.name : "",
        "Customer Mobile No.": auditdata[i].patient
          ? auditdata[i].patient?.mobileno
          : "",

        "Doctor Name": auditdata[i].doctor ? auditdata[i].doctor?.name : "",
        "Stockist Name": auditdata[i].stockist
          ? auditdata[i].stockist[0]?.name
          : "",
        "Koshin Clinic": auditdata[i].clinic
          ? auditdata[i].clinic?.name
          : "",
        "MR Name": auditdata[i].mr ? auditdata[i].mr?.name : "",


        Status: auditdata[i].order ? auditdata[i].order.orderStatus : "",
      });
    }
    return formatedArr;
  }

  getOrderList = (payload) => {
    this.setState({ orders: [] });
    this.setState({ exportData: null });

    OrderService.getAllOrders(payload)
      .then(async (data) => {
        console.log(data, 10000);
        if (data.data.success) {
          console.log(data.data.data.order, 6666666);

          this.setState({ orders: data.data.data.order });

          const formatedData = await this.formatData(data.data.data.order);
          console.log(formatedData, "formatedData");
          this.setState({ exportData: formatedData });

          this.getDoctors(data.data.data.order);
        }
      })
      .catch((err) => { });
  };



  componentDidMount() {
    const admindetails = this.props.admin;
    const admin_id = admindetails._id;
    let details = {};

    this.getOrderList(details);
    // this.getDoctors()

    setTimeout(() => {
      this.populateStates("IN");
      // this.populateStates(countryObj.isoCode)
    }, 500);
    this.getSummary();
  }

  countTotalRevenue = () => {
    const storeSearchData = this.state.storeSearchData;
    const callCenterListData = storeSearchData.callcenterdetailsList;
    const totalqty = callCenterListData.reduce(
      (totalQuantity, callcenter) =>
        totalQuantity + parseFloat(callcenter.total),
      0
    );
    const twoDecimel = totalqty ? totalqty.toFixed(2) : 0;
    this.setState({ totalRevenue: twoDecimel });
    // console.log(totalqty,666666)
  };

  getDoctors = (doctors) => {
    console.log(doctors, 6666661);

    // Create an array to store the grouped data
    const groupedData = [];
    const finalData = [];

    // Create an object to keep track of seen doctor._id values
    const seenDoctorIds = {};

    // Use forEach to iterate through the data
    doctors.forEach((currentValue) => {
      const doctorId = currentValue.doctor._id;

      // Check if we've already seen this doctor._id
      if (!seenDoctorIds[doctorId]) {
        // Add the current value to the groupedData array
        groupedData.push(currentValue.doctor);

        // Mark the doctor._id as seen
        seenDoctorIds[doctorId] = true;
      }
    });

    console.log(groupedData, 6666661);

    for (let index = 0; index < groupedData.length; index++) {
      finalData[index] = {
        name: groupedData[index].name,
        label: groupedData[index].name,
        value: groupedData[index]._id,
        id: groupedData[index]._id,
      };
    }

    const allOption = {
      name: "All",
      label: "All",
      value: "All",
      id: "All",
    };

    let optionsWithAll = [allOption, ...finalData];

    console.log(optionsWithAll, "finalData");
    // Now 'groupedData' contains unique doctors with a regular index

    this.setState({ doctorList: optionsWithAll });

    // const storeSearchData = this.state.storeSearchData;
    // if (storeSearchData.doctorList.length == 0) {
    //     storeSearchData.doctorList = optionsWithAll;
    //     this.props.setSearchValue(storeSearchData);
    // }

    console.log(groupedData, "groupedData");

    // Now 'groupedData' contains the data grouped by doctor._id
  };

  populateStates = (isoCode = null) => {
    if (isoCode) {
      CountryService.getStates(isoCode).then((data) => {
        //console.log(data, "states")
        if (data.success) {
          const storeSearchData = this.state.storeSearchData;
          storeSearchData.states = data.states;

          console.log(storeSearchData.states, 6666666);
          this.props.setSearchValue(storeSearchData);
          this.setState({ states: data.states, towns: [] }, async () => {
            this.setState({ showTowns: false });
            let _state = this.state.storeSearchData.selectedState || null;
            let _states = this.state.storeSearchData.states || [];
            console.log(_state, this.state.storeSearchData.states, "jhgjhgjhg");
            if (_state) {
              // alert("ok");
              let stateObj = null;
              let stateObjAll = null;
              for (
                let i = 0;
                i < this.state.storeSearchData.states.length;
                i++
              ) {
                if (this.state.states[i].name === _state) {
                  stateObj = this.state.storeSearchData.states[i];
                }
              }

              console.log(stateObj, _state, 1234545677);
              console.log("stateObj found", stateObj);
              // if (stateObj) {
              setTimeout(() => {
                if (stateObj != null && stateObj.countryCode)
                  this.populateTowns(stateObj.countryCode, stateObj.isoCode);
              }, 500);
              // }
            }
          });

          setTimeout(() => {
            let stateDetails = this.state.storeSearchData.states;

            const findIfExistAll = stateDetails.find(
              (item) => item.name === "All"
            );

            console.log(findIfExistAll, 33333);

            if (!findIfExistAll) {
              console.log(stateDetails, "details state all 1");
              const allOption = {
                name: "All",
                isoCode: null,
                countryCode: null,
                latitude: null,
                longitude: null,
                label: "All",
                value: "all",
              };
              console.log(this.state.storeSearchData.states, "details state");
              // Create a new array with the "All" option at the beginning
              let optionsWithAll = [allOption, ...stateDetails];
              console.log(optionsWithAll, "details state all");
              this.setState({ states: optionsWithAll });
              const storeSearchData = this.state.storeSearchData;
              storeSearchData.states = optionsWithAll;
              this.props.setSearchValue(storeSearchData);
            }
          }, 500);
        }
      });
    }
  };

  populateTowns = (countryCode = null, stateCode = null) => {
    console.log(countryCode, stateCode, "countryCode stateCode");
    if (countryCode && stateCode) {
      CountryService.getTowns(countryCode, stateCode).then((data) => {
        console.log(data, "towns");
        // setTimeout(() => {
        if (data.success) {
          const storeSearchData = this.state.storeSearchData;
          storeSearchData.towns = data.towns;
          this.props.setSearchValue(storeSearchData);
          this.setState({ towns: data.towns }, () =>
            this.setState({ showTowns: true })
          );

          setTimeout(() => {
            let stateDetails = this.state.storeSearchData.towns;
            const allOption = {
              name: "All",
              isoCode: null,
              countryCode: null,
              latitude: null,
              longitude: null,
              label: "All",
              value: "all",
            };
            console.log(this.state.storeSearchData.towns, "details state");
            // Create a new array with the "All" option at the beginning
            let optionsWithAll = [allOption, ...stateDetails];
            console.log(optionsWithAll, "details state all");
            this.setState({ towns: optionsWithAll });

            const storeSearchData = this.state.storeSearchData;
            storeSearchData.towns = optionsWithAll;
            this.props.setSearchValue(storeSearchData);
          }, 500);

          // this.props.setSearchValue(this.state);
        }
        // }, 2000);
      });
    }
  };

  componentWillUnmount() {
    if (this.dataTable) {
      this.dataTable.destroy(true);
    }
  }

  handleTrackOrder = (event) => {
    // const allOrder = this.props.OrderReducer.allOrders;
    const targetId = event.target.name;
    console.log(targetId, "target ID");
    const { orders } = this.state;
    console.log(orders, 7777);
    try {
      const selectedOrder = orders.filter((item) => item._id == targetId);
      console.log(selectedOrder, "selectedOrder");
      this.props.saveSelectedOrders(selectedOrder);
      this.props.navigate("/admin/track-info-order");
      this.props.setUserType("admin");

      // OtherService.getOrderDetails({ _id: targetId }).then(data => {
      //     console.log(data.data, "order details 123456789");
      //     if (data.data.success) {
      //         console.log(data.data.data.order, "okkkkk 123456789");
      //         this.props.saveSelectedOrders(data.data.data.order)
      //         this.props.navigate('/patient/track-info-order');
      //         this.props.setUserType('patient');
      //         // this.setState({mrdetailsList:data.data.data.mrdetails});
      //     }
      // })
    } catch (error) { }
  };

  changeValFromDate = async (date) => {
    // const formatedDate = moment(date).format('DD-MM-YYYY');
    console.log(date, "fromdate");
    this.setState({ fromdate: date });
    const storeSearchData = this.state.storeSearchData;
    storeSearchData.fromdate = date;
    this.props.setSearchValue(storeSearchData);
  };

  changeValToDate = async (date) => {
    // const formatedDate = moment(date).format('DD-MM-YYYY');
    console.log(date, "todate");
    this.setState({ todate: date });

    const storeSearchData = this.state.storeSearchData;
    storeSearchData.todate = date;
    this.props.setSearchValue(storeSearchData);
  };

  handleEditPatientDetails = async (event) => {
    event.preventDefault();
    const customer_id = event.target.name;
    const callCenterDetails = this.state.storeSearchData.callcenterdetailsList;
    const selectedPatientDetails = await callCenterDetails.filter(
      (item) => item.customer._id == customer_id
    );
    console.log(
      selectedPatientDetails,
      "selectedPatientDetails with doctor25252525"
    );
    if (selectedPatientDetails.length) {
      // let order_details
      //  selectedPatientDetails[0].customer['order_id'] = selectedPatientDetails[0].order_i

      console.log(this.props.callcenterdetails, "callcender reducer");
      // this.props.setCallCenter(callCenterDetails);
      this.props.setCallCenterCustomer(selectedPatientDetails[0].customer);
    } else {
      // this.props.setCustomer(selectedPatientDetails)
    }

    this.props.navigate("/admin/patient-details");
    // console.log(selectedPatientDetails);
  };
  searchFilter = (event) => {
    this.setState({ callcenterdetailsList: [] });
    const storeSearchData = this.state.storeSearchData;
    storeSearchData.callcenterdetailsList = [];
    this.props.setSearchValue(storeSearchData);
    const callcenterdetails = this.props.admin;
    const callcenter_id = callcenterdetails._id;
    const {
      fromdate,
      todate,
      selectedState,
      selectedTown,
      selectedDoctor,
      setorderStatus,
    } = this.state.storeSearchData;

    const selectedOnlyDateFrom = fromdate
      ? moment(fromdate).format("YYYY-MM-DD")
      : "";
    const selectedOnlyDateTo = todate
      ? moment(todate).format("YYYY-MM-DD")
      : "";
    // const selectedOnlyDateFrom = selectedMomentFrom.format('YYYY-MM-DD');
    // Calculate the next day using moment
    // const nextDayMomentFrom = selectedMomentFrom.clone().add(1, 'day');
    // const nextDayOnlyDateFrom = nextDayMomentFrom.format('YYYY-MM-DD');

    // const selectedOnlyDateTo = selectedMomentTo.format('YYYY-MM-DD');
    // Calculate the next day using moment
    // const nextDayMomentTo = selectedMomentTo.clone().add(1, 'day');
    // const nextDayOnlyDateTo = nextDayMomentTo.format('YYYY-MM-DD');

    let details = {
      // callcenter_id: callcenter_id,
      fromDate: fromdate ? selectedOnlyDateFrom : "",
      toDate: todate ? selectedOnlyDateTo : "",
      stateName: selectedState,
      town: selectedTown,
      doctor_id: selectedDoctor,
      orderStatus: setorderStatus,
    };

    console.log(details, 1234567890);

    if (fromdate != "" && todate == "") {
      this.props.hoc.customAlert(
        "Please select both fromdate and todate",
        false
      );
    } else if (fromdate == "" && todate != "") {
      this.props.hoc.customAlert(
        "Please select both fromdate and todate",
        false
      );
    } else {
      this.getOrderList(details);
      this.getSummary();

      // CallCenterService.getCallCenterDetails(details).then(data => {
      //     console.log(data.data, "callcenter details 2 after filter");

      // if (data.data.success && data.data.data.callcenterdetails !== '') {
      //     console.log(data.data.data.callcenterdetails, "okkkkk after filter");

      //     const formatedData = this.formatData(data.data.data.callcenterdetails);
      //     console.log(formatedData, "formatedData")
      //     this.setState({ exportData: formatedData });
      //     const currentDateTime = moment().format('DD-MM-YYYY H:m:s');
      //     const filename = "Callcenter_Data" + currentDateTime;
      //     this.setState({ filename: filename });

      //     this.getDoctors(data.data.data.callcenterdetails);
      //     // setTimeout(() => {
      //     this.setState({ callcenterdetailsList: data.data.data.callcenterdetails });
      //     this.setState({ isSearch: true })

      //     const storeSearchData = this.state.storeSearchData;
      //     storeSearchData.exportData = formatedData;
      //     storeSearchData.filename = filename;
      //     storeSearchData.callcenterdetailsList = data.data.data.callcenterdetails;
      //     storeSearchData.isSearch = true;
      //     this.props.setSearchValue(storeSearchData);
      //     // this.populateStates(countryObj.isoCode)
      //     // }, 500);
      //     this.getSummary()

      // } else {
      //     this.getSummary()
      // }
      // this.countTotalRevenue();

      // this.setState({mrdetailsList:data.data.data.mrdetails});

      // }).catch(err => {

      //     console.log(err, "err")
      // })
    }

    console.log("search filter");
  };

  changeDoctor = (event) => {
    const doctorId = event.target.value;
    this.setState({ selectedDoctor: doctorId });
    console.log(doctorId, "doctorId");
    const storeSearchData = this.state.storeSearchData;
    storeSearchData.selectedDoctor = doctorId;
    this.props.setSearchValue(storeSearchData);
  };

  setOrderHistoryData = (data) => {
    let resp = [];
    // console.log(data[0],66666)
    for (let i = 0; i < data.length; i++) {
      resp.push({
        _id: data[i]._id,
        order_no: data[i].order.order_no,
        order_date: data[i].order.createdAt
          ? moment(data[i].order.createdAt).format("DD-MM-YYYY")
          : "",
        order_status: data[i].order.orderStatus
          ? data[i].order.orderStatus.toUpperCase()
          : "",
        stockist_name: data[i].stockist[0].name,
        patient_name: data[i].patient.name,
        patient_mobile: data[i].patient.mobileno,
        vacutainer_code: data[i].unique_qr_id,
        serial_no: data[i].serial_no ? config.seriesName + data[i].serial_no : "",
        mr_name: data[i].mr.name,
        doctor_naame: data[i].doctor.name,
        clinic_name: data[i].clinic.name,
        concent: data[i].order.concent == null ? 'No' : 'Yes',
        unique_qr_id: data[i].unique_qr_id

      });
    }
    return resp;
  };

  handleCancelOrder = (event) => {
    console.log(event.target.dataset.cid, 55555);
    const orderId = event.target.name;
    const mobileno = event.target.id;
    const customerid = event.target.dataset.cid;

    // alert(mobileno,"mobileno");
    this.setState({
      isShowCancelModal: true,
      cancelOrderId: orderId,
      mobileno: mobileno,
      customerid: customerid,
    });
    // this.setState({ cancelOrderId: orderId });
    // this.setState({ mobileno: mobileno });

    const storeSearchData = this.state.storeSearchData;
    storeSearchData.isShowCancelModal = true;
    storeSearchData.cancelOrderId = orderId;
    storeSearchData.mobileno = mobileno;
    storeSearchData.customerid = customerid;
    this.props.setSearchValue(storeSearchData);
  };
  handleOkClick = () => {
    const orderId = this.state.cancelOrderId;
    const callcenterdetails = this.props.admin;

    console.log(callcenterdetails, "callcenterdetails");

    const callcenter_id = callcenterdetails._id;
    const userType = callcenterdetails.userType;
    // const mobileno = this.props.callcenterdetails.customer.mobileno;

    let details = {
      order_id: orderId,
      user_id: callcenter_id,
      user_type: userType,
      reason: this.state.storeSearchData.cancelReason,
      mobileno: this.state.storeSearchData.mobileno,
      customer_id: this.state.storeSearchData.customerid,
    };

    console.log(details, "details");
    let body = {
      callcenter_id: callcenter_id,
    };

    if (this.state.storeSearchData.cancelReason != "") {
      OtherService.cancelOrder(details)
        .then((data) => {
          console.log(data.data, "cancel order");

          if (data.data.success) {
            console.log(data.data, "data.data");
            this.setState({ callcenterdetailsList: [] });
            CallCenterService.getCallCenterDetails(body)
              .then((data) => {
                console.log(data.data, "callcenter details 2");

                if (
                  data.data.success &&
                  data.data.data.callcenterdetails !== ""
                ) {
                  console.log(data.data.data.callcenterdetails, "okkkkk12");

                  // this.getDoctors(data.data.data.callcenterdetails);
                  this.setState({
                    callcenterdetailsList: data.data.data.callcenterdetails,
                  });
                  const storeSearchData = this.state.storeSearchData;
                  storeSearchData.callcenterdetailsList =
                    data.data.data.callcenterdetails;
                  this.props.setSearchValue(storeSearchData);
                }
              })
              .catch((err) => {
                console.log(err, "err");
              });

            this.props.hoc.customAlert("Order rejected successfully.", true);
          }
          // this.setState({mrdetailsList:data.data.data.mrdetails});
        })
        .catch((err) => {
          this.props.hoc.customAlert("Order rejected successfully.", true);
          console.log(err, "err");
        });
    } else {
      this.props.hoc.customAlert("Select reject reason", false);
    }

    // alert(callcenter_id);
  };

  handleRadioClick = (event) => {
    // alert("ok")
    //  alert(event.target.value);

    this.setState({ cancelReason: event.target.value });
    const storeSearchData = this.state.storeSearchData;
    storeSearchData.cancelReason = event.target.value;
    this.props.setSearchValue(storeSearchData);
  };

  downloadPdf = async (event) => {
    const order_id = event.target.id;

    const downloadUrl =
      config.serviceUrl + "/images/invoice/" + order_id + ".pdf"; // Replace with the actual URL or path of the file
    try {
      const response = await fetch(downloadUrl);
      if (response.status === 200) {
        window.open(downloadUrl, "_blank");
        console.log("present");
      } else {
        // alert("not present")
        this.props.hoc.customAlert("Invoice not yet generated.", false);
        console.log("not present");
      }
    } catch (error) {
      window.open(downloadUrl, "_blank");
      // Handle network or other errors
      console.log("error");
    }

    // alert(order_id);
  };

  clearFilter = () => {
    this.setState({
      callcenterdetails: null,
      admin: null,
      totalRevenue: 0,
      storeSearchData: null,
      customerid: "",
      scallcenterid: "",
      callcenterList: [],
      callcenterinfo: "",
      callcenterdetailsList: [],
      countries: [],
      towns: [],
      showTowns: false,
      selectedState: "",
      selectedTown: "",
      selectedDoctor: "",
      fromdate: "",
      todate: "",
      currentDate: this.getCurrentDate(),
      doctorList: [],
      isSearch: false,
      isShowCancelModal: false,
      cancelOrderId: null,
      cancelReason: "",
      mobileno: "",
      summaryDetails: null,
      setorderStatus: "",
      exportData: null,
      fileName: "Order Details",
    });

    const callcenterdetails = this.props.admin;
    const callcenter_id = callcenterdetails._id;
    let details = {
      callcenter_id: callcenter_id,
    };

    const storeSearchData = this.state.storeSearchData;

    storeSearchData.callcenterdetails = null;
    storeSearchData.admin = null;
    storeSearchData.storeSearchData = null;
    storeSearchData.customerid = "";
    storeSearchData.scallcenterid = "";
    storeSearchData.callcenterList = [];
    storeSearchData.callcenterinfo = "";
    storeSearchData.callcenterdetailsList = [];
    storeSearchData.countries = [];
    storeSearchData.towns = [];
    storeSearchData.showTowns = false;
    storeSearchData.selectedState = "";
    storeSearchData.selectedTown = "";
    storeSearchData.selectedDoctor = "";
    storeSearchData.fromdate = "";
    storeSearchData.todate = "";
    storeSearchData.currentDate = this.getCurrentDate();
    storeSearchData.doctorList = [];
    storeSearchData.isSearch = false;
    storeSearchData.isShowCancelModal = false;
    storeSearchData.cancelOrderId = null;
    storeSearchData.cancelReason = "";
    storeSearchData.mobileno = "";
    storeSearchData.summaryDetails = null;
    storeSearchData.setorderStatus = "";
    storeSearchData.exportData = null;
    storeSearchData.fileName = "Order Details";

    this.props.setSearchValue(storeSearchData);

    this.getOrderList(details);

    this.getSummary();

    setTimeout(() => {
      this.countTotalRevenue();
      // this.populateStates(countryObj.isoCode)
    }, 500);
  };

  render = () => {
    // console.log(this.state.callcenterdetailsList,8888);

    const {
      fileName,
      countries,
      states,
      towns,
      isLoading,
      showTowns,
      selectedState,
      selectedTown,
      fromdate,
      todate,
      selectedDoctor,
    } = this.state.storeSearchData;

    // console.log(doctorList,99999);
    const {
      summaryCols,
      summaryDetails,
      totalRevenue,
      orders,
      doctorList,
      exportData,
    } = this.state;
    let _data = this.setOrderHistoryData(orders);
    console.log(_data, "details_data");
    console.log(summaryDetails, "summaryDetails");
    // console.log(this.state.storeSearchData.callcenterdetailsList, "callcenterdetailsList");
    // console.log(summaryCols, "summaryDetails");

    // const newfromdate = fromdate?new Date(fromdate).toString():"";
    // const newtodate = todate?new Date(todate).toString():"";

    console.log(fromdate, todate, 222222222);
    // console.log(new Date(fromdate).toString(), new Date(todate).toString(), 222222222)
    return (
      <>
        <div className="layout-wrapper">
          <AdminHeader />
          <AdminSidebar />
          <div className="main-content">
            <div className="page-content">
              <div className="container-fluid">
                <div className="row g-4">
                  {/* Row end */}
                  {/* <div className="row">
                                        <div className="landing-page-pt pt-3 pb-4">
                                            <div className="container-fluid px-0"> */}
                  {/* <div className="mr-landing-page">
                                                    <div className="landing-head-pt"> */}
                  {/* <h2>Call Center</h2> */}
                  {/* <h5>Order Managment</h5> */}
                  {/* </div> */}
                  {/* </div> */}
                  {/* </div>
                                        </div>
                                    </div>{" "} */}

                  {/* Row end*/}
                  <div className="landing-form-dtls">
                    <div className="row mb-25">
                      <div className="col-md-6">
                        <h5 className="mb-20 headingstyle">Order Details</h5>
                      </div>

                      <div className="col-md-6">
                        {/* <div className="flotright">
                                                    <button className="btn btn-success floatright" onClick={() => this.props.navigate('/callcenter/audit-page')}>Audit Trial</button>
                                                </div> */}
                        <div className="flotright marginright">
                          <ExportToExcel
                            apiData={exportData}
                            fileName={fileName}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row mt-30 mb-20 ">
                      <div className="col-md-2 mb-3">
                        <label htmlFor className="form-label lvl-clr-hd">
                          Order From Date
                        </label>

                        <DatePicker
                          selected={fromdate}
                          onChange={(date) => this.changeValFromDate(date)}
                          dateFormat="dd-MM-yyyy" // Set the desired display format
                          className="form-control name-pt-ft"
                          placeholderText="DD-MM-YYYY"
                        />
                      </div>
                      <div className="col-md-2 mb-3">
                        <label htmlFor className="form-label lvl-clr-hd">
                          Order To Date
                        </label>

                        <DatePicker
                          selected={todate}
                          onChange={(date) => this.changeValToDate(date)}
                          dateFormat="dd-MM-yyyy" // Set the desired display format
                          className="form-control name-pt-ft"
                          placeholderText="DD-MM-YYYY"
                          minDate={fromdate}
                        />
                      </div>
                      <div className="col-md-3 xs-3 mb-2 dropdownstyle">
                        <label
                          htmlFor="disabledTextInput"
                          className="form-label lvl-clr-hd"
                        >
                          State{" "}
                        </label>
                        {states.length ? (
                          <Dropdown
                            items={states}
                            onSelect={(item) => {
                              if (item) {
                                // let _registration = this.state.registration
                                // _registration.address["state"] = item.name
                                // _registration.address["town"] = ""
                                this.setState({ selectedState: item.name });
                                const storeSearchData =
                                  this.state.storeSearchData;
                                storeSearchData.selectedState = item.name;
                                this.props.setSearchValue(storeSearchData);
                                //console.log(item, "item");
                                // alert(item.name)
                                if (item.name != "All") {
                                  this.populateTowns(
                                    item.countryCode,
                                    item.isoCode
                                  );
                                } else {
                                  this.setState({ towns: [] });
                                  this.setState({ selectedTown: "" });
                                  const storeSearchData =
                                    this.state.storeSearchData;
                                  storeSearchData.towns = [];
                                  storeSearchData.selectedTown = "";
                                  this.props.setSearchValue(storeSearchData);
                                }
                              }
                            }}
                            value={selectedState}
                          />
                        ) : (
                          <Dropdown items={states} value={selectedState} />
                        )}
                        {/* <input type="text" value={state} onChange={(event) => this.changeAddr(event)} name="state" id className="form-control name-pt-ft" placeholder /> */}
                      </div>
                      <div className="col-md-3 xs-3 mb-2 dropdownstyle">
                        <label htmlFor className="form-label lvl-clr-hd">
                          City/Town{" "}
                        </label>
                        {towns.length ? (
                          <Dropdown
                            items={towns}
                            onSelect={(item) => {
                              if (item) {
                                // alert(item.name);
                                // let _registration = this.state.registration
                                // _registration.address["town"] = item.name
                                this.setState({ selectedTown: item.name });
                                const storeSearchData =
                                  this.state.storeSearchData;
                                storeSearchData.selectedTown = item.name;
                                this.props.setSearchValue(storeSearchData);
                                //console.log(item, "item")
                              }
                            }}
                            value={selectedTown}
                          />
                        ) : (
                          <Dropdown items={towns} value={selectedTown} />
                        )}
                        {/* <input type="text" value={town} onChange={(event) => this.changeAddr(event)} name="town" id className="form-control name-pt-ft" placeholder /> */}
                      </div>
                      <div className="col-md-3 xs-3 mb-2 dropdownstyle">
                        <label htmlFor className="form-label lvl-clr-hd">
                          Doctors{" "}
                        </label>
                        {/* <select name="doctorname" className="form-control name-pt-ft" onChange={(event) => this.changeDoctor(event)} value={selectedDoctor}>
                                        <option value="">Please Select</option>
                                        {doctorList.length > 0 ? doctorList.map(doctor => (
                                            <option value={doctor._id}   >{doctor.name}</option>

                                        )) : <option></option>} */}

                        {doctorList.length ? (
                          <DropdownDoctor
                            items={doctorList}
                            onSelect={(item) => {
                              if (item) {
                                // alert(item.name);
                                // let _registration = this.state.registration
                                // _registration.address["town"] = item.name
                                this.setState({ selectedDoctor: item.id });
                                // this.props.setSearchValue(this.state);

                                const storeSearchData =
                                  this.state.storeSearchData;
                                storeSearchData.selectedDoctor = item.id;
                                this.props.setSearchValue(storeSearchData);
                                //console.log(item, "item")
                              }
                            }}
                            value={selectedDoctor}
                          />
                        ) : (
                          <DropdownDoctor
                            items={doctorList}
                            value={selectedDoctor}
                          />
                        )}

                        {/* </select> */}
                      </div>
                      <div className="">
                        <button
                          type="button"
                          className="landing-success-btn"
                          onClick={(event) => this.searchFilter(event)}
                        >
                          Search
                        </button>
                        <button
                          type="button"
                          className="landing-clear-btn"
                          onClick={(event) => this.clearFilter()}
                        >
                          Clear Filter
                        </button>
                      </div>
                    </div>
                    <Summary
                      columns={summaryCols}
                      data={[
                        {
                          totalOrderCount: summaryDetails
                            ? summaryDetails.totalOrderCount || 0
                            : 0,
                          totalOrderUnderProcessCount: summaryDetails
                            ? summaryDetails.totalOrderUnderProcessCount || 0
                            : 0,
                          totalOrderPending: summaryDetails
                            ? summaryDetails.totalOrderPendingCount || 0
                            : 0,
                          totalOrderReceivedAtKoshinClinic: summaryDetails
                            ? summaryDetails.totalOrderReceivedAtKoshinClinicCount ||
                            0
                            : 0,
                          totalOrderDelivered: summaryDetails
                            ? summaryDetails.totalOrderDeliveredCount || 0
                            : 0,
                          // totalOrderCompleted: summaryDetails
                          //   ? summaryDetails.totalOrderCompletedCount || 0
                          //   : 0,
                          // totalRevenue: totalRevenue ? '₹' + totalRevenue || 0 : 0,
                        },
                      ]}
                      clickFn={this.clickFn}
                    />

                    <h5 className="mb-20 headingstyle">Details</h5>
                    <div className="table-responsive order-details-table">
                      {_data && _data.length ? (
                        <CustomDatatable
                          pagination={true}
                          columns={[
                            {
                              name: "Order No",
                              // selector: row => row.mr_name,
                            },
                            {
                              name: "Order Date",
                              // selector: row => row.doctor_name,
                            },
                            {
                              name: "QR Code",
                              // selector: row => row.doctor_name,
                            },
                            {
                              name: "Customer Name",
                              // selector: row => row.mr_name,
                            },
                            {
                              name: "Customer Mobile No.",
                              // selector: row => row.mr_name,
                            },

                            {
                              name: "Doctor Name",
                              // selector: row => row.mr_name,
                            },
                            {
                              name: "Stockist Name",
                              // selector: row => row.mr_name,
                            },
                            {
                              name: "Koshin Clinic",
                              // selector: row => row.doctor_name,
                            },
                            {
                              name: "MR Name",
                              // selector: row => row.mr_name,
                            },
                            {
                              name: "Consent",
                              // selector: row => row.mr_name,
                            },

                            {
                              name: "Status",
                              // selector: row => row.createdAt,
                            },
                            {
                              name: "Track Order",
                              // selector: row => row.createdAt,
                            },
                          ]}
                          data={this.setOrderHistoryData(orders)}
                          pageName="adminlanding"
                          handleTrackOrder={this.handleTrackOrder}
                          handleEditPatientDetails={
                            this.handleEditPatientDetails
                          }
                          handleCancelOrder={this.handleCancelOrder}
                          downloadPdf={this.downloadPdf}
                        />
                      ) : (
                        <p className="norecord">
                          <strong>No Records Found</strong>
                        </p>
                      )}

                      <OrderCancelModal
                        message="Are you sure want to reject?"
                        handleOkClick={this.handleOkClick}
                        handleRadioClick={this.handleRadioClick}
                        cancelReasonData={
                          this.state.storeSearchData.cancelReason
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <AdminFooter />
          </div>
        </div>
      </>
    );
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    // dispatching plain actions
    setCustomer: (customer) => dispatch(CustomerAction.setCustomer(customer)),
    setUserType: (userType) => dispatch(CommonAction.setUserType(userType)),
    saveSelectedOrders: (selectedOrders) =>
      dispatch(OrderAction.saveSelectedOrders(selectedOrders)),
    setCallCenterCustomer: (callcentercustomer) =>
      dispatch(CallCenterAction.setCallCenterCustomer(callcentercustomer)),
    setSearchValue: (storeSearchData) =>
      dispatch(AdminAction.setSearchValue(storeSearchData)),
  };
};

const mapStateToProps = ({
  CustomerReducer,
  OrderReducer,
  CallCenterReducer,
  CommonReducer,
  AdminReducer,
}) => {
  const { customer } = CustomerReducer;
  const { orders } = OrderReducer;
  const { callcenterdetails } = CallCenterReducer;
  const { summaryDetails } = CommonReducer;
  const { admin, storeSearchData } = AdminReducer;

  console.log(admin, 123);
  console.log(callcenterdetails, 123456);
  console.log(storeSearchData, 12345678);
  return {
    // dispatching plain actions
    // OrderListReducer: state.OrderListReducer,
    customer,
    orders,
    callcenterdetails,
    summaryDetails,
    admin,
    storeSearchData,
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Common(OrderManagment)));
