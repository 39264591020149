import {
    SET_DOCTOR,
    LOGOUT,
    UNIQUE_QR_CODE
} from '../types/Doctor.type'

const initState = {
    doctordetails: {},
    token: null,
    uniqueqrcode: null
}

export default (state = initState, action) => {
    const { type, payload } = action
    switch (type) {
        case SET_DOCTOR:
            return Object.assign({}, state, { doctordetails: { ...payload } })
        case UNIQUE_QR_CODE:
            return Object.assign({}, state, { uniqueqrcode: payload  })
        case LOGOUT:
            return initState;
        default:
            return state
    }
}