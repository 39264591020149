import Base from './Base.service'
import { store } from '../redux/index.store'
import {
  DOCTOR_LOGIN_API, DOCTOR_DETAIL,DOCTOR_INFO,DOCTOR_VERIFY_OTP,ALL_DOCTOR_LIST_BY_STOCKIST,ALL_DOCTOR_LIST_BY_PATIENT,DOCTOR_BULK_UPLOAD, DOCTOR_BULK_UPLOAD_ADDRESS, GET_DOCTOR_ALL_ADDRESS
} from './Slugs'
import DoctorAction from '../redux/actions/Doctor.action';
// import config from '../../config/knorish.config';
// import AuthAction from '../redux/actions/Auth.action'
// import Validation from '../models/index.model'
// import { STRINGS } from '../utils/strings';
// import TenantInfoAction from '../redux/actions/TenantInfo.action';

class DoctorService extends Base {
  constructor(props) {
    super(props)
  }

  doctorLogin(data) {
    return this.post(DOCTOR_LOGIN_API, data);
  }
  verifyOtp(data) {
    return this.post(DOCTOR_VERIFY_OTP, data);
  }

  getdoctorDetails(data) {
    return this.post(DOCTOR_DETAIL,data);
  }
  getdoctorInfo(data) {
    return this.post(DOCTOR_INFO,data);
  }
  getAllDoctorList(data){
    return this.post(ALL_DOCTOR_LIST_BY_STOCKIST,data);
  }

  getAllDoctorListByPatientId(data){
    return this.post(ALL_DOCTOR_LIST_BY_PATIENT,data);
  }

  bulkUpload(data){
    return this.post(DOCTOR_BULK_UPLOAD,data);
  }
  bulkUploadAddress(data){
    return this.post(DOCTOR_BULK_UPLOAD_ADDRESS,data);
  }
  getdoctorAddress(data){
    return this.post(GET_DOCTOR_ALL_ADDRESS,data);
  }

}

export default new DoctorService()